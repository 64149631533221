import React, { useState } from 'react';
import './UpdatePassword.css';
import hide from '../../assets/show-hide/hide.svg';
import show from '../../assets/show-hide/show.svg';
import { updatePassword } from '../../ApiServices';

function UpdatePassword() {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const togglePasswordVisibility3 = () => {
    setShowPassword3(!showPassword3);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();

  if (newPassword !== confirmPassword) {
    setError("Passwords don't match");
    return;
  }
  if (oldPassword == newPassword) {
    setError("new passeords not same an old password");
    return;
  }

  setError('');
  setSuccess('');

  const data = {
    email: email,
    oldPassword: oldPassword,
    newPassword: newPassword,
  };

  updatePassword(data)
    .then((response) => {
      setSuccess('Password and email updated successfully');
    })
    .catch((error) => {
      setError('Error updating password and email: ' + error.message);
    });
};

  return (
    <>
      <div className='d-flex ms-md-5 ps-md-5 justify-content-center justify-content-md-start input-box1'>
        <div className='col-lg-4 col-xl-3 col-md-6 col-9 mt-5'>
        <div className=''>Update Password</div>
          <form className='mt-4' onSubmit={handleSubmit}>
            
            <label>Email</label>
            <div className='form-group inner-addon right-addon mb-3'>
              <input
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='form-control'
                id='email-field'
                placeholder='Enter Email'
                autoComplete='off'
                type='email'
              />
            </div>
            <label>Old Password</label>
            <div className='form-group inner-addon right-addon mb-3'>
              <div className='input-group col-6 mx-auto'>
                <input
                  name='oldPassword'
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className='form-control pwd-control'
                  id='old-password-field'
                  placeholder='Enter Old Password'
                  autoComplete='off'
                  type={showPassword1 ? 'text' : 'password'}
                />
                <div className='far' onClick={togglePasswordVisibility1}>
                  {showPassword1 ? <img src={show} alt='show' /> : <img src={hide} alt='hide' />}
                </div>
              </div>
            </div>
            <label>New Password</label>
            <div className='form-group inner-addon right-addon mb-3'>
              <div className='input-group col-6 mx-auto'>
                <input
                  name='newPassword'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className='form-control pwd-control'
                  id='new-password-field'
                  placeholder='Enter New Password'
                  autoComplete='off'
                  type={showPassword2 ? 'text' : 'password'}
                />
                <div className='far' onClick={togglePasswordVisibility2}>
                  {showPassword2 ? <img src={show} alt='show' /> : <img src={hide} alt='hide' />}
                </div>
              </div>
            </div>
            <label>Confirm Password</label>
            <div className='form-group inner-addon right-addon mb-3'>
              <div className='input-group col-6 mx-auto'>
                <input
                  name='confirmPassword'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className='form-control pwd-control'
                  id='confirm-password-field'
                  placeholder='Confirm New Password'
                  autoComplete='off'
                  type={showPassword3 ? 'text' : 'password'}
                />
                <div className='far' onClick={togglePasswordVisibility3}>
                  {showPassword3 ? <img src={show} alt='show' /> : <img src={hide} alt='hide' />}
                </div>
              </div>
            </div>
            <div className='mt-2 pb-4 mb-5'>
              <button className='btn btn-primary form-control' type='submit'>
                Submit
              </button>
            </div>
            {error && <div className='text-danger'>{error}</div>}
            {success && <div className='text-success'>{success}</div>}
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdatePassword;
