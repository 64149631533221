import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { PatternFormat } from "react-number-format";
import { NumericFormat } from "react-number-format";
// import './EmployeeCreateForm.css'
import "./CreateEmployee.css";
import back from "../../../../assets/back.png";
import user from "../../../../assets/user.png";
import { Link, useNavigate } from "react-router-dom";

import {
  employeeCreate,
  CheckDuplicateValues,
  empIdCall,
  empDetails,
} from "../../../../ApiServices";
import { ToastContainer, toast } from "react-toastify";
import { toDate } from "date-fns";
import { AxiosResponse, AxiosError } from "axios";
import { Console, log } from "console";
import { clearWaitingQueue } from "react-toastify/dist/core/store";
function CreateEmployee() {
  const navigate = useNavigate();
  const [numFields, setNumFields] = useState(1);
  const [tab, setTab] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [noImage, setNoImage] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [sameAsPresentAddress, setSameAsPresentAddress] = useState(false);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [submitTime, setSubmitTime] = useState(null);
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showEmployeeIdInput, setShowEmployeeIdInput] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>("");
  const [empId, setEmpId] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [statusAction, setStatusAction] = useState(true);
  const [teachingYes, setTeachingYes] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [base64String, setBase64String] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [otherYes, setOtherYes] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [ docDrop , setDocDrop ] = useState(false)
  const [pgDrop, setPgDrop] =  useState (false)

  const [setEmp, autosetEmployee] = useState({
    addressModal: {
      employeeId: "",
      presentAddress: {
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        pincode: "",
        file: "",
      },
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        pincode: "",
        file: "",
      },
      emergencyContact1: {
        name: "",
        relationShip: "",
        email: "",
        mobileNumber: "",
      },
      emergencyContact2: {
        name: "",
        relationShip: "",
        email: "",
        mobileNumber: "",
      },
    },
    empName: "",
    designation: "",
    deptId: "",
    reportingTo: "",
    joinedOn: "",
    empType: "",
    employeeId: "",
    previouslyWorkedInKings: "no",
    status: "",
    teachingExperiences: {
      details: [
        {
          id: 1,
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        },
      ],
    },
    otherExperiences: {
      details: [
        {
          id: 2,
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        },
      ],
    },
    academicDetails: {
      academics: [
        {
          id: "10th",
          yearOfComplete: "",
          board: "",
          mode: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          universit: "",
          specialization: "",
          isItUpdated: "",
          class: "",
          degree: "",
        },
        {
          id: "12th",
          yearOfComplete: "",
          mode: "  ",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          studied: "",
          class: " ",
        },
        {
          id: "UG",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          degree: "",
        },
        {
          id: "PG",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
        },
        {
          id: "OTHER",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
        },
      ],
    },
    otherAcademics: {
      academics: [
        {
          id: "",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          universit: "",
          specialization: "",
          isItUpdated: "",
          class: "",
          state: "",
          city: "",
          degree: "",
          markSheetFile: "",
          courseCompletionCertificate: "",
        },
      ],
    },
    holders: {
      persistence: [
        {
          id: "",
          key: "",
          value: "",
        },
      ],
    },
    dob: "",
    age: "",
    gender: "",
    bloodGroup: "",
    religion: "",
    community: "",
    nationality: "",
    aadhaarNumber: "",
    martialStatus: "",
    typeOfIdProof: "",
    idProof: "",
    email: "",
    mobileNumber: "",
    empStatus: "",
    updatedOn: "",
    createdOn: "",
    image: "",
    imageName: "",
    imageType: "",
    updatedBy: "",
  });


  const handleCurrentWorkChange = (e:ChangeEvent<HTMLInputElement>,index:any, isCurrent:any) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");
    const updatedDetails = employeeIdForm.teachingExperiences.details.map((detail, i) => {
      if (i === index) {
        return {
          ...detail,
          toDate: isCurrent ? currentDate : '',
          institution: isCurrent ? 'Kings Engineering College' : '',
          isCurrentWork: isCurrent,
        };
      }
      return detail;
    });
  
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      teachingExperiences: {
        ...prevState.teachingExperiences,
        details: updatedDetails,
      },
      previouslyWorkedInKings:trimmedValue
    }));
  }
  useEffect(() => {
    console.log(base64String, "am the selected fileeeeeeeeeeeeeeeeeeeee");
  }, [base64String]);

  const pageHead = {
    title: "New Employee",
    subtitle: "Create a new employee profile",
    backPagetitle: "Page Title",
  };

  interface CreateEmp {
    id: number;
    institution: string;
    designation: string;
    previouslyWorkedInKings1: string;
    department: string;
    fromDate: Date;
    toDate: Date;
    experience: any;
    file: any;
    isItUpdated: boolean;
  }
  interface TeachingExpProps {
    id: number;
    institution: string;
    designation: string;
    fromDate: string;
    toDate: string;
    experience: any;
    file: string;
    isItUpdated: string;
    previouslyWorkedInKings: string;
    // experience: any ;
  }

  type OtherExperience = {
    id: string;  
    previouslyWorkedInKings1: string;
    institution: string;
    designation: string;
    pvskings: string;
    fromDate: string;
    department: string;
    category: string;
    toDate: string;
    experience: string;
    file: string;
    isItUpdated: string;
  };
  const [employeeIdForm, setEmployeeIdForm] = useState({
    addressModal: {
      employeeId: "",
      presentAddress: {
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        pincode: "",
        file: "",
      },
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        pincode: "",
        file: "",
      },
      emergencyContact1: {
        name: "",
        relationShip: "",
        email: "",
        mobileNumber: "",
      },
      emergencyContact2: {
        name: "",
        relationShip: "",
        email: "",
        mobileNumber: "",
      },
    },
    empName: "",
    employeeNo: "",
    designation: "",
    deptId: "",
    reportingTo: "",
    joinedOn: "",
    empType: "",
    employeeId: "",
    previouslyWorkedInKings: "no",
    teachExp: "no",
    otherExp: "no",
    status: "",
    teachingExperiences: {
      details: [
        {
          id: 1,
          institution: "",
          previouslyWorkedInKings: "",
          designation: "",
          department: "",
          pvskings: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        },
      ],
    },
    otherExperiences: {
      details: [
        {
          id: "",
          previouslyWorkedInKings1: "",
          institution: "",
          designation: "",
          pvskings: "",
          fromDate: "",
          department: "",
          category: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        },
      ],
    },
    teachingExperiences1: {
      details: [
        {
          id: 1,
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        },
      ],
    },
    otherExperiences1: {
      details: [
        {
          id: "",
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        },
      ],
    },
    otherAcademics: {
      academics: [
        {
          id: "",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "",
          class: "",
          state: "",
          city: "",
          degree: "",
          markSheetFile: "",
          courseCompletionCertificate: "",
        },
      ],
    },

    academicDetails: {
      academics: [
        {
          id: "10th",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "",
          class: "",
          state: "",
          city: "",
          markSheetFile: "",
        },
        {
          id: "12th",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          class: " ",
          state: "",
          city: "",
          markSheetFile: "",
        },
        {
          id: "UG",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          degree: "",
          class: "",
          state: "",
          city: "",
          markSheetFile: "",
          courseCompletionCertificate: "",
        },
        {
          id: "PG",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          class: "",
          state: "",
          city: "",
          markSheetFile: "",
          courseCompletionCertificate: "",
        },
        {
          id: "Ph.D",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          class: "",
          state: "",
          city: "",
          remarks:"",
          markSheetFile: "",
          courseCompletionCertificate: "",
        },
        {
          id: "OTHER",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          state: "",
          city: "",
        },
        {
          id: "Diploma",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board: "",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          state: "",
          city: "",
          markSheetFile: "",
        },
      ],
    },
    holders: {
      persistence: [
        {
          id: "",
          key: "",
          value: "",
        },
      ],
    },
    program:"",
    dob: "",
    age: "",
    gender: "",
    bloodGroup: "",
    religion: "",
    community: "",
    nationality: "",
    aadhaarNumber: "",
    martialStatus: "",
    typeOfIdProof: "",
    email: "",
    mobileNumber: "",
    empStatus: "",
    updatedOn: "",
    createdOn: "",
    image: "",
    imageName: "",
    imageType: "",
    updatedBy: "",
  });
  // const [te, sette] = useState<TeachingExpProps[]>( [
  //     {
  //       id:2,
  //       institution: "",
  //       designation: "",
  //       fromDate: "",
  //       toDate: "",
  //       experience: "",
  //       file: "",
  //       isItUpdated: "",
  //     }
  //   ],
  // )
  const [employeeBasicForm, setEmployeeBasicForm] = useState({
    type: "",
    kectype: "",
    joinedOn: "",
    empName: "",
    empid: "",
    cgdate: "",
    designation: "",
    designation1: "",
    designation2: "",
    designation3: "",
    departmentId: "",
    reportingTo: "",
    yrsOfExp: "",
    yrsOfExp1: "",
    role: "",
    mypassing: "",
    mypassing1: "",
    mypassing2: "",
    mypassing3: "",
    mypassing4: "",
    mode: "",
    mode1: "",
    mode2: "",
    mode3: "",
    mode4: "",
    class: "",
    class1: "",
    class2: "",
    class3: "",
    class4: "",
    cgpa: "",
    cgpa1: "",
    cgpa2: "",
    cgpa3: "",
    cgpa4: "",
    institution: "",
    institution1: "",
    institution2: "",
    institution3: "",
    institution4: "",
    institution5: "",
    institution6: "",
    university: "",
    university1: "",
    university2: "",
    university3: "",
    university4: "",
    specialization: "",
    degree: "",
    degree1: "",
    degree2: "",
    mstatus: "",
    studied: "",
    fromdate: "",
    fromdate1: "",
    todate: "",
    todate1: "",
    expinyr: "",
    expinyr1: "",
    dob: "",
    age: "",
    gender: "",
    bgroup: "",
    religion: "",
    community: "",
    nation: "",
    ano: "",
    idproof: "",
    adress1: "",
    addressline1: "",
    adress2: "",
    addressline2: "",
    email: "",
    email1: "",
    email2: "",
    phoneNumber: "",
    phoneNumber1: "",
    phoneNumber2: "",
    state: "",
    state1: "",
    city: "",
    city1: "",
    pin: "",
    pin1: "",
    name1: "",
    name2: "",
    relation1: "",
    relation2: "",
  });
    
  const handletoDate = (index: any) => {
    setEmployeeIdForm((prevState) => {
      const updatedTeachingExperiences = [
        ...prevState.teachingExperiences.details,
      ];
      updatedTeachingExperiences[index] = {
        ...updatedTeachingExperiences[index],
        toDate: "",
      };

      return {
        ...prevState,
        teachingExperiences: {
          ...prevState.teachingExperiences,
          details: updatedTeachingExperiences,
        },
      };
    });
  };

  console.log(employeeIdForm, "dsjhksdfjsdfjlkd");
  const MAX_LIMIT = 101;

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log("File input change event:", e);
    const file = e.target.files && e.target.files[0];
    console.log(name, "name");
  };

  const prevTab = () => {
    setActiveIndex((prevIndex) => prevIndex - 1);
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setEmployeeIdForm((prevForm) => ({
      ...prevForm,
      addressModal: {
        ...prevForm.addressModal,
        presentAddress: {
          ...prevForm.addressModal.presentAddress,
          [name]: value,
        },
      },
    }));
  };
  
  const parseExperience = (experience: string | null | undefined): string => {
    if (!experience) {
      return "0.0";
    }
  
    const yearsMatch = experience.match(/(\d+)\s*year\(s?\)/);
    const monthsMatch = experience.match(/(\d+)\s*month\(s?\)/);
  
    const years = yearsMatch ? parseInt(yearsMatch[1], 10) : 0;
    const months = monthsMatch ? parseInt(monthsMatch[1], 10) : 0;
  
    return `${years}.${months < 10 ? '0' : ''}${months}`; 
  };
  
  const calculateTotalExperience = (): string => {
    let totalMonths = 0;
  
    employeeIdForm.teachingExperiences.details.forEach((item: TeachingExpProps) => {
      const experience = item.experience || '0.0'; 
      const [years, months] = experience.split('.').map(Number);
      totalMonths += years * 12 + months;
    });
  
    const totalYears = Math.floor(totalMonths / 12);
    const remainingMonths = totalMonths % 12;

    return `${totalYears}.${remainingMonths}`;
  };
  
  
  
  const parseExperiences = (experience: string): number => {
    const yearsMatch = experience.match(/(\d+)\s*year\(s?\)/);
    const monthsMatch = experience.match(/(\d+)\s*month\(s?\)/);
  
    const years = yearsMatch ? parseInt(yearsMatch[1], 10) : 0;
    const months = monthsMatch ? parseInt(monthsMatch[1], 10) : 0;
  
    return years + months / 12;
  };
  

  const calculateTotalOther = (): string => {
    let totalMonths = 0;
  
    employeeIdForm.otherExperiences.details.forEach((item: OtherExperience) => {
      const experience = item.experience || '0.0'; 
      const [years, months] = experience.split('.').map(Number);
      totalMonths += years * 12 + months;
    });
  
    const totalYears = Math.floor(totalMonths / 12);
    const remainingMonths = totalMonths % 12;
    return `${totalYears}.${remainingMonths}`;
  };
  
  const handleChange0 = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setEmployeeIdForm((prevForm) => ({
      ...prevForm,
      addressModal: {
        ...prevForm.addressModal,
        permanentAddress: {
          ...prevForm.addressModal.permanentAddress,
          [name]: value,
        },
      },
    }));
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
  };
  const nextTab = () => {
    setActiveIndex((prevIndex) => prevIndex + 1);
  };

  const [addMore, setAddMore] = useState<CreateEmp>({
    id: 1,
    institution: "",
    designation: "",
    previouslyWorkedInKings1: "",
    department: "",
    fromDate: new Date(),
    toDate: new Date(),
    experience: "2",
    file: "",
    isItUpdated: false,
  });

  console.log(imageUrl);

  const handleStateCreateEmp = (value: keyof CreateEmp, name: any) => {
    setAddMore({
      ...addMore,
      [value]: name,
    });
  };

  const addMoreFields = () => {
    const newObj = {
      id: 2,
      institution: "",
      previouslyWorkedInKings: "",
      designation: "",
      department: "",
      pvskings: "",
      fromDate: "",
      toDate: "",
      experience: "",
      file: "",
      isItUpdated: "",
    };
    setEmployeeIdForm({
      ...employeeIdForm,
      teachingExperiences: {
        ...employeeIdForm.teachingExperiences,
        details: [...employeeIdForm.teachingExperiences.details, newObj],
      },
    });
  };

  const addMoreTypes = () => {
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      holders: {
        ...prevState.holders,
        persistence: [
          ...prevState.holders.persistence,
          {
            id: "",
            key: "",
            value: "",
          },
        ],
      },
    }));
  };
  const handleAddMore = () => {
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      otherExperiences: {
        details: [
          ...prevState.otherExperiences.details,
          {
            id: "",
            experience: "",
            institution: "",
            previouslyWorkedInKings1: "",
            pvskings: "",
            designation: "",
            department: "",
            category: "",
            fromDate: "",
            toDate: "",
            file: "",
            isItUpdated: "",
          },
        ],
      },
    }));
  };
  console.log(employeeIdForm, "empIforn");
  // const addMoreProgram = () => {
  //   const newObj ={
  //     count:2,
  //     id:"",
  //     degree: "",
  //     yearOfComplete: "",
  //     mode: "",
  //     classOfComplete: "",
  //     cgpa: "",
  //     institutionName: "",
  //     university: "",
  //     specialization: "",
  //     isItUpdated: "",
  //   };
  //   setEmployeeIdForm({
  //     ...employeeIdForm,
  //     academicDetails: {
  //       ...employeeIdForm.academicDetails,
  //       academics: [...employeeIdForm.academicDetails.academics, newObj]
  //     }
  //   });
  // };

  // const handleNextButtonClick = () => {
  //   let hasEmptyFields = false;

  //   switch (activeIndex) {
  //     case 0:
  //       // hasEmptyFields = validatePage1Fields(employeeIdForm, setEmp);
  //       break;
  //     case 1:
  //       // hasEmptyFields = validatePage2Fields();
  //       break;

  //     case 2:
  //       if (teachingYes) {
  //         hasEmptyFields = validatePage3Fields();
  //       }
  //       break;

  //     case 3:
  //       // hasEmptyFields = validatePage3Fields();
  //       break;

  //     case 2:
  //       if (otherYes) {
  //         hasEmptyFields = validatePage5Fields();
  //       }
  //       break;

  //     case 3:
  //       hasEmptyFields = validatePage4Fields();

  //       break;

  //     default:
  //       break;
  //   }

  //   if (hasEmptyFields) {
  //     setNextButtonClicked(true);
  //   } else {
  //     setActiveIndex(activeIndex + 1);
  //     setNextButtonClicked(false);
  //   }
  // };
  const handleNextButtonClick = () => {
    let hasEmptyFields = false;
  
    switch (activeIndex) {
      case 0:
         hasEmptyFields = validatePage1Fields(employeeIdForm, setEmp);
        break;
      case 1:
        // hasEmptyFields = validatePage2Fields();
        break; 
      case 2: 
      if (employeeIdForm.teachExp !== 'no') {
        hasEmptyFields = validatePage3Fields();
      }
      if (employeeIdForm.otherExp !== 'no') {
        hasEmptyFields = validatePage5Fields(); 
      }
        break; 
      case 3:
        hasEmptyFields = validatePage4Fields();
        break;
      default:
        break;
    }
  
    if (hasEmptyFields) {
      setNextButtonClicked(true);
    } else {
      setActiveIndex(activeIndex + 1);
      setNextButtonClicked(false);
    }
  };
  interface EmployeeFormData {
    deptId: string;
    designation: string;
    joinedOn: string;
    employeeId: string;
    empName: string;
    empType: string;
    empStatus: string;
    email: string;
    previouslyWorkedInKings: string;
    dob: string;
    gender: string;
    aadhaarNumber: string;
    mobileNumber: string;
    
  }

  const handlechangeOtherExperience = (e: any) => {
    const { name, value } = e.target;
    const files = e.target.files;
    const file = files[0];
    if (file?.size > 2 * 1024 * 1024) {
      toast.error(
        "File size exceeds 2 MB limit. Please upload a smaller file."
      );
    } else {
      if (file?.type !== "application/pdf") {
        toast.error(
          "File format not supported. Please Try uploading pdf files"
        );
        return;
      } else {
        // getBase64(file,e);
        // handleChange(e);
      }
    }
    // const trimmedValue = value.replace(/^\s+/,"");
    // const updatedte = [...employeeIdForm.teachingExperiences.otherExperiences.details];
    // updatedte[0] = {
    //   ...updatedte[0],
    //   [name]:trimmedValue,
    // };

    // setEmployeeIdForm(prevState => {
    //   const updatedTeachingExperiences = { ...prevState.teachingExperiences };
    //   if (updatedTeachingExperiences.otherExperiences) {
    //     updatedTeachingExperiences.otherExperiences.details = updatedte;
    //   }
    //   return {
    //     ...prevState,
    //     teachingExperiences: updatedTeachingExperiences,
    //   };
    // });
  };
  const [experience, setExperience] = useState({
    id: "",
    institution: "",
    designation: "",
    previouslyWorkedInKings1: "",
    fromDate: "",
    degree: "",
    toDate: "",
    experience: "",
    file: "",
    isItUpdated: "",
    category: "",
  });
  // const calculateotherExperience = (fromDate: Date, toDate: Date): string => {
  //   const diffInYears = toDate.getFullYear() - fromDate.getFullYear();
  //   const isBeforeAnniversary = (toDate.getMonth() < fromDate.getMonth()) || 
  //                               (toDate.getMonth() === fromDate.getMonth() && toDate.getDate() < fromDate.getDate());
  //   return (isBeforeAnniversary ? diffInYears - 1 : diffInYears).toString();
  // };
  const calculateotherExperience = (fromDate: Date, toDate: Date): string => {
    const diffInMs = toDate.getTime() - fromDate.getTime();
    
    const diffInMonths = diffInMs / (1000 * 60 * 60 * 24 * 30.44); 
  
    const years = Math.floor(diffInMonths / 12);
    const months = Math.floor(diffInMonths % 12);
  
    return `${years}.${months}`; 
  }
  
  const handlechangeOtherExperiences = (
    e: any,
    index: number,
    val?: string,
    id?: any
  ) => {
    const { name, value } = e.target;
    const files = e.target.files;
  
    let processedValue = value.replace(/^\s+/, '');
  
    if (name !== 'fileInputName') {
      if (processedValue.length > 0) {
        const words = processedValue.split(' ');
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
        }
        processedValue = words.join(' ');
      }
    }
  
    const getBase64 = (
      file: any,
      onLoad: (result: string | ArrayBuffer | null) => void
    ) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        onLoad(reader.result);
      };
    };
  
    const onLoad = (fileString: string | ArrayBuffer | null) => {
      console.log(fileString);
      const updatedDetails = [...employeeIdForm.otherExperiences.details];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: fileString,
      };
      setEmployeeIdForm({
        ...employeeIdForm,
        otherExperiences: {
          ...employeeIdForm.otherExperiences,
          details: updatedDetails,
        },
      });
    };
  
    if (id) {
      if (files && files.length > 0) {
        const file = files[0];
        if (file.size > 2 * 1024 * 1024) {
          toast.error("File size exceeds 2 MB limit. Please upload a smaller file.");
        } else {
          if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
            toast.error("File format not supported. Please try uploading PDF or JPG files.");
            return;
          } else {
            getBase64(file, onLoad);
          }
        }
      }
    } else {
      setEmployeeIdForm((prevState) => {
        const updatedDetails = prevState.otherExperiences.details.map((detail, i) => {
          if (i === index) {
            const updatedDetail = {
              ...detail,
              [name]: val ? val : processedValue,
            };
            
            if (name === "fromDate") {
              updatedDetail.toDate = "";
            }
  
            if (name === "toDate" || name === "fromDate") {
              const fromDate = new Date(updatedDetail.fromDate);
              const toDate = new Date(updatedDetail.toDate);
              if (fromDate && toDate && !isNaN(fromDate.getTime()) && !isNaN(toDate.getTime())) {
                updatedDetail.experience = calculateotherExperience(fromDate, toDate);
              }
            }
  
            return updatedDetail;
          }
          return detail;
        });
  
        return {
          ...prevState,
          otherExperiences: {
            ...prevState.otherExperiences,
            details: updatedDetails,
          },
        };
      });
    }
  };  
  
  


  const idProofFunc = (e: any, index: number, val?: string, id?: any) => {
    const { name, value } = e.target;
    const files = e.target.files;
    if (id) {
      const getBase64 = (
        file: any,
        onLoad: (result: string | ArrayBuffer | null) => void
      ) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onLoad(reader.result);
        };
      };
      const onLoad = (fileString: string | ArrayBuffer | null) => {
        console.log(fileString);

        setEmployeeIdForm((prevState) => {
          const updatedDetails = prevState.holders.persistence.map(
            (detail, i) => {
              if (i === index) {
                return {
                  ...detail,
                  [name]: fileString,
                };
              }
              return detail;
            }
          );
          return {
            ...prevState,
            holders: {
              ...prevState.holders,
              persistence: updatedDetails,
            },
          };
        });
      };
      if (files && files.length > 0) {
        const file = files[0];
        if (file.size > 2 * 1024 * 1024) {
          toast.error(
            "File size exceeds 2 MB limit. Please upload a smaller file."
          );
        } else {
          if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
            toast.error(
              "File format not supported. Please try uploading PDF or JPG files."
            );
            return;
          } else {
            getBase64(file, onLoad);
          }
        }
      }
    } else {
      setEmployeeIdForm((prevState) => {
        const updatedDetails = prevState.holders.persistence.map(
          (detail, i) => {
            if (i === index) {
              return {
                ...detail,
                [name]: value,
              };
            }
            return detail;
          }
        );

        return {
          ...prevState,
          holders: {
            ...prevState.holders,
            persistence: updatedDetails,
          },
        };
      });
    }
  };
  // const validatePage1Fields = () => {
  //   const {
  //     deptId,
  //     designation,
  //     joinedOn,
  //     employeeId,
  //     empName,
  //     empType,
  //     empStatus,
  //     previouslyWorkedInKings,
  //   } = employeeIdForm;
  //   return (
  //     !deptId ||
  //     !designation ||
  //     !joinedOn ||
  //     !employeeId ||
  //     !empName ||
  //     !empType ||
  //     !empStatus ||
  //     !previouslyWorkedInKings
  //   );
  // };
  const onlyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;     
    if (!/^[0-9]$/.test(key)) {
      e.preventDefault();
    }
  };
  const validatePage1Fields = (
    employeeIdForm: EmployeeFormData,
    setEmp: EmployeeFormData
  ) => {
    const {
      deptId,
      designation,
      joinedOn,
      employeeId,
      empName,
      empType,
      email,
      mobileNumber,
    } = employeeIdForm;
    const {
      deptId: empDeptId,
      designation: empDesignation,
      joinedOn: empJoinedOn,
      employeeId: empEmployeeId,
      empName: empEmpName,
      empType: empEmpType,
      email: empEmpemail,
      mobileNumber: empEmpmobileNumber,
    } = setEmp;

    const isDeptIdValid = !!deptId || !!empDeptId;
    const isDesignationValid = !!designation || !!empDesignation;
    const isJoinedOnValid = !!joinedOn || !!empJoinedOn;
    const isEmpNameValid = !!empName || !!empEmpName;
    const isEmpTypeValid = !!empType || !!empEmpType;
    const isEmpEmailValid = isValidEmail(email) || isValidEmail(empEmpemail);
    const IsEmpMobileNumberValid =
    (!!mobileNumber && mobileNumber.length === 10) ||
    (!!empEmpmobileNumber && empEmpmobileNumber.length === 10);
    // const isEmpStatusValid = !!empStatus || !!empEmpStatus;
    // const isPreviouslyWorkedInKingsValid = !!previouslyWorkedInKings || !!empPreviouslyWorkedInKings;

    return !(
      isDeptIdValid &&
      isDesignationValid &&
      isJoinedOnValid &&
      isEmpNameValid &&
      isEmpTypeValid &&
      isEmpEmailValid &&
      IsEmpMobileNumberValid 
      // isDuplicateEmail 
    );
  };
  
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePage2Fields = () => {
    const academicDetails = employeeIdForm.academicDetails;

    const hasEmptyFields = academicDetails.academics.some((detail: any) => {
      return (
        academicDetails.academics[0].yearOfComplete === "" ||
        //  academicDetails.academics[0].mode === "" ||
        academicDetails.academics[0].cgpa === "" ||
        academicDetails.academics[0].institutionName === "" ||
        academicDetails.academics[0].state === "" ||
        academicDetails.academics[0].board === "" ||
        academicDetails.academics[0].city === "" ||
        // academicDetails.academics[1].classOfComplete === "" ||
        // academicDetails.academics[1].mode === "" ||
        // academicDetails.academics[1].cgpa === "" ||
        // academicDetails.academics[1].state === "" ||
        // academicDetails.academics[1].board === "" ||
        // academicDetails.academics[1].city === "" ||
        // academicDetails.academics[2].degree === "" ||
        // academicDetails.academics[2].specialization === "" ||
        academicDetails.academics[2].yearOfComplete === "" ||
        academicDetails.academics[2].mode === "" ||
        academicDetails.academics[2].classOfComplete === "" ||
        academicDetails.academics[2].cgpa === "" ||
        academicDetails.academics[2].institutionName === "" ||
        academicDetails.academics[2].city === "" ||
        //  academicDetails.academics[2].university === ""
        // academicDetails.academics[3].degree === ""
        academicDetails.academics[3]?.classOfComplete === "" ||
        academicDetails.academics[3]?.yearOfComplete === "" ||
        academicDetails.academics[3]?.mode === "" ||
        academicDetails.academics[3]?.cgpa === "" ||
        academicDetails.academics[3].state === "" ||
        academicDetails.academics[3]?.classOfComplete === "" ||
        academicDetails.academics[3]?.institutionName === ""
        // academicDetails.academics[4].classOfComplete === "" ||
        // academicDetails.academics[4].specialization === "" ||
        // academicDetails.academics[4].yearOfComplete === "" ||
        // academicDetails.academics[4].mode === "" ||
        // academicDetails.academics[4].cgpa === "" ||
        // academicDetails.academics[4].state === "" ||
        // academicDetails.academics[4].institutionName === ""
        // academicDetails.academics[4].cgpa === "" ||
        // academicDetails.academics[4].specialization === "" ||
        // academicDetails.academics[4].university === ""
      );
    });
    console.log("Class:", academicDetails.academics[1].class);
    return hasEmptyFields;
  };
  // const validatePage4Fields = () => {
  //   // dob, gender,
  //   const {  aadhaarNumber } = employeeIdForm;
  //   // return!dob || !gender || !aadhaarNumber ;
  //   // !dob || !gender ||
  //   return  !aadhaarNumber;
  // };
  const validatePage4Fields = () => {
    const { aadhaarNumber } = employeeIdForm;
    return !aadhaarNumber || aadhaarNumber.replace(/\D/g, '').length !== 12;
  };
  
  const validatePage5Fields = () => {
    const { details } = employeeIdForm.otherExperiences;

    const hasEmptyOtherFields = details.some((experience) => {
      return (
        !experience.institution ||
        !experience.designation ||
        !experience.department ||
        !experience.fromDate ||
        !experience.toDate ||
        !experience.experience
      );
    });

    return hasEmptyOtherFields;
  };
  const validatePage3Fields = () => {
    const { details } = employeeIdForm.teachingExperiences;
    // const { otherExperiences } = employeeIdForm.teachingExperiences;

    const hasEmptyTeachingFields = details.some((detail: any) => {
      return (
        !detail.institution ||
        !detail.designation ||
        !detail.fromDate ||
        !detail.toDate ||
        !detail.department ||
        // !detail.file ||
        !detail.experience
      );
    });

    // const hasEmptyOtherFields = otherExperiences.details.some((experience: any) => {
    //   return (
    //     !experience.institution ||
    //     !experience.designation ||
    //     !experience.department ||
    //     !experience.fromDate ||
    //     !experience.toDate ||
    //     // !experience.file ||
    //     !experience.experience
    //   );
    // });

    return hasEmptyTeachingFields;
  };

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png"];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64Image = reader.result as string;
        setImageUrl(base64Image);
        setNoImage(false);
        // Update employeeIdForm state
        setEmployeeIdForm((prevState) => ({
          ...prevState,
          image: base64Image.split(",")[1],
          imageBase64: base64Image.split(",")[1],
        }));
      };
    } else {
      toast.error("Please select a valid image file (JPEG, PNG).");
      event.target.value = ""; // Reset the input field
    }
  };
  // const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

  //   if (file && allowedTypes.includes(file.type)) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const imageData = e.target?.result;
  //       setImageUrl(imageData as string);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     alert('Please select a valid image file (JPEG, PNG, GIF).');
  //     event.target.value = '';
  //   }
  // };

  const removeImage = () => {
    setImageUrl("");
    setNoImage(true);
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; 
    }
  };
  const [sameAsPresent, setSameAsPresent] = useState(false);

  const handleSameAsPresentClick = () => {
    setSameAsPresent(!sameAsPresent);

    if (!sameAsPresent) {
      setEmployeeIdForm({
        ...employeeIdForm,
        addressModal: {
          ...employeeIdForm.addressModal,
          permanentAddress: { ...employeeIdForm.addressModal.presentAddress },
        },
      });
    } else {
      setEmployeeIdForm({
        ...employeeIdForm,
        addressModal: {
          ...employeeIdForm.addressModal,
          permanentAddress: {
            addressLine1: "",
            addressLine2: "",
            state: "",
            city: "",
            pincode: "",
            file: "",
          },
        },
      });
    }
  };
  const handleChanges = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
  
    let processedValue = value.replace(/^\s+/, '');
  
    if (processedValue.length > 0) {
      const words = processedValue.split(' ');
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      }
      processedValue = words.join(' ');
    }
  
    setEmployeeIdForm((prevFormState) => ({
      ...prevFormState,
      addressModal: {
        ...prevFormState.addressModal,
        presentAddress: {
          ...prevFormState.addressModal.presentAddress,
          [name]: processedValue,
        },
      },
    }));
  };
  
  const handleChanges1 = (event: any, id?: any) => {
    const { name, value } = event.target;
    const files = event.target.files;
  
    if (id) {
      const getBase64 = (file: any, onLoad: (result: string | ArrayBuffer | null) => void) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onLoad(reader.result);
        };
      };
  
      const onLoad = (fileString: string | ArrayBuffer | null) => {
        console.log(fileString, "base64check");
        setEmployeeIdForm((prevState) => ({
          ...prevState,
          addressModal: {
            ...prevState.addressModal,
            permanentAddress: {
              ...prevState.addressModal.permanentAddress,
              [name]: fileString,
            },
          },
        }));
      };
  
      if (files && files.length > 0) {
        const file = files[0];
        if (file.size > 2 * 1024 * 1024) {
          toast.error("File size exceeds 2 MB limit. Please upload a smaller file.");
        } else {
          if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
            toast.error("File format not supported. Please try uploading PDF or JPG files.");
          } else {
            getBase64(file, onLoad);
          }
        }
      }
    } else {
      const processedValue = value
        .replace(/^\s+/, '')
        .split(' ')
        .map((word: string) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
  
      setEmployeeIdForm((prevState) => ({
        ...prevState,
        addressModal: {
          ...prevState.addressModal,
          permanentAddress: {
            ...prevState.addressModal.permanentAddress,
            [name]: processedValue,
          },
        },
      }));
    }
  };
  
  
  const handleChanges2 = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const trimmedValue = value.replace(/^\s+/, "");
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      addressModal: {
        ...prevState.addressModal,
        emergencyContact1: {
          ...prevState.addressModal.emergencyContact1,
          [name]: trimmedValue,
        },
      },
    }));
  };
  const handleChanges3 = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const trimmedValue = value.replace(/^\s+/, "");
    console.log();
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      addressModal: {
        ...prevState.addressModal,
        emergencyContact2: {
          ...prevState.addressModal.emergencyContact2,
          [name]: trimmedValue,
        },
      },
    }));
  };

  //   const handletoDate = (e:any) => {
  //     employeeIdForm.details(prevState => ({
  //         ...prevState,
  //         memberTill: ''
  //     }));
  // };

  const handleChanges4 = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const trimmedValue = value.replace(/^\s+/, "");
    setEmployeeIdForm((prevState) => {
      const updatedDetails = [...prevState.teachingExperiences.details];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: trimmedValue,
      };
      return {
        ...prevState,
        teachingExperiences: {
          ...prevState.teachingExperiences,
          details: updatedDetails,
        },
      };
    });
  };

  const calculateExperience = (fromDate: Date, toDate: Date): string => {
    const diffInMs = toDate.getTime() - fromDate.getTime();
    
    const diffInMonths = diffInMs / (1000 * 60 * 60 * 24 * 30.44); 
  
    const years = Math.floor(diffInMonths / 12);
    const months = Math.floor(diffInMonths % 12);
  
    return `${years}.${months}`;
  };
  
const handletechange = (index: number, e: any, val?: string, id?: any) => {
  const { name, value } = e.target;
  const files = e.target.files;

  let processedValue = value.replace(/^\s+/, '');

  if (name !== 'fileInputName') {
    if (processedValue.length > 0) {
      const words = processedValue.split(' ');
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      }
      processedValue = words.join(' ');
    }
  }

  if (id) {
    const getBase64 = (
      file: any,
      onLoad: (result: string | ArrayBuffer | null) => void
    ) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        onLoad(reader.result);
      };
    };

    const onLoad = (fileString: string | ArrayBuffer | null) => {
      const updatedte = [...employeeIdForm.teachingExperiences.details];
      updatedte[index] = {
        ...updatedte[index],
        [name]: fileString,
      };
    
      setEmployeeIdForm({
        ...employeeIdForm,
        teachingExperiences: {
          ...employeeIdForm.teachingExperiences,
          details: updatedte,
        },
      });
    };

    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        toast.error(
          "File size exceeds 2 MB limit. Please upload a smaller file."
        );
      } else {
        if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
          toast.error(
            "File format not supported. Please try uploading PDF or JPG files."
          );
          return;
        } else {
          getBase64(file, onLoad);
        }
      }
    }
  } else {
    const updatedte = [...employeeIdForm.teachingExperiences.details];
    updatedte[index] = {
      ...updatedte[index],
      [name]: val ? val : processedValue,
    };

    if (name === "fromDate" && employeeIdForm.teachingExperiences.details[index]
                          ?.institution !== "Kings Engineering College" 
      ) {
      updatedte[index].toDate = "";
    }

    // Calculate experience if fromDate or toDate changes
    if (name === "toDate" || name === "fromDate") {
      const fromDate = new Date(updatedte[index].fromDate);
      const toDate = new Date(updatedte[index].toDate);
      if (fromDate && toDate && !isNaN(fromDate.getTime()) && !isNaN(toDate.getTime())) {
        const experience = calculateExperience(fromDate, toDate);
        updatedte[index].experience = experience;
      }
    }

    setEmployeeIdForm({
      ...employeeIdForm,
      teachingExperiences: {
        ...employeeIdForm.teachingExperiences,
        details: updatedte,
      },
    });
  }
};
  
  const handleChange5 = (event: any, index: number, val?: string, id?: any) => {
    const { name, value } = event.target;
    const trimmedValue = value.replace(/^\s+/, "");
  
    let processedValue = '';
    if (name === "cgpa") {
      if (trimmedValue === "" || isNaN(parseFloat(trimmedValue))) {
        processedValue = "";  
      } else {
        processedValue = Math.min(100, parseFloat(trimmedValue)).toString();
      }
    } else {
      processedValue = (name === 'board' || name === 'degree'
        ? trimmedValue
        : trimmedValue
            .split(' ')
            .map((word: any) =>
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' '));
    }  
  
  
    const files = event.target.files;
  
    if (id) {
      const getBase64 = (
        file: any,
        onLoad: (result: string | ArrayBuffer | null) => void
      ) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onLoad(reader.result);
        };
      };
  
      const onLoad = (fileString: string | ArrayBuffer | null) => {
        console.log(fileString, "filess");
  
        setEmployeeIdForm((prevState) => ({
          ...prevState,
          academicDetails: {
            ...prevState.academicDetails,
            academics: prevState.academicDetails.academics.map(
              (academic, i) => {
                if (i === index) {
                  return {
                    ...academic,
                    [name]: fileString,
                  };
                }
                return academic;
              }
            ),
          },
        }));
      };
  
      if (files && files.length > 0) {
        const file = files[0];
        if (file.size > 2 * 1024 * 1024) {
          toast.error("File size exceeds 2 MB limit. Please upload a smaller file.");
        } else {
          if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
            toast.error("File format not supported. Please try uploading PDF or JPG files.");
            return;
          } else {
            getBase64(file, onLoad);
          }
        }
      }
    } else {
      setEmployeeIdForm((prevState) => ({
        ...prevState,
        academicDetails: {
          ...prevState.academicDetails,
          academics: prevState.academicDetails.academics.map((academic, i) => {
            if (i === index) {
              return {
                ...academic,
                [name]: processedValue, 

              };
            }
            return academic;
          }),
        },
      }));
    }
  };
  const handleChangeOtherAcademics = (
    index: number,
    e: any,
    val?: string,
    id?: any
  ) => {
    const { name, value } = e.target;
    const files = e.target.files;
    const trimmedValue = value.replace(/^\s+/, "");
    let processedValue = '';
    processedValue = name === "cgpa" 
    ? Math.min(100, isNaN(parseFloat(trimmedValue)) ? 0 : parseFloat(trimmedValue)) 
    : trimmedValue
        .split(' ')
        .map((word:any) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
    
    if (name !== 'fileInputName') { 
      if (processedValue.length > 0) {
        const words = processedValue.split(' ');
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
        }
        processedValue = words.join(' ');
      }
    }
  
    if (id) {
      const getBase64 = (
        file: any,
        onLoad: (result: string | ArrayBuffer | null) => void
      ) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onLoad(reader.result);
        };
      };
  
      const onLoad = (fileString: string | ArrayBuffer | null) => {
        const updatedAcademics = [...employeeIdForm.otherAcademics?.academics];
        updatedAcademics[index] = {
          ...updatedAcademics[index],
          [name]: fileString,
        };
        setEmployeeIdForm({
          ...employeeIdForm,
          otherAcademics: {
            ...employeeIdForm.otherAcademics,
            academics: updatedAcademics,
          },
        });
      };
  
      if (files && files.length > 0) {
        const file = files[0];
        if (file.size > 2 * 1024 * 1024) {
          toast.error(
            "File size exceeds 2 MB limit. Please upload a smaller file."
          );
        } else {
          if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
            toast.error(
              "File format not supported. Please try uploading PDF or JPG files."
            );
            return;
          } else {
            getBase64(file, onLoad);
          }
        }
      }
    } else {
      const updatedAcademics = [...employeeIdForm.otherAcademics.academics];
      updatedAcademics[index] = {
        ...updatedAcademics[index],
        [name]: val ? val : processedValue, 
      };
      setEmployeeIdForm({
        ...employeeIdForm,
        otherAcademics: {
          ...employeeIdForm.otherAcademics,
          academics: updatedAcademics,
        },
      });
    }
  };
  
  console.log(employeeIdForm.otherAcademics, "employeeIdForm.otherAcademics");
  const addMoreDegrees = () => {
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      otherAcademics: {
        ...prevState.otherAcademics,
        academics: [
          ...prevState.otherAcademics.academics,
          {
            id: "",
            yearOfComplete: "",
            mode: "",
            board: "",
            classOfComplete: "",
            cgpa: "",
            institutionName: "",
            university: "",
            specialization: "",
            isItUpdated: "",
            class: "",
            state: "",
            city: "",
            degree: "",
            markSheetFile: "",
            courseCompletionCertificate: "",
          },
        ],
      },
    }));
  };

  // const handleChanges7 = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;
  //   setEmployeeIdForm(prevState => ({
  //       ...prevState,
  //       [name]: value
  //   }));
  // };
  // const handleChanges7 = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
  //   const { name, value } = event.target;

  //   const trimmedValue = value.replace(/^\s+/, '');
  //   setEmployeeIdForm(prevState => ({
  //     ...prevState,
  //     [name]: trimmedValue
  //   }));
  //   const limitedValue = value.replace(/\D/g, '').slice(0, 12);

  //   if (name === 'dob') {
  //     const currentDate = new Date();
  //     const dob = new Date(value);
  //     let age = currentDate.getFullYear() - dob.getFullYear();

  //     if (
  //       currentDate.getMonth() < dob.getMonth() ||
  //       (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())
  //     ) {
  //       age--;
  //     }

  //     setEmployeeIdForm(prevState => ({
  //       ...prevState,
  //       age: age.toString()
  //     }));
  //   }
  // };
  const handleChanges7 = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
  
    let processedValue = value.replace(/^\s+/, '');
  
    if (name === 'empName' ||  name === "employeeNo" || name === "nationality" && processedValue.length > 0) {
      const words = processedValue.split(' ');
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      }
      processedValue = words.join(' ');
    }
  
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      [name]: processedValue,
    }));
  
    if (name === 'mobileNumber' && processedValue.length !== 10) {
      setValidationMessage('Mobile number must be exactly 10 digits');
    } else {
      setValidationMessage('');
    }
  
    if (name === 'dob') {
      const currentDate = new Date();
      const dob = new Date(value);
      let age = currentDate.getFullYear() - dob.getFullYear();
  
      if (
        currentDate.getMonth() < dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() &&
          currentDate.getDate() < dob.getDate())
      ) {
        age--;
      }
  
      if (age < 18) {
        toast.error(
          "Please select a date of birth that is at least 18 years ago."
        );
        setEmployeeIdForm((prevState) => ({
          ...prevState,
          dob: "",
          age: "",
        }));
        return;
      }
  
      setEmployeeIdForm((prevState) => ({
        ...prevState,
        age: age.toString(),
      }));
    }
  };
  
  
  
  
  console.log(
    nextButtonClicked && employeeIdForm.academicDetails.academics[1].class,
    "llllllllllllllllllllll"
  );
  console.log(
    nextButtonClicked && employeeIdForm.academicDetails.academics[0].class,
    "000000"
  );
  console.log(employeeIdForm.teachExp, "employeeIdForm.teachExp");

  const handleChanges8 = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    const trimmedValue = value.replace(/^\s+/, "");
    const currentDate = new Date().toISOString().split("T")[0];
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      [name]: trimmedValue,
    }));
  };

  const handleSubmits = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    // if (
    //   !employeeIdForm.mobileNumber ||
    //   !employeeIdForm.email ||
    //   !employeeIdForm.addressModal.presentAddress.state ||
    //   !employeeIdForm.addressModal.presentAddress.addressLine1 ||
    //   !employeeIdForm.addressModal.presentAddress.addressLine2 ||
    //   !employeeIdForm.addressModal.presentAddress.city ||
    //   !employeeIdForm.addressModal.presentAddress.pincode ||
    //   !employeeIdForm.addressModal.permanentAddress.addressLine1 ||
    //   !employeeIdForm.addressModal.permanentAddress.addressLine2 ||
    //   !employeeIdForm.addressModal.permanentAddress.state ||
    //   !employeeIdForm.addressModal.permanentAddress.city ||
    //   !employeeIdForm.addressModal.permanentAddress.pincode
    // ) {
    //   console.log("All fields are required");
    //   return;
    // }
    const payload = {
      addressModal: {
        ...employeeIdForm.addressModal,
        permanentAddress: {
          ...employeeIdForm.addressModal.permanentAddress,
        },
      },
      empName: employeeIdForm.empName,
      employeeNo: employeeIdForm.employeeNo,
      designation: employeeIdForm.designation,
      deptId: employeeIdForm.deptId,
      reportingTo: employeeIdForm.reportingTo,
      joinedOn: employeeIdForm.joinedOn,
      empType: employeeIdForm.empType,
      employeeId: employeeIdForm.employeeId,
      previouslyWorkedInKings: employeeIdForm.previouslyWorkedInKings,
      teachExp: employeeIdForm.teachExp,
      otherExp:employeeIdForm.otherExp,
      program:employeeIdForm.program,
      empStatus: employeeIdForm.empStatus,
      teachingExperiences: {
        details: [...employeeIdForm?.teachingExperiences?.details],
      },
      otherExperiences: {
        details: [...employeeIdForm?.otherExperiences?.details],
      },
      holders: {
        persistence: [...employeeIdForm?.holders.persistence],
      },
      otherAcademics: {
        academics: [...employeeIdForm.otherAcademics.academics],
      },
      academicDetails: {
        academics: [...employeeIdForm.academicDetails.academics],
      },
      dob: employeeIdForm.dob,
      gender: employeeIdForm.gender,
      bloodGroup: employeeIdForm.bloodGroup,
      religion: employeeIdForm.religion,
      community: employeeIdForm.community,
      nationality: employeeIdForm.nationality,
      aadhaarNumber: employeeIdForm.aadhaarNumber,
      martialStatus: employeeIdForm.martialStatus,
      typeOfIdProof: employeeIdForm.typeOfIdProof,
      // idProof: payloadID,
      email: employeeIdForm.email,
      mobileNumber: employeeIdForm.mobileNumber,
      createdOn: employeeIdForm.createdOn,
      image: imageUrl,
      imageName: employeeIdForm.imageName,
      imageType: employeeIdForm.imageType,
      updatedBy: employeeIdForm.updatedBy,
    };
    handleEmployeeCreate(payload);
  };

  const handleEmployeeCreate = async (payload: any) => {
    try {
      setStatusAction(false);
      const res = await employeeCreate(payload);
      console.log(res);
      toast.success(res.data.responseMessage);
      setTimeout(() => {
        navigate("/faculty/secondsidebar/collections/employees");
      }, 2000);
      setStatusAction(true);
    } catch (error: any) {
      if (error.response?.data?.responseMessage) {
        toast.error(error.response.data.responseMessage);
        setStatusAction(true);
      } 
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const char = e.key;

    const isValidCharacter = /^[a-zA-Z0-9]$/.test(char);

    if (!isValidCharacter) {
      e.preventDefault();
    }
  };
  const handleKeyPressNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    if (!/^[0-9]$/.test(key)) {
      e.preventDefault();
    }
  };
  const currentDate = new Date().toISOString().split("T")[0];
  // const currentDates = new Date()
  // const minYear = currentDates.getFullYear() - 18;
  // const minMonth = currentDates.getMonth() + 1;
  // const minDay = currentDates.getDate();
  // const minDateString = `${minYear}-${minMonth < 10 ? '0' + minMonth : minMonth}-${minDay < 10 ? '0' + minDay : minDay}`;

  const handleBackButtonClick = () => {
    setShowModal(true);
  };
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth());
  const lastMonthFormatted = lastMonth
    .toISOString()
    .split("-")
    .slice(0, 2)
    .join("-");
  // function formatInputValue(value: string): string {
  //   return value.slice(0, 4);
  // }
  function formatInputValue(value: string): string {
    if (typeof value !== "string") {
      return value;
    }
    return value.slice(0, 4);
  }
  const getDatas = () => {
    empIdCall()
      .then((res) => {
        console.log(res.data);
        setEmpId(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmpdetails = (employeeId: string) => {
    empDetails(employeeId)
      .then((res: AxiosResponse) => {
        autosetEmployee(res.data.data[0]);
        // console.log();
      })
      .catch((err: AxiosError) => console.log(err));
  };
  const handleChangeEducation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEducation(e.target.value);
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      program : e.target.value
    }))
  };
  const handleMouseOver = () => {
    setShowTooltip(true);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    console.log(teachingYes, " am the value u r serching for this long time ");
  }, [teachingYes]);

  useEffect(() => {
    console.log(otherYes, " other exp ");
  }, [otherYes]);

  const PriorExp = () => {
    setTeachingYes(true);
    setEmployeeIdForm((prevState) => ({
      ...prevState,
      previouslyWorkedInKings:"no"
    }));
  };
  const PriorExpNo = () => {
    setTeachingYes(false);
    clearTeachingExperienceDetails();
  };
  const othersExps = () => {
    setOtherYes(true);
  };
  const othersNo = () => {
    setOtherYes(false);
    clearOtherExperienceDetails()
  };
  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];
  //     console.log(file, "am te fileeeeeeeeeeeeee")

  //     const maxSizeInBytes = 2 * 1024 * 1024;
  //     if (file.size > maxSizeInBytes) {
  //       setErrorMessage("File size exceeds 2MB. Please select a smaller file.");
  //       setSelectedFile(null);
  //       setBase64String(null);
  //       return;
  //     }

  //     setSelectedFile(file);
  //     setErrorMessage(null);

  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64String = reader.result as string;
  //       setBase64String(base64String);
  //     };
  //     reader.readAsDataURL(file);
  //   }

  // };
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        setBase64String(reader.result.toString());
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    console.log(
      base64String,
      " dvsdfsndksdkjssdjsajdeeeenghkmhgfheeeeeeeeeeeeeeeeeeeeeeee"
    );
  }, [base64String]);
  const clearTeachingExperienceDetails = () => {
    setEmployeeIdForm((prevForm) => ({
      ...prevForm,
      teachingExperiences: {
        details: prevForm.teachingExperiences.details.map(() => ({
          id: 0, 
          institution: "",
          previouslyWorkedInKings: "",
          designation: "",
          department: "",
          pvskings: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        })),
      },
    }));
  };
  const clearITI = () => {
    setEmployeeIdForm((prevForm) => ({
      ...prevForm,
      academicDetails: {
        academics: prevForm.academicDetails.academics.map((item, index) => {
          if (index === 6) {
            return {
              id: "ITI",
              degree: "",
              yearOfComplete: "",
              mode: "",
              board: "",
              classOfComplete: "",
              cgpa: "",
              institutionName: "",
              university: "",
              specialization: "",
              isItUpdated: "false",
              state: "",
              city: "",
              markSheetFile: "",
            };
          }
          return item;
        }),
      },
    }));
  };
  const clearXII = () => {
    setEmployeeIdForm((prevForm) => ({
      ...prevForm,
      academicDetails: {
        academics: prevForm.academicDetails.academics.map((item, index) => {
          if (index === 1) {
            return {
              id: "XII Std",
              degree: "",
              yearOfComplete: "",
              mode: "",
              board: "",
              classOfComplete: "",
              cgpa: "",
              institutionName: "",
              university: "",
              specialization: "",
              isItUpdated: "false",
              state: "",
              city: "",
              markSheetFile: "",
            };
          }
          return item;
        }),
      },
    }));
  };
  const clearOtherExperienceDetails = () => {
    setEmployeeIdForm((prevForm) => ({
      ...prevForm,
      otherExperiences: {
        details: prevForm.otherExperiences.details.map(() => ({
          id: "",
          previouslyWorkedInKings1: "", 
          institution: "",
          designation: "",
          pvskings: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
          department: "",
          category: "", 
        })),
      },
    }));
  };
  const calculateTotalExperiences = () => {
    let totalExperience = 0;
    employeeIdForm.otherExperiences.details.forEach((experience) => {
      if (experience.experience) {
        totalExperience += parseFloat(experience.experience);
      }
    });
    return totalExperience.toFixed(1); 
  };
  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (selectedValue === "Other") {
      setDocDrop(true);
    } else {
      handleChange5(e, 4);
      setDocDrop(false); 
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange5(e, 4);
  };

  const handleClearInput = () => {
    setDocDrop(false);
  };
  const handlepgDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (selectedValue === "Others") {
      setPgDrop(true);
    } else {
      handleChange5(e, 3);
      setPgDrop(false); 
    }
  };

  const handlepgInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange5(e, 3);
  };

  const handlepgClearInput = () => {
    setPgDrop(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/collections/employees"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
      <div aria-live="polite">
      <div className="row">
  <div className="col-12 col-lg-5">
    <div className="mainheader">{pageHead.title}</div>
    <div className="greyfont mt-2">{pageHead.subtitle}</div>
  </div>
  <div className="col-12 col-lg-7">
    <div className="d-flex justify-content-lg-end justify-content-start mt-3 mt-lg-0">
      <span
        className="bacdept cursorPointer labelinput me-4 d-flex align-items-center pb-0"
        data-bs-toggle="modal"
        data-bs-target="#myModal"
        onClick={handleBackButtonClick}
      >
        <img src={back} className="img-fluid me-2" alt="Back Icon" />
        Back to Employees
      </span>

      {tab && (
        <div className="d-flex align-items-center">
          {activeIndex > 0 && (
            <button
              className="me-3 saveBtn "
              onClick={prevTab}
              disabled={activeIndex === 0}
            >
              Prev
            </button>
          )}
          <div>
            {activeIndex < 4 ? (
              <button
                className="addnewbtn "
                onClick={handleNextButtonClick}
              >
                Next
              </button>
            ) : (
              <button
                className="addnewbtn "
                onClick={handleSubmits}
                disabled={!statusAction}
              >
                Save
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
</div>


        <div className="row d-flex ">
          {/* <div className="overflow-auto"> */}
          <div className="col ">
            {/* <ul className="nav nav-tabs mt-3 scrollbar " id="myTab" role="tablist" >
        <li className="me-3">
          <span
            className={`uborder  pointerEvent d-flex ${
              activeIndex === 0 && 'active'
            }`}
            id="basic_Info"
            data-bs-toggle="pill"
            data-bs-target="#basicInfo"
            
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected={activeIndex === 0}
            onClick={() => setActiveIndex(0)}
          >
            <span className={`me-2 num   ${activeIndex === 0 && 'numval'}`}>1</span>
            Basic Info
          </span>
        </li>
        <li className="me-3">
          <span
            className={`uborder pointerEvent d-flex ${
              activeIndex === 1 && 'active'
            }`}
            id="academics_Info"
            data-bs-toggle="tab"
            data-bs-target="#academicsInfo"
            role="tab"
            aria-controls="contact-tab-pane"
            aria-selected={activeIndex === 1}
            onClick={() => setActiveIndex(1)}
          >
            <span className={`me-2 num  ${activeIndex === 1 && 'numval'}`}>2</span>
            Academics
         </span>
        </li>
        <li className="me-3">
          <span
            className={`uborder pointerEvent d-flex ${
              activeIndex === 2 && 'active'
            }`}
            id="workexperience_Info"
            data-bs-toggle="tab"
            data-bs-target="#workexperienceInfo"
            role="tab"
            aria-controls="contact-tab-pane"
            aria-selected={activeIndex === 2}
            onClick={() => setActiveIndex(2)}
          >
            <span className={`me-2 num  ${activeIndex === 2 && 'numval'}`}>3</span>
            Work Experience
         </span>
        </li>
          <li className="me-3">
            <span
              className={`uborder pointerEvent d-flex ${
                activeIndex === 3 && 'active'
              }`}
              id="personal_Info"
              data-bs-toggle="tab"
              data-bs-target="#personalInfo"
              role="tab"
              aria-controls="contact-tab-pane"
              aria-selected={activeIndex === 3}
              onClick={() => setActiveIndex(3)}
            >
              <span className={`me-2 num  ${activeIndex === 3 && 'numval'}`}>4</span>
              Personal Info
          </span>
          </li>
          <li className="me-3">
            <span
              className={`uborder pointerEvent d-flex ${
                activeIndex === 4 && 'active'
              }`}
              id="contact_Info"
              data-bs-toggle="tab"
              data-bs-target="#contactInfo"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected={activeIndex === 4}
              onClick={() => setActiveIndex(4)}
            >
              <span className={`me-2 num ${activeIndex === 4 && 'numval'}`}>5</span>
              Contact Info
            </span>
          </li>
      </ul> */}
            <div className="table-responsive bordernone">
              <table className=" mt-3">
                <tbody>
                  <tr className="d-flex">
                    <span className="under">
                      <td className="academics-info-td">
                        <span
                          className={`uborder pointerEvent d-flex mb-2 ${
                            activeIndex === 0 && "active"
                          }`}
                          id="basic_Info"
                          data-bs-toggle="pill"
                          data-bs-target="#basicInfo"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected={activeIndex === 0}
                          onClick={() => setActiveIndex(0)}
                        >
                          <span
                            className={`me-2 num   ${
                              activeIndex === 0 && "numval"
                            }`}
                          >
                            1
                          </span>
                          Basic Info
                        </span>
                      </td>
                      <td className="me-3 academics-info-td">
                        <span
                          className={`uborder pointerEvent d-flex ${
                            activeIndex === 1 && "active"
                          }`}
                          id="academics_Info"
                          data-bs-toggle="tab"
                          data-bs-target="#academicsInfo"
                          role="tab"
                          aria-controls="contact-tab-pane"
                          aria-selected={activeIndex === 1}
                          onClick={() => setActiveIndex(1)}
                        >
                          <span
                            className={`me-2 num  ${
                              activeIndex === 1 && "numval"
                            }`}
                          >
                            2
                          </span>
                          Academics
                        </span>
                      </td>
                      <td className="me-3 academics-info-td">
                        <span
                          className={`uborder pointerEvent d-flex ${
                            activeIndex === 2 && "active"
                          }`}
                          id="workexperience_Info"
                          data-bs-toggle="tab"
                          data-bs-target="#workexperienceInfo"
                          role="tab"
                          aria-controls="contact-tab-pane"
                          aria-selected={activeIndex === 2}
                          onClick={() => setActiveIndex(2)}
                        >
                          <span
                            className={`me-1 num  ${
                              activeIndex === 2 && "numval"
                            }`}
                          >
                            3
                          </span>
                          Work Experience
                        </span>
                      </td>
                      <td className="me-3 academics-info-td">
                        <span
                          className={`uborder pointerEvent d-flex ${
                            activeIndex === 3 && "active"
                          }`}
                          id="personal_Info"
                          data-bs-toggle="tab"
                          data-bs-target="#personalInfo"
                          role="tab"
                          aria-controls="contact-tab-pane"
                          aria-selected={activeIndex === 3}
                          onClick={() => setActiveIndex(3)}
                        >
                          <span
                            className={`me-2 num  ${
                              activeIndex === 3 && "numval"
                            }`}
                          >
                            4
                          </span>
                          Personal Info
                        </span>
                      </td>
                      <td className="me-3 basic-info-td ">
                        <span
                          className={`uborder pointerEvent d-flex ${
                            activeIndex === 4 && "active"
                          }`}
                          id="contact_Info"
                          data-bs-toggle="tab"
                          data-bs-target="#contactInfo"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected={activeIndex === 4}
                          onClick={() => setActiveIndex(4)}
                        >
                          <span
                            className={`me-2 num ${
                              activeIndex === 4 && "numval"
                            }`}
                          >
                            5
                          </span>
                          Contact Info
                        </span>
                      </td>
                    </span>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* </div> */}
          </div>
        </div>
        {/* <hr></hr> */}
        <div
          className={`tab-pane fade ${activeIndex === 0 ? "show active" : ""}`}
          id="basicInfo"
          role="tabpanel"
          aria-labelledby="basic_Info"
          style={{ display: activeIndex === 0 ? "block" : "none" }}
        >
          <div className="d-flex align-items-end labelinput">
            {noImage && <img src={user} alt="" className="me-3 img-fluid" />}
            {!noImage && (
              <img
                src={imageUrl}
                // id="fileInput"
                alt="Preview"
                className="me-3 img-fluid imgWidth"
              />
            )}
            <button
              onClick={() => document.getElementById("fileInput")?.click()}
              className="btn btn-light prevBtn me-3"
            >
              Upload
            </button>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={onFileSelected}
            />
            <button className="btn prevBtn btn-light" onClick={removeImage}>
              Remove
            </button>
          </div>
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mt-4">
              <label className="labelinput">Employee Type</label>
            </div>
            <div className="d-flex flex-wrap mt-2">
              <div className="form-check d-flex align-items-center me-4 labelinput">
                <input
                  type="radio"
                  className="form-check-input me-2 mb-1 "
                  name="empType"
                  value="Faculty"
                  checked={
                    employeeIdForm.empType === "Faculty" ||
                    setEmp.empType === "Faculty"
                  }
                  onChange={handleChanges7}
                  style={{
                    border: "1px solid #A0AECB",
                    borderRadius: "50%",
                  }}
                />
                <label className="form-check-label" htmlFor="faculty">
                  Faculty
                </label>
              </div>
              <div className="form-check d-flex align-items-center me-4 labelinput">
                <input
                  type="radio"
                  className="form-check-input me-2 mb-1"
                  name="empType"
                  value="Office Staff"
                  checked={
                    employeeIdForm.empType === "Office Staff" ||
                    setEmp.empType === "Office Staff"
                  }
                  onChange={handleChanges7}
                  style={{
                    border: "1px solid #A0AECB",
                    borderRadius: "50%",
                  }}
                />
                <label className="form-check-label" htmlFor="Office Staff">
                  Office Staff
                </label>
              </div>
              <div className="form-check d-flex align-items-center me-4 labelinput">
                <input
                  type="radio"
                  className="form-check-input me-2 mb-1"
                  name="empType"
                  value="Maintenance"
                  checked={
                    employeeIdForm.empType === "Maintenance" ||
                    setEmp.empType === "Maintenance"
                  }
                  onChange={handleChanges7}
                  style={{
                    border: "1px solid #A0AECB",
                    borderRadius: "50%",
                  }}
                />
                <label className="form-check-label" htmlFor="Maintenance">
                  Maintenance
                </label>
              </div>
              <div className="form-check d-flex align-items-center me-4 labelinput">
                <input
                  type="radio"
                  className="form-check-input me-2 mb-1"
                  name="empType"
                  value="House Keeping"
                  checked={
                    employeeIdForm.empType === "House Keeping" ||
                    setEmp.empType === "House Keeping"
                  }
                  onChange={handleChanges7}
                  style={{
                    border: "1px solid #A0AECB",
                    borderRadius: "50%",
                  }}
                />
                <label className="form-check-label" htmlFor="House Keeping">
                  House Keeping
                </label>
              </div>
            </div>
            {nextButtonClicked &&
              employeeIdForm.empType === "" &&
              setEmp.empType === "" && (
                <span className="labelinput" style={{ color: "#dc3545" }}>
                  * Required
                </span>
              )}

            <div className="row ">
              {/* <div className="col-sm-4 col-md-6 col-lg-4">
    <label className="labelinput">Previously worked in KEC</label>
    <div className="mt-2">
      <div className="form-check form-check-inline labelinput">
        <input
          type="radio"
          className="form-check-input me-2 labelinput"
          name="previouslyWorkedInKings"
          value="yes"
          checked={employeeIdForm.previouslyWorkedInKings === "yes"}
          onChange={handleChanges7}
        />
        <label className="form-check-label">Yes</label>
      </div>
      <div className="form-check form-check-inline labelinput">
        <input
          type="radio"
          className="form-check-input me-2 labelinput"
          name="previouslyWorkedInKings"
          value="no"
          checked={employeeIdForm.previouslyWorkedInKings === "no"}
          onChange={handleChanges7}
        />
        <label className="form-check-label">No</label>
      </div>
    </div>
    {nextButtonClicked && (employeeIdForm.previouslyWorkedInKings === '' && setEmp.previouslyWorkedInKings === '') && (
          <span className='labelinput' style={{ color: '#dc3545' }}>* Required</span>
        )}  
  </div> */}

              {/* <div className="col-sm-4 col-md-6 col-lg-4 labelinput">
          <label className="">Employee Id</label>
          <input
            type="text"
            className="form-control mt-2 labelinput"
            placeholder='Enter Employee Id'
            value={employeeIdForm.employeeId}
            onChange={handleChanges7}
            name="employeeId"
            autoComplete="off"
            onKeyPress={handleKeyPress}
          />
          {nextButtonClicked && employeeIdForm.employeeId === '' && (
          <span style={{ color: '#dc3545' }}>* Required</span>
        )}  
        </div> */}
              {/* {
  showEmployeeIdInput && (
    <div className="col-sm-4 col-md-6 col-lg-4 labelinput">
      <label className="">Employee Id</label>
      <select
        className="form-control mt-2 labelinput"
        value={employeeIdForm.employeeId}
        // value ={employeId}
        onChange={(event) => {
          // handleChanges7(event);
          // getDatas(); 
          // getEmpdetails();
          const selectedId = event.target.value;
          setSelectedEmployeeId(selectedId);
          handleChanges7(event);
          getDatas();
          getEmpdetails(selectedId);
        //   .then((res)=>{
        //     setEmployeeIdForm(res)
        //   })
        //   .catch((err)=>{
        //     console.log(err)
        //   })
        }}
        name="employeeId"
        autoComplete="off"
        style={{ fontSize: '14px', fontFamily: 'Satoshi medium' }}
      >
        {empId.map((item: any, index: any) => {
          return <option key={index} value={item}>{item}</option>;
        })}
      </select>
    </div>
  )
} */}
            </div>
            <div className="row mt-4">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput pb-0">Employee Name</label>
                <input
                  type="text"
                  placeholder="Enter employee name"
                  className="form-control mt-2 labelinput inputWidth"
                  value={employeeIdForm.empName || setEmp.empName}
                  // value={setEmp.empName}
                  onChange={handleChanges7}
                  name="empName"
                  autoComplete="off"
                />
                {nextButtonClicked &&
                  employeeIdForm.empName === "" &&
                  setEmp.empName === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}{" "}
              </div>
              {/* <div className="col-sm-4 col-md-6 col-lg-4">
          <label className="labelinput">Designation</label>
          <select
            className="form-control mt-2 labelinput"
            value={employeeIdForm.designation || setEmp.designation}
            onChange={handleChanges7}
            name="designation"
            autoComplete="off"
            style={{fontSize: '14px', fontFamily: 'Satoshi medium'}}
          >
            <option selected>--Select--</option>
            <option value="Professor">Professor</option>
            <option value="Associate Professor">Associate Professor</option>
            <option value="Assistant Professor">Assistant Professor</option>
            </select>
         {nextButtonClicked &&  (employeeIdForm.designation === '' && setEmp.designation === '') &&  (
          <span className='labelinput' style={{ color: '#dc3545' }}>* Required</span>
        )}
        </div> */}
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput pb-0">Employee No</label>
                <input
                  type="text"
                  placeholder="Enter employee number"
                  className="form-control mt-2 labelinput inputWidth"
                  value={employeeIdForm.employeeNo}
                  onChange={handleChanges7}
                  name="employeeNo"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="row mt-md-4 mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput pb-0">
                  Email Address
                  <span
                    className="blueround ms-2"
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    {" "}
                    <span className="pe-1">i</span>
                    {showTooltip && (
                      <div className="tooltip-text">
                        Enter the email address created in Google Workspace
                      </div>
                    )}
                  </span>
                </label>
                {/* <input
                  type="email"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter email address"
                  value={employeeIdForm.email || setEmp.email}
                  onChange={handleChanges7}
                  name="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  title="Please enter a valid email address"
                  required
                />
               
                {nextButtonClicked &&  (employeeIdForm.email === '' && setEmp.email === '') && (
          <span className='labelinput' style={{ color: '#dc3545' }}>* Required</span>
        )}   */}

                <input
                  type="email"
                  className="form-control mt-2 labelinput inputWidth"
                  placeholder="Enter employee email address"
                  value={employeeIdForm.email || setEmp.email}
                  onChange={handleChanges7}
                  name="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  title="Please enter a valid email address"
                  required
                />
                {nextButtonClicked &&
                  employeeIdForm.email === "" &&
                  setEmp.email === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
                {nextButtonClicked &&
                  employeeIdForm.email !== "" &&
                  !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
                    employeeIdForm.email
                  ) && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      Enter Valid Email Address
                    </span>
                  )}
              </div>
              {/* <div className="col-sm-4 col-md-6 col-lg-4">
                  <label className="labelinput">Mobile No</label>
                  <input
                    type="tel"
                    className="form-control mt-2 labelinput inputWidth"
                    value={employeeIdForm.mobileNumber || setEmp.mobileNumber}
                    onChange={handleChanges7}
                    name="mobileNumber"
                    pattern="[0-9]{10}"
                    onKeyPress={handleKeyPressNumber}
                    maxLength={10}
                    required
                  />
                  {nextButtonClicked &&
                    employeeIdForm.mobileNumber === "" &&
                    setEmp.mobileNumber === "" && (
                      <span className="labelinput" style={{ color: "#dc3545" }}>
                        * Required
                      </span>
                    )}
                </div> */}
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput pb-0">Mobile No</label>
                <div className="input-container currency d-flex align-items-center">
                  <span
                    className="country-symbol labelinput "
                    style={{ color: "#7784A1", fontSize: "14px", paddingTop: "1.2rem" }}
                  >
                    +91
                  </span>
                  <input
          type="tel"
          className="form-control mt-2 labelinput inputWidth"
          value={employeeIdForm.mobileNumber}
          onChange={handleChanges7}
          style={{
            fontSize: "14px",
            fontFamily: "Satoshi medium",
            border: '1px solid #dee2e6 !important',
            paddingLeft: "2.1rem" 
          }}
          name="mobileNumber"
          pattern="[0-9]{10}"
          onKeyPress={handleKeyPressNumber}
          maxLength={10}
          required
        />
      </div>
      {nextButtonClicked && (employeeIdForm.mobileNumber === "" || employeeIdForm.mobileNumber.length !== 10) && (
        <span className="labelinput" style={{ color: "#dc3545" }}>
           {employeeIdForm.mobileNumber === "" ? '*Required' : validationMessage}
        </span>
      )}
              </div>
              
            </div>
            <div className="row mt-md-4 mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput pb-0">Designation</label>
                <select
                  className="form-select mt-2 labelinput inputWidth"
                  value={employeeIdForm.designation || setEmp.designation}
                  onChange={handleChanges7}
                  name="designation"
                  autoComplete="off"
                  // style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                  style={{
                    fontSize: employeeIdForm.designation ? "14px" : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.designation ? "black" : "grey",
                  }}
                >
                  <option value="" className="select">--Select--</option>
                  <option
                    value="Professor & Head"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Professor & Head
                  </option>
                  <option
                    value="Professor"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Professor
                  </option>
                  <option
                    value="Associate Professor & Head"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Associate Professor & Head
                  </option>
                  <option
                    value="Associate Professor"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Associate Professor
                  </option>
                  <option
                    value="Assistant Professor & Head"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                   Assistant Professor & Head
                  </option>
                  <option
                    value="Assistant Professor"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                   Assistant Professor
                  </option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.designation === "" &&
                  setEmp.designation === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                {/* <label className="labelinput">Department </label>
    <input
      type="text"
      placeholder='Enter Department'
      className="form-control mt-2 labelinput"
      value={employeeIdForm.deptId}
      onChange={handleChanges7}
      name="deptId"
      autoComplete="off"
      required  
    /> */}
                <label className="labelinput pb-0">Department</label>
                <select
                  className="form-select mt-2 labelinput inputWidth"
                  value={employeeIdForm.deptId || setEmp.deptId}
                  onChange={handleChanges7}
                  name="deptId"
                  // style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                  style={{
                    fontSize: employeeIdForm.deptId ? "14px" : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.deptId ? "black" : "grey",
                  }}
                >
                  <option value="" className="select">--Select--</option>
                  <option
                    value="Artificial Intelligence & Data Science"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Artificial Intelligence & Data Science
                  </option>
                  <option
                    value="Artificial Intelligence & Machine Learning"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Artificial Intelligence & Machine Learning
                  </option>
                  <option
                    value="Biomedical Engineering"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Biomedical Engineering
                  </option>
                  <option
                    value="Computer Science and Engineering"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Computer Science and Engineering
                  </option>
                  <option
                    value="Electronics and Communication Engineering"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Electronics and Communication Engineering
                  </option>
                  <option
                    value="Information Technology"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Information Technology
                  </option>
                  <option
                    value="Mechanical Engineering"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Mechanical Engineering
                  </option>
                  <option
                    value="Robotics and Automation"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Robotics and Automation
                  </option>
                  <option
                    value="M. E. Computer Science and Engineering"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    M. E. Computer Science and Engineering
                  </option> 
                  <option
                    value="Physics"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Physics
                  </option>
                  <option
                    value="Chemistry "
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Chemistry{" "}
                  </option>
                  <option
                    value="Mathematics"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Mathematics{" "}
                  </option>
                  <option
                    value="English"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    English{" "}
                  </option>
                  <option
                    value="Tamil"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    Tamil{" "}
                  </option>
                  <option
                    value="General Engineering"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    General Engineering{" "}
                  </option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.deptId === "" &&
                  setEmp.deptId === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )} 
              </div>
            </div>
            <div className="row mt-md-4 mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput pb-0">Reporting to</label>
                <select
                  className="form-control mt-2 labelinput inputWidth"
                  value={employeeIdForm.reportingTo}
                  onChange={handleChanges7}
                  name="reportingTo"
                  autoComplete="off"
                  style={{
                    fontSize: employeeIdForm.reportingTo ? "14px" : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.reportingTo ? "black" : "grey",
                  }}
                >
                  <option value="" className="select">
                    --Select--
                  </option>
                  <option
                    value="Chair Person"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Chair Person
                  </option>
                  <option
                    value="Director"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Director
                  </option>
                  <option
                    value="Dean (Academics)"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Dean (Academics)
                  </option>
                  <option
                    value="Principal"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Principal
                  </option>
                  <option
                    value="HOD"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    HOD
                  </option>
                </select>
                {/* {nextButtonClicked && employeeIdForm.reportingTo === '' && (
          <span className='labelinput' style={{ color: '#dc3545' }}>* Required</span>
        )} */}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <div style={{position:'relative'}}>
                <label className="labelinput pb-0">Date of Joining</label>
                <input
                  type="date"
                  id="start"
                  className="form-control mt-2 labelinput inputWidth"
                  placeholder="--select--"
                  value={employeeIdForm.joinedOn || setEmp.joinedOn || ""}
                  onChange={handleChanges7}
                  name="joinedOn"
                  autoComplete="off"
                  max={currentDate}
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                  onFocus={(e) => {
                    e.target.type = "date";
                  }}
                />
               <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
               </div>
                 {nextButtonClicked &&
                  employeeIdForm.joinedOn === "" &&
                  setEmp.joinedOn === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )} 
              </div>

              {/* <div className="col-sm-4 col-md-6 col-lg-4">
    <label className="labelinput">Status</label>
    <div className="mt-2">
      <div className="form-check form-check-inline labelinput">
        <input
          type="radio"
          className="form-check-input me-2 labelinput"
          name="empStatus"
          value="Active"
          checked={employeeIdForm.empStatus === "Active" || setEmp.empStatus === "Active"}
          onChange={handleChanges7}
        />
        <label className="form-check-label">Active</label>
      </div>
      <div className="form-check form-check-inline labelinput">
        <input
          type="radio"
          className="form-check-input me-2 labelinput"
          name="empStatus"
          value="Inactive"
          checked={employeeIdForm.empStatus === "Inactive" || setEmp.empStatus === "Inactive"}
          onChange={handleChanges7}
        />
        <label className="form-check-label">Inactive</label>
      </div>
    </div>
    {nextButtonClicked &&  (employeeIdForm.empStatus === '' && setEmp.empStatus === '') && (
          <span className='labelinput' style={{ color: '#dc3545' }}>* Required</span>
        )}  
  </div> */}
            </div>

            {/* <button type="submit" className="btn btn-primary">Submit</button> */}
          </form>
        </div>
        <div
          className={`tab-pane fade ${activeIndex === 1 ? "show active" : ""}`}
          id="academicsInfo"
          role="tabpanel"
          aria-labelledby="academics_Info"
          style={{ display: activeIndex === 1 ? "block" : "none" }}
        >
          <div className=" subheader">X Std</div>
          <form onSubmit={handleSubmit}>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-4 col-lg-2">
                <div style={{position:'relative'}}>
                <label className="labelinput">Month & year of Passing</label>
                <input
                  type="month"
                  max={lastMonthFormatted}
                  className="form-control labelinput"
                  value={
                    employeeIdForm.academicDetails.academics[0].yearOfComplete
                  }
                  onChange={(e) => handleChange5(e, 0)}
                  name="yearOfComplete"
                  // max={currentDate}
                  required
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                />
                <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
                </div>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[0].yearOfComplete ===
                    "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-3">
                <label className="labelinput">Board</label>
                <select
                  className="form-select labelinput"
                  value={employeeIdForm.academicDetails.academics[0].board}
                  onChange={(e) => handleChange5(e, 0)}
                  // style={{ fontSize: "13px", fontFamily: "Satoshi medium" }}
                  name="board"
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[0].board ? '14px' : '12px',
                    height: "38px",
                    fontFamily: 'Satoshi medium',
                    color: employeeIdForm.academicDetails.academics[0].board ? 'black' : 'grey', 
                  }}
                >
                  <option value="" className="select">--Select--</option>
                  <option value="State Board" style={{ color: "black", fontSize: "14px" }}  >State Board</option>
                  <option value="CBSE" style={{ color: "black", fontSize: "14px" }}>CBSE</option>
                  <option value="NIOS" style={{ color: "black", fontSize: "14px" }}>NIOS</option>
                  <option value="IB" style={{ color: "black", fontSize: "14px" }}>IB</option>
                  <option value="ICSE" style={{ color: "black", fontSize: "14px" }}>ICSE</option>
                  <option value="IGCSE" style={{ color: "black", fontSize: "14px" }}>IGCSE</option>
                  <option value="CISCE" style={{ color: "black", fontSize: "14px" }}  >CISCE</option>
                  
                  <option value="Matriculation" style={{ color: "black", fontSize: "14px" }}  >Matriculation</option>
                  {/* <option value="Correspondence">Correspondence</option>
                  <option value="Distance learning">Distance learning</option> */}
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[0].board === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              {/* <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">Class</label>
                <select
                  className="form-select mt-2 labelinput"
                  value={employeeIdForm.academicDetails.academics[0].classOfComplete || setEmp.academicDetails.academics[0].classOfComplete}
                  onChange={(e) => handleChange5(e, 0)}
                  name="classOfComplete"
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                >
                  <option selected>--Select--</option>
                  <option value="First Class With Distinction">First class with Distinction</option>
                  <option value="First">First</option>
                  <option value="Second">Second</option>
                  <option value="Third">Third</option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[0].classOfComplete === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required{" "}
                    </span>
                  )}
              </div> */}

              <div className="col-sm-4 col-md-4 col-lg-3">
                <label className="labelinput" style={{whiteSpace:'nowrap'}}>Total marks secured in %</label>
                <div className="input-container">
                {/* <input
                  className="form-control labelinput inputWidth"
                  value={
                    employeeIdForm.academicDetails.academics[0].cgpa
                  }
                  onKeyPress={onlyNumber}
                  maxLength={4}
                  onChange={(e) => handleChange5(e, 0)}
                  // maxLength={5}
                  // isAllowed={(values) => {
                  //   if (!values.value) return true;
                  //   const { floatValue = 0 } = values;
                  //   return floatValue < MAX_LIMIT;
                  // }}
                  name="cgpa"
                  // decimalScale={1}
                  // fixedDecimalScale
                  placeholder="Enter total "
                /> */}
                <NumericFormat
                    className="form-control labelinput inputWidth"
                    value={formatInputValue(
                        employeeIdForm.academicDetails.academics[0].cgpa
                    )}
                    onChange={(e) => handleChange5(e, 0)}
                    maxLength={5}
                    isAllowed={(values) => {
                      if (!values.value) return true;
                      const { floatValue = 0 } = values;
                      return floatValue < MAX_LIMIT;
                    }}
                    name="cgpa"
                    decimalScale={1}
                    fixedDecimalScale
                    placeholder="Enter total "
                  />

                <div className="vertical-line"></div>
                <span className="percentage-sign">%</span>
                </div>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[0].cgpa === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">School Name</label>
                <input
                  type="text"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter School Name"
                  value={
                    employeeIdForm.academicDetails.academics[0].institutionName
                  }
                  onChange={(e) => handleChange5(e, 0)}
                  name="institutionName"
                />
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[0]
                    .institutionName === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">State</label>
                <select
                  className="form-select mt-2 labelinput"
                  value={employeeIdForm.academicDetails.academics[0].state}
                  onChange={(e) => handleChange5(e, 0)}
                  name="state"
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[0].state ? '14px' : '12px',
                    height: "38px",
                    fontFamily: 'Satoshi medium',
                    color: employeeIdForm.academicDetails.academics[0].state ? '#212529' : 'grey', 
                    border: '1px solid #dee2e6 !important'
                  }}
                >
                  <option value="" className="select">--Select--</option>
                  <option value="Andhra Pradesh" style={{ color: "black", fontSize: "14px" }} >Andhra Pradesh</option>
                  <option value="Arunachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Arunachal Pradesh</option>
                  <option value="Assam" style={{ color: "black", fontSize: "14px" }} >Assam</option>
                  <option value="Bihar" style={{ color: "black", fontSize: "14px" }} >Bihar</option>
                  <option value="Chattisgarh" style={{ color: "black", fontSize: "14px" }} >Chattisgarh</option>
                  <option value="Gujarat" style={{ color: "black", fontSize: "14px" }} >Gujarat</option>
                  <option value="Haryana" style={{ color: "black", fontSize: "14px" }} >Haryana</option>
                  <option value="Himachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Himachal Pradesh</option>
                  <option value="Jharkhand" style={{ color: "black", fontSize: "14px" }} >Jharkhand</option>
                  <option value="Karnataka" style={{ color: "black", fontSize: "14px" }} >Karnataka</option>
                  <option value="Kerala" style={{ color: "black", fontSize: "14px" }} >Kerala</option>
                  <option value="Madhya Pradesh" style={{ color: "black", fontSize: "14px" }} >Madhya Pradesh</option>
                  <option value="Maharashtra" style={{ color: "black", fontSize: "14px" }} >Maharashtra</option>
                  <option value="Manipur" style={{ color: "black", fontSize: "14px" }} >Manipur</option>
                  <option value="Meghalaya" style={{ color: "black", fontSize: "14px" }} >Meghalaya</option>
                  <option value="Odisha" style={{ color: "black", fontSize: "14px" }} >Odisha</option>
                  <option value="Punjab" style={{ color: "black", fontSize: "14px" }} >Punjab</option>
                  <option value="Rajasthan" style={{ color: "black", fontSize: "14px" }} >Rajasthan</option>
                  <option value="Sikkim" style={{ color: "black", fontSize: "14px" }} >Sikkim</option>
                  <option value="Tamil Nadu" style={{ color: "black", fontSize: "14px" }} >Tamil Nadu</option>
                  <option value="Telangana" style={{ color: "black", fontSize: "14px" }} >Telangana</option>
                  <option value="Tripura" style={{ color: "black", fontSize: "14px" }} >Tripura</option>
                  <option value="Uttar Pradesh" style={{ color: "black", fontSize: "14px" }} >Uttar Pradesh</option>
                  <option value="Uttarakhand" style={{ color: "black", fontSize: "14px" }} >Uttarakhand</option>
                  <option value="West Bengal" style={{ color: "black", fontSize: "14px" }} >West Bengal</option>
                  {/* <option value="Correspondence">Correspondence</option>
                  <option value="Distance learning">Distance learning</option> */}
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[0].state === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              {/* <div className="col-sm-4 col-md-6 col-lg-4">
        <label className="labelinput">University</label>
        <input
          type="text"
          className="form-control mt-2 labelinput"
          placeholder="Enter University Name"
          value={employeeIdForm.academicDetails.academics[0].university}
          onChange={handleChange}
          name="university"
        />
      </div> */}
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">City/Town</label>
                <input
                  // type="number"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter City/Town"
                  value={employeeIdForm.academicDetails.academics[0].city}
                  onChange={(e) => handleChange5(e, 0)}
                  name="city"
                />
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[0].city === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 col-sm-6 col-md-8 col-lg-4 ">
                 <div className="d-flex justify-content-between mt-2 flex-wrap">
      <label className="labelinput">
        Marksheet
        <span className="optional ms-2">Optional</span>
      </label>
      <span className="optional text-nowrap">
        Only pdf/jpg file under 2MB
      </span>
    </div>
                <div className="input-group">
                  <label className="form-control boxText boxtextellipse browsetext inputWidth">
                  <span className="labelinput">
                    {employeeIdForm.academicDetails.academics[0]?.markSheetFile}
                    </span>
                    <input
                      type="file"
                      id={`markSheetFile_${0}`}
                      className="invisible "
                      name="markSheetFile"
                      onChange={(e) => {
                        let val = e.target.value;
                        val = val.replace(/^\s+/, "");
                        handleChange5(e, 0, val, `markSheetFile_${0}`);
                      }}
                      // onChange={(e) => base64ConverterFile(e, 'fileInputX','')}
                    />
                  </label>
                  <span
                    className="input-group-text lname cursorPointer labelinput"
                    onClick={() =>
                      document.getElementById(`markSheetFile_${0}`)?.click()
                    }
                  >
                    Select File
                  </span>
                </div>

                {/* <input
                      type="file"
                      name="file"
                      value={employeeIdForm.academicDetails.academics[0].file}
                      onChange={handleFileInputChange}
                      /> */}
                {/* {errorMessage && (
        <div className="mt-2 text-danger">
          <p>{errorMessage}</p>
        </div>
      )}
      {selectedFile && (
        <div className="mt-2">
          <p>Selected file: {selectedFile.name}</p>
        </div>
      )}
      {base64String && (
        <div className="mt-2">
          <p>Base64 string: {base64String}</p>
        </div>
      )} */}
              </div>
            </div>
            <div className="mt-3 subheader labelinput">XII Std / Diploma</div>
            <div className="row ">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Program</label>
                <div className="mt-2">
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="program"
                      value="XII Std"
                      checked={employeeIdForm.program === "XII Std"}
                      // checked={
                      //   employeeIdForm.academicDetails.academics[1].id ===
                      //   "XII Std" || setEmp.academicDetails.academics[1].id ===
                      //   "XII Std"
                      // }
                      // onChange={(e) => handleChange5(e, 1)}
                      onChange={handleChangeEducation}
                      onClick={clearITI}
                    />
                    <label className="form-check-label">XII Std</label>
                  </div>
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="program"
                      value="Diploma"
                      checked={employeeIdForm.program === "Diploma"}
                      // checked={
                      //   employeeIdForm.academicDetails.academics[1].id ===
                      //   "ITI" || setEmp.academicDetails.academics[1].id ===
                      //   "ITI"
                      // }
                      // onChange={(e) => handleChange5(e, 1)}
                      onChange={handleChangeEducation}
                      onClick={clearXII}
                    />
                    <label className="form-check-label">Diploma</label>
                  </div>
                </div>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[1].id === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              {/* <div className="col-sm-4 col-md-6 col-lg-4">
        <label className="labelinput">Specialization</label>
        <select
          className="form-select mt-2 labelinput"
          value={employeeIdForm.academicDetails.academics[1].specialization}
          onChange={(e) => handleChange5(e, 1)}
          name="specialization"
        >
          <option selected>--Select--</option>
          <option value="none">Nones</option>
          <option value="none">None</option>
        </select>
      </div> */}
            </div>
            {selectedEducation === "XII Std" && (
              <div>
                <div className="row mt-3">
                  <div className="col-sm-4 col-md-4 col-lg-2">
                  <div style={{position:'relative'}}>
                    <label className="labelinput ">Month & year of Passing</label>
                    <input
                      type="month"
                      className="form-control  labelinput"
                      value={
                        employeeIdForm.academicDetails.academics[1]
                          .yearOfComplete
                      }
                      onChange={(e) => handleChange5(e, 1)}
                      name="yearOfComplete"
                      // max={currentDate}
                      max={lastMonthFormatted}
                      style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                      required
                    />
<span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
</div>
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[1]
                        .yearOfComplete === "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>

                  <div className="col-sm-4 col-md-4 col-lg-3">
                    <label className="labelinput">Board</label>
                    <select
                      className="form-select  labelinput"
                      value={employeeIdForm.academicDetails.academics[1].board}
                      onChange={(e) => handleChange5(e, 1)}
                      name="board"
                      // style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                      style={{
                        fontSize: employeeIdForm.academicDetails.academics[1].board ? '14px' : '12px',
                        height: "38px",
                        fontFamily: 'Satoshi medium',
                        color: employeeIdForm.academicDetails.academics[1].board ? 'black' : 'grey', 
                      }}
                    >
                      <option value="" className="select">--Select--</option>
                      <option value="State Board" style={{ color: "black", fontSize: "14px" }}  >State Board</option>
                  <option value="CBSE" style={{ color: "black", fontSize: "14px" }}>CBSE</option>
                  <option value="NIOS" style={{ color: "black", fontSize: "14px" }}>NIOS</option>
                  <option value="IB" style={{ color: "black", fontSize: "14px" }}>IB</option>
                  <option value="ICSE" style={{ color: "black", fontSize: "14px" }}>ICSE</option>
                  <option value="IGCSE" style={{ color: "black", fontSize: "14px" }}>IGCSE</option>
                  <option value="CISCE" style={{ color: "black", fontSize: "14px" }}  >CISCE</option>
                  
                  <option value="Matriculation" style={{ color: "black", fontSize: "14px" }}  >Matriculation</option>
                      {/* <option value="Correspondence">Correspondence</option>
                  <option value="Distance learning">Distance learning</option> */}
                    </select>
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[1].board ===
                        "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>
                  {/* <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">Class</label>
                <select
                  className="form-select mt-2 labelinput"
                  value={employeeIdForm.academicDetails.academics[1].classOfComplete || setEmp.academicDetails.academics[1].classOfComplete }
                  onChange={(e) => handleChange5(e, 1)}
                  name="classOfComplete"
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                >
                  <option selected>--Select--</option>
                  <option value="First Class With Distinction">First class with Distinction</option>
                  <option value="First">First</option>
                  <option value="Second">Second</option>
                  <option value="Third">Third</option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[1].classOfComplete === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div> */}

                  <div className="col-sm-4 col-md-4 col-lg-3">
                    <label className="labelinput" style={{whiteSpace:'nowrap'}}>
                      Total marks secured in %
                    </label>
                    <div className="input-container">
                    {/* <input
                      // type="number"
                      className="form-control labelinput inputWidth"
                      placeholder="Enter total "
                      // value={formatInputValue(
                      //   employeeIdForm.academicDetails.academics[1].cgpa
                      // )}
                      onKeyPress={onlyNumber}
                      maxLength={4}
                      value={
                        employeeIdForm.academicDetails.academics[1].cgpa
                      }
                      onChange={(e) => handleChange5(e, 1)}
                      // isAllowed={(values) => {
                      //   if (!values.value) return true;
                      //   const { floatValue = 0 } = values;
                      //   return floatValue < MAX_LIMIT;
                      // }}
                      name="cgpa"
                      // decimalScale={1}
                      // fixedDecimalScale
                      
                    /> */}
                    <NumericFormat
                    className="form-control labelinput inputWidth"
                    value={formatInputValue(
                        employeeIdForm.academicDetails.academics[1].cgpa
                    )}
                    onChange={(e) => handleChange5(e, 1)}
                    maxLength={5}
                    isAllowed={(values) => {
                      if (!values.value) return true;
                      const { floatValue = 0 } = values;
                      return floatValue < MAX_LIMIT;
                    }}
                    name="cgpa"
                    decimalScale={1}
                    fixedDecimalScale
                    placeholder="Enter total "
                  />

                    <div className="vertical-line"></div>
                    <span className="percentage-sign">%</span>
                    </div>
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[1].cgpa ===
                        "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-4 col-md-6 col-lg-4">
                    <label className="labelinput">School Name</label>
                    <input
                      type="text"
                      className="form-control mt-2 labelinput"
                      placeholder="Enter School Name "
                      value={
                        employeeIdForm.academicDetails.academics[1]
                          .institutionName
                      }
                      onChange={(e) => handleChange5(e, 1)}
                      name="institutionName"
                    />
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[1]
                        .institutionName === "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-2">
                    <label className="labelinput">State</label>
                    <select
                      className="form-select mt-2 labelinput"
                      value={employeeIdForm.academicDetails.academics[1].state}
                      onChange={(e) => handleChange5(e, 1)}
                      style={{
                        fontSize: employeeIdForm.academicDetails.academics[1].state ? '14px' : '12px',
                        height: "38px",
                        fontFamily: 'Satoshi medium',
                        color: employeeIdForm.academicDetails.academics[1].state ? '#212529' : 'grey', 
                        border: '1px solid #dee2e6 !important'
                      }}
                      name="state"
                    >
                   <option value="" className="select">--Select--</option>
                  <option value="Andhra Pradesh" style={{ color: "black", fontSize: "14px" }} >Andhra Pradesh</option>
                  <option value="Arunachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Arunachal Pradesh</option>
                  <option value="Assam" style={{ color: "black", fontSize: "14px" }} >Assam</option>
                  <option value="Bihar" style={{ color: "black", fontSize: "14px" }} >Bihar</option>
                  <option value="Chattisgarh" style={{ color: "black", fontSize: "14px" }} >Chattisgarh</option>
                  <option value="Gujarat" style={{ color: "black", fontSize: "14px" }} >Gujarat</option>
                  <option value="Haryana" style={{ color: "black", fontSize: "14px" }} >Haryana</option>
                  <option value="Himachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Himachal Pradesh</option>
                  <option value="Jharkhand" style={{ color: "black", fontSize: "14px" }} >Jharkhand</option>
                  <option value="Karnataka" style={{ color: "black", fontSize: "14px" }} >Karnataka</option>
                  <option value="Kerala" style={{ color: "black", fontSize: "14px" }} >Kerala</option>
                  <option value="Madhya Pradesh" style={{ color: "black", fontSize: "14px" }} >Madhya Pradesh</option>
                  <option value="Maharashtra" style={{ color: "black", fontSize: "14px" }} >Maharashtra</option>
                  <option value="Manipur" style={{ color: "black", fontSize: "14px" }} >Manipur</option>
                  <option value="Meghalaya" style={{ color: "black", fontSize: "14px" }} >Meghalaya</option>
                  <option value="Odisha" style={{ color: "black", fontSize: "14px" }} >Odisha</option>
                  <option value="Punjab" style={{ color: "black", fontSize: "14px" }} >Punjab</option>
                  <option value="Rajasthan" style={{ color: "black", fontSize: "14px" }} >Rajasthan</option>
                  <option value="Sikkim" style={{ color: "black", fontSize: "14px" }} >Sikkim</option>
                  <option value="Tamil Nadu" style={{ color: "black", fontSize: "14px" }} >Tamil Nadu</option>
                  <option value="Telangana" style={{ color: "black", fontSize: "14px" }} >Telangana</option>
                  <option value="Tripura" style={{ color: "black", fontSize: "14px" }} >Tripura</option>
                  <option value="Uttar Pradesh" style={{ color: "black", fontSize: "14px" }} >Uttar Pradesh</option>
                  <option value="Uttarakhand" style={{ color: "black", fontSize: "14px" }} >Uttarakhand</option>
                  <option value="West Bengal" style={{ color: "black", fontSize: "14px" }} >West Bengal</option>
                      {/* <option value="Correspondence">Correspondence</option>
                  <option value="Distance learning">Distance learning</option> */}
                    </select>
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[1].state ===
                        "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>

                  <div className="col-sm-4 col-md-4 col-lg-2">
                    <label className="labelinput">City/Town</label>
                    <input
                      // type="number"
                      className="form-control mt-2 labelinput"
                      placeholder="Enter City/Town"
                      value={employeeIdForm.academicDetails.academics[1].city}
                      onChange={(e) => handleChange5(e, 1)}
                      name="city"
                    />
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[1].city ===
                        "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>
                  <div className="row mt-2">
                  {/* <div className="row"> */}
  <div className="col-12 col-sm-6 col-md-8 col-lg-4">
    <div className="d-flex justify-content-between mt-2 flex-wrap">
      <label className="labelinput">
        Marksheet
        <span className="optional ms-2">Optional</span>
      </label>
      <span className="optional text-nowrap">
        Only pdf/jpg file under 2MB
      </span>
    </div>

    <div className="input-group mt-2">
      <label className="form-control boxText boxtextellipse browsetext inputWidth">
        <span className="labelinput">
          {employeeIdForm.academicDetails.academics[1]?.markSheetFile}
        </span>
        <input
          type="file"
          id={`markSheetFile_${1}`}
          className="invisible"
          name="markSheetFile"
          onChange={(e) => {
            let val = e.target.value;
            val = val.replace(/^\s+/, "");
            handleChange5(e, 1, val, `markSheetFile_${1}`);
          }}
        />
      </label>
      <span
        className="input-group-text lname cursorPointer"
        onClick={() =>
          document.getElementById(`markSheetFile_${1}`)?.click()
        }
      >
        Select File
      </span>
    </div>
  {/* </div> */}
</div>

                  </div>
                  {/* <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">University</label>
                <input
                  type="text"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter University Name"
                  value={employeeIdForm.academicDetails.academics[1].university || setEmp.academicDetails.academics[1].university}
                  onChange={(e) => handleChange5(e, 1)}
                  name="university"
                />
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[1].university ===
                    "" && (
                    <span className="labelinput" style={{ color: "red" }}>
                      * Required
                    </span>
                  )}
              </div> */}
                </div>
              </div>
            )}
            {selectedEducation === "Diploma" && (
              <div>
                <div className="row mt-3">
                  <div className="col-sm-4 col-md-6 col-lg-4">
                    <div className="">
                      <label className="labelinput">Degree/Course Name</label>
                      {/* <select
                        className="form-select mt-2 labelinput"
                        value={
                          employeeIdForm.academicDetails.academics[6].degree
                        }
                        onChange={(e) => handleChange5(e, 6)}
                        name="degree"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Satoshi medium",
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="B.E">B.E</option>
                        <option value="B.Tech">B.Tech</option>
                        <option value="B.Sc">B.Sc</option>
                        <option value="B.Com">B.Com</option>
                        <option value="B.C.A">B.C.A</option>
                        <option value="B.A">B.A</option>
                      </select> */}
                      <input
                      className="form-control mt-2 labelinput"
                      value={
                        employeeIdForm.academicDetails.academics[6].degree
                      }
                      onChange={(e) => handleChange5(e, 6)}
                      name="degree"
                      style={{
                        fontSize: "14px",
                        fontFamily: "Satoshi medium",
                      }}
                      />
                    </div>
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[6].degree ===
                        "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-4 col-md-4 col-lg-2">
                  <div style={{position:'relative'}}>
                    <label className="labelinput ">Month & year of Passing</label>
                    <input
                      type="month"
                      className="form-control labelinput"
                      value={
                        employeeIdForm.academicDetails.academics[6]
                          .yearOfComplete
                      }
                      onChange={(e) => handleChange5(e, 6)}
                      name="yearOfComplete"
                      // max={currentDate}
                      max={lastMonthFormatted}
                      style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                      required
                    />
<span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
</div>
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[6]
                        .yearOfComplete === "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-2">
                    <label className="labelinput">Class</label>
                    <select
                      className="form-select  labelinput"
                      value={
                        employeeIdForm.academicDetails.academics[6]
                          .classOfComplete
                      }
                      onChange={(e) => handleChange5(e, 6)}
                      name="classOfComplete"
                      // style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                      style={{
                        fontSize: employeeIdForm.academicDetails.academics[6]
                        .classOfComplete ? '14px' : '12px',
                        height: "38px",
                        fontFamily: 'Satoshi medium',
                        color: employeeIdForm.academicDetails.academics[6]
                        .classOfComplete ? 'black' : 'grey', 
                      }}
                    >
                      <option value="" className="select">--Select--</option>
                      <option value="First Class With Distinction"
                      style={{ color: "black", fontSize: "14px" }}
                      >
                        First class with Distinction
                      </option>
                      <option value="First" style={{ color: "black", fontSize: "14px" }} >First</option>
                      <option value="Second" style={{ color: "black", fontSize: "14px" }} >Second</option>
                      <option value="Third" style={{ color: "black", fontSize: "14px" }} >Third</option>
                    </select>
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[6]
                        .classOfComplete === "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-3">
                    <label className="labelinput" style={{whiteSpace:'nowrap'}}>Grade or % or CGPA</label>
                    <div className="input-container">
                    {/* <input
                      // type="number"
                      className="form-control labelinput inputWidth"
                      placeholder="Enter CGPA "
                      value={
                        employeeIdForm.academicDetails.academics[6].cgpa
                      }
                      onKeyPress={onlyNumber}
                      maxLength={4}
                      // value={
                      //   formatInputValue(
                      //     (setEmp ? setEmp.academicDetails.academics[6].cgpa : null) ||
                      //     employeeIdForm.academicDetails.academics[6].cgpa
                      //   )
                      // }
                      onChange={(e) => handleChange5(e, 6)}
                      // isAllowed={(values) => {
                      //   if (!values.value) return true;
                      //   const { floatValue = 0 } = values;
                      //   return floatValue < MAX_LIMIT;
                      // }}
                      name="cgpa"
                      // decimalScale={1}
                      // fixedDecimalScale
                    /> */}
                    <NumericFormat
                    className="form-control labelinput inputWidth"
                    value={formatInputValue(
                        employeeIdForm.academicDetails.academics[6].cgpa
                    )}
                    onChange={(e) => handleChange5(e, 6)}
                    maxLength={5}
                    isAllowed={(values) => {
                      if (!values.value) return true;
                      const { floatValue = 0 } = values;
                      return floatValue < MAX_LIMIT;
                    }}
                    name="cgpa"
                    decimalScale={1}
                    fixedDecimalScale
                    placeholder="Enter CGPA "
                  />

                    <div className="vertical-line"></div>
                    <span className="percentage-sign">%</span>
                    </div>
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[6].cgpa ===
                        "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}{" "}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-4 col-md-6 col-lg-4">
                    <label className="labelinput">College</label>
                    <input
                      type="text"
                      className="form-control mt-2 labelinput"
                      placeholder="Enter college Name "
                      value={
                        employeeIdForm.academicDetails.academics[6]
                          .institutionName
                      }
                      onChange={(e) => handleChange5(e, 6)}
                      name="institutionName"
                    />
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[6]
                        .institutionName === "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-2">
                    <label className="labelinput">State</label>
                    <select
                      className="form-select mt-2 labelinput"
                      value={employeeIdForm.academicDetails.academics[6].state}
                      onChange={(e) => handleChange5(e, 6)}
                      style={{
                        fontSize: employeeIdForm.academicDetails.academics[6].state ? '14px' : '12px',
                        height: "38px",
                        fontFamily: 'Satoshi medium',
                        color: employeeIdForm.academicDetails.academics[6].state ? '#212529' : 'grey', 
                        border: '1px solid #dee2e6 !important'
                      }}
                      name="state"
                    >
                    <option value="" className="select">--Select--</option>
                  <option value="Andhra Pradesh" style={{ color: "black", fontSize: "14px" }} >Andhra Pradesh</option>
                  <option value="Arunachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Arunachal Pradesh</option>
                  <option value="Assam" style={{ color: "black", fontSize: "14px" }} >Assam</option>
                  <option value="Bihar" style={{ color: "black", fontSize: "14px" }} >Bihar</option>
                  <option value="Chattisgarh" style={{ color: "black", fontSize: "14px" }} >Chattisgarh</option>
                  <option value="Gujarat" style={{ color: "black", fontSize: "14px" }} >Gujarat</option>
                  <option value="Haryana" style={{ color: "black", fontSize: "14px" }} >Haryana</option>
                  <option value="Himachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Himachal Pradesh</option>
                  <option value="Jharkhand" style={{ color: "black", fontSize: "14px" }} >Jharkhand</option>
                  <option value="Karnataka" style={{ color: "black", fontSize: "14px" }} >Karnataka</option>
                  <option value="Kerala" style={{ color: "black", fontSize: "14px" }} >Kerala</option>
                  <option value="Madhya Pradesh" style={{ color: "black", fontSize: "14px" }} >Madhya Pradesh</option>
                  <option value="Maharashtra" style={{ color: "black", fontSize: "14px" }} >Maharashtra</option>
                  <option value="Manipur" style={{ color: "black", fontSize: "14px" }} >Manipur</option>
                  <option value="Meghalaya" style={{ color: "black", fontSize: "14px" }} >Meghalaya</option>
                  <option value="Odisha" style={{ color: "black", fontSize: "14px" }} >Odisha</option>
                  <option value="Punjab" style={{ color: "black", fontSize: "14px" }} >Punjab</option>
                  <option value="Rajasthan" style={{ color: "black", fontSize: "14px" }} >Rajasthan</option>
                  <option value="Sikkim" style={{ color: "black", fontSize: "14px" }} >Sikkim</option>
                  <option value="Tamil Nadu" style={{ color: "black", fontSize: "14px" }} >Tamil Nadu</option>
                  <option value="Telangana" style={{ color: "black", fontSize: "14px" }} >Telangana</option>
                  <option value="Tripura" style={{ color: "black", fontSize: "14px" }} >Tripura</option>
                  <option value="Uttar Pradesh" style={{ color: "black", fontSize: "14px" }} >Uttar Pradesh</option>
                  <option value="Uttarakhand" style={{ color: "black", fontSize: "14px" }} >Uttarakhand</option>
                  <option value="West Bengal" style={{ color: "black", fontSize: "14px" }} >West Bengal</option>
                      {/* <option value="Correspondence">Correspondence</option>
                    <option value="Distance learning">Distance learning</option> */}
                    </select>
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[6].state ===
                        "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>

                  <div className="col-sm-4 col-md-4 col-lg-2">
                    <label className="labelinput">City/Town</label>
                    <input
                      // type="number"
                      className="form-control mt-2 labelinput"
                      placeholder="Enter City/Town"
                      value={employeeIdForm.academicDetails.academics[6].city}
                      onChange={(e) => handleChange5(e, 6)}
                      name="city"
                    />
                    {nextButtonClicked &&
                      employeeIdForm.academicDetails.academics[6].city ===
                        "" && (
                        <span
                          className="labelinput"
                          style={{ color: "#dc3545" }}
                        >
                          * Required
                        </span>
                      )}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-4 col-md-6 col-lg-4">
                  <div className="d-flex justify-content-between mt-2 flex-wrap">
      <label className="labelinput">
        Marksheet
        <span className="optional ms-2">Optional</span>
      </label>
      <span className="optional text-nowrap">
        Only pdf/jpg file under 2MB
      </span>
    </div>
                    {/* <input
                      type="file"
                      className="form-control mt-2 labelinput"
                      // value={
                      //   employeeIdForm.teachingExperiences.details[index]
                      //     ?.file || setEmp.teachingExperiences.details[index]
                      //     ?.file
                      // }
                      // onChange={(e) => {
                      //   let val = e.target.value;
                      //   val =val.replace(/^\s+/, "");
                      //   // handleStateCreateEmp("file", e.target.value);
                      //   // handleChanges4(e, index);
                      //   handletechange(index,e,val)
                      // }}
                      onChange={(e) => handleChange5(e, 6)}  
                      name="file"
                    /> */}
                    <div className="input-group labelinput">
                      <label className="form-control boxText boxtextellipse browsetext inputWidth">
                        {
                          employeeIdForm.academicDetails.academics[6]
                            ?.markSheetFile
                        }
                        <input
                          type="file"
                          id={`markSheetFile_${6}`}
                          className="invisible"
                          name="markSheetFile"
                          onChange={(e) => {
                            let val = e.target.value;
                            val = val.replace(/^\s+/, "");
                            handleChange5(e, 6, val, `markSheetFile_${6}`);
                          }}
                        />
                      </label>
                      <span
                        className="input-group-text lname cursorPointer"
                        onClick={() =>
                          document.getElementById(`markSheetFile_${6}`)?.click()
                        }
                      >
                        Select File
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-3 subheader">UG Program</div>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <div className="">
                  <label className="labelinput">Degree/Course Name</label>
                  <select
                    className="form-select mt-2 labelinput"
                    value={employeeIdForm.academicDetails.academics[2].degree}
                    // value={employeeIdForm.otherAcademics.details[0].degree}
                    onChange={(e) => handleChange5(e, 2)}
                    name="degree"
                    style={{
                      fontSize: employeeIdForm.academicDetails.academics[2].degree ? '14px' : '12px',
                      height: "38px",
                      fontFamily: 'Satoshi medium',
                      color: employeeIdForm.academicDetails.academics[2].degree ? '#212529' : 'grey', 
                      border: '1px solid #dee2e6 !important'
                    }}
                  >
                    <option value="" className="select">--Select--</option>
                    <option value="B.E" style={{ color: "black", fontSize: "14px" }}>B.E</option>
                    <option value="B.Tech" style={{ color: "black", fontSize: "14px" }}>B.Tech</option>
                    <option value="B.Sc" style={{ color: "black", fontSize: "14px" }}>B.Sc</option>
                    <option value="B.Com" style={{ color: "black", fontSize: "14px" }}>B.Com</option>
                    <option value="B.C.A" style={{ color: "black", fontSize: "14px" }}>B.C.A</option>
                    <option value="B.A" style={{ color: "black", fontSize: "14px" }}>B.A</option>
                    {/* <option value="Others" style={{ color: "black", fontSize: "14px" }}>Others</option> */}
                  </select>
                </div>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[2].degree === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              {/* <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Specialization</label>
                <select
                  className="form-select mt-2 labelinput"
                  value={
                    employeeIdForm.academicDetails.academics[2].specialization
                    ||
                    setEmp.academicDetails.academics[2].specialization
                  }
                  onChange={(e) => handleChange5(e, 2)}
                  name="specialization"
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                >
                  <option selected>--Select--</option>
                  <option value="Artificial Intelligence & Data Science">
                    {" "}
                    Artificial Intelligence & Data Science
                  </option>
                  <option value="Artificial Intelligence & Machine Learning">
                    {" "}
                    Artificial Intelligence & Machine Learning
                  </option>
                  <option value="Biomedical Engineering">
                    {" "}
                    Biomedical Engineering
                  </option>
                  <option value="Computer Science and Engineering">
                    {" "}
                    Computer Science and Engineering
                  </option>
                  <option value="Electronics and Communication Engineering">
                    {" "}
                    Electronics and Communication Engineering
                  </option>
                  <option value="Information Technology">
                    {" "}
                    Information Technology
                  </option>
                  <option value="Mechanical Engineering">
                    {" "}
                    Mechanical Engineering
                  </option>
                  <option value="Robotics and Automation">
                    {" "}
                    Robotics and Automation
                  </option>
                  <option value="Computer Applications">
                    {" "}
                    Computer Applications
                  </option>
                  <option value="Chemistry">Chemistry</option>
                  <option value="English">English</option>
                  <option value="Mathematics">Mathematics</option>
                  <option value="physics">Physics</option>
                  <option value="Others"> Others</option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[2].specialization ===
                    "" && (
                    <span className="labelinput" style={{ color: "red" }}>
                      * Required
                    </span>
                  )}
              </div> */}
            </div>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-4 col-lg-3">
              <div style={{position:'relative'}}>
                <label className="labelinput">Month & Year of passing</label>
                <input
                  type="month"
                  className="form-control  labelinput"
                  value={
                    employeeIdForm.academicDetails.academics[2].yearOfComplete
                  }
                  onChange={(e) => handleChange5(e, 2)}
                  name="yearOfComplete"
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                  // max={currentDate}
                  max={lastMonthFormatted}
                />
                <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
                </div>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[2].yearOfComplete ===
                    "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">Mode</label>
                <select
                  className="form-select  labelinput"
                  value={employeeIdForm.academicDetails.academics[2].mode}
                  onChange={(e) => handleChange5(e, 2)}
                  name="mode"
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[2].mode ? '14px' : '12px',
                    height: "38px",
                    fontFamily: 'Satoshi medium',
                    color: employeeIdForm.academicDetails.academics[2].mode ? '#212529' : 'grey', 
                    border: '1px solid #dee2e6 !important'
                  }}
                >
                  <option value="" className="select">--Select--</option>
                  <option value="Full Time" style={{ color: "black", fontSize: "14px" }}>Full Time</option>
                  <option value="Part Time" style={{ color: "black", fontSize: "14px" }}>Part Time</option>
                  <option value="Correspondence" style={{ color: "black", fontSize: "14px" }}>Correspondence</option>
                  <option value="Distance Learning" style={{ color: "black", fontSize: "14px" }}>Distance Learning</option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[2].mode === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">Class</label>
                <select
                  className="form-select  labelinput"
                  value={
                    employeeIdForm.academicDetails.academics[2].classOfComplete
                  }
                  onChange={(e) => handleChange5(e, 2)}
                  name="classOfComplete"
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[2].classOfComplete ? '14px' : '12px',
                    height: "38px",
                    fontFamily: 'Satoshi medium',
                    color: employeeIdForm.academicDetails.academics[2].classOfComplete ? '#212529' : 'grey', 
                    border: '1px solid #dee2e6 !important'
                  }}
                >
                    <option value="" className="select">--Select--</option>
                      <option value="First Class With Distinction"
                      style={{ color: "black", fontSize: "14px" }}
                      >
                        First class with Distinction
                      </option>
                      <option value="First" style={{ color: "black", fontSize: "14px" }} >First</option>
                      <option value="Second" style={{ color: "black", fontSize: "14px" }} >Second</option>
                      <option value="Third" style={{ color: "black", fontSize: "14px" }} >Third</option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[2]
                    .classOfComplete === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>

              <div className="col-sm-4 col-md-4 col-lg-3">
                <label className="labelinput"  style={{whiteSpace:'nowrap'}}>Grade or % or CGPA</label>
                <div className="input-container">
                {/* <input
                  // type="number"
                  className="form-control  labelinput"
                  placeholder="Enter CGPA "
                  // value={formatInputValue(
                  //   employeeIdForm.academicDetails.academics[2].cgpa
                  // )}
                  onKeyPress={onlyNumber}
                  maxLength={4}
                  value={
                    employeeIdForm.academicDetails.academics[2].cgpa
                  }
                  onChange={(e) => handleChange5(e, 2)}
                  // isAllowed={(values) => {
                  //   if (!values.value) return true;
                  //   const { floatValue = 0 } = values;
                  //   return floatValue < MAX_LIMIT;
                  // }}
                  name="cgpa"
                  // decimalScale={1}
                  // fixedDecimalScale
                /> */}
                <NumericFormat
                    className="form-control labelinput inputWidth"
                    value={formatInputValue(
                        employeeIdForm.academicDetails.academics[2].cgpa
                    )}
                    onChange={(e) => handleChange5(e, 2)}
                    maxLength={5}
                    isAllowed={(values) => {
                      if (!values.value) return true;
                      const { floatValue = 0 } = values;
                      return floatValue < MAX_LIMIT;
                    }}
                    name="cgpa"
                    decimalScale={1}
                    fixedDecimalScale
                    placeholder="Enter CGPA "
                  />

                 <div className="vertical-line"></div>
                 <span className="percentage-sign">%</span>
                </div>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[2].cgpa === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}{" "}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">College</label>
                <input
                  type="text"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter College Name"
                  value={
                    employeeIdForm.academicDetails.academics[2].institutionName
                  }
                  onChange={(e) => handleChange5(e, 2)}
                  name="institutionName"
                />
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[2]
                    .institutionName === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}{" "}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">State</label>
                <select
                  className="form-select mt-2 labelinput"
                  value={employeeIdForm.academicDetails.academics[2].state}
                  onChange={(e) => handleChange5(e, 2)}
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[2].state ? '14px' : '12px',
                    height: "38px",
                    fontFamily: 'Satoshi medium',
                    color: employeeIdForm.academicDetails.academics[2].state ? '#212529' : 'grey', 
                    border: '1px solid #dee2e6 !important'
                  }}
                  name="state"
                >
                  <option value="" className="select">--Select--</option>
                  <option value="Andhra Pradesh" style={{ color: "black", fontSize: "14px" }} >Andhra Pradesh</option>
                  <option value="Arunachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Arunachal Pradesh</option>
                  <option value="Assam" style={{ color: "black", fontSize: "14px" }} >Assam</option>
                  <option value="Bihar" style={{ color: "black", fontSize: "14px" }} >Bihar</option>
                  <option value="Chattisgarh" style={{ color: "black", fontSize: "14px" }} >Chattisgarh</option>
                  <option value="Gujarat" style={{ color: "black", fontSize: "14px" }} >Gujarat</option>
                  <option value="Haryana" style={{ color: "black", fontSize: "14px" }} >Haryana</option>
                  <option value="Himachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Himachal Pradesh</option>
                  <option value="Jharkhand" style={{ color: "black", fontSize: "14px" }} >Jharkhand</option>
                  <option value="Karnataka" style={{ color: "black", fontSize: "14px" }} >Karnataka</option>
                  <option value="Kerala" style={{ color: "black", fontSize: "14px" }} >Kerala</option>
                  <option value="Madhya Pradesh" style={{ color: "black", fontSize: "14px" }} >Madhya Pradesh</option>
                  <option value="Maharashtra" style={{ color: "black", fontSize: "14px" }} >Maharashtra</option>
                  <option value="Manipur" style={{ color: "black", fontSize: "14px" }} >Manipur</option>
                  <option value="Meghalaya" style={{ color: "black", fontSize: "14px" }} >Meghalaya</option>
                  <option value="Odisha" style={{ color: "black", fontSize: "14px" }} >Odisha</option>
                  <option value="Punjab" style={{ color: "black", fontSize: "14px" }} >Punjab</option>
                  <option value="Rajasthan" style={{ color: "black", fontSize: "14px" }} >Rajasthan</option>
                  <option value="Sikkim" style={{ color: "black", fontSize: "14px" }} >Sikkim</option>
                  <option value="Tamil Nadu" style={{ color: "black", fontSize: "14px" }} >Tamil Nadu</option>
                  <option value="Telangana" style={{ color: "black", fontSize: "14px" }} >Telangana</option>
                  <option value="Tripura" style={{ color: "black", fontSize: "14px" }} >Tripura</option>
                  <option value="Uttar Pradesh" style={{ color: "black", fontSize: "14px" }} >Uttar Pradesh</option>
                  <option value="Uttarakhand" style={{ color: "black", fontSize: "14px" }} >Uttarakhand</option>
                  <option value="West Bengal" style={{ color: "black", fontSize: "14px" }} >West Bengal</option>
                  {/* <option value="Correspondence">Correspondence</option>
                  <option value="Distance learning">Distance learning</option> */}
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[2].state === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>

              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">City/Town</label>
                <input
                  // type="number"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter City/Town"
                  value={employeeIdForm.academicDetails.academics[2].city}
                  onChange={(e) => handleChange5(e, 2)}
                  name="city"
                />
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[2].city === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              <div className="row mt-2">
                <div className="col-sm-4 col-md-6 col-lg-4">
                  <label className="labelinput">
                    Marksheet <span className="optional ms-2">Optional</span>
                  </label>
                  {/* <input
                      type="file"
                      className="form-control mt-2 labelinput"
                      // value={
                      //   employeeIdForm.teachingExperiences.details[index]
                      //     ?.file || setEmp.teachingExperiences.details[index]
                      //     ?.file
                      // }
                      // onChange={(e) => {
                      //   let val = e.target.value;
                      //   val =val.replace(/^\s+/, "");
                      //   // handleStateCreateEmp("file", e.target.value);
                      //   // handleChanges4(e, index);
                      //   handletechange(index,e,val)
                      // }}
                      onChange={(e) => handleChange5(e, 2)} 
                      name="file"
                    /> */}
                  <div className="input-group labelinput">
                    <label className="form-control boxText boxtextellipse browsetext inputWidth">
                      {
                        employeeIdForm.academicDetails.academics[2]
                          ?.markSheetFile
                      }
                      <input
                        type="file"
                        id={`markSheetFile_${2}`}
                        className="invisible"
                        name="markSheetFile"
                        onChange={(e) => {
                          let val = e.target.value;
                          val = val.replace(/^\s+/, "");
                          handleChange5(e, 2, val, `markSheetFile_${2}`);
                        }}
                      />
                    </label>
                    <span
                      className="input-group-text lname cursorPointer"
                      onClick={() =>
                        document.getElementById(`markSheetFile_${2}`)?.click()
                      }
                    >
                      Select File
                    </span>
                  </div>
                  <div className="optional mt-2">
                    Only pdf/jpg file under 2MB
                  </div>
                </div>
                <div className="col-sm-4 col-md-6 col-lg-4">
                  <label className="labelinput">
                  Degree / Provisional Certificate
                  </label>
                  <span className="optional ms-2">Optional</span>
                  <div className="input-group labelinput">
                    <label className="form-control boxText boxtextellipse browsetext inputWidth">
                      {
                        employeeIdForm.academicDetails.academics[2]
                          ?.courseCompletionCertificate
                      }
                      <input
                        type="file"
                        id={`courseCompletionCertificate_${2}`}
                        className="invisible"
                        name="courseCompletionCertificate"
                        onChange={(e) => {
                          let val = e.target.value;
                          val = val.replace(/^\s+/, "");
                          handleChange5(
                            e,
                            2,
                            val,
                            `courseCompletionCertificate_${2}`
                          );
                        }}
                      />
                    </label>
                    <span
                      className="input-group-text lname cursorPointer"
                      onClick={() =>
                        document
                          .getElementById(`courseCompletionCertificate_${2}`)
                          ?.click()
                      }
                    >
                      Select File
                    </span>
                  </div>
                  <div className="optional mt-2">
                    Only pdf/jpg file under 2MB
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 subheader">PG Program</div>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <div className="">
                  <label className="labelinput">Degree/Course Name</label>
                  {/* <select
                    className="form-select mt-2 labelinput"
                    value={employeeIdForm.academicDetails.academics[3].degree}
                    onChange={(e) => handleChange5(e, 3)}
                    style={{
                      fontSize: employeeIdForm.academicDetails.academics[3].degree ? '14px' : '12px',
                      height: "38px",
                      fontFamily: 'Satoshi medium',
                      color: employeeIdForm.academicDetails.academics[3].degree ? '#212529' : 'grey', 
                      border: '1px solid #dee2e6 !important'
                    }}
                    name="degree"
                  >
                    <option value="" className="select">--Select--</option>
                    <option
                      value="M.E"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.E{" "}
                    </option>
                    <option
                      value="M.Tech"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.Tech{" "}
                    </option>
                    <option
                      value="M.Sc"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.Sc{" "}
                    </option>
                    <option
                      value="M.C.A"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.C.A{" "}
                    </option>
                    <option
                      value="M.Com"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.Com{" "}
                    </option>
                    <option
                      value="M.A"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.A{" "}
                    </option>
                    <option
                      value="MS (Research)"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      MS (Research){" "}
                    </option>
                    <option
                      value="Ph.D"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      Ph.D
                    </option>
                    <option
                      value="Others"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      Others
                    </option>
                  </select> */}
                   {!pgDrop ? (
        <select
          className="form-select labelinput inputWidth"
          onChange={handlepgDropdownChange}
          style={{
            fontSize: "14px",
            height: "38px",
            fontFamily: "Satoshi medium",
            color: "black",
          }}
          name="degree"
        >
        <option value="" className="select">--Select--</option>
                    <option
                      value="M.E"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.E{" "}
                    </option>
                    <option
                      value="M.Tech"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.Tech{" "}
                    </option>
                    <option
                      value="M.Sc"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.Sc{" "}
                    </option>
                    <option
                      value="M.C.A"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.C.A{" "}
                    </option>
                    <option
                      value="M.Com"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.Com{" "}
                    </option>
                    <option
                      value="M.A"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      M.A{" "}
                    </option>
                    <option
                      value="MS (Research)"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {" "}
                      MS (Research){" "}
                    </option>
                    <option
                      value="Ph.D"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      Ph.D
                    </option>
                    <option
                      value="Others"
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      Others
                    </option>
        </select>
      ) : (
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            className="form-control inputWidth"
            placeholder="Enter your degree"
            onChange={handlepgInputChange}
            name="degree"
            style={{
              fontSize: "14px",
              height: "38px",
              fontFamily: "Satoshi medium",
              color: "black",
            }}
          />
          <button
            type="button"
            onClick={handlepgClearInput}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            X
          </button>
        </div>
      )}

                </div>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3].degree === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              {/* <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Specialization</label>
                <select
                  className="form-select mt-2 labelinput"
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                  value={
                    employeeIdForm.academicDetails.academics[3].specialization || setEmp.academicDetails.academics[3].specialization
                  }
                  onChange={(e) => handleChange5(e, 3)}
                  name="specialization"
                >
                  <option selected>--Select--</option>
                  <option value="Artificial Intelligence & Data Science">
                    {" "}
                    Artificial Intelligence & Data Science
                  </option>
                  <option value="Artificial Intelligence & Machine Learning">
                    {" "}
                    Artificial Intelligence & Machine Learning
                  </option>
                  <option value="Biomedical Engineering">
                    {" "}
                    Biomedical Engineering
                  </option>
                  <option value="Computer Science and Engineering">
                    {" "}
                    Computer Science and Engineering
                  </option>
                  <option value="Electronics and Communication Engineering">
                    {" "}
                    Electronics and Communication Engineering
                  </option>
                  <option value="Information Technology">
                    {" "}
                    Information Technology
                  </option>
                  <option value="Mechanical Engineering">
                    {" "}
                    Mechanical Engineering
                  </option>
                  <option value="Robotics and Automation">
                    {" "}
                    Robotics and Automation
                  </option>
                  <option value="Computer Applications">
                    {" "}
                    Computer Applications
                  </option>
                  <option value="Chemistry">Chemistry</option>
                  <option value="English">English</option>
                  <option value="Mathematics">Mathematics</option>
                  <option value="physics">Physics</option>
                  <option value="Others"> Others</option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3].specialization ===
                    "" && (
                    <span className="labelinput" style={{ color: "red" }}>
                      * Required
                    </span>
                  )}
              </div> */}
            </div>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-4 col-lg-3">
              <div style={{position:'relative'}}>
                <label className="labelinput">Month & Year of passing</label>
                <input
                  type="month"
                  className="form-control labelinput"
                  value={
                    employeeIdForm.academicDetails.academics[3].yearOfComplete
                  }
                  onChange={(e) => handleChange5(e, 3)}
                  name="yearOfComplete"
                  // max={currentDate}
                  max={lastMonthFormatted}
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                />
                <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
                </div>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3].yearOfComplete ===
                    "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">Mode</label>
                <select
                  className="form-select  labelinput"
                  value={employeeIdForm.academicDetails.academics[3].mode}
                  onChange={(e) => handleChange5(e, 3)}
                  name="mode"
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[3].mode ? '14px' : '12px',
                    height: "38px",
                    fontFamily: 'Satoshi medium',
                    color: employeeIdForm.academicDetails.academics[3].mode ? '#212529' : 'grey', 
                    border: '1px solid #dee2e6 !important'
                  }}
                >
                  <option value="" className="select">--Select--</option>
                  <option value="Full Time" style={{ color: "black", fontSize: "14px" }}>Full Time</option>
                  <option value="Part Time" style={{ color: "black", fontSize: "14px" }}>Part Time</option>
                  <option value="Correspondence" style={{ color: "black", fontSize: "14px" }}>Correspondence</option>
                  <option value="Distance Learning" style={{ color: "black", fontSize: "14px" }}>Distance Learning</option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3].mode === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">Class</label>
                <select
                  className="form-select  labelinput"
                  value={
                    employeeIdForm.academicDetails.academics[3].classOfComplete
                  }
                  onChange={(e) => handleChange5(e, 3)}
                  name="classOfComplete"
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[3].classOfComplete ? '14px' : '12px',
                    height: "38px",
                    fontFamily: 'Satoshi medium',
                    color: employeeIdForm.academicDetails.academics[3].classOfComplete ? '#212529' : 'grey', 
                    border: '1px solid #dee2e6 !important'
                  }}
                >
                   <option value="" className="select">--Select--</option>
                      <option value="First Class With Distinction"
                      style={{ color: "black", fontSize: "14px" }}
                      >
                        First class with Distinction
                      </option>
                      <option value="First" style={{ color: "black", fontSize: "14px" }} >First</option>
                      <option value="Second" style={{ color: "black", fontSize: "14px" }} >Second</option>
                      <option value="Third" style={{ color: "black", fontSize: "14px" }} >Third</option>
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3]
                    .classOfComplete === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}{" "}
              </div>

              <div className="col-sm-4 col-md-4 col-lg-3">
                <label className="labelinput" style={{whiteSpace:'nowrap'}}>Grade or % or CGPA</label>
                <div className="input-container">
                {/* <input
                  className="form-control  labelinput"
                  placeholder="Enter CGPA "
                  value={
                    employeeIdForm.academicDetails.academics[3].cgpa
                  }
                  onKeyPress={onlyNumber}
                  maxLength={4}
                  onChange={(e) => handleChange5(e, 3)}
                  name="cgpa"
                  // isAllowed={(values) => {
                  //   if (!values.value) return true;
                  //   const { floatValue = 0 } = values;
                  //   return floatValue < MAX_LIMIT;
                  // }}
                  // decimalScale={1}
                  // fixedDecimalScale
                /> */}
                <NumericFormat
                    className="form-control labelinput inputWidth"
                    value={formatInputValue(
                        employeeIdForm.academicDetails.academics[3].cgpa
                    )}
                    onChange={(e) => handleChange5(e, 3)}
                    maxLength={5}
                    isAllowed={(values) => {
                      if (!values.value) return true;
                      const { floatValue = 0 } = values;
                      return floatValue < MAX_LIMIT;
                    }}
                    name="cgpa"
                    decimalScale={1}
                    fixedDecimalScale
                    placeholder="Enter CGPA "
                  />

                 <div className="vertical-line"></div>
                 <span className="percentage-sign">%</span>
                </div>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3].cgpa === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}{" "}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">College</label>
                <input
                  type="text"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter College Name"
                  value={
                    employeeIdForm.academicDetails.academics[3].institutionName
                  }
                  onChange={(e) => handleChange5(e, 3)}
                  name="institutionName"
                />
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3]
                    .institutionName === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}{" "}
              </div>
              {/* <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">University</label>
                <input
                  type="text"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter University Name"
                  value={employeeIdForm.academicDetails.academics[3].university || setEmp.academicDetails.academics[3].university}
                  onChange={(e) => handleChange5(e, 3)}
                  name="university"
                />
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3].university ===
                    "" && (
                    <span className="labelinput" style={{ color: "red" }}>
                      * Required
                    </span>
                  )}{" "}
              </div> */}
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">State</label>
                <select
                  className="form-select mt-2 labelinput"
                  value={employeeIdForm.academicDetails.academics[3].state}
                  onChange={(e) => handleChange5(e, 3)}
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[3].state ? '14px' : '12px',
                    height: "38px",
                    fontFamily: 'Satoshi medium',
                    color: employeeIdForm.academicDetails.academics[3].state ? '#212529' : 'grey', 
                    border: '1px solid #dee2e6 !important'
                  }}
                  name="state"
                >
                <option value="" className="select">--Select--</option>
                  <option value="Andhra Pradesh" style={{ color: "black", fontSize: "14px" }} >Andhra Pradesh</option>
                  <option value="Arunachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Arunachal Pradesh</option>
                  <option value="Assam" style={{ color: "black", fontSize: "14px" }} >Assam</option>
                  <option value="Bihar" style={{ color: "black", fontSize: "14px" }} >Bihar</option>
                  <option value="Chattisgarh" style={{ color: "black", fontSize: "14px" }} >Chattisgarh</option>
                  <option value="Gujarat" style={{ color: "black", fontSize: "14px" }} >Gujarat</option>
                  <option value="Haryana" style={{ color: "black", fontSize: "14px" }} >Haryana</option>
                  <option value="Himachal Pradesh" style={{ color: "black", fontSize: "14px" }} >Himachal Pradesh</option>
                  <option value="Jharkhand" style={{ color: "black", fontSize: "14px" }} >Jharkhand</option>
                  <option value="Karnataka" style={{ color: "black", fontSize: "14px" }} >Karnataka</option>
                  <option value="Kerala" style={{ color: "black", fontSize: "14px" }} >Kerala</option>
                  <option value="Madhya Pradesh" style={{ color: "black", fontSize: "14px" }} >Madhya Pradesh</option>
                  <option value="Maharashtra" style={{ color: "black", fontSize: "14px" }} >Maharashtra</option>
                  <option value="Manipur" style={{ color: "black", fontSize: "14px" }} >Manipur</option>
                  <option value="Meghalaya" style={{ color: "black", fontSize: "14px" }} >Meghalaya</option>
                  <option value="Odisha" style={{ color: "black", fontSize: "14px" }} >Odisha</option>
                  <option value="Punjab" style={{ color: "black", fontSize: "14px" }} >Punjab</option>
                  <option value="Rajasthan" style={{ color: "black", fontSize: "14px" }} >Rajasthan</option>
                  <option value="Sikkim" style={{ color: "black", fontSize: "14px" }} >Sikkim</option>
                  <option value="Tamil Nadu" style={{ color: "black", fontSize: "14px" }} >Tamil Nadu</option>
                  <option value="Telangana" style={{ color: "black", fontSize: "14px" }} >Telangana</option>
                  <option value="Tripura" style={{ color: "black", fontSize: "14px" }} >Tripura</option>
                  <option value="Uttar Pradesh" style={{ color: "black", fontSize: "14px" }} >Uttar Pradesh</option>
                  <option value="Uttarakhand" style={{ color: "black", fontSize: "14px" }} >Uttarakhand</option>
                  <option value="West Bengal" style={{ color: "black", fontSize: "14px" }} >West Bengal</option>
                  {/* <option value="Correspondence">Correspondence</option>
                  <option value="Distance learning">Distance learning</option> */}
                </select>
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3].state === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>

              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">City/Town</label>
                <input
                  // type="number"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter City/Town"
                  value={employeeIdForm.academicDetails.academics[3].city}
                  onChange={(e) => handleChange5(e, 3)}
                  name="city"
                />
                {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[3].city === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )}
              </div>
              <div className="row mt-2">
                <div className="col-sm-4 col-md-6 col-lg-4">
                  <label className="labelinput">
                    Marksheet <span className="optional ms-2">Optional</span>
                  </label>
                  <div className="input-group labelinput">
                    <label className="form-control boxText boxtextellipse browsetext inputWidth">
                      {
                        employeeIdForm.academicDetails.academics[3]
                          ?.markSheetFile
                      }
                      <input
                        type="file"
                        id={`markSheetFile_${3}`}
                        className="invisible"
                        name="markSheetFile"
                        onChange={(e) => {
                          let val = e.target.value;
                          val = val.replace(/^\s+/, "");
                          handleChange5(e, 3, val, `markSheetFile_${2}`);
                        }}
                      />
                    </label>
                    <span
                      className="input-group-text lname cursorPointer"
                      onClick={() =>
                        document.getElementById(`markSheetFile_${3}`)?.click()
                      }
                    >
                      Select File
                    </span>
                  </div>
                  <div className="optional mt-2">
                    Only pdf/jpg file under 2MB
                  </div>
                </div>
                <div className="col-sm-4 col-md-6 col-lg-4">
                  <label className="labelinput">
                  Degree / Provisional Certificate
                  </label>
                  <span className="optional ms-2">Optional</span>
                  <div className="input-group labelinput">
                    <label className="form-control boxText boxtextellipse browsetext inputWidth">
                      {
                        employeeIdForm.academicDetails.academics[3]
                          ?.courseCompletionCertificate
                      }
                      <input
                        type="file"
                        id={`courseCompletionCertificate_${3}`}
                        className="invisible"
                        name="courseCompletionCertificate"
                        onChange={(e) => {
                          let val = e.target.value;
                          val = val.replace(/^\s+/, "");
                          handleChange5(
                            e,
                            3,
                            val,
                            `courseCompletionCertificate_${3}`
                          );
                        }}
                      />
                    </label>
                    <span
                      className="input-group-text lname cursorPointer"
                      onClick={() =>
                        document
                          .getElementById(`courseCompletionCertificate_${3}`)
                          ?.click()
                      }
                    >
                      Select File
                    </span>
                  </div>
                  <div className="optional mt-2">
                    Only pdf/jpg file under 2MB
                  </div>
                </div>
              </div>
            </div>

            {/* PHD PROGRAM  */}
            <div className="mt-3 subheader">Doctoral Degree</div>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <div className="">
                  <label className="labelinput">Degree/Course Name</label>
                  {!docDrop ? (
        <select
          className="form-select labelinput inputWidth"
          onChange={handleDropdownChange}
          style={{
            fontSize: "14px",
            height: "38px",
            fontFamily: "Satoshi medium",
            color: "black",
          }}
          name="degree"
        >
          <option value="">--Select--</option>
          <option value="Ph.D. (Information Technology)">Ph.D. (Information Technology)</option>
          <option value="Ph.D. (Chemical Engineering)">Ph.D. (Chemical Engineering)</option>
          <option value="Ph.D. (Engineering)">Ph.D. (Engineering)</option>
          <option value="Ph.D. (Electronics and Telecommunication)">Ph.D. (Electronics and Telecommunication)</option>
          <option value="Ph.D. (Computer Science)">Ph.D. (Computer Science)</option>
          <option value="Ph.D. (Information Technology)">Ph.D. (Information Technology)</option>
          <option value="Ph.D. (Instrumentation and Controls )">Ph.D. (Instrumentation and Controls)</option>
          <option value="Ph.D. (Mechanical Engineering)">Ph.D. (Mechanical Engineering)</option>
          <option value="Other">Other</option>
        </select>
      ) : (
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            className="form-control inputWidth"
            placeholder="Enter your degree"
            onChange={handleInputChange}
            name="degree"
            style={{
              fontSize: "14px",
              height: "38px",
              fontFamily: "Satoshi medium",
              color: "black",
            }}
          />
          <button
            type="button"
            onClick={handleClearInput}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            X
          </button>
        </div>
      )}

                </div>
                {/* {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[4].degree === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )} */}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Specialization</label>
                <input
                  className="form-control  labelinput inputWidth"
                  // style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                 
                  value={
                    employeeIdForm.academicDetails.academics[4]
                      .specialization 
                  }
                  onChange={(e) => handleChange5(e, 4)}
                  name="specialization"
                  placeholder="Enter specialization"
                />
                {/* {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[4].specialization ===
                    "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )} */}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-4 col-md-4 col-lg-4">
              <div style={{position:'relative'}}>
                <label className="labelinput">Month & year of Passing</label>
                <input
                  type="month"
                  className="form-control  labelinput"
                  value={
                    employeeIdForm.academicDetails.academics[4].yearOfComplete
                  }
                  onChange={(e) => handleChange5(e, 4)}
                  name="yearOfComplete"
                  // max={currentDate}
                  max={lastMonthFormatted}
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                />
                <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
                </div>
                {/* {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[4].yearOfComplete ===
                    "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )} */}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <label className="labelinput">Mode</label>
                <select
                  className="form-select  labelinput"
                  value={employeeIdForm.academicDetails.academics[4].mode}
                  onChange={(e) => handleChange5(e, 4)}
                  name="mode"
                  // style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[4].mode
                      ? "14px"
                      : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.academicDetails.academics[4].mode
                      ? "black"
                      : "grey",
                  }}
                >
                  <option value="" className="select">
                    --Select--
                  </option>
                  <option value="Full Time" style={{ color: "black", fontSize: "14px" }}>Full Time</option>
                  <option value="Part Time" style={{ color: "black", fontSize: "14px" }}>Part Time</option>
                  <option value="Correspondence" style={{ color: "black", fontSize: "14px" }}>Correspondence</option>
                  <option value="Distance Learning" style={{ color: "black", fontSize: "14px" }}>Distance Learning</option>
                </select>
                {/* {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[4].mode === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )} */}
              </div>
              
            </div>
            <div className="row mt-3">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">University / Institution</label>
                <input
                  type="text"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter College/Institute Name"
                  value={
                    employeeIdForm.academicDetails.academics[4].institutionName
                  }
                  onChange={(e) => handleChange5(e, 4)}
                  name="institutionName"
                />
                {/* {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[4]
                    .institutionName === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )} */}
              </div>
              {/* <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">University</label>
                <input
                  type="text"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter University Name"
                  value={employeeIdForm.academicDetails.academics[4].university || setEmp.academicDetails.academics[4].university}
                  onChange={(e) => handleChange5(e, 4)}
                  name="university"
                /> */}
              {/* {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[4].university ===
                    "" && (
                    <span className="labelinput" style={{ color: "red" }}>
                      * Required
                    </span>
                  )} */}
              {/* </div> */}
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">State</label>
                <select
                  className="form-select mt-2 labelinput"
                  value={employeeIdForm.academicDetails.academics[4].state}
                  onChange={(e) => handleChange5(e, 4)}
                  style={{
                    fontSize: employeeIdForm.academicDetails.academics[4]
                      .state
                      ? "14px"
                      : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.academicDetails.academics[4].state
                      ? "black"
                      : "grey",
                  }}
                  name="state"
                >
                  <option value="" className="select" >--Select--</option>
                  <option value="Andhra Pradesh" style={{ color: "black", fontSize: "14px" }}>Andhra Pradesh</option>
                  <option value="Arunachal Pradesh" style={{ color: "black", fontSize: "14px" }}>Arunachal Pradesh</option>
                  <option value="Assam" style={{ color: "black", fontSize: "14px" }}>Assam</option>
                  <option value="Bihar" style={{ color: "black", fontSize: "14px" }}>Bihar</option>
                  <option value="Chattisgarh" style={{ color: "black", fontSize: "14px" }}>Chattisgarh</option>
                  <option value="Gujarat" style={{ color: "black", fontSize: "14px" }}>Gujarat</option>
                  <option value="Haryana" style={{ color: "black", fontSize: "14px" }}>Haryana</option>
                  <option value="Himachal Pradesh" style={{ color: "black", fontSize: "14px" }}>Himachal Pradesh</option>
                  <option value="Jharkhand" style={{ color: "black", fontSize: "14px" }}>Jharkhand</option>
                  <option value="Karnataka" style={{ color: "black", fontSize: "14px" }}>Karnataka</option>
                  <option value="Kerala" style={{ color: "black", fontSize: "14px" }}>Kerala</option>
                  <option value="Madhya Pradesh" style={{ color: "black", fontSize: "14px" }}>Madhya Pradesh</option>
                  <option value="Maharashtra" style={{ color: "black", fontSize: "14px" }}>Maharashtra</option>
                  <option value="Manipur" style={{ color: "black", fontSize: "14px" }}>Manipur</option>
                  <option value="Meghalaya" style={{ color: "black", fontSize: "14px" }}>Meghalaya</option>
                  <option value="Odisha" style={{ color: "black", fontSize: "14px" }}>Odisha</option>
                  <option value="Punjab" style={{ color: "black", fontSize: "14px" }}>Punjab</option>
                  <option value="Rajasthan" style={{ color: "black", fontSize: "14px" }}>Rajasthan</option>
                  <option value="Sikkim" style={{ color: "black", fontSize: "14px" }}>Sikkim</option>
                  <option value="Tamil Nadu" style={{ color: "black", fontSize: "14px" }}>Tamil Nadu</option>
                  <option value="Telangana" style={{ color: "black", fontSize: "14px" }}>Telangana</option>
                  <option value="Tripura" style={{ color: "black", fontSize: "14px" }}>Tripura</option>
                  <option value="Uttar Pradesh" style={{ color: "black", fontSize: "14px" }}>Uttar Pradesh</option>
                  <option value="Uttarakhand" style={{ color: "black", fontSize: "14px" }}>Uttarakhand</option>
                  <option value="West Bengal" style={{ color: "black", fontSize: "14px" }}>West Bengal</option>
                  {/* <option value="Correspondence">Correspondence</option>
                  <option value="Distance learning">Distance learning</option> */}
                </select>
                {/* {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[4].state === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )} */}
              </div>

              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput">City/Town</label>
                <input
                  // type="number"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter City/Town"
                  value={employeeIdForm.academicDetails.academics[4].city}
                  onChange={(e) => handleChange5(e, 4)}
                  name="city"
                />
                {/* {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[4].city === "" && (
                    <span className="labelinput" style={{ color: "#dc3545" }}>
                      * Required
                    </span>
                  )} */}
              </div>
              <div className="row mt-2">
                <div className="col-sm-4 col-md-6 col-lg-4">
                  <label className="labelinput">
                    Marksheet <span className="optional ms-2">Optional</span>
                  </label>
                  <div className="input-group labelinput">
                    <label className="form-control boxText boxtextellipse browsetext inputWidth">
                      {
                        employeeIdForm.academicDetails.academics[4]
                          ?.markSheetFile
                      }
                      <input
                        type="file"
                        id={`markSheetFile_${4}`}
                        className="invisible"
                        name="markSheetFile"
                        onChange={(e) => {
                          let val = e.target.value;
                          val = val.replace(/^\s+/, "");
                          handleChange5(e, 4, val, `markSheetFile_${4}`);
                        }}
                      />
                    </label>
                    <span
                      className="input-group-text lname cursorPointer"
                      onClick={() =>
                        document.getElementById(`markSheetFile_${4}`)?.click()
                      }
                    >
                      Select File
                    </span>
                  </div>
                  <div className="optional mt-2">
                    Only pdf/jpg file under 2MB
                  </div>
                </div>
                <div className="col-sm-4 col-md-6 col-lg-4">
                  <label className="labelinput">
                  Degree / Provisional Certificate
                  </label>
                  <span className="optional ms-2">Optional</span>
                  <div className="input-group labelinput">
                    <label className="form-control boxText boxtextellipse browsetext inputWidth">
                      {
                        employeeIdForm.academicDetails.academics[4]
                          ?.courseCompletionCertificate
                      }
                      <input
                        type="file"
                        id={`courseCompletionCertificate_${4}`}
                        className="invisible"
                        name="courseCompletionCertificate"
                        onChange={(e) => {
                          let val = e.target.value;
                          val = val.replace(/^\s+/, "");
                          handleChange5(
                            e,
                            4,
                            val,
                            `courseCompletionCertificate_${4}`
                          );
                        }}
                      />
                    </label>
                    <span
                      className="input-group-text lname cursorPointer"
                      onClick={() =>
                        document
                          .getElementById(`courseCompletionCertificate_${4}`)
                          ?.click()
                      }
                    >
                      Select File
                    </span>
                  </div>
                  <div className="optional mt-2">
                    Only pdf/jpg file under 2MB
                  </div>
                </div>
                </div>
              <div className="row">
              <div className="col-sm-4 col-md-4 col-lg-4">
        <div className="form-group">
          <label className="labelinput">Remarks</label>
          <textarea 
           className="form-control labelinput inputWidth" 
          //  style={{height:"14px"}}
           value={
            employeeIdForm.academicDetails.academics[4]
              .remarks
          }
          onChange={(e) => handleChange5(e, 4)}
          name="remarks"
           ></textarea>
        </div>
      </div>
              </div>
            </div>
            <div className="mt-3 subheader">Other Program</div>

            <div className="row mt-2">
              <div>
                {employeeIdForm.otherAcademics.academics.map(
                  (academics, index) => (
                    <div>
                      <div className="row">
                        <div
                          className="col-sm-4 col-md-6 col-lg-4 mt-2"
                          key={index}
                        >
                          <div>
                            <label className="labelinput">
                              Degree/Course Name
                            </label>
                            <input
                              className="form-control mt-2 labelinput"
                              value={academics.degree}
                              onChange={(e) =>
                                handleChangeOtherAcademics(index, e)
                              }
                              placeholder="Enter degree/course Name"
                              type="text"
                              name="degree"
                            />
                          </div>
                        </div>
                        <div
                          className="col-sm-4 col-md-6 col-lg-4 mt-2"
                          key={index}
                        >
                          <label className="labelinput">Specialization</label>
                          <input
                            className="form-control mt-2 labelinput"
                            value={academics.specialization}
                            onChange={(e) =>
                              handleChangeOtherAcademics(index, e)
                            }
                            name="specialization"
                            type="text"
                            placeholder="Enter Specialization"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-4 col-md-4 col-lg-3" key={index}>
                        <div style={{position:'relative'}}>
                          <label className="labelinput">Month & year of Passing</label>
                          <input
                            type="month"
                            className="form-control  labelinput"
                            value={academics.yearOfComplete}
                            onChange={(e) =>
                              handleChangeOtherAcademics(index, e)
                            }
                            name="yearOfComplete"
                            max={lastMonthFormatted}
                            style={{
                              fontSize: "14px",
                              fontFamily: "Satoshi medium",
                            }}
                          />
                          <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-2">
                          <label className="labelinput">Mode</label>
                          <select
                            className="form-select  labelinput"
                            value={academics.mode}
                            onChange={(e) =>
                              handleChangeOtherAcademics(index, e)
                            }
                            name="mode"
                            style={{
                              fontSize: academics.mode ? "14px" : "12px",
                              height: "38px",
                              fontFamily: "Satoshi medium",
                              color: academics.mode ? "black" : "grey",
                            }}
                          >
                            <option value="" className="select">--Select--</option>
                            <option value="Full Time" style={{ color: "black", fontSize: "14px" }}>Full Time</option>
                            <option value="Part Time" style={{ color: "black", fontSize: "14px" }}>Part Time</option>
                            <option value="Correspondence" style={{ color: "black", fontSize: "14px" }}>
                              Correspondence
                            </option>
                            <option value="Distance Learning" style={{ color: "black", fontSize: "14px" }}>
                              Distance Learning
                            </option>
                          </select>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-2">
                          <label className="labelinput">Class</label>
                          <select
                            className="form-select  labelinput"
                            value={academics.classOfComplete}
                            onChange={(e) =>
                              handleChangeOtherAcademics(index, e)
                            }
                            name="classOfComplete"
                            style={{
                              fontSize: academics.classOfComplete ? "14px" : "12px",
                              height: "38px",
                              fontFamily: "Satoshi medium",
                              color: academics.classOfComplete ? "black" : "grey",
                            }}
                          >
                            <option value="" className="select">--Select--</option>
                      <option value="First Class With Distinction"
                      style={{ color: "black", fontSize: "14px" }}
                      >
                        First class with Distinction
                      </option>
                      <option value="First" style={{ color: "black", fontSize: "14px" }} >First</option>
                      <option value="Second" style={{ color: "black", fontSize: "14px" }} >Second</option>
                      <option value="Third" style={{ color: "black", fontSize: "14px" }} >Third</option>
                          </select>
                        </div>

                        <div className="col-sm-4 col-md-4 col-lg-3">
                          <label className="labelinput" style={{whiteSpace:'nowrap'}}>
                            Grade or % or CGPA
                          </label>
                          <div className="input-container">
                          {/* <input
                            className="form-control  labelinput"
                            placeholder="Enter CGPA "
                            // value={
                            //   formatInputValue(
                            //     (setEmp ? setEmp.academicDetails.academics[4].cgpa : null) ||
                            //     employeeIdForm.academicDetails.academics[4].cgpa
                            //   )
                            // }
                            value={academics.cgpa}
                            onChange={(e) =>
                              handleChangeOtherAcademics(index, e)
                            }
                            onKeyPress={onlyNumber}
                            maxLength={4}
                            // isAllowed={(values) => {
                            //   if (!values.value) return true;
                            //   const { floatValue = 0 } = values;
                            //   return floatValue < MAX_LIMIT;
                            // }}
                            name="cgpa"
                            // decimalScale={1}
                            // fixedDecimalScale
                          /> */}
                          <NumericFormat
                    className="form-control labelinput inputWidth"
                    value={academics.cgpa}
                    onChange={(e) => handleChangeOtherAcademics(index, e)}
                    maxLength={5}
                    isAllowed={(values) => {
                      if (!values.value) return true;
                      const { floatValue = 0 } = values;
                      return floatValue < MAX_LIMIT;
                    }}
                    name="cgpa"
                    decimalScale={1}
                    fixedDecimalScale
                    placeholder="Enter CGPA "
                  />

                           <div className="vertical-line"></div>
                           <span className="percentage-sign">%</span>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-4 col-md-6 col-lg-4 labelinput">
                          <label className="labelinput"> College / Institute</label>
                          <input
                            type="text"
                            className="form-control mt-2 labelinput"
                            placeholder="Enter College/Institute Name"
                            value={academics.institutionName}
                            onChange={(e) =>
                              handleChangeOtherAcademics(index, e)
                            }
                            name="institutionName"
                          />
                        </div>

                        <div className="col-sm-4 col-md-4 col-lg-2">
                          <label className="labelinput">State</label>
                          <select
                            className="form-select mt-2 labelinput"
                            value={academics.state}
                            onChange={(e) =>
                              handleChangeOtherAcademics(index, e)
                            }
                            style={{
                              fontSize: academics.state ? "14px" : "12px",
                              height: "38px",
                              fontFamily: "Satoshi medium",
                              color: academics.state ? "black" : "grey",
                            }}
                            name="state"
                          >
                            <option value="" className="select">--Select--</option>
                            <option value="Andhra Pradesh" style={{ color: "black", fontSize: "14px" }}>
                              Andhra Pradesh
                            </option>
                            <option value="Arunachal Pradesh" style={{ color: "black", fontSize: "14px" }}>
                              Arunachal Pradesh
                            </option>
                            <option value="Assam" style={{ color: "black", fontSize: "14px" }}>Assam</option>
                            <option value="Bihar" style={{ color: "black", fontSize: "14px" }}>Bihar</option>
                            <option value="Chattisgarh" style={{ color: "black", fontSize: "14px" }}>Chattisgarh</option>
                            <option value="Gujarat" style={{ color: "black", fontSize: "14px" }}>Gujarat</option>
                            <option value="Haryana" style={{ color: "black", fontSize: "14px" }}>Haryana</option>
                            <option value="Himachal Pradesh" style={{ color: "black", fontSize: "14px" }}>
                              Himachal Pradesh
                            </option>
                            <option value="Jharkhand" style={{ color: "black", fontSize: "14px" }}>Jharkhand</option>
                            <option value="Karnataka" style={{ color: "black", fontSize: "14px" }}>Karnataka</option>
                            <option value="Kerala" style={{ color: "black", fontSize: "14px" }}>Kerala</option>
                            <option value="Madhya Pradesh" style={{ color: "black", fontSize: "14px" }}>
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra" style={{ color: "black", fontSize: "14px" }}>Maharashtra</option>
                            <option value="Manipur" style={{ color: "black", fontSize: "14px" }}>Manipur</option>
                            <option value="Meghalaya" style={{ color: "black", fontSize: "14px" }}>Meghalaya</option>
                            <option value="Odisha" style={{ color: "black", fontSize: "14px" }}>Odisha</option>
                            <option value="Punjab" style={{ color: "black", fontSize: "14px" }}>Punjab</option>
                            <option value="Rajasthan" style={{ color: "black", fontSize: "14px" }}>Rajasthan</option>
                            <option value="Sikkim" style={{ color: "black", fontSize: "14px" }}>Sikkim</option>
                            <option value="Tamil Nadu" style={{ color: "black", fontSize: "14px" }}>Tamil Nadu</option>
                            <option value="Telangana" style={{ color: "black", fontSize: "14px" }}>Telangana</option>
                            <option value="Tripura" style={{ color: "black", fontSize: "14px" }}>Tripura</option>
                            <option value="Uttar Pradesh" style={{ color: "black", fontSize: "14px" }}>Uttar Pradesh</option>
                            <option value="Uttarakhand" style={{ color: "black", fontSize: "14px" }}>Uttarakhand</option>
                            <option value="West Bengal" style={{ color: "black", fontSize: "14px" }}>West Bengal</option>
                            {/* <option value="Correspondence">Correspondence</option>
                  <option value="Distance learning">Distance learning</option> */}
                            {nextButtonClicked &&
                              employeeIdForm.academicDetails.academics[1]
                                .state === "" && (
                                <span
                                  className="labelinput"
                                  style={{ color: "red" }}
                                >
                                  * Required
                                </span>
                              )}
                          </select>
                        </div>

                        <div className="col-sm-4 col-md-4 col-lg-2">
                          <label className="labelinput">City/Town</label>
                          <input
                            // type="number"
                            className="form-control mt-2 labelinput"
                            placeholder="Enter City/Town"
                            value={academics.city}
                            onChange={(e) =>
                              handleChangeOtherAcademics(index, e)
                            }
                            name="city"
                          />
                          {/* {nextButtonClicked &&
                  employeeIdForm.academicDetails.academics[1].cgpa === "" && (
                    <span className="labelinput" style={{ color: "red" }}>
                      * Required
                    </span>
                  )} */}
                        </div>
                        <div className="row mt-2">
                          <div className="col-sm-4 col-md-6 col-lg-4">
                            <label className="labelinput">
                              Marksheet{" "}
                              <span className="optional ms-2">Optional</span>
                            </label>
                            <div className="input-group labelinput">
                              <label className="form-control boxText boxtextellipse browsetext inputWidth">
                                {
                                  employeeIdForm.otherAcademics.academics[index]
                                    ?.markSheetFile
                                }
                                <input
                                  type="file"
                                  id={`fileInputOther_${index}`} // Use a unique ID for each input
                                  className="invisible"
                                  // value={
                                  //   employeeIdForm.otherAcademics.academics[index]?.markSheetFile
                                  //   || setEmp.otherAcademics.academics[index]?.markSheetFile
                                  // }
                                  name="markSheetFile"
                                  onChange={(e) => {
                                    let val = e.target.value;
                                    val = val.replace(/^\s+/, "");
                                    handleChangeOtherAcademics(
                                      index,
                                      e,
                                      val,
                                      `fileInputOther_${index}`
                                    );
                                  }}
                                />
                              </label>
                              <span
                                className="input-group-text lname cursorPointer"
                                onClick={() =>
                                  document
                                    .getElementById(`fileInputOther_${index}`)
                                    ?.click()
                                }
                              >
                                Select File
                              </span>
                            </div>
                            <div className="optional mt-2">
                              Only pdf/jpg file under 2MB
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-6 col-lg-4">
                            <label className="labelinput">
                              Course Completion Certificate
                            </label>
                            <span className="optional ms-2">Optional</span>
                            <div className="input-group labelinput">
                              <label className="form-control boxText boxtextellipse browsetext inputWidth">
                                {
                                  employeeIdForm.otherAcademics.academics[index]
                                    ?.courseCompletionCertificate
                                }
                                <input
                                  type="file"
                                  id={`fileInputCourseOther_${index}`}
                                  className="invisible"
                                  name="courseCompletionCertificate"
                                  // value={academic.courseCompletionCertificate}
                                  // value={
                                  //   employeeIdForm.otherAcademics.academics[index]?.courseCompletionCertificate
                                  //   || setEmp.otherAcademics.academics[index]?.courseCompletionCertificate
                                  // }
                                  onChange={(e) => {
                                    let val = e.target.value;
                                    val = val.replace(/^\s+/, "");
                                    // base64ConverterFile(e,`fileInputCourseOther_${index}`, index);
                                    handleChangeOtherAcademics(
                                      index,
                                      e,
                                      val,
                                      `fileInputCourseOther_${index}`
                                    );
                                  }}
                                />
                              </label>
                              <span
                                className="input-group-text lname cursorPointer"
                                onClick={() =>
                                  document
                                    .getElementById(
                                      `fileInputCourseOther_${index}`
                                    )
                                    ?.click()
                                }
                              >
                                Select File
                              </span>
                            </div>
                            <div className="optional mt-2">
                              Only pdf/jpg file under 2MB
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="col-sm-4 col-md-6 col-lg-4 mt-3">
              <button className="btn addMore">
                <span onClick={addMoreDegrees}>+ Add more</span>
              </button>
            </div>
            {/* <button type="submit" className="btn btn-primary">Submit</button> */}
          </form>
        </div>

          <div
            className={`tab-pane fade ${activeIndex === 2 ? "show active" : ""}`}
            id="workexperienceInfo"
            role="tabpanel"
            aria-labelledby="workexperience_Info"
            style={{ display: activeIndex === 2 ? "block" : "none" }}
          >
            <form onSubmit={handleSubmit}>
              <div className="row ">
                <div className="col-sm-4 col-md-6 col-lg-4">
                  <label className="subheader">Teaching Experience</label>
                  <br></br>
                  <label className="greyfont">Only Engineering College</label>
                </div>
                <div className="col-sm-4 col-md-6 col-lg-4">
                  {/* <label className="labelinput">Total years of experience</label> */}
                  <input
  type="text" 
  readOnly
  className="form-control mt-2 labelinput non-selectable inputWidth"
  placeholder="Total Experience (in yrs)"
  value={calculateTotalExperience()} 
  onChange={(e) => handletechange(index, e)}
  style={{ backgroundColor: "#F7F8FA", border: "none" }}
/>

                </div>
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Any prior work experience?</label>
                <div className="mt-2">
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput "
                      name="teachExp"
                      value="yes"
                      checked={employeeIdForm.teachExp === "yes"}
                      onChange={handleChanges7}
                      onClick={PriorExp}
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="teachExp"
                      value="no"
                      checked={employeeIdForm.teachExp === "no"}
                      onClick={PriorExpNo}
                      onChange={handleChanges7}
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
                {nextButtonClicked && employeeIdForm.teachExp === "" && (
                  <span className="labelinput" style={{ color: "#dc3545" }}>
                    * Required
                  </span>
                )}
              </div>
              {teachingYes &&
  <div className="col-sm-4 col-md-6 col-lg-4 mt-4">
  <label className="labelinput">Currently Working in KEC?</label>
  <div className="mt-2">
    <div className="form-check form-check-inline labelinput">
      <input
        type="radio"
        className="form-check-input me-2 labelinput"
        // name={employeeIdForm.previouslyWorkedInKings}
        name="previouslyWorkedInKings"
        value="yes"
        checked={employeeIdForm.previouslyWorkedInKings === "yes"}
        onChange={(e) => handleCurrentWorkChange(e, index, true)}
      />
      <label className="form-check-label">Yes</label>
    </div>
    <div className="form-check form-check-inline labelinput">
      <input
        type="radio"
        className="form-check-input me-2 labelinput"
        // name={employeeIdForm.previouslyWorkedInKings}
            name="previouslyWorkedInKings"
        value="no"
        checked={employeeIdForm.previouslyWorkedInKings === "no"}
        onChange={(e) => handleCurrentWorkChange(e, index, false)}
      />
      <label className="form-check-label">No</label>
    </div>
  </div>
</div>
}
              {employeeIdForm.teachingExperiences.details.map((item, index) => (
                <>
                  <div>
                    <div className="row">
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <label className="labelinput">Institution</label>
                        <input
                          type="type"
                          className="form-control mt-2 labelinput inputWidth"
                          placeholder="Enter Institution Name"
                          value={
                            employeeIdForm.teachingExperiences.details[index]
                              ?.institution ||
                            setEmp.teachingExperiences.details[index]?.institution
                          }
                          onChange={(e) => {
                            handletechange(index, e);
                            // handleStateCreateEmp("institution", e.target.value);
                            // handleChanges4(e, index);
                          }}
                          name="institution"
                          disabled={employeeIdForm.teachingExperiences.details[index]
                            ?.institution == "Kings Engineering College" || employeeIdForm.teachExp === "no"}
                        />
                        {nextButtonClicked &&
                          employeeIdForm.teachingExperiences.details[index]
                            ?.institution === "" &&
                          employeeIdForm.teachExp === "yes" && (
                            <span className="labelinput" style={{ color: "red" }}>
                              * Required
                            </span>
                          )}
                      </div>
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <label className="labelinput pb-0">Designation</label>
                        <input
                          className="form-control mt-2 labelinput inputWidth"
                          // placeholder='Enter Designation Name'
                          value={
                            employeeIdForm.teachingExperiences.details[index]
                              ?.designation ||
                            setEmp.teachingExperiences.details[index]?.designation
                          }
                          onChange={(e: any) => {
                            handletechange(index, e);
                          }}
                          name="designation"
                          disabled={employeeIdForm.teachExp === "no"}
                          placeholder="Enter Designation"
                        ></input>
                        {nextButtonClicked &&
                          employeeIdForm.teachingExperiences.details[index]
                            ?.designation === "" &&
                          employeeIdForm.teachExp === "yes" && (
                            <span className="labelinput" style={{ color: "red" }}>
                              * Required
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-sm-4 col-md-6 col-lg-4">
                        <label className="labelinput pb-0">Department</label>
                        <input
                          type="type"
                          className="form-control mt-2 labelinput inputWidth"
                          placeholder="Enter Department "
                          value={
                            employeeIdForm.teachingExperiences.details[index]
                              ?.department
                          }
                          name="department"
                          onChange={(e) => {
                            handletechange(index, e);
                          }}
                          disabled={employeeIdForm.teachExp === "no"}
                        />
                        {nextButtonClicked &&
                          employeeIdForm.teachingExperiences.details[index]
                            ?.department === "" &&
                          employeeIdForm.teachExp === "yes" && (
                            <span className="labelinput" style={{ color: "red" }}>
                              * Required
                            </span>
                          )}
                      </div>
                      <div className="col-sm-4 col-md-3 col-lg-2">
          <label className="labelinput">From Date</label>
          <input
            type="date"
            className="form-control mt-2 labelinput inputWidth"
            value={employeeIdForm.teachingExperiences.details[index]?.fromDate}
            max={currentDate}
            onChange={(e) => handletechange(index, e)}
            name="fromDate"
            disabled={employeeIdForm.teachExp === 'no'}
            style={{
              fontSize: '14px',
              fontFamily: 'Satoshi medium',
            }}
            placeholder="Select date"
          />
          {nextButtonClicked &&
            employeeIdForm.teachingExperiences.details[index]?.fromDate === '' &&
            employeeIdForm.teachExp === 'yes' && (
              <span className="labelinput" style={{ color: 'red' }}>
                * Required
              </span>
            )}
        </div>

        <div className="col-sm-4 col-md-3 col-lg-2">
          <label className="labelinput">To Date</label>
          <input
            type="date"
            className="form-control mt-2 labelinput inputWidth"
            value={employeeIdForm.teachingExperiences.details[index]?.toDate}
            onChange={(e) => handletechange(index, e)}
            name="toDate"
            // disabled={employeeIdForm.teachExp === 'no'}  
            disabled={employeeIdForm.teachingExperiences.details[index]?.toDate === new Date().toISOString().split('T')[0] && employeeIdForm.previouslyWorkedInKings === "yes" || employeeIdForm.teachExp === "no"}
             min={employeeIdForm.teachingExperiences.details[index]?.fromDate}
            max={currentDate}
            style={{
              fontSize: '14px',
              fontFamily: 'Satoshi medium',
            }}
          />
          {nextButtonClicked &&
            employeeIdForm.teachingExperiences.details[index]?.toDate === '' &&
            employeeIdForm.teachExp === 'yes' && (
              <span className="labelinput" style={{ color: 'red' }}>
                * Required
              </span>
            )}
        </div>
      </div>
                    <div className="row mt-4">
                    <div className="col-sm-4 col-md-6 col-lg-4">
    <div className="d-flex mt-3 justify-content-between">
      <label className="labelinput">Experience Letter</label>
      <span className="ms-5 optional labelinput">
        Only pdf/jpg file under 2MB
      </span>
    </div>

    <div className="input-group labelinput">
      <label className={`form-control boxText boxtextellipse browsetext inputWidth ${employeeIdForm.teachExp === "no" ? 'disabled-input-label' : ''}`}>
        {employeeIdForm.teachingExperiences.details[index]?.file ||
          setEmp.teachingExperiences.details[index]?.file}
        <input
          type="file"
          id={`fileT_${index}`}
          className="invisible"
          name="file"
          onChange={(e) => {
            let val = e.target.value;
            val = val.replace(/^\s+/, "");
            handletechange(index, e, val, `file_${index}`);
          }}
          disabled={employeeIdForm.teachExp === "no"}
        />
      </label>
      <span
        className="input-group-text lname cursorPointer"
        onClick={() =>
          document.getElementById(`fileT_${index}`)?.click()
        }
      >
        Select File
      </span>
    </div>
  </div>

  <div className="col-sm-4 col-md-4 col-lg-2 mt-2">
  <label className="labelinput">Experiences (in yrs)</label>
  <NumericFormat
    type="text"
    placeholder="Enter Experience (in yrs)"
    className="form-control labelinput inputWidth"
    value={
      employeeIdForm.teachingExperiences.details[index].experience
    }
    isAllowed={(values) => {
      if (!values.value) return true;
      const { floatValue = 0 } = values;
      return floatValue < MAX_LIMIT;
    }}
    maxLength={5}
    onChange={(e) => {
      let val = e.target.value;
      val = val.replace(/[^\d.]/g, "");
      handletechange(index, e, val);
    }}
    name="experience"
    disabled
    decimalScale={2}
    fixedDecimalScale={false}
  />
  {/* {nextButtonClicked &&
    employeeIdForm.teachingExperiences.details[index]?.experience === "" &&
    employeeIdForm.teachExp === "yes" && (
      <span className="labelinput" style={{ color: "red" }}>
        * Required
      </span>
    )} */}
</div>

                    </div>
                  </div>
                </>
              ))}
              <div className="col-sm-4 col-md-6 col-lg-4 mt-4 ">
    <button className="btn addMore" disabled={employeeIdForm.teachExp === 'no'}>
      <span onClick={addMoreFields}>+ Add more</span>
    </button>
  </div>
              {/* <div className="d-flex flex-wrap mt-2">
          <div className="form-check d-flex align-items-center me-4 labelinput">
            <label className="form-check-label" htmlFor="faculty">workexperience</label>
          </div>
        </div>
        {isSubmitted && !employeeBasicForm.type && <div className="is-invalid">*Required</div>} */}

              {/* <button type="submit" className="btn btn-primary">Submit</button> */}

              {/* other experience  */}

              {/* Your existing form structure */}
                <div className="row mt-4">
                  <div className="col-sm-4 col-md-6 col-lg-4">
                    <label className="subheader">Other experience</label>
                    <br></br>
                    <label className="greyfont">
                      Research, Industry, Teaching in Arts & Science, Diploma,
                      School,
                    </label>
                  </div>
                  <div className="col-sm-4 col-md-6 col-lg-4 labelinput">
                    {/* <label className="labelinput">Total years of experience</label> */}
                    {/* <input
                      type="number"
                      readOnly
                      className="form-control mt-2 labelinput non-selectable"
                      placeholder="Total Experience (in yrs)"
                      style={{ backgroundColor: "#F7F8FA", border: "none" }}
                      // value={employeeIdForm.otherExperiences.details[0].experience  }

                      value={calculateTotalOther()}
                      // value={experience.experience}
                      onChange={(e) => handlechangeOtherExperiences(e, index)}
                      name="experience"
                    /> */}
                    <input
  type="text" 
  readOnly
  className="form-control mt-2 labelinput non-selectable inputWidth"
  placeholder="Total Other Experience (in yrs)"
  value={calculateTotalOther()} 
  onChange={(e) => handlechangeOtherExperiences(e, index)}
  style={{ backgroundColor: "#F7F8FA", border: "none" }}
/>
                  </div>

                  
                </div>
                
                {employeeIdForm.otherExperiences.details.map(
                  (experience, index) => (
                    <div className="row" key={index}>
                      <div className="row">
                      {index === 0 && (
        <div className="col-sm-4 col-md-6 col-lg-4">
          <label className="labelinput">Any prior work experience?</label>
          <div className="mt-2">
            <div className="form-check form-check-inline labelinput">
              <input
                type="radio"
                className="form-check-input me-2 labelinput"
                name="otherExp"
                value="yes"
                checked={employeeIdForm.otherExp === "yes"}
                onChange={handleChanges7}
                onClick={othersExps}
              />
              <label className="form-check-label">Yes</label>
            </div>
            <div className="form-check form-check-inline labelinput">
              <input
                type="radio"
                className="form-check-input me-2 labelinput"
                name="otherExp"
                value="no"
                checked={employeeIdForm.otherExp === "no"}
                onChange={handleChanges7}
                onClick={othersNo}
              />
              <label className="form-check-label">No</label>
            </div>
          </div>
        </div>
      )}
                    <div className="col-sm-4 col-md-6 col-lg-4">
    <label className="labelinput">Category</label>


    <select
      className="form-select mt-2 labelinput inputWidth"
      value={experience.category}
      onChange={(e) => {
        handlechangeOtherExperiences(e, index)

      }}
      name="category"
      disabled={employeeIdForm.otherExp === "no"}
      style={{
        fontSize: experience.category ? "14px" : "12px",
        height: "38px",
        fontFamily: "Satoshi medium",
        color: experience.category ? "black" : "grey",
      }}
    >
      <option value="" style={{ color: "grey", fontSize: "14px" }}>--Select--</option>
      <option value="Research" style={{ color: "black", fontSize: "14px" }} >Research</option>
      <option value="Industry" style={{ color: "black", fontSize: "14px" }}  >Industry </option>
      <option value="Teaching In Arts Science" style={{ color: "black", fontSize: "14px" }}  >Teaching in arts & science</option>
      <option value="Diploma" style={{ color: "black", fontSize: "14px" }}  >Diploma</option>
      <option value="School" style={{ color: "black", fontSize: "14px" }}  >School</option>
      <option value="Others" style={{ color: "black", fontSize: "14px" }}  >Others</option>
    </select>




    
    {nextButtonClicked &&
      employeeIdForm.otherExperiences.details[index]?.category === "" &&
      employeeIdForm.otherExp === "yes" && (
        <span className="labelinput" style={{ color: "red" }}>
          * Required
        </span>
      )}
  </div>

                  </div>
                      <div className="row mt-4">
                        <div className="col-sm-4 col-md-6 col-lg-4">
                          <label className="labelinput">
                            Institution/Organization
                          </label>
                          <input
                            type="type"
                            className="form-control mt-2 labelinput inputWidth"
                            placeholder="Enter institution/organization Name"
                            value={experience.institution}
                            onChange={(e) => handlechangeOtherExperiences(e, index)}
                            name="institution"
                            disabled={employeeIdForm.otherExp === "no"}
                          />
                          {nextButtonClicked &&
                            employeeIdForm.otherExperiences.details[index]
                              ?.institution === "" &&
                            employeeIdForm.otherExp === "yes" && (
                              <span className="labelinput" style={{ color: "red" }}>
                                * Required
                              </span>
                            )}
                        </div>
                        <div className="col-sm-4 col-md-6 col-lg-4">
                          <label className="labelinput">Designation</label>
                          <input
                            // type="text"
                            className="form-control mt-2 labelinput inputWidth"
                            // placeholder='Enter Designation Name'

                            value={experience.designation}
                            onChange={(e: any) =>
                              handlechangeOtherExperiences(e, index)
                            }
                            name="designation"
                            disabled={employeeIdForm.otherExp === "no"}
                            placeholder="Enter Designation"
                          ></input>
                          {nextButtonClicked &&
                            employeeIdForm.otherExperiences.details[index]
                              ?.designation === "" &&
                            employeeIdForm.otherExp === "yes" && (
                              <span className="labelinput" style={{ color: "red" }}>
                                * Required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-sm-4 col-md-6 col-lg-4">
                          <label className="labelinput">Department</label>
                          <input
                            type="type"
                            className="form-control mt-2 labelinput inputWidth"
                            placeholder="Enter Department"
                            value={experience.department}
                            onChange={(e: any) =>
                              handlechangeOtherExperiences(e, index)
                            }
                            name="department"
                            disabled={employeeIdForm.otherExp === "no"}
                          />
                          {nextButtonClicked &&
                            employeeIdForm.otherExperiences.details[index]
                              ?.department === "" &&
                            employeeIdForm.otherExp === "yes" && (
                              <span className="labelinput" style={{ color: "red" }}>
                                * Required
                              </span>
                            )}
                        </div>
                        <div className="col-sm-4 col-md-3 col-lg-2">
                          <label className="labelinput">From Date</label>
                          <input
                            type="date"
                            className="form-control mt-2 labelinput inputWidth"
                            value={experience.fromDate}
                            onChange={(e) => handlechangeOtherExperiences(e, index)}
                            name="fromDate"
                            disabled={employeeIdForm.otherExp === "no"}
                            max={currentDate}
                            style={{
                              fontSize: "14px",
                              fontFamily: "Satoshi medium",
                            }}
                          />
                          {nextButtonClicked &&
                            employeeIdForm.otherExperiences.details[index]
                              ?.fromDate === "" &&
                            employeeIdForm.otherExp === "yes" && (
                              <span className="labelinput" style={{ color: "red" }}>
                                * Required
                              </span>
                            )}
                        </div>
                        <div className="col-sm-4 col-md-3 col-lg-2">
                          <label className="labelinput">To Date</label>
                          <input
                            type="date"
                            className="form-control mt-2 labelinput inputWidth"
                            placeholder="Enter Employee Name"
                            value={experience.toDate}
                            onChange={(e) => handlechangeOtherExperiences(e, index)}
                            disabled={employeeIdForm.otherExp === "no"}
                            name="toDate"
                            max={currentDate}
                            style={{
                              fontSize: "14px",
                              fontFamily: "Satoshi medium",
                            }}
                          />
                          {nextButtonClicked &&
                            employeeIdForm.otherExperiences.details[index]
                              ?.toDate === "" &&
                            employeeIdForm.otherExp === "yes" && (
                              <span className="labelinput" style={{ color: "red" }}>
                                * Required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-sm-4 col-md-6 col-lg-4 mt-2">
                          <div className="d-flex mt-3 justify-content-between">
                            <label className="labelinput">Experience Letter</label>
                            <span className="ms-5 optional labelinput">
                              Only pdf/jpg file under 2MB
                            </span>
                          </div>
                        
                          <div className="input-group labelinput">
                            <label className={`form-control boxText boxtextellipse browsetext inputWidth ${employeeIdForm.otherExp === "no" ? 'disabled-input-label' : ''}`}>
                              {employeeIdForm.otherExperiences.details[index].file}
                              <input
                                type="file"
                                id={`file_${index}`}
                                className="invisible"
                                name="file"
                              
                                onChange={(e) => {
                                  let val = e.target.value;
                                  val = val.replace(/^\s+/, "");
                                  handlechangeOtherExperiences(
                                    e,
                                    index,
                                    val,
                                    `file_${index}`
                                  );
                                }}
                                disabled={employeeIdForm.otherExp === "no"}
                              />
                            </label>
                            <span
                              className="input-group-text lname cursorPointer"
                              onClick={() =>
                                document.getElementById(`file_${index}`)?.click()
                              }
                            >
                              Select File
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-2 mt-2" >
                          <label className="labelinput">Experience (in yrs)</label>
                          {/* <input
                type="number"
                placeholder='Enter Experience (in yrs)'
                className="form-control mt-2 labelinput"
                value={employeeIdForm.teachingExperiences.details[4].experience}
                onChange={(e) => handleChanges4(e, 4)}
                onKeyPress={handleKeyPressNumber}
                name="experience"
              /> */}
                          <NumericFormat
                            type="text"
                            placeholder="Enter Experience (in yrs)"
                            className="form-control mt-2 labelinput inputWidth"
                            value={experience.experience}
                            maxLength={5}
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              const { floatValue = 0 } = values;
                              return floatValue < MAX_LIMIT;
                            }}
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              inputValue = inputValue.replace(/[^\d.]/g, "");
                              handleStateCreateEmp("experience", inputValue);
                              handlechangeOtherExperiences(e, index);
                            }}
                            name="experience"
                            disabled
                            decimalScale={2}
                            fixedDecimalScale={false}
                          />
                          {/* {nextButtonClicked &&
                            employeeIdForm.otherExperiences.details[index]
                              ?.experience === "" &&
                            employeeIdForm.otherExp === "yes" && (
                              <span className="labelinput" style={{ color: "red" }}>
                                * Required
                              </span>
                            )} */}
                        </div>
                      </div>
                    </div>
                  )
                )}
            
              <div className="col-sm-4 col-md-6 col-lg-4 mt-4 ">
              
              </div>
              <button className="btn addMore" disabled={employeeIdForm.otherExp === "no"}>
                <span onClick={handleAddMore} >+ Add more</span>
              </button>
            </form>
          </div>

        <div
          className={`tab-pane fade ${activeIndex === 3 ? "show active" : ""}`}
          id="personalInfo"
          role="tabpanel"
          aria-labelledby="personal_Info"
          style={{ display: activeIndex === 3 ? "block" : "none" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="row ">
              <div className="col-sm-4 col-md-4 col-lg-2">
              <div style={{position:'relative'}}>
                <label className="labelinput pb-0">Date of Birth</label>
                <input
                  type="date"
                  // max={currentDate}
                  className="form-control mt-2 labelinput inputWidth"
                  value={employeeIdForm.dob || setEmp.dob}
                  onChange={handleChanges7}
                  name="dob"
                  max="2006-05-02"
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                />
                <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
                </div>
                {/* {nextButtonClicked && employeeIdForm.dob === "" && (
                  <span className="labelinput" style={{ color: "red" }}>
                    * Required
                  </span>
                )}{" "} */}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput pb-0">Age</label>
                <input
                  type="text"
                  placeholder="-"
                  className="form-control mt-2 labelinput inputWidth"
                  value={employeeIdForm.age || setEmp.age}
                  onChange={handleChanges7}
                  name="age"
                  style={{ backgroundColor: "#F7F8FA" }}
                  readOnly
                />
                
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput pb-0">Gender</label>
                <select
                  className="form-select mt-2 labelinput inputWidth "
                  value={employeeIdForm.gender || setEmp.gender}
                  onChange={handleChanges8}
                  style={{
                    fontSize: employeeIdForm.gender ? "14px" : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.gender ? "black" : "grey",
                  }}
                  name="gender"
                >
                  <option value="" className="select">--Select--</option>
                  <option
                    value="Male"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Male
                  </option>
                  <option
                    value="Female"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Female
                  </option>
                  <option
                    value="other"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Other Gender
                  </option>
                </select>
                {/* {nextButtonClicked && employeeIdForm.gender === "" && (
                  <span className="labelinput" style={{ color: "red" }}>
                    * Required
                  </span>
                )}{" "} */}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput pb-0">Blood Group</label>
                <select
                  className="form-select mt-2 labelinput inputWidth"
                  value={employeeIdForm.bloodGroup || setEmp.bloodGroup}
                  onChange={handleChanges8}
                  name="bloodGroup"
                  style={{
                    fontSize: employeeIdForm.bloodGroup ? "14px" : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.bloodGroup ? "black" : "grey",
                  }}
                >
                  <option value="" className="select">--Select--</option>
                  <option value="A+" className="options">A+</option>
                  <option value="A-" className="options">A-</option>
                  <option value="B+" className="options">B+</option>
                  <option value="B-" className="options">B-</option>
                  <option value="O+" className="options">O+</option>
                  <option value="O-" className="options">O-</option>
                  <option value="AB+" className="options">AB+</option>
                  <option value="AB-" className="options">AB-</option>
                </select>
                {/* {nextButtonClicked && employeeIdForm.bloodGroup === "" && (
    <span className='labelinput' style={{ color: "red" }}>* Required</span>
  )}       */}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput pb-0">Religion</label>
                <select
                  className="form-select mt-2 labelinput inputWidth"
                  value={employeeIdForm.religion || setEmp.religion}
                  onChange={handleChanges8}
                  name="religion"
                  style={{
                    fontSize: employeeIdForm.religion ? "14px" : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.religion ? "black" : "grey",
                  }}
                >
                  <option value="" className="select">--Select--</option>
                  <option
                    value="Hindu"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Hindu
                  </option>
                  <option
                    value="Christian"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Christian
                  </option>
                  <option
                    value="Muslim"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Muslim
                  </option>
                </select>
                {/* {nextButtonClicked && employeeIdForm.religion === "" && (
                  <span className="labelinput" style={{ color: "red" }}>
                    * Required
                  </span>
                )} */}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <label className="labelinput pb-0">Community</label>
                <select
                  className="form-select mt-2 labelinput inputWidth"
                  value={employeeIdForm.community || setEmp.community}
                  onChange={handleChanges8}
                  style={{
                    fontSize: employeeIdForm.community ? "14px" : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.community ? "black" : "grey",
                  }}
                  name="community"    
                >
                  <option value="" className="select">--Select--</option>
                  <option
                    value="BC"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    BC
                  </option>
                  <option
                    value="MBC"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    MBC
                  </option>
                  <option
                    value="OC"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    OC
                  </option>
                  <option
                    value="BCM"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    BCM
                  </option>
                  <option
                    value="SC"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    SC
                  </option>
                  <option
                    value="ST"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {" "}
                    ST
                  </option>
                  <option
                    value="Arunthathiyars"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    SC (Arunthathiyars)
                  </option>
                </select>
                {/* {nextButtonClicked && employeeIdForm.community === "" && (
                  <span className="labelinput" style={{ color: "red" }}>
                    * Required
                  </span>
                )} */}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput pb-0">Nationality</label>
                <input
                  type="text"
                  placeholder="Enter Nationality"
                  className="form-control mt-2 labelinput inputWidth"
                  value={employeeIdForm.nationality || setEmp.nationality}
                  onChange={handleChanges7}
                  name="nationality"
                />
                  {/* {nextButtonClicked && employeeIdForm.nationality === "" && (
                    <span className="labelinput" style={{ color: "red" }}>
                      * Required
                    </span>
                  )} */}
              </div>
            </div>
            <div className="row mt-4">
            <div className="col-sm-4 col-md-6 col-lg-4">
  <label className="labelinput pb-0">Aadhar No </label>
  <PatternFormat
    format="#### #### ####"
    placeholder="Enter Aadhar No"
    className="form-control mt-2 labelinput inputWidth"
    value={employeeIdForm.aadhaarNumber || ''}
    onChange={handleChanges7}
    name="aadhaarNumber"
    required
  />

  {nextButtonClicked && (
    <>
      {employeeIdForm.aadhaarNumber === "" ? (
        <span className="labelinput" style={{ color: "red" }}>
          *Required
        </span>
      ) : employeeIdForm.aadhaarNumber.replace(/\D/g, '').length !== 12 ? (
        <span className="labelinput" style={{ color: "red" }}>
                      Please enter valid Aadhar No
                      </span>
      ) : null}
    </>
  )}
</div>

              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput pb-0">Marital Status</label>
                <div className="mt-2">
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="martialStatus"
                      value="Single"
                      checked={
                        employeeIdForm.martialStatus === "Single" ||
                        setEmp.martialStatus === "Single"
                      }
                      onChange={handleChanges7}
                    />
                    <label className="form-check-label">Single</label>
                  </div>
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="martialStatus"
                      value="Married"
                      checked={
                        employeeIdForm.martialStatus === "Married" ||
                        setEmp.martialStatus === "Married"
                      }
                      onChange={handleChanges7}
                    />
                    <label className="form-check-label">Married</label>
                  </div>
                </div>
              </div>
              <form>
                {employeeIdForm.holders.persistence.map((item, index) => (
                  <div className="row mt-4" key={index}>
                    <div className="col-sm-4 col-md-6 col-lg-4">
                      <label className="labelinput pb-0">Types of ID Proof</label>
                      
                      <select
                        className="form-select mt-2 labelinput inputWidth"
                        value={employeeIdForm.holders.persistence[index].key}
                        onChange={(e) => idProofFunc(e, index)}
                        style={{
                          fontSize: employeeIdForm.holders.persistence[index]
                            .key
                            ? "14px"
                            : "12px",
                          height: "38px",
                          fontFamily: "Satoshi medium",
                          color: employeeIdForm.holders.persistence[index].key
                            ? "black"
                            : "grey",
                        }}
                        name="key"
                      >
                        <option value="" className="select">--Select--</option>
                        <option
                          value="Birthcertificate"
                          style={{ color: "black", fontSize: "14px" }}
                        >
                          Birth certificate
                        </option>
                        <option
                          value="Pancard"
                          style={{ color: "black", fontSize: "14px" }}
                        >
                          PAN card
                        </option>
                        <option
                          value="Bank"
                          style={{ color: "black", fontSize: "14px" }}
                        >
                          Bank Account
                        </option>
                      </select>
                    </div>
                    <div className="col-sm-4 col-md-6 col-lg-4">
                      <div className="d-flex mt-2 justify-content-between">
                        <label className="labelinput pb-0">Upload File</label>
                        <span className="ms-5 optional labelinput pb-0">
                          Only pdf/jpg file under 2MB
                        </span>
                      </div>
                      <div className="input-group labelinput mt-2">
                        <label className="form-control boxText boxtextellipse browsetext inputWidth">
                          {employeeIdForm.holders.persistence[index].value}
                          <input
                            type="file"
                            id={`value_${index}`}
                            className="invisible"
                            name="value"
                            onChange={(e) => {
                              let val = e.target.value;
                              val = val.replace(/^\s+/, "");
                              // updateIdProof(index, { idProof: e.target.value }, `idProof_${index}`, val, e);
                              idProofFunc(e, index, val, `value_${index}`);
                            }}
                          />
                        </label>
                        <span
                          className="input-group-text lname cursorPointer"
                          onClick={() =>
                            document.getElementById(`value_${index}`)?.click()
                          }
                        >
                          Select File
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-sm-4 col-md-6 col-lg-4 mt-4">
                  {/* <p style={{ color: "blue" }}>
          <span className="bgadd" onClick={addMoreTypes}>+ Add more</span>
        </p> */}
                  <span className="btn addMore">
                    <span onClick={addMoreTypes}>+ Add more</span>
                  </span>
                </div>
              </form>
            </div>
          </form>
        </div>

        <div
          className={`tab-pane fade ${activeIndex === 4 ? "show active" : ""}`}
          id="contactInfo"
          role="tabpanel"
          aria-labelledby="contact_Info"
          style={{ display: activeIndex === 4 ? "block" : "none" }}
        >
          <form onSubmit={handleSubmit}>
            {/* <div className="row mt-4">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Email Address</label>
                <input
                  type="email"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter email address"
                  value={employeeIdForm.email || setEmp.email}
                  onChange={handleChanges7}
                  name="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  title="Please enter a valid email address"
                  required
                />
                {isSubmitted && !employeeIdForm.email && (
                  <div className="labelinput" style={{ color: "red" }}>
                    *Required
                  </div>
                )}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Phone Number</label>
                <input
                  type="tel"
                  className="form-control mt-2 labelinput"
                  placeholder="Enter phone number"
                  value={employeeIdForm.mobileNumber || setEmp.mobileNumber}
                  onChange={handleChanges7}
                  name="mobileNumber"
                  pattern="[0-9]{10}"
                  onKeyPress={handleKeyPressNumber}
                  maxLength={10}
                  required
                />
                {isSubmitted ? (
                  employeeIdForm.mobileNumber === "" ? (
                    <span className="labelinput" style={{ color: "red" }}>
                      *Required
                    </span>
                  ) : employeeIdForm.mobileNumber.length !== 10 ? (
                    <span className="labelinput" style={{ color: "red" }}>
                      Phone number must be exactly 10 digits
                    </span>
                  ) : null
                ) : null}
              </div>
            </div> */}
            <div className=" subheader">Present Address</div>
            <div className="row mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Address Line 1</label>
                <input
                  type="text"
                  placeholder=""
                  className="form-control labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.presentAddress.addressLine1 ||
                    setEmp.addressModal.presentAddress.addressLine1
                  }
                  onChange={handleChanges}
                  name="addressLine1"
                />
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.presentAddress.addressLine1 && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Address Line 2</label>
                <input
                  type="text"
                  placeholder=""
                  className="form-control  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.presentAddress.addressLine2 ||
                    setEmp.addressModal.presentAddress.addressLine2
                  }
                  onChange={handleChanges}
                  name="addressLine2"
                />
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.presentAddress.addressLine2 && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">State</label>
                <select
                  className="form-select  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.presentAddress.state ||
                    setEmp.addressModal.presentAddress.state
                  }
                  onChange={handleChange}
                  name="state"
                  style={{
                    fontSize: employeeIdForm.addressModal.presentAddress.state
                      ? "14px"
                      : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.addressModal.presentAddress.state
                      ? "black"
                      : "grey",
                  }}
                >
                  <option value="" className="select">--Select--</option>
                  <option
                    value="Andhra Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Andhra Pradesh
                  </option>
                  <option
                    value="Arunachal Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Arunachal Pradesh
                  </option>
                  <option
                    value="Assam"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Assam
                  </option>
                  <option
                    value="Bihar"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Bihar
                  </option>
                  <option
                    value="Chattisgarh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Chattisgarh
                  </option>
                  <option
                    value="Gujarat"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Gujarat
                  </option>
                  <option
                    value="Haryana"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Haryana
                  </option>
                  <option
                    value="Himachal Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Himachal Pradesh
                  </option>
                  <option
                    value="Jharkhand"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Jharkhand
                  </option>
                  <option
                    value="Karnataka"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Karnataka
                  </option>
                  <option
                    value="Kerala"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Kerala
                  </option>
                  <option
                    value="Madhya Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Madhya Pradesh
                  </option>
                  <option
                    value="Maharashtra"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Maharashtra
                  </option>
                  <option
                    value="Manipur"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Manipur
                  </option>
                  <option
                    value="Meghalaya"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Meghalaya
                  </option>
                  <option
                    value="Odisha"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Odisha
                  </option>
                  <option
                    value="Punjab"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Punjab
                  </option>
                  <option
                    value="Rajasthan"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Rajasthan
                  </option>
                  <option
                    value="Sikkim"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Sikkim
                  </option>
                  <option
                    value="Tamil Nadu"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Tamil Nadu
                  </option>
                  <option
                    value="Telangana"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Telangana
                  </option>
                  <option
                    value="Tripura"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Tripura
                  </option>
                  <option
                    value="Uttar Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Uttar Pradesh
                  </option>
                  <option
                    value="Uttarakhand"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Uttarakhand
                  </option>
                  <option
                    value="West Bengal"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    West Bengal
                  </option>
                </select>
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.presentAddress.state && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
              <div className="col-sm-4 col-md-3 col-lg-2">
                <label className="labelinput">City</label>
                <input
                  type="text"
                  placeholder=""
                  className="form-control  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.presentAddress.city ||
                    setEmp.addressModal.presentAddress.city
                  }
                  onChange={handleChanges}
                  name="city"
                />
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.presentAddress.city && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
              <div className="col-sm-4 col-md-3 col-lg-2">
                <label className="labelinput">Pin Code</label>
                <input
                  type="tel"
                  placeholder=""
                  className="form-control  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.presentAddress.pincode ||
                    setEmp.addressModal.presentAddress.pincode
                  }
                  onChange={handleChanges}
                  name="pincode"
                  maxLength={6}
                  onKeyPress={handleKeyPressNumber}
                />
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.presentAddress.pincode && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
            </div>

            <div className="d-flex mt-4">
              <div className="col">
                <div className="subheader">Permanent Address</div>
              </div>
              <div className="col">
                <div className="d-flex">
                  <span className="mt-1">
                    <input
                      type="checkbox"
                      className="form-check-input labelinput inputWidth"
                      id="sameAsPresent"
                      onClick={handleSameAsPresentClick}
                    />
                  </span>
                  <span>
                    <label
                      className="form-check-label labelinput ms-2"
                      htmlFor="sameAsPresent"
                    >
                      <span className="">Same as Present Address</span>
                    </label>
                  </span>
                </div>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Address Line 1</label>
                <input
                  type="text"
                  placeholder=""
                  className="form-control labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.permanentAddress.addressLine1 ||
                    setEmp.addressModal.permanentAddress.addressLine1
                  }
                  onChange={handleChanges1}
                  name="addressLine1"
                />
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.permanentAddress
                    .addressLine1 && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Address Line 2</label>
                <input
                  type="text"
                  placeholder=""
                  className="form-control  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.permanentAddress.addressLine2 ||
                    setEmp.addressModal.permanentAddress.addressLine2
                  }
                  onChange={handleChanges1}
                  name="addressLine2"
                />
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.permanentAddress
                    .addressLine2 && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">State</label>
                <select
                  className="form-select  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.permanentAddress.state ||
                    setEmp.addressModal.permanentAddress.state
                  }
                  style={{
                    fontSize: employeeIdForm.addressModal.permanentAddress.state
                      ? "14px"
                      : "12px",
                    height: "38px",
                    fontFamily: "Satoshi medium",
                    color: employeeIdForm.addressModal.permanentAddress.state
                      ? "black"
                      : "grey",
                  }}
                  onChange={handleChange0}
                  name="state"
                >
                  <option value="" className="select">--Select--</option>
                  <option
                    value="Andhra Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Andhra Pradesh
                  </option>
                  <option
                    value="Arunachal Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Arunachal Pradesh
                  </option>
                  <option
                    value="Assam"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Assam
                  </option>
                  <option
                    value="Bihar"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Bihar
                  </option>
                  <option
                    value="Chattisgarh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Chattisgarh
                  </option>
                  <option
                    value="Gujarat"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Gujarat
                  </option>
                  <option
                    value="Haryana"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Haryana
                  </option>
                  <option
                    value="Himachal Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Himachal Pradesh
                  </option>
                  <option
                    value="Jharkhand"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Jharkhand
                  </option>
                  <option
                    value="Karnataka"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Karnataka
                  </option>
                  <option
                    value="Kerala"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Kerala
                  </option>
                  <option
                    value="Madhya Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Madhya Pradesh
                  </option>
                  <option
                    value="Maharashtra"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Maharashtra
                  </option>
                  <option
                    value="Manipur"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Manipur
                  </option>
                  <option
                    value="Meghalaya"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Meghalaya
                  </option>
                  <option
                    value="Odisha"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Odisha
                  </option>
                  <option
                    value="Punjab"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Punjab
                  </option>
                  <option
                    value="Rajasthan"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Rajasthan
                  </option>
                  <option
                    value="Sikkim"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Sikkim
                  </option>
                  <option
                    value="Tamil Nadu"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Tamil Nadu
                  </option>
                  <option
                    value="Telangana"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Telangana
                  </option>
                  <option
                    value="Tripura"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Tripura
                  </option>
                  <option
                    value="Uttar Pradesh"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Uttar Pradesh
                  </option>
                  <option
                    value="Uttarakhand"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    Uttarakhand
                  </option>
                  <option
                    value="West Bengal"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    West Bengal
                  </option>
                </select>
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.permanentAddress.state && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
              <div className="col-sm-4 col-md-3 col-lg-2">
                <label className="labelinput">City</label>
                <input
                  type="text"
                  placeholder=""
                  className="form-control  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.permanentAddress.city ||
                    setEmp.addressModal.permanentAddress.city
                  }
                  onChange={handleChanges1}
                  name="city"
                />
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.permanentAddress.city && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
              <div className="col-sm-4 col-md-3 col-lg-2">
                <label className="labelinput">Pin Code</label>
                <input
                  type="number"
                  placeholder=""
                  className="form-control  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.permanentAddress.pincode ||
                    setEmp.addressModal.permanentAddress.pincode
                  }
                  onChange={handleChanges1}
                  name="pincode"
                  onKeyPress={handleKeyPressNumber}
                />
                {/* {isSubmitted &&
                  !employeeIdForm.addressModal.permanentAddress.pincode && (
                    <div className="labelinput" style={{ color: "red" }}>
                      *Required
                    </div>
                  )} */}
              </div>
            </div>
            <div className="col-sm-4 col-md-6 col-lg-4 mt-2">
              {/* <label className="labelinput">Upload File</label> */}
              <div className="d-flex mt-3 justify-content-between">
                <label className="labelinput">Upload File</label>
                <span className="ms-5 optional labelinput">
                  Only pdf/jpg file under 2MB
                </span>
              </div>
              {/* <input
                type="file"
                className="form-control mt-2 labelinput"
                value={employeeIdForm.addressModal.permanentAddress.file || setEmp.addressModal.permanentAddress.file}
                onChange={handleFileUpload}
                name="file"
              /> */}
              <div className="input-group labelinput">
                <label className="form-control boxText boxtextellipse browsetext inputWidth">
                  {employeeIdForm.addressModal.permanentAddress.file}
                  <input
                    type="file"
                    id="permanentAddressFile"
                    className="invisible"
                    name="file"
                    onChange={(e) => {
                      handleChanges1(e, "permanentAddressFile");
                    }}
                  />
                </label>
                <span
                  className="input-group-text lname cursorPointer"
                  onClick={() =>
                    document.getElementById("permanentAddressFile")?.click()
                  }
                >
                  Select File
                </span>
              </div>
            </div>
            <div className="mt-3 subheader">Emergency Contact-1</div>
            <div className="row mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Name</label>
                <input
                  type="text"
                  placeholder="Enter name of the person"
                  className="form-control  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.emergencyContact1.name ||
                    setEmp.addressModal.emergencyContact1.name
                  }
                  onChange={handleChanges2}
                  name="name"
                />
                {/* {isSubmitted && !employeeBasicForm.name2 && <div className="is-invalid">*Required</div>} */}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Relationship</label>
                <input
                  type="text"
                  placeholder="Eg: Father/Mother/Guardian/Spouse"
                  className="form-control  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.emergencyContact1
                      .relationShip ||
                    setEmp.addressModal.emergencyContact1.relationShip
                  }
                  onChange={handleChanges2}
                  name="relationShip"
                />
                {/* {isSubmitted && !employeeBasicForm.relation1 && <div className="is-invalid">*Required</div>} */}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Email Address</label>
                <input
                  type="email"
                  className="form-control  labelinput inputWidth"
                  placeholder="Enter email address"
                  value={
                    employeeIdForm.addressModal.emergencyContact1.email ||
                    setEmp.addressModal.emergencyContact1.email
                  }
                  onChange={handleChanges2}
                  name="email"
                  required
                />
                {/* {isSubmitted && !employeeBasicForm.email1 && <div className="is-invalid">*Required</div>} */}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Phone Number</label>
                <input
                  type="tel"
                  maxLength={10}
                  className="form-control  labelinput inputWidth"
                  placeholder="Enter phone number"
                  value={
                    employeeIdForm.addressModal.emergencyContact1
                      .mobileNumber ||
                    setEmp.addressModal.emergencyContact1.mobileNumber
                  }
                  onChange={handleChanges2}
                  name="mobileNumber"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  onKeyPress={handleKeyPressNumber}
                  required
                />
                {/* {isSubmitted && !employeeBasicForm.phoneNumber1 && <div className="is-invalid">*Required</div>} */}
              </div>
            </div>
            <div className="mt-3 subheader">Emergency Contact-2</div>
            <div className="row mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Name</label>
                <input
                  type="text"
                  placeholder="Enter name of the person"
                  className="form-control  labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.emergencyContact2.name ||
                    setEmp.addressModal.emergencyContact2.name
                  }
                  onChange={handleChanges3}
                  name="name"
                  autoComplete="off"
                />
                {/* {isSubmitted && !employeeBasicForm.name1 && <div className="is-invalid">*Required</div>} */}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Relationship</label>
                <input
                  type="text"
                  placeholder="Eg: Father/Mother/Guardian/Spouse"
                  className="form-control labelinput inputWidth"
                  value={
                    employeeIdForm.addressModal.emergencyContact2
                      .relationShip ||
                    setEmp.addressModal.emergencyContact2.relationShip
                  }
                  onChange={handleChanges3}
                  name="relationShip"
                  autoComplete="off"
                />
                {/* {isSubmitted && !employeeBasicForm.relation2 && <div className="is-invalid">*Required</div>} */}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Email Address</label>
                <input
                  type="email"
                  className="form-control labelinput inputWidth"
                  placeholder="Enter email address"
                  value={
                    employeeIdForm.addressModal.emergencyContact2.email ||
                    setEmp.addressModal.emergencyContact2.email
                  }
                  onChange={handleChanges3}
                  name="email"
                  required
                />
                {/* {isSubmitted && !employeeBasicForm.email2 && <div className="is-invalid">*Required</div>} */}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4 mb-5">
                <label className="labelinput">Phone Number</label>
                <input
                  type="tel"
                  className="form-control labelinput inputWidth"
                  placeholder="Enter phone number"
                  value={
                    employeeIdForm.addressModal.emergencyContact2.mobileNumber
                  }
                  onChange={handleChanges3}
                  name="mobileNumber"
                  autoComplete="off"
                  maxLength={10}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  onKeyPress={handleKeyPressNumber}
                  required
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateEmployee;