import React from 'react'
import { Outlet } from 'react-router-dom'

type Props = {}

const PerformanceEmp = (props: Props) => {
  return (
  <>
   <Outlet/>
  </>
  )
}
export default PerformanceEmp