
import { useKeycloak } from "@react-keycloak/web";
import { Navigate } from "react-router-dom";


const PrivateRoute = ({ children } : any) => {
  const auth = useKeycloak();
  return auth.keycloak.authenticated ? children : <Navigate to="/" />;
};
export default PrivateRoute;
 
