import React, { useEffect, useState } from "react";
// import DynamicTables from "../../../../components/DynamicTable/DynamicTable";
import DynamicTables from "../../../../components/DynamicTable/DynamicTables";
import searchIcon from "../../../../assets/Icon search.svg";
import "../../../FacultyModule/Proficiency/OnlineCourse/OnlineCourse.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import cross from "../../../../assets/crossimg.png";
import { MdCancel } from "react-icons/md";
import {
  DeleteTabledata,
  OnlineCourseGetTable,
  OnlineCourseGetTableData,
  AppraisalGetTable,
} from "../../../../ApiServices";
import CourseTable from "../../../../components/CourseDetailTable/CourseTable";
import { format as formatDate } from "date-fns";
import close from "../../../../assets/close.svg";
import noRecord from "../../../../assets/norecords.png";
function Appraisals() {
  const navigate = useNavigate();
  const columnRedirectsOnlineCourse = {
    courseTitle:
      "/faculty/secondsidebar/proficiency/onlinecourses/courseDetailsTable",
  };

  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalElements, setTotalElements] = useState(0);
  const [totalpages, settotalpages] = useState(0);
  const [sort, setSort] = useState(false);
  const [sortusing, setSortUsing] = useState("coId");
  const [tableDatas, settableDatas] = useState([]);
  const [selectedItem, setselectedItem] = useState<any>([]);
  const [viewSingle, setViewSingle] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearch = () => {
    setIsSearching(!isSearching);
    setSearchTerm("");
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setSearchTerm("");
    }
  };
  const handleCancel = () => {
    setSearchTerm("");
    setIsSearchOpen(false);
  };

  console.log(searchTerm, "am search term");

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalpages));
    console.log("next button clicked and the value now is " + currentPage);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleSort = () => {
    setSortUsing("academicYear");
    setSort(!sort);
  };

  const sortOrder = sort ? "asc" : "desc";
  // const sordId = sort? "academicYear":"coId"
  // console.log(sortOrder,"testsort")

  const getDatas = () => {
    const data = {
      empID: sessionStorage.getItem("empId"),
      page: currentPage - 1,
      sort: sortOrder,
      sordId: sortusing,
      keyword: searchTerm,
    };

    AppraisalGetTable(data)
      .then((res) => {
        const formattedData = res.data.datas.content.map((item: any) => {
          const createdOnDate = new Date(item.createdOn);
          const formattedCreatedDate = formatDate(
            createdOnDate,
            "dd MMM yyyy hh:mm a"
          );

          const updatedOnDate = new Date(item.updatedOn);
          const formattedUpdatedDate = formatDate(
            updatedOnDate,
            "dd MMM yyyy hh:mm a"
          );

          return {
            ...item,
            createdOn: formattedCreatedDate,
            updatedOn: formattedUpdatedDate,
          };
        });
        settableDatas(formattedData);
        console.log(res.data.datas.content, "gopi");
        setTotalElements(res.data.datas.totalElements);
        settotalpages(res.data.datas.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Header = [
    { label: "Academic Year", key: "academicYear" },
    { label: "Name", key: "courseTitle" },
    { label: "Status", key: "status" },
    { label: "Created on", key: "createdOn" },
    { label: "Last Updated on", key: "updatedOn" },
  ];

  function navigateView(item: any) {
    OnlineCourseGetTableData(item.coId)
      .then((res: any) => {
        setselectedItem(res.data.data);
        setViewSingle(true);
      })
      .catch((err: any) => console.log(err));
  }

  function editCourse(item: any) {
    console.log(item, "ssbiki");
    navigate("editOnlineCourse/" + item.coId);
  }

  const deleteApi = (data: any) => {
    const url = "/online/courses?coId=" + data.coId;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);
        alert(res.data.status);
        getDatas();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDatas();
  }, [currentPage, sort, searchTerm]);

  return (
    <>
      {!viewSingle ? (
        <>
          <div className="mainheader">Appraisals</div>
          <div className="d-flex justify-content-between greytext">
            <span>
              Enhancing the quality of education and the overall teaching and
              learning environment.
            </span>
          </div>
          {tableDatas.length > 0 && (
            <div className="addresult">
              <span className="active tablink bg addresult ">All Results</span>
              <div className="search me-5">
                <img
                  alt="search"
                  className="fasearch mb-2"
                  src={searchIcon}
                  onClick={toggleSearch}
                />
                {isSearchOpen && (
                  <div className="search-input-container">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                    />
                    <img
                      src={close}
                      alt=""
                      onClick={handleCancel}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {tableDatas.length === 0 ? (
            <div
              className="d-flex flex-column align-items-center"
              style={{ marginTop: "200px" }}
            >
              <img src={noRecord} alt="No records" />
              <p className="mt-4 greyfont" style={{ textAlign: "center" }}>
                You haven't added any publications yet.
                <br />
                Start by adding a publication.
              </p>
              <button className="text-white addnewbtn">Add New</button>
            </div>
          ) : (
            <DynamicTables
              editCourse={editCourse}
              currentPage={currentPage}
              pages={totalpages}
              data={tableDatas}
              headers={Header}
              columnRedirects={columnRedirectsOnlineCourse}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              TotalElements={TotalElements}
              handleChangePage={handleChangePage}
              handleSort={handleSort}
              deleteApi={deleteApi}
              navigateView={navigateView}
            />
          )}
        </>
      ) : (
        <CourseTable
          data={selectedItem}
          back={() => {
            setViewSingle(false);
          }}
        />
      )}
      <Outlet />
    </>
  );
}

export default Appraisals;
