import "./styles.css";
import {Outlet} from "react-router-dom"
type Props = {};

const Students = (props: Props) => {
  return <div>Students</div>;
  <Outlet/>
};

export default Students;
