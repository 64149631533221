import React, { useState,useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import user from '../../assets/user.png'
import back from '../../assets/back.png'
import { IoMdContact } from "react-icons/io";
import './viewsingleemployee.css'
import { employeeSingleData, employeeupdateSingleData ,imageCall ,employeeDeactivate, host} from '../../ApiServices'
import { AxiosResponse, AxiosError } from 'axios';
import ViewEmployee from '../AdminModule/Employees/ViewEmployees/ViewEmployees';
import employeeBio from "../../assets/employee bio.png"
import proficiencyLogo from "../../assets/proficiency.png"
import salaryDetails from "../../assets/salary.png" 
import activities from "../../assets/activities.png"
import subjectHandled from "../../assets/subjects.png"
import performance from "../../assets/performance.png"




const ViewSingleEmployee= (data: any , imageUrl:any) => {
  console.log(data,"yhihiyhihyi");
  console.log(data.imageUrl,"yhffffihiyhihyi");
  // const imagePath = data.imageUrl
  //  const imagePath = "/Images/KECEMP21080004/Image(10).jpeg"
  const navigate = useNavigate();
  const [noImage, setNoImage] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const { id } = useParams();
  const [employeeIds, setEmployeeid]=useState()
  const [employeeData, setEmployeeData] = useState(data.data[0]);
  const [image , setImage] = useState("")
  const [imagePath, setImagePath] =useState();
  const [employeeIdForm, setEmployeeIdForm] = useState({
    addressModal: {
      employeeId: "",
      presentAddress: {
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        pincode: "",
        file: "",
      },
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        pincode: "",
        file:"",
      },
      emergencyContact1: {
        name: "",
        relationShip: "",
        email: "",
        mobileNumber: "",
      },
      emergencyContact2: {
        name: "",
        relationShip: "",
        email: "",
        mobileNumber: "",
      },
    },
    empName: "",
    empNo:"",
    designation: "",
    deptId: "",
    reportingTo: "",
    joinedOn: "",
    empType: "",
    employeeId: "",
    previouslyWorkedInKings: "",
    previouslyWorkedInKings1: "",
    status: "",
    teachingExperiences: {
      details: [
        {
          id: 1,
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        }
      ],
      otherExperiences: {
        details: [
          {
            id: "",
            institution: "",
            designation: "",
            fromDate: "",
            department:"",
            category:"",
            toDate: "",
            experience: "",
            file: "",
            isItUpdated: ""
          }
        ]
      },
      
    },
    teachingExperiences1: {
      details: [
        {
          id: 1,
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        }
      ],
      
    },
    otherExperiences1: {
      details: [
        {
          id: "",
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: ""
        }
      ]
    },
    otherAcademics:{
      details: [
        {
          id:"",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          universit: "",
          specialization: "",
          isItUpdated: "",
          class: "",
          state:"",
          city: '',
          degree:""
        }
      ]
    },
    academicDetails: {
      academics: [
        {
          id: "10th",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          universit: "",
          specialization: "",
          isItUpdated: "",
          class: "",
          state:"",
          city: '',
        },
        {
          id: "",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          class: " ",
          state:"",
          city: '',
        },
        {
          id: "UG",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          degree: "",
          class: "",
          state:"",
          city: '',
        },
        {
          id: "PG",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          class: "",
          state:"",
          city: '',
        },
        {
          id: "Ph.D",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          class: "",
          state:"",
          city: '',
        },
        {
          id: "OTHER",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          state:"",
          city: '',
        },
      ],
    },
    dob: "",
    age: "",
    gender: "",
    bloodGroup: "",
    religion: "",
    community: "",
    nationality: "",
    aadhaarNumber: "",
    martialStatus: "",
    typeOfIdProof: "",
    idProof:"",
    email: "",
    mobileNumber: "",
    empStatus: "",
    updatedOn: "",
    createdOn: "",
    image: "",
    imageName: "",
    imageType: "",
    updatedBy: "",
  });


  // const [formValue , setFormValue] = useState("") 

  const [formValue, setFormValue] = useState({
    LastWorkingDate: "",
    Reason: "",
    comment: ""
  });


  const [errors, setErrors] = useState({
    LastWorkingDate: "",
    Reason: "",
    comment: ""
  });

  console.log(formValue , "formValue")

  const handleBioClick = () => {
    setShowInput(!showInput);
  };



  const deactiateId =()=>{

    if (!validateForm()) {
      return;
    }

    const data = {
      employeeId : employeeIds, 
      reason:formValue.Reason,
      comment:formValue.comment
    }
    employeeDeactivate(data)  
    .then((res)=>{
  console.log(res)
    }).catch((err)=>{
  console.log(err)
    })
    setShowInput(!showInput);
  }

   const validateForm = () => {
    let isValid = true;
    let errors = { LastWorkingDate: "", Reason: "", comment: "" };

    if (!formValue.LastWorkingDate) {
      isValid = false;
      errors.LastWorkingDate = 'Please fill in the last working date.';
    }
    if (!formValue.Reason) {
      isValid = false;
      errors.Reason = 'Please fill in the reason.';
    }
    if (!formValue.comment) {
      isValid = false;
      errors.comment = 'Please fill in the comment.';
    }

    setErrors(errors);
    return isValid;
  }

  useEffect(()=>{
    setEmployeeid(data.data[0].employeeId)
},[employeeIds])


const formatDateDifference = (joinedDate: string) => {
  if (!joinedDate) return null;

  const joined = new Date(joinedDate);

  if (isNaN(joined.getTime())) return null;

  const currentDate = new Date();

  const diffMs = currentDate.getTime() - joined.getTime();
  const diffYears = diffMs / (1000 * 60 * 60 * 24 * 365.25); 
  const years = Math.floor(diffYears);
  const months = Math.floor((diffYears - years) * 12);

  if (years === 0 && months === 0) return null;

  const experience = years + (months / 10);
  return experience.toFixed(1) + (years >= 1 ? ' yrs experience' : ' yr experience');
};


  const handleConvert = (joinedDate: string) => {
    const date = new Date(joinedDate);
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `Joined on ${day} ${month} ${year}`;
  };

  const getSingleData = () => {
    employeeupdateSingleData()
        .then((res: AxiosResponse) => {
          console.log(res.data.data[0],"huihuihiuhi");  
          console.log(res.data.data[0], "searching for the photo ")
          const employeeId = res.data.employeeId;
          console.log(employeeId , "am the empId")
          const cgpaAsString = res.data.data[0].academicDetails.academics[0].cgpa.toString();
          console.log(res.data.data[0].academicDetails.academics[0].cgpa ,"am the cgpa ")
            setEmployeeIdForm(res.data.data[0]);
            // setData(res)
           
        })
        .catch((err: AxiosError) => console.log(err));
};

    // image call
      
    // const getImage =()=>{
    //   console.log(imageUrl ,"this is the image url")
    //   imageCall(imagePath)
    //   .then((res:any)=>{
    //   console.log(res)
    //   setImage(res)
    //    console.log(res , "am the ress")
    //   }).catch((err:any)=>console.log(err))
    // }

  const fetchData = async() =>{
  const imageUrls = host+"employee/get/uploaded/file?fileName="+ data.data[0].image;
   try{
    const response = await fetch(imageUrls)
    if(response.ok){
      setImage(imageUrls)
      setNoImage(true);
    }
   }catch(error){
    console.error("Error making HTTP call:", error);
    setNoImage(false);
   }
}

useEffect(() => {
  getSingleData();
  // getImage()
  fetchData()
}, [id]);

const viewEmployee = () => {
 navigate("viewemployee/"+ employeeIds)
};

 const ViewProficiency = () => {
  navigate("viewproficiancy/" + employeeIds, { state: { employeeData , imagePath } });
};

  return (
    <>
    <span className='mainheader'></span>
    <div className="col-md-12 col-lg-12 col-12">
            <div className="float-end">
              <span className="bacdept cursorPointer labelinput me-4">
                {/* <Link to={"/faculty/secondsidebar/collections/employees"}> */}
                <img src={back} className="img-fluid me-2"  />
                Back to Employees
                {/* </Link> */}
              </span>
</div>        
    </div>
    {/* {data.data[0].map((item:any,index:any)=>{
      <>
      <span>{item.employeeId}</span>
      </>
    })} */}
    <div className="mainheader">
      {/* Employee Bio */}
      {data.data[0].employeeId}
      </div>
         
          <p className='greyforview mt-2'>View details about the employee '{data.data[0].employeeId}'</p>
          
          
<div className="container-fluid px-0">
  <div className="row">
    <div className="col-md-6 col-12 ">
      <div className='d-flex'>
      <div className='me-3'>

            {/* <img src={user} style={{ width: "96px" }}></img> */}
            {noImage ? (
  <img src={image} alt="" className="me-3 img-fluid imgW" />
) : (
  <img src={user} alt="" className="me-3 img-fluid imgWidth" />
)}
 </div>
            <div>
              <div className='extraDatas'>{data.data[0].empName}</div>
              
              <div className='employeeView mt-1'>{data.data[0].employeeId} | {data.data[0].designation}</div>
              <div className='employeeView mt-1'>{data.data[0].deptId}</div>
              <div className='employeeView'>

              {data.data[0].joinedOn ? (
  <>
    <span>
    <IoMdContact /></span>    
    <span className='employeeView mt-1 ms-1'>
      {handleConvert(data.data[0].joinedOn)}
      {formatDateDifference(data.data[0].joinedOn) && (
        <>
          {" | "}
          {formatDateDifference(data.data[0].joinedOn)}
        </>
      )}
    </span>
  </>
) : null}
{/* </span> */}

{/* </span>             */}
</div>
            </div>
            </div>
    </div>

            <div className="col-md-6 col-12 text-md-end text-start pt-2 pt-md-0">
  {/* <div className="float-end d-flex flex-column align-items-end"> */}
    {!showInput && (
      <>
        <button className="deactivebtn" style={{ color: 'white' }} onClick={handleBioClick}>
          Deactivate
        </button>
        <div className="mt-3 labelinput ">
          Current status <span className="activebox p-1 ms-2">Active</span>
        </div>
      </>
    )}
  </div>
</div>
 </div>
          <hr></hr>
          {/* <div className='hrl'></div> */}
          <div className='row'>
        {!showInput && (
          <>
          
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width ">
            {/* <Link to={`/faculty/secondsidebar/collections/employees/viewemployee`} className='link'>  */}
                         <div className="viewbox h-100" onClick={viewEmployee}>
                <div className="pendingPadding">
                  <img src={employeeBio}></img>
                  <p className="mt-2 mb-1 extraDatas cursorPointer" style={{color:"#1B1922"}} >Employee Bio</p>
                </div>
              </div>
              {/* </Link> */}
            </div>
            

            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width ">
             {/* <Link to={`/faculty/secondsidebar/collections/employees/viewproficiancy`} className='link'>  */}
              <div className="viewbox h-100" onClick={ViewProficiency}>
                <div className="pendingPadding">
                <img src={proficiencyLogo}></img>
                  <p className="mt-2 mb-1 extraDatas" style={{color:"#1B1922"}}>Proficiency</p>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width ">
              <div className="viewbox h-100">
                <div className="pendingPadding">
                <img src={salaryDetails}></img>
                  <p className="mt-2 mb-1 extraDatas" style={{color:"#1B1922"}}>Salary Details</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width ">
              <div className="viewbox h-100">
                <div className="pendingPadding">
                <img src={activities}></img>
                  <p className="mt-2 mb-1 extraDatas" style={{color:"#1B1922"}}>Activities/Edit History</p>
                </div>
              </div>
            </div>
            {/* <div className='row'> */}
     <div
     
     className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width "
     
   >
         <div className="viewbox h-100 ">
       <div className="pendingPadding">
       <img src={subjectHandled}></img>
         <p className="mt-2 mb-1 extraDatas" style={{color:"#1B1922"}}>Subjects Handled</p>
      
       </div>
     </div>
     </div>
     <div
     
     className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3  custom-col-width "
   >
         <div className="viewbox h-100">
       <div className="pendingPadding">
       <img src={performance}></img>
         <p className="mt-2 mb-1 extraDatas" style={{color:"#1B1922"}}>Performance</p>
        
       </div>
     </div>
     </div>
     {/* </div> */}
          </>
        )}
      </div>
    
     {showInput && 
     <>
     <div className="col-sm-4 col-md-6 col-lg-3">
                <label className="labelinput" style={{color:"#1B1922"}}>Last Working Date</label>
                <input
                  type="date"
                  className="form-control mt-2 labelinput"
                  style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                  value={formValue.LastWorkingDate}
              onChange={(e) => setFormValue({ ...formValue, LastWorkingDate: e.target.value })}
                />
                {errors.LastWorkingDate && <p className="error">{errors.LastWorkingDate}</p>}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4 mt-4">
                <label className="labelinput">Reason for deactivating</label>
                <div className="mt-2">
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="empStatus"
                      id='Resignation'
                      value="Resignation"
                      checked={formValue.Reason == "Resignation"}
              onChange={(e) => setFormValue({ ...formValue, Reason: e.target.value })}
                    />
                    <label className="form-check-label">Resignation</label>
                  </div>
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="empStatus"
                      id="Termination"
                      value="Termination"
                      checked={formValue.Reason == "Termination"}
                      onChange={(e) => setFormValue({ ...formValue, Reason: e.target.value })}
                    />
                    <label className="form-check-label">Termination</label>
                  </div>
                  <div className="form-check form-check-inline labelinput">
                    <input  
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="empStatus"
                      id="Others"
                      value="Others"
                      checked={formValue.Reason == "Others"}
                      onChange={(e) => setFormValue({ ...formValue, Reason: e.target.value })}
                    />
                    
                    <label className="form-check-label">Others <span className='greyforview ms-2'>(Specify the reason in comments)</span></label>
                  </div>
                  {errors.Reason && <p className="error">{errors.Reason}</p>}
                </div>
                <div className="col-sm-4 col-md-6 col-lg-4">
                <label className="labelinput">Comments</label>
                <textarea
                  className="form-control mt-2 labelinput"
                  placeholder="Enter Comments"
                  name="Comments"
                  style={{ height: '100px', width:'385px'}}
                  value={formValue.comment}
                  onChange={(e) => setFormValue({ ...formValue, comment: e.target.value })}
                />
                {errors.comment && <p className="error">{errors.comment}</p>}
              
              </div>
              <div className="col-md-6 col-lg-9 col-4">
           
          
              <span
                className="bacdept cursorPointer labelinput "
          
              >
                <button className="mt-3 deactivebtn" style={{ color: 'white' }} onClick={deactiateId} >
                Deactivate
                </button>
                
              </span>     
    </div>
              </div>
     </>
     }
     
    </>
  )
}

export default ViewSingleEmployee