import React from "react"
import { Outlet } from "react-router-dom";

const Admin =()=>{
    return (
    <div>
    <Outlet/>
    </div>
    )
}
export default Admin