import React, { useState, ChangeEvent, FormEvent,useEffect } from "react";
import './AddNew.css'
import { Link,useNavigate } from "react-router-dom";
import back from '../../../../../assets/back.png'
import { guidanceCreate,AcademicYear } from "../../../../../ApiServices";
import { ToastContainer , toast  } from "react-toastify";
import yearList from "../../../../../YearList.json"

function GuidanceCreate() {
    const navigate =useNavigate();
    const [GuidanceCreateForm, setGuidanceCreateForm] = useState({
        academicYear:'',
        type:'',
        universityName:'',
        noOfScholarsPast:'',
        noOfScholars:'',
        uploadFile:'',
    })
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [uploadFiles, setUploadFiles] = useState<string>('');
    const [fileVal, setFileVal] = useState<string>('');
    const [toggleSubMod , setToggleSubMod] = useState(false)


    const handleSave=(e: FormEvent<HTMLButtonElement> )=>{
      setToggleSubMod(false)
      CreateGuidance(e,"Yet to submit")
    }
  
    const handleSubmit=(e: FormEvent<HTMLButtonElement> )=>{
       setIsSubmitted(true);
      if(validation() === true){
        if (!toggleSubMod) {
          setToggleSubMod(true);
        } else {
          setToggleSubMod(false);
          CreateGuidance(e,"Pending")
        }
      }else{
      }
    }
  
    const validation =()=>{
      if (
        !GuidanceCreateForm.academicYear ||
        !GuidanceCreateForm.universityName ||
        !GuidanceCreateForm.noOfScholars || 
        !GuidanceCreateForm.noOfScholarsPast
        
      ) {
        console.log("All fields are required");
        return;
      }else return true
    }
   
    const CreateGuidance= (e: FormEvent<HTMLButtonElement> , status:any) =>{
        e.preventDefault();
        setIsSubmitted(true);
        if (
            !GuidanceCreateForm.academicYear ||
            !GuidanceCreateForm.universityName ||
            !GuidanceCreateForm.noOfScholars || 
            !GuidanceCreateForm.noOfScholarsPast
            
          ) {
            console.log("All fields are required");
            return;
          }
        const payload = {
          empId: sessionStorage.getItem('empId'),
          academicYear: GuidanceCreateForm.academicYear,
          createdBy:"",
          type: GuidanceCreateForm.type,
          universityName: GuidanceCreateForm.universityName,
          noOfScholars: GuidanceCreateForm.noOfScholars,
          noOfScholarsPast:GuidanceCreateForm.noOfScholarsPast,
          uploadFile: uploadFiles,
          status:status
        };
        console.log(payload,"guidanceCreate");
        guidanceCreate(payload)
          .then((res) => {
            setIsButtonClicked(true)
          // alert(res.data.status);
           navigate("/faculty/secondsidebar/proficiency/guidance")
           setTimeout(()=>{
            toast.success(res.data.status);
          } , 200)
          })
          .catch((err) => {
            console.log(err);
          });
          console.log(payload)
      };
    const handleChange =(e:any)=>{
      const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/,"");
    let updatedValue = value;
    if (value.length > 0) {
      var words = value.split(' ');
      for (var i = 0; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      updatedValue = words.join(' ');
  }
        setGuidanceCreateForm({
          ...GuidanceCreateForm,
          [e.target.name]:e.target.value,
          [name]: updatedValue 
      })
      }
    // const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    //     const { name, value } = event.target;
    //     setGuidanceCreateForm({ ...GuidanceCreateForm, [name]: value });
    // };
    const onlyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key;     
      if (!/^[0-9]$/.test(key)) {
        e.preventDefault();
      }
    };

    const handleBackButtonClick = () => { 
      setShowModal(true); 
        }; 
        var base64CodePhoto = "";
        const base64ConverterFile = (e:any) => {
          const files = e.target.files;
          const file = files[0];
          if (file.size > 2 * 1024 * 1024) {
            toast.error('File size exceeds 2 MB limit. Please upload a smaller file.');
          }else{
          if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
            toast.error('File format not supported. Please try uploading PDF or JPG files.');
            return;
          }  else{
            getBase64(file);
            handleChange(e);
            setFileVal(file.name);
          }
        }
      }
        const onLoad = (fileString :any) =>{
        base64CodePhoto = fileString;  
        setUploadFiles(fileString)
        }
        const getBase64 = (file:any) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () =>{
            onLoad(reader.result);
          }
        }  
        const [academicYears,setAcademicYear] = useState([]);
        interface AcademicYear {
          id: number;
          academicYear: string;
          term: number;
          startDate: string;
          endDate: string;
        }
        const acad = ()=>{
          AcademicYear()
          .then((res =>{
            console.log(res.data.data);
            const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
              index === self.findIndex((t:any) => (
                t.academicYear === item.academicYear
              ))
            );
            setAcademicYear(uniqueAcademicYears)
          })) 
        }
        const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
        useEffect(()=>{
          acad()
          },[])
  return (
    <>
     <ToastContainer/>
<div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/guidance"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
    <div className="">
    <div className="row">
    <div className="col-md-12 col-lg-5 col-12">
    <div className="mainheader">Add New Guidance</div>
    <div className="greyfont mt-2">New Research Guidance</div>
    </div>
    <div className="col-md-12 col-lg-7 col-12">
    <div className="float-end">
    {/* <Link to="/faculty/secondsidebar/proficiency/guidance" className="link"> */}
        <span className='bacdept cursorPointer labelinput'
        >
          <span
          onClick={handleBackButtonClick}
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          >

          <img src={back} className="img-fluid me-2 " />
            Back to Guidance
          </span> 
            
            <button  className=" ms-3 saveBtn "style={{ color: 'white' }} onClick={handleSave} disabled={isButtonClicked}
>Save</button>
 
<button  className=" ms-3 addnewbtn"style={{ color: 'white' }} onClick={handleSubmit} disabled={isButtonClicked}
>Save & Submit</button>


{toggleSubMod && (
                      <>
                      <div className="modal fade show" tabIndex={-1} style={{ display: "block" }}>
            <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content mt-2 mb-2">
              <div className="d-flex justify-content-end ">
            <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setToggleSubMod(false);
                  }}
                ></button>
                </div>
              <div className="modal-header d-flex justify-content-center">
                <h5 className="modal-title submitHead">Submit for validation</h5> 
              </div>
              <div className="">
                <p className="fontModel items-center px-5">
                  This entry will be sent to admin for validation. Click 'Save' if you don't want to submit now.
                </p>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="button"
                  className="btn bg-white text-black cancelBtn"
                  // onClick={() => setToggleSubMod(false)}
                  onClick={handleSave}
                >
                  Save  
                </button>
                <button
                  type="button"
                  className="btn btnBack"
                  onClick={handleSubmit}
                >
                 Save & Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {toggleSubMod && <div className="modal-backdrop fade show"></div> }
                      </>
                    )
                  }

            </span>
            {/* </Link>            */}
</div>        
    </div>
</div>
        <form className="mt-2">
        <div className="row">
    <div className="col-sm-4 col-md-4 col-xl-2 col-lg-3 mt-2">
        <label className="labelinput">Academic Year</label>
        <select
            className="form-select labelinput inputWidth"
            value={GuidanceCreateForm.academicYear}
            onChange={handleChange}
            name="academicYear"
            style={{
              fontSize: GuidanceCreateForm.academicYear ? '14px' : '12px',
              height: "38px",
              fontFamily: 'Satoshi medium',
              color: GuidanceCreateForm.academicYear ? 'black' : 'grey', 
            }}
      >
          <option value="" disabled className="select">--Select--</option>
          {yearList.map((item) => (
    <option key={item} value={item} style={{ color: "black", fontSize: "14px" }}>
      {item}
    </option>
  ))}
          </select>
          {isSubmitted && !GuidanceCreateForm.academicYear && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
 
                  <div className="col-sm-4 col-md-4 col-xl-2 col-lg-3 mt-2">
        <label className="labelinput">No. of Past Scholars</label>
        <input
            type="tel"
            className="form-control labelinput inputWidth"
            placeholder="Enter Number of Scholars"
            value={GuidanceCreateForm.noOfScholarsPast}
            onChange={handleChange}
            name="noOfScholarsPast"
            maxLength={3}
            onKeyPress={onlyNumber}
        />
          {isSubmitted && !GuidanceCreateForm.noOfScholarsPast && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
    <div className="col-sm-4 col-md-4 col-xl-2 col-lg-3 mt-2">
        <label className="labelinput">No. of Present Scholars</label>
        <input
            type="tel"
            className="form-control labelinput inputFields inputWidth"
            placeholder="Enter Number of Scholars"
            value={GuidanceCreateForm.noOfScholars}
            onChange={handleChange}
            name="noOfScholars"
            maxLength={3}
            onKeyPress={onlyNumber}
        />
          {isSubmitted && !GuidanceCreateForm.noOfScholars && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
    </div>
    
<div className="row">
    <div className="col-sm-4 col-md-8 col-lg-6 col-xl-4 mt-2">
        <label className="labelinput">Name of the University</label>
        <input
            type="text"
            className="form-control labelinput inputWidth"
            placeholder="Enter Name of the University"
            value={GuidanceCreateForm.universityName}
            onChange={handleChange}
            name="universityName"
        />
        {isSubmitted && !GuidanceCreateForm.universityName && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
</div>
<div className="row">
<div className="col-sm-6 col-md-8 col-lg-6 col-xl-4 mt-2">
              <label className="labelinput mt-2 d-flex justify-content-between">
                <span className="" style={{ color: "#1B1922" }}>
                  Proof Document
                </span>{" "}
                <span className="" style={{ color: "#7784A1" }}>
                  Only pdf/jpg file under 2MB
                </span>
              </label>
    {/* <input
    type="file"
    className="form-control labelinput"
    value={GuidanceCreateForm.uploadFile}
    onChange={e => {base64ConverterFile(e)}}
    name="uploadFile"
    /> */}

<div className="input-group">
  <label className="form-control boxText boxtextellipse browsetext labelinput">
  {fileVal}
    <input
      type="file"
      id="fileInput2"
      className="invisible"
      onChange={(e) => base64ConverterFile(e)} 
    />
  </label>
  <span className="input-group-text lname cursorPointer labelinput"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
</div>
    </div>
    
</div>
        </form>
        </div>
</> 
 )
}

export default GuidanceCreate;