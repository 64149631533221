import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, Outlet } from "react-router-dom";
import "./employeeBio.css";
import user from "../../../../assets/user.png";
import back from "../../../../assets/back.png";
import joinedOn from "../../../../assets/joined.png";
import deActi from "../../../../assets/deactivated.png";
import comments from "../../../../assets/comments.png";
import { IoMdContact } from "react-icons/io";
import {
  employeeSingleData,
  employeeupdateSingleData,
  imageCall,
  employeeDeactivate,
  host,
} from "../../../../ApiServices";
import employeeBio from "../../../../assets/employee bio.png";
import proficiencyLogo from "../../../../assets/proficiency.png";
import salaryDetails from "../../../../assets/salary.png";
import activities from "../../../../assets/history.png";
import subjectHandled from "../../../../assets/subjects.png";
import performance from "../../../../assets/performance.png";
import Moment from "react-moment";

const EmployeeBio = (data: any, imageUrl: any) => {
  console.log(data, "yhihiyhihyi");
  console.log(data.imageUrl, "yhffffihiyhihyi");
  const navigate = useNavigate();
  const [noImage, setNoImage] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [empStatus, setEmpStatus] = useState("");
  const { employeeId } = useParams();
  const [employeeIds, setEmployeeid] = useState();
  const [employeeData, setEmployeeData] = useState<any>();
  const [image , setImage] = useState("")
  const [imagePath, setImagePath] =useState();
  const [employeeIdForm, setEmployeeIdForm] = useState({
    addressModal: {
      employeeId: "",
      presentAddress: {
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        pincode: "",
        file: "",
      },
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        pincode: "",
        file:"",
      },
      emergencyContact1: {
        name: "",
        relationShip: "",
        email: "",
        mobileNumber: "",
      },
      emergencyContact2: {
        name: "",
        relationShip: "",
        email: "",
        mobileNumber: "",
      },
    },
    empName: "",
    empNo:"",
    designation: "",
    deptId: "",
    reportingTo: "",
    joinedOn: "",
    empType: "",
    employeeId: "",
    previouslyWorkedInKings: "",
    previouslyWorkedInKings1: "",
    status: "",
    teachingExperiences: {
      details: [
        {
          id: 1,
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        }
      ],
      otherExperiences: {
        details: [
          {
            id: "",
            institution: "",
            designation: "",
            fromDate: "",
            department:"",
            category:"",
            toDate: "",
            experience: "",
            file: "",
            isItUpdated: ""
          }
        ]
      },
      
    },
    teachingExperiences1: {
      details: [
        {
          id: 1,
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: "",
        }
      ],
      
    },
    otherExperiences1: {
      details: [
        {
          id: "",
          institution: "",
          designation: "",
          fromDate: "",
          toDate: "",
          experience: "",
          file: "",
          isItUpdated: ""
        }
      ]
    },
    otherAcademics:{
      details: [
        {
          id:"",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          universit: "",
          specialization: "",
          isItUpdated: "",
          class: "",
          state:"",
          city: '',
          degree:""
        }
      ]
    },
    academicDetails: {
      academics: [
        {
          id: "10th",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          universit: "",
          specialization: "",
          isItUpdated: "",
          class: "",
          state:"",
          city: '',
        },
        {
          id: "",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          class: " ",
          state:"",
          city: '',
        },
        {
          id: "UG",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          degree: "",
          class: "",
          state:"",
          city: '',
        },
        {
          id: "PG",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          class: "",
          state:"",
          city: '',
        },
        {
          id: "Ph.D",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          class: "",
          state:"",
          city: '',
        },
        {
          id: "OTHER",
          degree: "",
          yearOfComplete: "",
          mode: "",
          board:"",
          classOfComplete: "",
          cgpa: "",
          institutionName: "",
          university: "",
          specialization: "",
          isItUpdated: "false",
          state:"",
          city: '',
        },
      ],
    },
    dob: "",
    age: "",
    gender: "",
    bloodGroup: "",
    religion: "",
    community: "",
    nationality: "",
    aadhaarNumber: "",
    martialStatus: "",
    typeOfIdProof: "",
    idProof:"",
    email: "",
    mobileNumber: "",
    empStatus: "",
    updatedOn: "",
    createdOn: "",
    image: "",
    imageName: "",
    imageType: "",
    updatedBy: "",
  });

  useEffect(()=>{
    getSingleData()
  },[])

  useEffect(()=>{
    console.log(employeeIds , "idCheck ")
  } , [employeeIds ])

  // const [formValue , setFormValue] = useState("") 

  const [formValue, setFormValue] = useState({
    LastWorkingDate: "",
    Reason: "",
    comment: "",
  });

  const [errors, setErrors] = useState({
    LastWorkingDate: "",
    Reason: "",
    comment: "",
  });

  console.log(formValue, "formValue");

  const handleBioClick = () => {
    setShowInput(!showInput);
    setFormValue({
      LastWorkingDate: "",
      Reason: "",
      comment: "",
    });
  };


  console.log(data.employeeIds ,"payload ")
  const deactiateId =()=>{

    if (!validateForm()) {
      return;
    }
    const data = {
      employeeId: employeeId,
      reason: formValue.Reason,
      comment: formValue.comment,
    };
    employeeDeactivate(data)
      .then((res) => {
        getSingleData();
        setShowInput(!showInput);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  interface TeachingExperienceDetail {
    experience?: string; 
  }
  interface OtherExperienceDetail {
    experience?: string;
  }
  // const calculateTotalExperience = () => {
  //   const teachingDetails = employeeData?.data[0]?.teachingExperiences?.details as TeachingExperienceDetail[] || [];
  //   // Get other experiences
  //   const otherDetails = employeeData?.data[0]?.otherExperiences?.details as OtherExperienceDetail[] || [];
    
  //   // Combine both arrays of experiences
  //   const allDetails = [...teachingDetails, ...otherDetails];
    
  //   const totalMonths = allDetails.reduce<number>((sum, detail) => {
  //     const experienceStr = String(detail.experience || '0.0');
  //     const [yearsStr, monthsStr] = experienceStr.split('.');
  //     const years = parseInt(yearsStr, 10) || 0;
  //     const months = parseInt(monthsStr, 10) || 0;
  //     return sum + (years * 12 + months);
  //   }, 0);
  
  //   const totalYears = Math.floor(totalMonths / 12);
  //   const remainingMonths = totalMonths % 12;
  
  //   return totalMonths > 0 ? `${totalYears}.${remainingMonths < 10 ? '0' : ''}${remainingMonths}` : '';
  // };
  const calculateTotalExperience = () => {
    const details = employeeData?.data[0]?.teachingExperiences?.details as TeachingExperienceDetail[] || [];
    
    const totalMonths = details.reduce<number>((sum, detail) => {
      const experienceStr = String(detail.experience || '0.0');
      const [yearsStr, monthsStr] = experienceStr.split('.');
      const years = parseInt(yearsStr, 10) || 0;
      const months = parseInt(monthsStr, 10) || 0;
      return sum + (years * 12 + months);
    }, 0);
  
    const totalYears = Math.floor(totalMonths / 12);
    const remainingMonths = totalMonths % 12;
  
    return totalMonths > 0 ? `${totalYears}.${remainingMonths < 10 ? '0' : ''}${remainingMonths}` : '';
  };
  const validateForm = () => {
    let isValid = true;
    let errors = { LastWorkingDate: "", Reason: "", comment: "" };

    if (!formValue.LastWorkingDate) {
      isValid = false;
      errors.LastWorkingDate = "*Required";
    }
    if (!formValue.Reason) {
      isValid = false;
      errors.Reason = "*Required";
    }
    if (!formValue.comment) {
      isValid = false;
      errors.comment = "*Required";
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    setEmployeeid(data.employeeId);
    // fetchData()
  }, [employeeIds]);

  const formatDateDifference = (joinedDate: string) => {
    const joined = new Date(joinedDate);
    const currentDate = new Date();

    const diffMs = currentDate.getTime() - joined.getTime();
    const diffYears = diffMs / (1000 * 60 * 60 * 24 * 365.25);
    const years = Math.floor(diffYears);
    const months = Math.floor((diffYears - years) * 12);

    const experience = years + months / 10;

    const yearsLabel = years <= 1 ? "yr" : "yrs";

    return `${experience.toFixed(1)} ${yearsLabel} experience`;
  };
  const getSingleData = () =>{
    employeeupdateSingleData(employeeId)
      .then((res: any) => {
        console.log(res.data,"sethures");
        
        setEmployeeData(res.data);
        setEmpStatus(res.data.data[0].empStatus);
        console.log(res.data, "ressemp");
        fetchData(res.data.data[0].image);
        console.log(res.data.data[0].image , " image check ")
      })
      .catch((err) => console.log(err));
    }
      const fetchData = async (data: string) => {
        console.log(data,"sethu");
        
        const imageUrls = host + "/employee/get/uploaded/file?fileName=" + data;
        try {
          const response = await fetch(imageUrls);
          if (response.ok) {
            setImage(imageUrls);
            console.log(imageUrls , " image check ")
            setNoImage(true);
          }
        } catch (error) {
          console.error("Error making HTTP call:", error);
          setNoImage(false);
        }
      };
    
  const dateFormater = (joinedOn: any) => {
    joinedOn = new Date();
    joinedOn.toDateString();
    return joinedOn;
  };

  const viewEmployee = () => {
    navigate("viewemployee/" + employeeId);
  };

  const ViewProficiency = () => {
    navigate("viewproficiancy/" + employeeId, {
      state: { employeeData, imagePath },
    });
  };

  return (
    <>
      <span className="mainheader"></span>
      <div className="col-md-12 col-lg-12 col-12">
        <div className="float-end">
          <span className="bacdept cursorPointer labelinput">
            {!showInput && (
              <Link
                to={"/faculty/secondsidebar/collections/employees"}
                className="link"
              >
                <img src={back} className="img-fluid me-2 " alt="Back" />
                Back to Employees
              </Link>
            )}

            {showInput && (
              <span
                onClick={() => setShowInput(!showInput)}
                className="cursorPointer"
              >
                <img src={back} className="img-fluid me-2 " alt="Back" />
                Back to Employee home
              </span>
            )}
          </span>
        </div>
      </div>
      {/* {data.data[0].map((item:any,index:any)=>{
    <>
    <span>{item.employeeId}</span>
    </>
  })} */}
      <div className="mainheader">
        {/* Employee Bio */}
        {employeeData?.data[0].employeeId}
      </div>

      <p className="greyforview mt-2">
        View details about the employee '{employeeData?.data[0].employeeId}'
      </p>

      {/* <div className='d-flex'>
          {noImage ? (
<img src={image} alt="" className="me-3 img-fluid imgW" />
) : (
<img src={user} alt="" className="me-3 img-fluid imgWidth" />
)}
          <div className='row mx-2'>
            <span className='extraDatas'>{employeeData?.data[0].empName}</span>
            <span className='employeeView'>{employeeData?.data[0].employeeId} | {employeeData?.data[0].designation} </span>
            <span className='employeeView'>{employeeData?.data[0].deptId}</span>
            
<span className='employeeView'>
<IoMdContact /> {employeeData?.data[0].joinedOn} | {formatDateDifference(employeeData?.data[0].joinedOn)} 
</span> */}
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-md-8 col-12 ">
            <div className="d-flex">
              <div className="me-3">
                {noImage ? (
                  <img src={image} alt="" className="imgW" />
                ) : (
                  <img src={user} alt="" className="imgWidth" />
                )}
              </div>
              <div>
                  {/* <div className="extraDatas ">
                    {employeeData?.data[0].empName}
                  </div> */}
                <div className='extraDatas pb-1'>{(employeeData?.data[0].empName)} {employeeData?.data[0].empType !== "" && employeeIdForm.empType !== null ? <span className='emptype labelinput ms-1 '><span className='px-3'>{(employeeData?.data[0].empType)}</span></span> : ''}</div> 
                <div className="employeeView pb-1" id="employeeView">
                  {employeeData?.data[0].employeeId} |{" "}
                  {employeeData?.data[0].designation}{" "}
                </div>
                <div className="employeeView pb-1" id="employeeView">
                  {employeeData?.data[0].deptId}
                </div>

                {/* <div className="employeeView">
                  <img className="mb-1" src={joinedOn} alt="" />&nbsp;Joined on{" "}
                  {employeeData?.data[0].joinedOn !== null ? (
                    <Moment format="DD MMM YYYY">
                      {employeeData?.data[0].joinedOn}
                    </Moment>
                  ) : (
                    "-"
                  )}{" "}
                  &nbsp;|{" "}
                  {employeeData?.data[0].totalExperience !== null
                    ? employeeData?.data[0].totalExperience + " yrs experience"
                    : "-"}
                </div>
                {calculateTotalExperience()} */}
                 {/* <div className="employeeView">
                  <img className="mb-1" src={joinedOn} alt="" />
                  Joined on{" "}
                  {employeeData?.data[0]?.joinedOn ? (
                    <Moment format="DD MMM YYYY">
                      {employeeData?.data[0]?.joinedOn}
                    </Moment>
                  ) : (
                    "-"
                  )}
                  {" | "}
                  {employeeData?.data[0]?.totalExperience ? (
                    `${employeeData?.data[0]?.totalExperience} yrs experience`
                  ) : (
                    "-"
                  )}
                </div>
                {calculateTotalExperience()} */}
                <div className="employeeView">
  <img className="mb-1" src={joinedOn} alt="" />
  Joined on{" "}
  {employeeData?.data[0]?.joinedOn ? (
    <Moment format="DD MMM YYYY">
      {employeeData?.data[0]?.joinedOn}
    </Moment>
  ) : (
    "-"
  )}
  {" | "}
  {calculateTotalExperience() ? (
    `${calculateTotalExperience()} yrs experience`
  ) : (
    "-"
  )}
</div>
              </div>
            </div>
            {employeeData?.data[0].empStatus !== "Active" ? (
              <div className="employeeView mt-3">
                <img className="mb-1" src={deActi} alt="" />&nbsp;Deactivated on&nbsp;
                {employeeData?.data[0].lastWorkingDate !== null ? (
                  <Moment format="DD MMM YYYY">
                    {employeeData?.data[0].lastWorkingDate}
                  </Moment>
                ) : (
                  "-"
                )}{" "}
                &nbsp;by Admin | Reason: {employeeData?.data[0].reason}{" "}
                &nbsp;&nbsp;<img src={comments} alt="" />&nbsp;{employeeData?.data[0].comments}{" "}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-4 col-12 text-md-end text-start pt-3 pt-md-0">
            {/* <div className="float-end d-flex flex-column align-items-end"> */}
            {/* <div className="col-md-6 col-12 text-md-end text-start pt-2 pt-md-0"> */}
            {!showInput && (
              <>
                {employeeData?.data[0].empStatus === "Active" ? (
                  <div>
                    <button
                      className="deactivebtn"
                      style={{ color: "#FFFFFF" }}
                      onClick={handleBioClick}
                    >
                      Deactivate
                    </button>
                    <div className="mt-3 labelinput ">
                      Current status{" "}
                      <span className="activebox p-1">Active</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Link
                      to={`/faculty/secondsidebar/collections/employees/editemploye/${employeeData?.data[0].employeeId}`}
                    >
                      <button
                        className="Activebtn"
                        style={{ color: "#FFFFFF" }}
                      >
                        Activate
                      </button>
                    </Link>
                    <div className="mt-3 labelinput ">
                      Current status{" "}
                      <span className="Inactivebox p-1">Inactive</span>
                    </div>
                  </div>
                )}
              </>
            )}
            {/* </div> */}
            {/* </div>   */}
          </div>
        </div>
      </div>
      <hr></hr>
      {/* <div className='hrl'></div> */}
      <div className="row">
        {!showInput && (
          <>
            {/* <Link to={`/faculty/secondsidebar/collections/employees/employeebio/viewemployee/${employeeId}`}>"cdsc</Link> */}
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width ">
              <Link
                to={`/faculty/secondsidebar/collections/employees/viewemployee/${employeeId}`}
                className="link"
              >
                <div
                  className="viewbox h-100"
                  // onClick={viewEmployee}
                >
                  <div className="pendingPadding">
                    <img src={employeeBio}></img>
                    <p
                      className="mt-2 mb-1 extraDatas cursorPointer"
                      style={{ color: "#1B1922" }}
                    >
                      Employee Bio
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width ">
              {/* <Link to={`/faculty/secondsidebar/collections/employees/viewemployee/${employeeId}`}> click</Link> */}
              <Link
                to={`/faculty/secondsidebar/collections/employees/viewproficiancy/${employeeId}`}
                className="link"
                state={{ employeeData, imagePath }}
              >
                <div className="viewbox h-100">
                  <div className="pendingPadding">
                    <img src={proficiencyLogo} alt="Proficiency Logo" />
                    <p
                      className="mt-2 mb-1 extraDatas"
                      style={{ color: "#1B1922" }}
                    >
                      Proficiency
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width disableView">
              <div className="viewbox h-100">
                <div className="pendingPadding">
                  <img src={salaryDetails}></img>
                  <p
                    className="mt-2 mb-1 extraDatas"
                    style={{ color: "#D0D0E0" }}
                  >
                    Salary Details
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width disableView">
              <div className="viewbox h-100">
                <div className="pendingPadding">
                  <img src={activities}></img>
                  <p
                    className="mt-2 mb-1 extraDatas"
                    style={{ color: "#D0D0E0" }}
                  >
                    Activities/Edit History
                  </p>
                </div>
              </div>
            </div>
            {/* <div className='row'> */}
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3 custom-col-width disableView">
              <div className="viewbox h-100 ">
                <div className="pendingPadding">
                  <img src={subjectHandled}></img>
                  <p
                    className="mt-2 mb-1 extraDatas"
                    style={{ color: "#D0D0E0" }}
                  >
                    Subjects Handled
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3  custom-col-width disableView">
              <div className="viewbox h-100">
                <div className="pendingPadding">
                  <img src={performance}></img>
                  <p
                    className="mt-2 mb-1 extraDatas"
                    style={{ color: "#D0D0E0" }}
                  >
                    Performance
                  </p>
                </div>
              </div>
            </div>
            {/* </div> */}
          </>
        )}
      </div>

      {showInput && (
        <>
          <div className="col-sm-4 col-md-6 col-lg-3">
            <label className="labelinput" style={{ color: "#1B1922" }}>
              Last Working Date
            </label>
            <input
              type="date"
              className="form-control labelinput"
              style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
              value={formValue.LastWorkingDate}
              onChange={(e) =>
                setFormValue({ ...formValue, LastWorkingDate: e.target.value })
              }
            />
            {!formValue.LastWorkingDate && errors.LastWorkingDate && (
              <p className="error">{errors.LastWorkingDate}</p>
            )}
          </div>
          <div className="col-sm-4 col-md-6 col-lg-4 mt-3">
            <label className="labelinput">Reason for deactivating</label>
            <div className="">
              <div className="form-check form-check-inline labelinput">
                <input
                  type="radio"
                  className="form-check-input me-2 labelinput"
                  name="empStatus"
                  id="Resignation"
                  value="Resignation"
                  checked={formValue.Reason == "Resignation"}
                  onChange={(e) =>
                    setFormValue({ ...formValue, Reason: e.target.value })
                  }
                />
                <label className="form-check-label">Resignation</label>
              </div>
              <div className="form-check form-check-inline labelinput">
                <input
                  type="radio"
                  className="form-check-input me-2 labelinput"
                  name="empStatus"
                  id="Termination"
                  value="Termination"
                  checked={formValue.Reason == "Termination"}
                  onChange={(e) =>
                    setFormValue({ ...formValue, Reason: e.target.value })
                  }
                />
                <label className="form-check-label">Termination</label>
              </div>
              <div className="form-check form-check-inline labelinput">
                <input
                  type="radio"
                  className="form-check-input me-2 labelinput"
                  name="empStatus"
                  id="Others"
                  value="Others"
                  checked={formValue.Reason == "Others"}
                  onChange={(e) =>
                    setFormValue({ ...formValue, Reason: e.target.value })
                  }
                />

                <label className="form-check-label">
                  Others{" "}
                  <span className="greyforview ms-2">
                    (Specify the reason in comments)
                  </span>
                </label>
              </div>
              {!formValue.Reason && errors.Reason && <p className="error">{errors.Reason}</p>}
            </div>
            <div className="col-sm-4 col-md-6 col-lg-4 mt-1">
              <label className="labelinput">Comments</label>
              <textarea
                className="form-control labelinput"
                placeholder="Enter Comments"
                name="Comments"
                style={{ height: "100px", width: "385px" }}
                value={formValue.comment}
                onChange={(e) =>
                  setFormValue({ ...formValue, comment: e.target.value })
                }
              />
              {!formValue.comment && errors.comment && <p className="error">{errors.comment}</p>}
            </div>
            <div className="col-md-6 col-lg-9 col-4">
              <span className="bacdept cursorPointer labelinput ">
                <button
                  className="mt-3 deactivebtn"
                  style={{ color: "white" }}
                  onClick={deactiateId}
                >
                  Deactivate
                </button>
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EmployeeBio;
