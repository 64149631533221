import { Outlet } from 'react-router-dom';

export interface IAppProps {
}

export function GuidanceEmp (props: IAppProps) {
  return (
    <div>
      <Outlet/>
    </div>
  );
}
