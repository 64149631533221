import React from "react";
import "./Layout.css";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import Keycloak from "keycloak-js";
import keycloak from "../keycloak";
// type Props = {};
type Props = {
  keycloak : Keycloak
};
const Layout = () => {
  return (
    <>
      <div>
      <Sidebar />
      <div className="">
      <Header />
        <div className="w-100">
          <div className="marginleft">
           
            <Outlet />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Layout;
