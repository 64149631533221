import React, { useEffect, useState } from "react";
import './Patents.css'
import { Link ,useNavigate } from "react-router-dom";
import { patentsGetTable ,patentsGetTableData ,DeleteTabledata,fileDownload , TableSubmitCall,host} from '../../../../ApiServices';
import DynamicTables from "../../../../components/DynamicTable/DynamicTables";
import { ToastContainer, toast } from "react-toastify";
import CourseTable from "../../../../components/CourseDetailTable/CourseTable";
import { MdCancel } from "react-icons/md";
import searchIcon from "../../../../assets/Icon search.svg"
import { format as formatDate } from "date-fns";
import close from "../../../../assets/close.svg";
import noRecord from "../../../../assets/norecords.png"
import { SkeletonLoader } from "../../../skeletonLoader";
import { NoData } from "../../../NoData";
function Patents() {
  const navigate = useNavigate();
  const columnRedirectsOnlineCourse = {
    courseTitle:"/proficiency/responsibilities/newResponsiblities/new-add",
  };
 
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage,setCurrentPage] = useState(1)
  const [TotalElements , setTotalElements] = useState(0)
  const [totalpages,settotalpages] = useState(0);
  const [sort , setSort] = useState(false);
  const [sortusing,setSortUsing]= useState("patId")
  const [tableDatas, settableDatas] = useState([])
  const [selectedItem, setselectedItem] = useState<any>([])
  const [viewSingle, setViewSingle] = useState(false)
  const [viewTitle,setViewtitle] = useState();
  const [pubIdView,setPubIdView] = useState();
  const [statusPat,setStatusPat] = useState<string>('');
  const [createdOn , setCreatedOn] = useState<string>('');
  const [updatedOn , setUpdatedOn] = useState<string>('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [getData, setGetData] = useState(); 
  const [loader , setLoader] = useState(true)

    const handleNextPage=()=>{
      setCurrentPage((prevPage)=>Math.min(prevPage+1,totalpages))
      console.log("next button clicked and the value now is "+ currentPage)
      }
    
     const handlePrevPage=()=>{
     setCurrentPage((prevPage)=>Math.max(prevPage-1,1))
     }
    
     const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
       setCurrentPage(value);
     };

     const handleSort =()=>{
      setSortUsing("academicYear")
      setSort(!sort);
    }

    const sortOrder = sort? "asc":"desc"

    const getDatas = () => {
        const data = {
          empID:sessionStorage.getItem('empId'),
          page:currentPage-1,
          sort:sortOrder,
          sordId:sortusing,
          keyword:searchTerm,
        }
        patentsGetTable(data)
        .then((res) => {
          const formattedData = res.data.datas.content.map((item: any) => {
            let formattedCreatedDate = "";
            let formattedUpdatedDate = "";
            let formattedSubmittedOn = "";
            let formattedValidatedOn = "";
          
            if (item.createdOn) {
              const createdOnDate = new Date(item.createdOn);
              formattedCreatedDate = formatDate(createdOnDate, "dd MMM yyyy hh:mm a");
            }
          
            if (item.updatedOn) {
              const updatedOnDate = new Date(item.updatedOn);
              formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
            }

          if(item.submittedOn){
            const submittedOnDate = new Date(item.submittedOn)
            formattedSubmittedOn = formatDate(submittedOnDate, "dd MMM yyyy hh:mm a")
          }

          if(item.validatedOn){
            const validatedOnDate = new Date(item.validatedOn)
            formattedValidatedOn = formatDate(validatedOnDate, "dd MMM yyyy hh:mm a")
          }

          
            return {
              ...item,
              createdOn: formattedCreatedDate,
              updatedOn: formattedUpdatedDate,
              submittedOn:formattedSubmittedOn,
              validatedOn:formattedValidatedOn
            };
          });
  
          settableDatas(formattedData);
          setLoader(false)
          setGetData(res.data.data[0]);
          console.log(res.data.datas.content, "Patents");
          setTotalElements(res.data.datas.totalElements);
          settotalpages(res.data.datas.totalPages);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const Header = [
        { label: "Academic Year", key: "academicYear" },
        { label: "Title of the Patent", key: "patentTitle" },
        { label: "Patent Status", key: "patentStatus" },
        { label: "Submitted on", key: "submittedOn" },
        { label: "Status", key: "status" },
        { label: "Validated on", key: "validatedOn" },
      ];

      const PatentViewHeaders=[
        {
          "title": "Academic Year",
          "key":"academicYear"
        },
        {
          "title": "File Number",
          "key":"fileNumber"
        }, {
          "title": "Title of the patent",
          "key":"patentTitle"
        }, {
          "title": "Agency Name",
          "key":"agencyName"
        }, {
          "title": "Status",
          "key":"patentStatus"
        }, {
          "title": "Year",
          "key":"year"
        },{
            "title": "Proof Document",
            "key":"uploadFile"
        }
      ]

      function navigateView(item: any){
        patentsGetTableData(item.patId).then((res:any)=>{
          
          setselectedItem(res.data.data[0]);
          console.log(res.data.data[0], "am the patent data u r searching for ")
          // console.log(selectedItem,"am the patent data u r searching for  2")
          setViewtitle(res.data.data[0].proficiency.patentTitle);
          setPubIdView(res.data.data[0].proficiency.patId);
          setStatusPat(res.data.data[0].proficiency.patentStatus);
          const formattedCreatedDate = new Date(res.data.data[0].proficiency.createdOn);
          const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");        
          let updatedOn = "";
          if (res.data.data[0].proficiency.updatedOn !== null) {
              const formattedUpdatedDate = new Date(res.data.data[0].proficiency.updatedOn);
              updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
          }         
          setCreatedOn(createdOn);
          setUpdatedOn(updatedOn);
          setViewSingle(true)
        }).catch((err:any)=>console.log(err))
      }

      function editCourse(item:any){
        console.log(item,"ssbiki")
        const data = item.patId?item.patId:item.proficiency.patId;
        console.log(data);
        navigate("editPatents/"+data)
      }
    
      const deleteApi=(data: any) =>{
        const url = "/patent?patId=" + data.patId;
        DeleteTabledata(url)
          .then((res) => {
            console.log(res.data.status);
            toast.success(res.data.status);
            getDatas()
          })
          .catch((err) => {
            console.log(err)
          });
      }
      const deleteApiView=() =>{
        const url = "/patent?patId=" + pubIdView;
        DeleteTabledata(url)
          .then((res) => {
            console.log(res.data.status); 
            setViewSingle(false)       
            toast.success(res.data.status);
            // alert(res.data.status);
            getDatas()
          })
          .catch((err) => {
            console.log(err)
          });
      }

      const FileDownload = () => {
        const url = host+"proficiency/get/patent?patId=" + pubIdView + "&keyword=file";
        fileDownload(url)
          .then((res) => {             
            toast.success(res.data.status);
            window.open(url, '_blank');
            getDatas();
          })
          .catch((err) => {
            console.log(err);
          });
      }

      const toggleSearch = () => {
        setIsSearching(!isSearching);
        setSearchTerm("");
        setIsSearchOpen(!isSearchOpen);
        if (!isSearchOpen) {
          setSearchTerm('');
        }
      };

      const handleCancel = () => {
        setSearchTerm('');
        setIsSearchOpen(false);
      };


     
      const backtoview = "to Patents"
      const deleteCont = "Patent"
      const heading = viewTitle
      const subheading = "View details of patent "+ "" + statusPat.toLowerCase();

      const TableSubmit =(item:any)=>{
        console.log("tablesumbit toggled ")
        const payload = {
          empId:sessionStorage.getItem('empId'),
          sectionId:item.patId,
          status :"Pending",
          section:"Patents",
         academicYear:item.academicYear
      }
    console.log(payload,"hkjgkjgh");
    
      TableSubmitCall(payload)
      .then((res)=>{
        console.log(res)
        getDatas()
        toast.success(res.data.responseMessage)
      })
      .catch((err)=>{
        console.log(err)
      })
      console.log(payload , "am the payload")
      setViewSingle(false)
      }
      
      useEffect(() => {
        getDatas();
      }, [currentPage,sort,searchTerm]);

      const moduleName = "Patent"
      const AddNewPath = "/faculty/secondsidebar/proficiency/patents/patentscreate"

      return (
        <>
          <ToastContainer />
          {!viewSingle ? (
            <>
              <div className="">
                <div className="row">
                  <div className="col-md-12 col-lg-5 col-12">
                    <div className="mainheader">Patents</div>
                    <div className="greyfont mt-2">List of patents published/awarded</div>
                  </div>
                  {getData === true && (
                    <div className="col-md-12 col-lg-7 col-12 AddNewBtnDiv">
                      <div className="float-end">
                        <Link to="/faculty/secondsidebar/proficiency/patents/patentscreate" className="link">
                          <span className='bacdept cursorPointer'>
                            <button className="ms-3 addnewbtn" style={{ color: 'white' }}>Add New</button>
                          </span>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <div className="">
                {getData === true && (
                  <div className="addresult mt-4">
                    <span className="active tablink pb-1 bg addresult mb-3">All Results</span>
                   
                      <div className={`${isSearchOpen ? 'me-0' : 'me-5'} search`} style={{boxShadow:'none'}}>
                        <img
                          alt="search"
                          // className="fasearch mb-2"
                          className={`${isSearchOpen ? 'ms-0' : 'ms-3'} fasearch mb-2`}
                          src={searchIcon}
                          onClick={toggleSearch}
                          style={{cursor:'pointer'}}
                        />
                        {isSearchOpen && (
                          <div className="search-input-container">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                            />
                            <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }} />
                          </div>
                        )}
                      </div>
                    
                  </div>
                  )}
                  {/* {getData === true ?
                  (
                    <DynamicTables
                      data={tableDatas}
                      headers={Header}
                      currentPage={currentPage}
                      pages={totalpages}
                      columnRedirects={columnRedirectsOnlineCourse}
                      handleNextPage={handleNextPage}
                      handlePrevPage={handlePrevPage}
                      TotalElements={TotalElements}
                      handleChangePage={handleChangePage}
                      handleSort={handleSort}
                      navigateView={navigateView}
                      editCourse={editCourse}
                      deleteApi={deleteApi}
                      TableSubmit={TableSubmit}
                    />
                  )
                  :
                  (
                    <div className="d-flex flex-column align-items-center" style={{ marginTop: "200px" }}>
                      <img src={noRecord} alt="No records" />
                      <p className="mt-4 greyfont" style={{ textAlign: 'center' }}>
                        You haven't added any patents yet.<br />Start by adding a patent.
                      </p>
                      <Link to="/faculty/secondsidebar/proficiency/patents/patentscreate">
                        <button className="text-white addnewbtn">Add New</button>
                      </Link>
                    </div>
                  ) } */}
                  {loader ? 
                  <SkeletonLoader/> :
                  getData?
                  <DynamicTables
                  data={tableDatas}
                  headers={Header}
                  currentPage={currentPage}
                  pages={totalpages}
                  columnRedirects={columnRedirectsOnlineCourse}
                  handleNextPage={handleNextPage}
                  handlePrevPage={handlePrevPage}
                  TotalElements={TotalElements}
                  handleChangePage={handleChangePage}
                  handleSort={handleSort}
                  navigateView={navigateView}
                  editCourse={editCourse}
                  deleteApi={deleteApi}
                  TableSubmit={TableSubmit}
                />
                :
                <NoData name={moduleName} path={AddNewPath}/>
                }

                </div>
              </div>
            </>
          ) : (
            <CourseTable
              data={[selectedItem]}
              deleteCont={deleteCont}
              backtoview={backtoview}
              back={() => { setViewSingle(!viewSingle) }}
              headers={PatentViewHeaders}
              heading={heading}
              subheading={subheading}
              createdOn={createdOn}
              updatedOn={updatedOn}
              deleteApiView={deleteApiView}
              FileDownload={FileDownload}
              TableSubmit={TableSubmit}
              editCourse={editCourse}
            />
          )}
        </>
      );
      
      
}
export default Patents;