import React, { useEffect, useState } from 'react'
import {AppraisalSingleCallPublication ,AppraisalSingleCallConsultancy ,AppraisalSingleCallBooksandChapter ,AppraisalSingleCallResponsibilities ,AppraisalSingleCallperformance ,AppraisalSingleCallsponsoredResearch ,AppraisalSingleCallGuidance ,AppraisalSingleCallTraining ,AppraisalSingleCallpatent, AppraisalGetTableData ,AppraisalSingleCallonlinecourse ,AppraisalSingleCallHonoursAndAwards } from '../../../../ApiServices'
import { Link,useParams } from 'react-router-dom'
import icon from "../../../../assets/Appraisals/Icon awesome-plus-circle.png"
import user from "../../../../assets/user.png"
import minus from "../../../../assets/Icon awesome-minus-circle.png"
import download from "../../../../assets/download.png"
import print from "../../../../assets/print.png"
import { GoArrowLeft } from "react-icons/go";
// import alert from "../../../../assets/Icon ionic-ios-alert.png"
type Props = {}

const AppraisalsView = (props: Props) => {
const {id}= useParams()
console.log(id ,"am id ")

const [data,setData]= useState<any>()
const [isloading, setisloading] = useState(true)
const [empDetails, setempDetails] = useState<any>(null)
const [appraisalList , setAppraisalList] = useState([])
const [employeeDetails, setEmployeeDetails] = useState([])
const [appraisalSingle , setAppraisalSingle] = useState([])
const[publication,setPublication]=useState([])
const [patent,setPatent]=useState([])
const[honours,setHonours] = useState([])
const [trainings , setTraining] = useState([])
const [guidance , setGuidance] = useState([])
const [sponsored , setSponsored] = useState([])
const [onlinecourse , setOnlineCourse] = useState([])
const [performances , setPerformance] = useState([])
const [Responsibilty , setResponsibility] = useState([])
const [BooksandChapter , setBooksandChapter] = useState([])
const [honoursAndAwards , sethonoursAndAwards] = useState([])
const [consultanc , setConsultancy] = useState([])
const [toggle1 , SetToggle1] = useState(false)
const [toggle2 , SetToggle2] = useState(false)
const [toggle3 , SetToggle3] = useState(false)
const [toggle4 , SetToggle4] = useState(false)
const [toggle5 , SetToggle5] = useState(false)
const [toggle6 , SetToggle6] = useState(false)
const [toggle7 , SetToggle7] = useState(false)
const [toggle8 , SetToggle8] = useState(false)
const [toggle9 , SetToggle9] = useState(false)
const [toggle10 , SetToggle10] = useState(false)
const [toggle11 , SetToggle11] = useState(false)
const [academicYear , setAcademicYear] = useState()
const [empId , setEmpId] = useState()
const [empName , setEmpName] = useState()
const [department , setDepartment] = useState()
const [designation , setDesignation] = useState()
// console.log(honurType,"Jeba");

const [empid , setEmpid]= useState("")
const [isOpen, setIsOpen] = useState(false);

  const getDatas =()=>{

    const data ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
  
    AppraisalGetTableData(data)
    .then((res)=>{
      // console.log(res.data.data , "am tableData response" )
      setempDetails(res.data.data)
      console.log(res.data.data[0]?.academicInformation[0]?.appraisalDetailsRepo.academicYear, "am tableData response");
      setAcademicYear(res.data.data[0]?.academicInformation[0]?.appraisalDetailsRepo.academicYear)
      setEmpId(res.data.data[0]?.academicInformation[0]?.appraisalDetailsRepo.empId)
      setEmpName(res.data.data[0]?.academicInformation[0]?.appraisalDetailsRepo.empName)
      setDepartment(res.data.data[0]?.academicInformation[0]?.appraisalDetailsRepo.deptId)
      setDesignation(res.data.data[0]?.academicInformation[0]?.appraisalDetailsRepo.designation)
      setAppraisalList(res.data.data[0].appraisalList);
      setisloading(false)
      // setData(res.data.data[0])
    })
    .catch((err)=>{
      console.log(err)
    })
  
    AppraisalSingleCallPublication(data)
    .then((res:any)=>{
      console.log(res.data.data,"hello")
      setPublication(res.data.data)
      console.log(res.data.data , "pub data")
    })
    .catch((err:any)=>{
      console.log(err)
    })

    // AppraisalSingleCallonlinecourse(data)
    // .then((res:any)=>{
    //   console.log(res.data.data,"am AppraisalSingleGetCall response")
    // })
    // .catch((err:any)=>{
    //   console.log(err)
    // })

    // AppraisalSingleCallHonoursAndAwards(data)
    // .then((res:any)=>{
    //   console.log(res.data.data,"am AppraisalSingleGetCall response")
    // })
    // .catch((err:any)=>{
    //   console.log(err)
    // })
  
  }

//   const handleHeaderClick = (item:any,index:any)=>{
//     console.log(item,index,"Its me Gopi");

// if( index === "Online Courses"){
//   console.log("items matched")
//   AppraisalSingleCallonlinecourse(id)
//   .then((res: any) => {
//       console.log(res.data.data, "Online Courses API response");
//       setAppraisalSingle(res.data.data)
//   })
//   .catch((err: any) => {
//       console.log(err);
//   });
// }
//      }

  useEffect(()=>{
    getDatas()
  },[])

  const handleTogglePub =()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallPublication(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setPublication(res.data)
      console.log(res.data.data , "pub data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
  SetToggle1(!toggle1)
  }

  const handletogglePat=()=>{
    setIsOpen(!isOpen);
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallpatent(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setPatent(res.data)
      console.log(res.data , "patent data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
  SetToggle2(!toggle2)
  }
  const handletoggleHon =()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    
    AppraisalSingleCallHonoursAndAwards(datas)
      .then((res) => {  
        res.data.forEach((h:any) => { 
          switch (h.type) {
            case "Awards":
              newHonoursAndAwards.push(
                {
                  title: "Award Title",
                  key: "awardTitle",
                },
                {
                  title: "Award Presented by",
                  key: "presentedBy",
                },
                {
                  title: "File",
                  key: "uploadFile",
                }
              );
              break;
            case "Fellowships":
              newHonoursAndAwards.push(
                {
                  title: "Fellowship Title",
                  key: "fellowTitle",
                },
                {
                  title: "Fellowship Awarded by",
                  key: "fellowPresentedBy",
                },
                {
                  title: "File",
                  key: "uploadFile",
                }
              );
              break;
            case "Honours":
              newHonoursAndAwards.push(
                {
                  title: "Honours Title",
                  key: "honourTitle",
                },
                {
                  title: "Honours Presented by",
                  key: "honourPresentedBy",
                },
                {
                  title: "File",
                  key: "uploadFile",
                }
              );
              break;
            case "Memberships":
              newHonoursAndAwards.push(
                {
                  title: "Organisation Name",
                  key: "organisationName",
                },
                {
                  title: "Role",
                  key: "organisationRole",
                },
                {
                  title: "Current Status",
                  key: "currentStatus",
                },
                {
                  title: "File",
                  key: "uploadFile",
                }
              );
              break;
            default:
              break;
          }
        });
    
        setHonours(res.data);
        // sethonoursAndAwards(newHonoursAndAwards);
        // console.log(res.data, "Appraisal data");
      })
      .catch((err) => {
        console.log(err);
      });
    
    SetToggle3(!toggle3);
    }    

  const handletoggleTraining=()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallTraining(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setTraining(res.data)
      console.log(res.data , "Appraisal data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
    // AppraisalSingleCallTraining(data)
    SetToggle4(!toggle4)
  }

  
  const handletoggleGui=()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallGuidance(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setGuidance(res.data)
      console.log(res.data , "Appraisal data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
    // AppraisalSingleCallGuidance(data)
    SetToggle5(!toggle5)
  }

  const handletoggleSpo=()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallsponsoredResearch(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setSponsored(res.data)
      console.log(res.data , "sponosored data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
    // AppraisalSingleCallsponsoredResearch(data)
    SetToggle6(!toggle6)
  }

  const handletoggleOnl=()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallonlinecourse(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setOnlineCourse(res.data)
      console.log(res.data , "sponosored data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
    // AppraisalSingleCallonlinecourse(data)
    SetToggle7(!toggle7)
  }

  const handletogglePer =()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallperformance(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setPerformance(res.data)
      console.log(res.data , "Performance data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
    // AppraisalSingleCallperformance(data)
    SetToggle8(!toggle8)
  }

  const handletoggleRes =()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallResponsibilities(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setResponsibility(res.data)
      console.log(res.data , "responsibility data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
    // AppraisalSingleCallResponsibilities(data)
    SetToggle9(!toggle9)
  }

  const handletoggleBooks =()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallBooksandChapter(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setBooksandChapter(res.data)
      console.log(res.data , "Books and chapter data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
    // AppraisalSingleCallBooksandChapter(data)
    SetToggle10(!toggle10)
  }

  const handletoggleConsult =()=>{
    const datas ={
      empId: sessionStorage.getItem('empId'),
      id:id,
    }
    AppraisalSingleCallConsultancy(datas)
    .then((res:any)=>{
      console.log(res.data,"heldewfwflo")
      setConsultancy(res.data)
      console.log(res.data , "consultancy data")
    })
    .catch((err:any)=>{
      console.log(err)
    })
    // AppraisalSingleCallConsultancy(data)
    SetToggle11(!toggle11)
  }

  const AppraisalViewHeaders=[
    {
      "title": "Academic Year",
      "key":"academicYear"
    },{
      "title": "Qualification",
      "key":""
    }, {
      "title": "Experience in KEC",
      "key":""
    }, {
      "title": "Total Experience in Teaching",
      "key":""
    }, {
      "title": "Salary at the time of join",
      "key":""
    }, {
      "title": "Current Salary",
      "key":""
    }, {
      "title": "No. of Papers publishedin SCI indexed Journals (only in KEC)",
      "key":""
    },{
      "title": "No. of Papers published in Scopus indexed Journals (only in KEC)",
      "key":""
    },{
      "title": "No. of Papers published in UGC Listed Journals (only in KEC)",
      "key":""
    },{
      "title": "As first author",
      "key":""
    },{
      "title": "No. of Patterns Received (only in KEC)",
      "key":""
    },{
      "title": "No. of Patterns Applied (only in KEC)",
      "key":""
    },{
      "title": "No. of Books Published (only in KEC)",
      "key":""
    },{
      "title": "If completed Ph.D - Name of the University",
      "key":""
    },{
      "title": "Ph.D -Year of Completion",
      "key":""
    },{
      "title": "Guideship",
      "key":""
    },{
      "title": "No. of Scholars",
      "key":""
    },{
      "title": "Name of the university",
      "key":""
    }
  ]

  const PublicationsHeaders=[
    {
      "title": "Title of the Publication",
      "key":"title"
    },{
      "title": "Listed in",
      "key":"listedIn"
    }, {
      "title": "DOI",
      "key":"doi"
    }, {
      "title": "Name of the Journal",
      "key":"journalName"
    }, {
      "title": "h-index",
      "key":"hindex"
    }, {
      "title": "i10-index",
      "key":"i10Index"
    },{
      "title": "Impact Factor",
      "key":"impactFactor"
    }, {
      "title": "Author Position",
      "key":"authorPosition"
    },
    {
      "title": "Publication URL",
      "key":"publicationUrl"
    },{
      "title": "File",
      "key":"uploadFile"
    }
  ]

  const patentsHeaders=[
    {
      "title": "File Number",
      "key":"fileNumber"
    }, {
      "title": "Title of the patent",
      "key":"patentTitle"
    }, {
      "title": "Agency Name",
      "key":"agencyName"
    },{
      "title": "Status",
      "key":"patentStatus"
    }, {
      "title": "Year",
      "key":"year"
    },{
        "title": "File",
        "key":"uploadFile"
    }
  ]
  
  // const honoursAndAwards=[
  //   {
  //     "title": "Type",
  //     "key":"type"
  //   }, {
  //     "title": "Award Title",
  //     "key":"awardTitle"
  //   }, {
  //     "title": "Award Presented by",
  //     "key":"awardPresentedBy"
  //   }, {
  //     "title": "File",
  //     "key":""
  //   }
  // ]
  // const honoursAndAwards=[
  //   {
  //     "title": "Academic Year",
  //     "key":"academicYear"
  //   },
  //   {
  //     "title": "Type",
  //     "key":"type"
  //   },
  // ]
  // if (honurType === "Awards") {
  //   honoursAndAwards.push(
  //     {
  //       "title": "Award Title",
  //       "key": "awardTitle"
  //     },
  //     {
  //       "title": "Award Presented by",
  //       "key": "presentedBy"
  //     },
  //     {
  //       "title": "File",
  //       "key": "uploadFile"
  //     }
  //   );
  // } else if(honurType === "Fellowships"){
  //   honoursAndAwards.push(
  //     {
  //       "title": "Fellowship Title",
  //       "key": "fellowTitle"
  //     },
  //     {
  //       "title": "Fellowship Awarded by",
  //       "key": "fellowPresentedBy"
  //     },
  //     {
  //       "title": "File",
  //       "key": "uploadFile"
  //     }
  //   );
  // }else if(honurType === "Honours"){
  //   honoursAndAwards.push(
  //     {
  //       "title": "Honours Title",
  //       "key": "honourTitle"
  //     },
  //     {
  //       "title": "Honours Presented by",
  //       "key": "honourPresentedBy"
  //     },
  //     {
  //       "title": "File",
  //       "key": "uploadFile"
  //     }
  //   );
  // }else if(honurType === "Memberships"){
  //   honoursAndAwards.push(
  //     {
  //       "title": "Organisation Name",
  //       "key": "organisationName"
  //     },
  //     {
  //       "title": "Role",
  //       "key": "organisationRole"
  //     },
  //     {
  //       "title": "Current Status",
  //       "key": "currentStatus"
  //     },
  //     {
  //       "title": "File",
  //       "key": "uploadFile"
  //     }
  //   );
  // }
  const newHonoursAndAwards = [
    {
      title: "Academic Year",
      key: "academicYear",
    },
    {
      title: "Type",
      key: "type",
    },
    {
      title: "Award Title",
      key: "awardTitle",
    },
    {
      title: "Award Presented by",
      key: "presentedBy",
    },
    {
      title: "File",
      key: "uploadFile",
    }
  ];
  const training =[
    {
      "title": "Title of the programme",
      "key":"programTitle"
    }, {
      "title": "Organization (AICTE/AU..)",
      "key":"organization"
    }, {
      "title": "Acted as",
      "key":"actedAs"
    }, {
      "title": "Duration",
      "key":"duration"
    }, {
      "title": "Period",
      "key":"period"
    }, {
      "title": "File",
      "key":"uploadFile"
    }
  ]

  const Guidance =[
    {
      "title": "Name of the University",
      "key":"universityName"
    },
    {
      "title": "Number of Scholars",
      "key":"noOfScholars"
    }, {
      "title": "File",
      "key":"uploadFile"
    }
  ]

  const sponsoredResearch =[
    {
      "title": "Category",
      "key":"category"
    }, {
      "title": "Agency",
      "key":"agency"
    }, {
      "title": "Sanctioned Amount in Rupees",
      "key":"amountSanction"
    },  {
      "title": "Completion Percentage",
      "key":"completionPercentage"
    }, {
      "title": "File",
      "key":"fileUpload"
    },
  ]

  const onlinecourses=[
    {
      "title": "Course Title",
      "key":"courseTitle"
    }, {
      "title": "Awarding Institution",
      "key":"awardingInstitution"
    }, {
      "title": "Grade",
      "key":"grade"
    }, {
      "title": "Course Completed on",
      "key":"completionOn"
    }, {
      "title": "Course duration",
      "key":"courseDuration"
    }, {
      "title": "File", 
      "key":"uploadFile"
    }
  ]


  const performance =[
    {
      "title": "Year",
      "key":"year"
    },
    {
      "title": "Semester",
      "key":"semester"
    },
    {
      "title": "Exam Type",
      "key":"examType"
    },
    {
      "title": "Department",
      "key":"department"
    },
    {
      "title": "Subject Handled",
      "key":"subHandled"
    },
    {
      "title": "Total No. of Student",
      "key":"totalStudents"
    },
    {
      "title": "No. of Student Appeared",
      "key":"studentsAppeared"
    },
    {
      "title": "No. of Student Passed",
      "key":"studentsPassed"
    },
    {
      "title": "Pass Percentage",
      "key":"passedPercentage"
    }
  ]

  const responsibilities =[
    {
      "title": "Name of the Responsibility",
      "key":"responsibilityName"
    }, {
      "title": "Role",
      "key":"role"
    }, {
      "title": "Duration",
      "key":"createdOn"
    }, {
      "title": "In-charge / HOD",
      "key":"inCharge"
    }, {
      "title": "File",
      "key":"uploadFile"
    }
  ]

  const BooksandChapters =[
    {
      "title": "Title of the book/chapter",
      "key":"titleOfBook"
    },
    {
      "title": "Authors",
      "key":"authors"
    },
    {
      "title": "Publisher",
      "key":"publisher"
    },
    {
      "title": "Year of Publication",
      "key":"yearOfPublications"
    },
    {
      "title": "Author Position",
      "key":"authorPosition"
    },
    {
      "title": "ISBN Number",
      "key":"isbnNo"
    },
    {
      "title": "File",
      "key":"uploadFile"
    },
  ]


  const consultancy =[
     {
      "title": "Category",
        "key":"category"
     },
     {
      "title": "Agency",
        "key":"agency"
     },
     {
      "title": "Sanctioned Amount in Rupees",
        "key":"amountSanction"
     },
     {
      "title": "Completion Percentage",
        "key":"completionPercentage"
     },
     {
      "title": "File",
        "key":"fileUpload"
     },
  ]


  return (
    <>
    <div>
      {/* <span className='alertApp'><img src={alert} alt="" /><span>Important : Make sure the data entered is correct. Once sent for approval, the data can't be edited / changed.</span></span> */}
      <div>
   
          <div className=''>
          <h5 className='mainheader'>{empId}</h5>
          <span className='' style={{color:"#7784A1" , font:"12px satoshi medium"}}>View Appraisal form of the academic year '{academicYear}'</span>
          <div className='d-flex pt-4'>
            <div className='col-lg-10 col-md-9 col-12'>
              <div className='d-flex'>
            <img src ={user} style={{width:"100px"}}></img>
            <div className='row ps-4 pt-2'>
            <span style={{ font:"16px satoshi-bold"}} className='mt-1'>{empName}</span>
            <span style={{ font:"13px satoshi medium"}} className='mt-1'>{empId}</span>
            <span style={{ font:"13px satoshi medium"}} className='mt-1'>{department}</span>
            <span style={{ font:"13px satoshi medium"}} className='mt-1'>{designation}</span>
            </div>
            </div>
            </div>
            <div className='col-lg-2 col-md-3 col-6 ms-3'>
  <Link to={`/faculty/secondsidebar/proficiency/appraisals`} className="link">
  <span className='bacdept cursorPointer labelinput me-3'>
            <img className="img-fluid me-2 " />
            <span className='me-2'> <GoArrowLeft/></span>
            <span>  Back to Appraisal </span> 
            </span>
            </Link>
</div>
          </div>
        
        </div>
  
       

      </div>
      
     
     <table className="col-md-12 col-12 partiTable mt-5">
          <tbody>
            {/* {AppraisalViewHeaders?.map((header:any, index:any) => (
              <tr key={index}>
                <td>{header.title}</td>
                {data?.map((item:any,dataIndex:any)=>(
                  <td>{item[header.key]?item[header.key]:"-"}</td>
                ))}
              </tr>
            ))} */}
            <div></div>
          </tbody>
        </table>

   {/* {appraisalList.map((item  , index:any)=>{
    return(
      <>
      <div className='d-flex mt-3 background-color-headers' key={index}>
      <img src={icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}} onClick={() => handleHeaderClick(index, item)}></img>
      <span className='headerAppraisal'>{item}</span>
      </div>
      </>
    )
   })} */}


        <table className="col-md-12 col-sm-12 col-12 partiTable cursorPointer">
          <div className='d-flex mt-3 background-color-headers px-3 p-2 ' onClick={handleTogglePub}>
            <div className='d-flex col-md-10 col-9 cursorPointer'>
            <span className='pt-1'>
          <img className='cursorPointer'  src={toggle1 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Publications</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
            200 Points
          </div>
          </div>
          {toggle1?
         <div>
         {publication && publication.length > 0 ? (
           publication.map((pub, index) => (
             <div  className='mt-3 mb-3 ms-3' key={index}>
               <span style={{ font:"21px satoshi-bold"}}>Publication 0{index +1}</span>
               <table className='col-md-12 col-12'>
                 <tbody>
                   {PublicationsHeaders.map((header, headerIndex) => (
                     <tr key={headerIndex}>
                       <td className='col-md-2'>{header.title}</td>
                       <td>{pub[header.key]}</td>
                     </tr>
                   ))}
                 </tbody>
               </table>
             </div>
           ))
         ) : (
           <div className="mt-3">
             <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No publication records found.</p>
           </div>
         )}
       </div>
       :
            ""
            }
        </table>

{/* patent  */}

        <table className="col-md-12 col-12 partiTable cursorPointer">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletogglePat}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle2 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Patent</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
            50 Points
          </div>
          </div>
          {toggle2?
              <div>
              {patent && patent.length > 0 ? (
                patent.map((pat, index) => (
                  <div key={index} className='mt-3 mb-3 ms-3'>
                    <span style={{ font:"21px satoshi-bold"}}>Patent 0{index +1}</span>
                    <table className='col-md-12 col-12'>
                      <tbody>
                        {patentsHeaders.map((header, headerIndex) => (
                          <tr key={headerIndex}>
                            <td className='col-md-2'>{header.title}</td>
                            <td>{pat[header.key]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <div>
                  <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No patents found.</p>
                </div>
              )}
            </div>
            :
            ""
            }
        </table>


{/* Honour */}

        <table className="col-md-12 col-12 partiTable cursorPointer">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletoggleHon}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle3 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Honours & Awards</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
          50 Points
          </div>
          </div>
          {toggle3?
              <div >
              {honours && honours.length > 0 ? (
                honours.map((hon, index) => (
                  <div key={index} className='mt-3 mb-3 ms-3'>
                      <span style={{ font:"21px satoshi-bold"}}>Honours & Awards 0{index +1}</span>
                    <table className='col-md-12 col-12'>
                      <tbody>
                        {newHonoursAndAwards.map((header, headerIndex) => (
                          <tr key={headerIndex}>
                            <td className="col-md-2">{header.title}</td>
                            <td>{hon[header.key]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <div>
                  <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}> No Honours & Awards details found.</p>
                </div>
              )}
            </div>
            :
            ""
            }
        </table>

{/* trainings */}

        <table className="col-md-12 col-12 partiTable cursorPointer">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletoggleTraining}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle4 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Trainings</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
          50 Points
          </div>

          </div>
          {toggle4?
            <div>
            {trainings && trainings.length > 0 ? (
              trainings.map((train, index) => (
                <div key={index} className='mt-3 mb-3 ms-3'>
                     <span style={{ font:"21px satoshi-bold"}}>Training 0{index +1}</span>
                  <table className='col-md-12 col-12'>
                    <tbody>
                      {training.map((header, headerIndex) => (
                        <tr key={headerIndex}>
                          <td className='col-md-2'>{header.title}</td>
                          <td>{train[header.key]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))
            ) : (
              <div>
                <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No training records found.</p>
              </div>
            )}
          </div>
          :
            ""
            }
        </table>

{/* guidance */}
        <table className="col-md-12 col-12 partiTable cursorPointer">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletoggleGui}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle5 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Guidance</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
          50 Points
          </div>
          </div>
          {toggle5?
              <div>
              {guidance && guidance.length > 0 ? (
                guidance.map((guide, index) => (
                  <div key={index} className='mt-3 mb-3 ms-3'>
                     <span style={{ font:"21px satoshi-bold"}}>Guidance 0{index +1}</span>
                    <table className='col-md-12 col-12'>
                      <tbody>
                        {Guidance.map((header, headerIndex) => (
                          <tr key={headerIndex}>
                            <td className='col-md-2'>{header.title}</td>
                            <td>{guide[header.key]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <div>
                  <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No guidance records found.</p>
                </div>
              )}
            </div>
            :
            ""
            }
        </table>

 {/* Sponsored Research */}

        <table className="col-md-12 col-12 partiTable cursorPointer">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletoggleSpo}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle6 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Sponsored Research</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
          50 Points
          </div>
          </div>
          {toggle6?
             <div>
             {sponsored && sponsored.length > 0 ? (
               sponsored.map((spon, index) => (
                 <div key={index} className='mt-3 mb-3 ms-3'>
                    <span style={{ font:"21px satoshi-bold"}}>Sponsored Research 0{index +1}</span>
                   <table className='col-md-12 col-12'>
                     <tbody>
                       {sponsoredResearch.map((header, headerIndex) => (
                         <tr key={headerIndex}>
                           <td className='col-md-2'>{header.title}</td>
                           <td>{spon[header.key]}</td>
                         </tr>
                       ))}
                     </tbody>
                   </table>
                 </div>
               ))
             ) : (
               <div>
                 <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No sponsored research records found.</p>
               </div>
             )}
           </div>
           :
            ""
            }
        </table>

 {/* Online Course */}
        <table className="col-md-12 col-12 partiTable cursorPointer">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletoggleOnl}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle7 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Online Course</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
          50 Points
          </div>
          </div>
          {toggle7?
            <div>
            {onlinecourse && onlinecourse.length > 0 ? (
              onlinecourse.map((on, index) => (
                <div key={index} className='mt-3 mb-3 ms-3'>
                   <span style={{ font:"21px satoshi-bold"}}>Online Course 0{index +1}</span>
                  <table className='col-md-12 col-12'>
                    <tbody>
                      {onlinecourses.map((header, headerIndex) => (
                        <tr key={headerIndex}>
                          <td className='col-md-2'>{header.title}</td>
                          <td>{on[header.key]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))
            ) : (
              <div>
                <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No online course records found.</p>
              </div>
            )}
          </div>
          :
            ""
            }
        </table>

{/* Performance */}

        <table className="col-md-12 col-12 partiTable cursorPointer">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletogglePer}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle8 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Performance</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
            100 Points
          </div>
          </div>
          {toggle8?
           <div>
           {performances && performances.length > 0 ? (
             performances.map((per, index) => (
               <div key={index} className='mt-3 mb-3 ms-3'>
                  <span style={{ font:"21px satoshi-bold"}}>Results analysis  0{index +1}</span>
                 <table className='col-md-12 col-12'>
                   <tbody className='col-md-2'>
                     {performance.map((header, headerIndex) => (
                       <tr key={headerIndex} >
                         <td>{header.title}</td>
                         <td>{per[header.key]}</td>
                       </tr>
                     ))}
                   </tbody>
                 </table>
               </div>
             ))
           ) : (
             <div>
               <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No Results analysis records found.</p>
             </div>
           )}
         </div>
         :
            ""
            }
        </table> 

        {/* Responsibilities */}
         <table className="col-md-12 col-12 partiTable cursorPointer">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletoggleRes}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle9 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Responsibilities</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
            200 Points
          </div>
          </div>
          {toggle9?
          <div>
          {BooksandChapter && BooksandChapter.length > 0 ? (
            BooksandChapter.map((res, index) => (
              <div key={index} className='mt-3 mb-3 ms-3'>
                <span style={{ font:"21px satoshi-bold"}}>Responsibilities  0{index +1}</span>
                <table className='col-md-12 col-12'>
                  <tbody>
                    {responsibilities.map((header, headerIndex) => (
                      <tr key={headerIndex}>
                        <td className='col-md-2'>{header.title}</td>
                        <td>{res[header.key]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))
          ) : (
            <div>
              <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No responsibility records found.</p>
            </div>
          )}
        </div>
        :
            ""
            }
        </table>

{/* Books & Chapters */}
        <table className="col-md-12 col-12 partiTable ">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletoggleBooks}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle10 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Books & Chapters</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
            50 Points
          </div>
          </div>
          {toggle10?
              <div>
              {BooksandChapter && BooksandChapter.length > 0 ? (
                BooksandChapter.map((res, index) => (
                  <div key={index} className='mt-3 mb-3 ms-3'>
                    <span style={{ font:"21px satoshi-bold"}}>Books & Chapters  0{index +1}</span>
                    <table className='col-md-12 col-12'>
                      <tbody>
                        {BooksandChapters.map((header, headerIndex) => (
                          <tr key={headerIndex}>
                            <td className='col-md-2'>{header.title}</td>
                            <td>{res[header.key]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <div>
                  <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No Books & Chapters records found.</p>
                </div>
              )}
            </div>
            :
            ""
            }
        </table>
{/* consultancy */}
{/* Consultancy */}
        <table className="col-md-12 col-12 partiTable">
          <div className='d-flex mt-3 background-color-headers px-3 p-2' onClick={handletoggleConsult}>
          <div className='d-flex col-md-10 col-9 cursorPointer'>
          <span className='pt-1'>
          <img className='cursorPointer' src={toggle11 ? minus : icon} style={{height:"16px", marginTop:"6px" ,marginRight:"11px"}}></img></span>
          <h4 className='headerAppraisal pt-2'>Consultancy</h4></div>
          <div className='col-md-2 col-3 points pt-2'>
            50 Points
          </div>
          </div>
          {toggle11?
           <div>
           {consultanc && consultanc.length > 0 ? (
             consultanc.map((hon, index) => (
               <div key={index} className='mt-3 mb-3 ms-3'>
                   <span style={{ font:"21px satoshi-bold"}}>Consultancy  0{index +1}</span>
                 <table className='col-md-12 col-12'>
                   <tbody>
                     {consultancy.map((header, headerIndex) => (
                       <tr key={headerIndex}>
                         <td>{header.title}</td>
                         <td>{hon[header.key]}</td>
                       </tr>
                     ))}
                   </tbody>
                 </table>
               </div>
             ))
           ) : (
             <div>
               <p className='text-center mt-3' style={{ font:"16px satoshi medium"}}>No consultancy records found.</p>
             </div>
           )}
         </div>
         :
            ""
            }
        </table>
        <div className='d-flex mt-5'>
          <div className='col-md-10 col-xl-11 col-lg-10 col-8'>
          <span className='me-3'>
          <Link to={`/pdf`} className="link"> 
              <img src={print} alt="" />
              </Link>
              {/* <Link to={`/pdf`} className="link" target="_blank" rel="noopener noreferrer"> 
            <img src={print} alt="Print" />
          </Link> */}
            </span>
            <span>
              <img src={download} alt="" />
            </span>
          </div>
          <div className='col-md-2 col-xl-1 col-lg-2 col-4'>
            <button className='btn sendApproval'>Send for approval</button>
          </div>
        </div>
        <div className='mt-5' style={{ font:"13px satoshi medium"}}>
         Instructions: This form aims to evaluate the performance and effectiveness of the faculty member. Please provide specific comments wherever applicable. All responses will be kept confidential. Wherever applicable, you are requested to provide proof for the information provided and get signature from the concerned coordinator/ In-charge / HOD; otherwise, your information will become void.
        </div>
    </div>
    </>
  )
}
export default AppraisalsView;