import React from "react";
import noRecord from "../../src/assets/norecords.png"
import {Link} from "react-router-dom"

type props ={
  name:any,
  path:any
}

export const NoData=({name , path} : props)=>{
return(
    <div className="d-flex flex-column align-items-center" style={{ marginTop: "200px" }}>
    <img src={noRecord} alt="No records" />
     <p className="mt-4 greyfont" style={{ textAlign: 'center' }}>
       You haven't added any {name}s yet.<br />Start by adding a {name}.
    </p>
     <Link to={path}>
       <button className="text-white addnewbtn">Add New</button>
     </Link>
   </div> 
)
}

