import React, { useEffect, FormEvent } from "react";
import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { OnlineCourseCreate } from "../../../../../ApiServices";
import {
  ResponsibilitiesCreate,
  ResponsibilitiesGetTableData,
  facultyCall,
  AcademicYear,
} from "../../../../../ApiServices";
import { GoArrowLeft } from "react-icons/go";
import back from "../../../../../assets/back.png";
import { ToastContainer, toast } from "react-toastify";
import yearList from "../../../../../YearList.json" ;

type Props = {};
const EditNewResponsibilities = (props: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { id } = useParams();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("text");
  const [faculties, setFaculties] = useState([]);
  const [ResformDatas, ResSetformDatas] = useState({
    resId: "",
    academicYear: "",
    responsibilityName: "",
    type: "",
    role: "",
    duration: "",
    inCharge: "",
    uploadFile: "",
    year: "",
    month: "",
  });
  const [fileVal, setFileVal] = useState<string>("");
  const [uploadFiles, setUploadFiles] = useState<string>("");
  const [showModal, setShowModal] = useState(false);



  const handleSave = (e: FormEvent<HTMLButtonElement>) => {
    createResponsibilities(e, "Yet to submit");
  };

  const handleSubmit = (e: FormEvent<HTMLButtonElement>) => {
    createResponsibilities(e, "Pending");
  };

  const createResponsibilities = (event: any, status: any) => {
    event?.preventDefault();
    setIsSubmitted(true);
    if (
      !ResformDatas.academicYear ||
      !ResformDatas.responsibilityName ||
      !ResformDatas.type ||
      !ResformDatas.role ||
      !ResformDatas.inCharge ||
      (!ResformDatas.year &&
        !ResformDatas.month )
    ) {
      console.log("All fields are required");
      return;
    }
    const payload = {
      resId: ResformDatas.resId,
      empId: sessionStorage.getItem("empId"),
      academicYear: ResformDatas.academicYear,
      createdBy: "",
      responsibilityName: ResformDatas.responsibilityName,
      type: ResformDatas.type,
      role: ResformDatas.role,
      grade: "",
      duration: ResformDatas.duration,
      inCharge: ResformDatas.inCharge,
      uploadFile: uploadFiles !== null ? uploadFiles : ResformDatas.uploadFile,
      year: ResformDatas.year !== "" ? ResformDatas.year : 0,
      month: ResformDatas.month !== "" ? ResformDatas.month : 0,
      status: status,
    };
    console.log(payload, "test");
    ResponsibilitiesCreate(payload)
      .then((res) => {
        setIsButtonClicked(true);
        // alert(res.data.status);
        navigate("/faculty/secondsidebar/proficiency/responsibilities");
        setTimeout(() => {
          toast.success(res.data.status);
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(payload);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");
    let updatedValue = value;
    if(name === "year" || name === "month"){
      if(updatedValue !== 0 && updatedValue !== '0'){
      ResSetformDatas({
        ...ResformDatas,
        [e.target.name]: e.target.value,
        [name]: updatedValue,
      });
    }
   }else{
    if (value.length > 0 && name !== "inCharge") {
      var words = value.split(" ");
      for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      updatedValue = words.join(" ");
    }
    ResSetformDatas({
      ...ResformDatas,
      [e.target.name]: e.target.value,
      [name]: updatedValue,
    });
  }
  };

  const departments = [
    "Artificial Intelligence & Data Science Hod",
    "Artificial Intelligence & Machine Learning Hod",
    "Biomedical Engineering Hod",
    "Computer Science and Engineering Hod",
    "Electronics and Communication Engineering Hod",
    "Information Technology Hod",
    "Mechanical Engineering Hod",
    "Robotics and Automation Hod",
    "M.E Computer Science and Engineering Hod",
    "Department of English Hod",
    "Department of Chemistry Hod",
    "Department of Mathematics Hod",
    "Department of Physics Hod"
  ];

  const [academicYears, setAcademicYear] = useState([]);
  interface AcademicYear {
    id: number;
    academicYear: string;
    term: number;
    startDate: string;
    endDate: string;
  }
  const facultyRes = () => {
    facultyCall(sessionStorage.getItem("empId")).then((res) => {
      console.log(res.data, "Gopi");
      setFaculties(res.data);
    });
    AcademicYear().then((res) => {
      console.log(res.data.data);
      const uniqueAcademicYears = res.data.data.filter(
        (item: any, index: any, self: any) =>
          index ===
          self.findIndex((t: any) => t.academicYear === item.academicYear)
      );
      setAcademicYear(uniqueAcademicYears);
    });
  };
  const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
  useEffect(() => {
    facultyRes();
  }, []);

  const getSingleData = () => {
    ResponsibilitiesGetTableData(id)
      .then((res) => {
        ResSetformDatas(res.data.data[0].proficiency);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getSingleData();
    setInputType("text");
  }, [id]);

  const handleBackButtonClick = () => {
    setShowModal(true);
  };

  const handleClick = () => {
    setInputType("file");
  };
  var base64CodePhoto = "";
  const base64ConverterFile = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error(
        "File size exceeds 2 MB limit. Please upload a smaller file."
      );
    } else {
      if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
        toast.error(
          "File format not supported. Please try uploading PDF or JPG files."
        );
        return;
      } else {
        getBase64(file);
        handleChange(e);
        setFileVal(file.name);
      }
    }
  };
  const onLoad = (fileString: any) => {
    base64CodePhoto = fileString;
    setUploadFiles(fileString);
  };
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const onlyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    if (!/^[0-9]$/.test(key)) {
      e.preventDefault();
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/responsibilities"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
      <form>
        {/* <h4 className='mainheader'>Add New Additional Responsibilities</h4>
         <div className="row greytext">
         <span className="col-8">
         Additional Roles and Responsibilities recently.
         </span>
         <div className="col-4 d-flex justify-content-between align-items-center">
         <Link to="/faculty/secondsidebar/proficiency/responsibilities">
         <p className="">
        <GoArrowLeft />
        Back to Additional Responsibilities
      </p>
    </Link>
    <button className="col-3 bg-primary text-white btn" type="submit" onClick={createResponsibilities}>
      Save
    </button>
  </div>
</div> */}
        <div className="row">
          <div className="col-md-12 col-lg-5 col-12">
            <div className="mainheader">Edit role / Responsibility</div>
            {/* <div className="greyfont mt-2">Enter details of new Role / Responsibility undertaken</div> */}
          </div>
          <div className="col-md-12 col-lg-7 col-12">
            <div className="float-end">
              {/* <Link to="/faculty/secondsidebar/proficiency/responsibilities" className="link"> */}
              <span className="bacdept cursorPointer labelinput">
                <span
                  onClick={handleBackButtonClick}
                  data-bs-toggle="modal"
                  data-bs-target="#myModal"
                >
                  <img src={back} className="img-fluid me-2 " />
                  Back to Additional Responsibilities
                </span>

                <button
                  className=" ms-3 saveBtn"
                  style={{ color: "white" }}
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className=" ms-3 addnewbtn"
                  style={{ color: "white" }}
                  onClick={handleSubmit}
                >
                  Save & submit
                </button>
              </span>
              {/* </Link>            */}
            </div>
          </div>
        </div>
        <div
          className="form row"
          style={{
            marginTop: "36px",
            fontSize: "14px",
            fontFamily: "Satoshi medium",
          }}
        >
          <div className="form-group col-md-6 col-lg-2">
            <label className="labelinput">Academic Year</label>
            <select
              className="form-select inputWidth"
              id=""
              name="academicYear"
              value={ResformDatas.academicYear}
              onChange={handleChange}
              required
              style={{
                fontSize: ResformDatas.academicYear ? "14px" : "12px",
                height: "38px",
                fontFamily: "Satoshi medium",
                color: ResformDatas.academicYear ? "black" : "grey",
              }}
            >
              <option value="" disabled className="select">
                --Select--
              </option>
              {yearList.map((item) => (
                <option
                  key={item}
                  value={item}
                  style={{ color: "black", fontSize: "14px" }}
                >
                  {item}
                </option>
              ))}
            </select>
            {isSubmitted && !ResformDatas.academicYear && (
              <div className="is-invalid">*Required</div>
            )}
          </div>
        </div>

        <div
          className="form row pt-2 pt-md-3"
          style={{
            paddingTop: "25px",
            fontSize: "14px",
            fontFamily: "Satoshi medium",
          }}
        >
          <div className="form-group col-md-6 col-lg-4">
            <label className="labelinput">Name of the Responsibility</label>
            <input
              className="form-control inputWidth"
              placeholder="Enter name of the Responsibility"
              name="responsibilityName"
              value={ResformDatas.responsibilityName}
              onChange={handleChange}
              required
            />
            {isSubmitted && !ResformDatas.responsibilityName && (
              <div className="is-invalid">*Required</div>
            )}
          </div>

          <div className="form-group col-md-6 col-lg-4">
            <label className="labelinput">Choose the level</label>
            <div className="d-flex flex-wrap mt-2">
              <div className="form-check d-flex align-items-center me-4 labelinput">
                <input
                  type="radio"
                  className="form-check-input me-2 mb-1"
                  name="type"
                  value="Institute"
                  checked={ResformDatas.type === "Institute"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="Institute">
                  Institute
                </label>
              </div>
              <div className="form-check d-flex align-items-center me-4 labelinput">
                <input
                  type="radio"
                  className="form-check-input me-2 mb-1"
                  name="type"
                  value="Department"
                  checked={ResformDatas.type === "Department"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="Department">
                  Department
                </label>
              </div>
            </div>
            {isSubmitted && !ResformDatas.type && (
              <div className="is-invalid">*Required</div>
            )}
          </div>
        </div>

        <div
          className="form row pt-2 pt-md-3"
          style={{
            paddingTop: "25px",
            fontSize: "14px",
            fontFamily: "Satoshi medium",
          }}
        >
          <div className="form-group col-md-6 col-lg-4">
          <label className="labelinput">Role (e.g. Academic Advisor)</label>
            <input
              className="form-control inputWidth"
              placeholder="Enter Role"
              name="role"
              value={ResformDatas.role}
              onChange={handleChange}
              required
            />
            {isSubmitted && !ResformDatas.role && (
              <div className="is-invalid">*Required</div>
            )}
          </div>

          <div className="form-group col-md-6 col-lg-2">
            <label className="labelinput">Duration</label>
            {/* <input  className="form-control" placeholder="Enter Duration" name="duration" value={ResformDatas.duration} onChange={handleChange} required/>
      {isSubmitted && !ResformDatas.duration && (
                <div className="is-invalid">*Required</div>
              )} */}
            <div className="d-flex col-md-4 col-3 ">
              <input
                type=""
                className="col-lg-12 col-xl-7 col-md-8 col-sm-10 col-4 me-2 inputWidth"
                style={{
                  fontSize: "16px",
                  fontFamily: "Satoshi medium",
                  borderRadius: "6px",
                  border: "1.5px solid #E8EBF0",
                  padding: "6px",
                }}
                value={ResformDatas.year}
                onChange={handleChange}
                maxLength={2}
                onKeyPress={onlyNumber}
                name="year"
              />{" "}
              <span className="mt-2 me-2">Yrs</span>
              <input
                type=""
                className="col-lg-12 col-xl-7 col-md-8 col-sm-10 col-4 me-2 inputWidth"
                style={{
                  fontSize: "16px",
                  fontFamily: "Satoshi medium",
                  borderRadius: "6px",
                  border: "1.5px solid #E8EBF0",
                  padding: "6px",
                }}
                value={ResformDatas.month}
                onChange={handleChange}
                maxLength={2}
                onKeyPress={onlyNumber}
                name="month"
              />
              <span className="mt-2 me-2">Mo</span>
            </div>
            {isSubmitted &&
                !ResformDatas.year &&
                !ResformDatas.month && (
                  <div className="is-invalid">*Required</div>
                )}
          </div>

          <div className="form-group col-md-6 col-lg-2">
            <label className="labelinput">In-charge / HOD</label>
            <select
              className="form-select inputWidth"
              id=""
              name="inCharge"
              value={ResformDatas.inCharge}
              onChange={handleChange}
              required
              style={{
                fontSize: ResformDatas.inCharge ? "14px" : "12px",
                height: "38px",
                fontFamily: "Satoshi medium",
                color: ResformDatas.inCharge ? "black" : "grey",
              }}
            >
              <option value="" disabled>
                --Select--
              </option>
              <option value="Chair Person">Chair Person</option>
              <option value="Director">Director</option>
              <option value="Dean (Academics)">Dean (Academics)</option>
              <option value="Principal">Principal</option>
              {departments.map((item:any , index:any)=>(
                <option
                value={item}
                style={{ color: "black", fontSize: "14px" }}
              >
                {item}
              </option>
              ))}
              {/* {faculties.map((item, index) => {
                console.log(faculties, "am faculty edit ");
                return (
                  <>
                    <option
                      value={item}
                      style={{ color: "black", fontSize: "14px" }}
                    >
                      {item}
                    </option>
                  </>
                );
              })} */}
            </select>
            {/* <input
     type='text' 
     className="form-control"
     id="" 
    name="inChargeHOD" 
    value={ResformDatas.inChargeHOD} 
    onChange={handleChange} required
    style={{ fontSize: '14px', fontFamily: 'Satoshi medium' }}
    placeholder="Incharge / Hod"
    >
   
    </input> */}

            {isSubmitted && !ResformDatas.inCharge && (
              <div className="is-invalid">*Required</div>
            )}
          </div>
        </div>

        <div
          className="form row pt-2 pt-md-3"
          style={{
            paddingTop: "25px",
            fontSize: "14px",
            fontFamily: "Satoshi medium",
          }}
        >
          <div className=" col-md-6 col-lg-4">
            <label className="labelinput mt-2 d-flex justify-content-between">
              <span className="" style={{ color: "#1B1922" }}>
                
              Certificate, if any <span style={{ color: "#7784A1" }}>(optional)</span>
              </span>{" "}
              <span className="" style={{ color: "#7784A1" }}>
                Only pdf/jpg file under 2MB
              </span>
            </label>
            {/* <input
                // type="file"
                type={inputType}
                className="form-control mt-2 labelinput"
                value={inputType === "text" ? ResformDatas.uploadFile : undefined} 
                onChange={e => {base64ConverterFile(e)}}
                name="uploadFile"
                onClick={handleClick}
              />     */}
            <div className="input-group">
              <label className="form-control boxText boxtextellipse browsetext labelinput">
                {fileVal !== "" ? fileVal : ResformDatas.uploadFile}
                <input
                  type="file"
                  id="fileInput2"
                  className="invisible"
                  onChange={(e) => base64ConverterFile(e)}
                />
              </label>
              <span
                className="input-group-text lname cursorPointer labelinput"
                onClick={() => document.getElementById("fileInput2")?.click()}
              >
                Select File
              </span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default EditNewResponsibilities;
