import React, { useEffect, useState } from "react";
import './Honours.css'
import DynamicTables from "../../../../components/DynamicTable/DynamicTables";
import { Link ,useNavigate } from "react-router-dom";
import { honourGetTable ,honourGetTableData ,DeleteTabledata,fileDownload , TableSubmitCall,host } from '../../../../ApiServices';
import CourseTable from "../../../../components/CourseDetailTable/CourseTable";
import { MdCancel } from "react-icons/md";
import searchIcon from "../../../../assets/Icon search.svg"
import { ToastContainer, toast } from "react-toastify";
import { format as formatDate } from "date-fns";
import { format, parseISO } from 'date-fns';
import close from "../../../../assets/close.svg";
import noRecord from "../../../../assets/norecords.png"
import { SkeletonLoader } from "../../../skeletonLoader";
import { NoData } from "../../../NoData";
function Honours() {
  const navigate = useNavigate();
  const columnRedirectsOnlineCourse = {
    courseTitle:"/proficiency/responsibilities/newResponsiblities/new-add",
  };

  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage,setCurrentPage] = useState(1)
  const [TotalElements , setTotalElements] = useState(0)
  const [totalpages,settotalpages] = useState(0);
  const [sort , setSort] = useState(false);
  const [sortusing,setSortUsing]= useState("honId")
  const [tableDatas, settableDatas] = useState([]);
  const [selectedItem, setselectedItem] = useState<any>([])
  const [viewSingle, setViewSingle] = useState(false)
  const [viewType, setViewType] = useState<string>('');
  const [viewTitle,setViewtitle] = useState()
  const [pubIdView,setPubIdView] = useState();
  const [createdOn , setCreatedOn] = useState<string>('');
  const [updatedOn , setUpdatedOn] = useState<string>('');
  const [period,setperiod]  = useState<string>('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [getData, setGetData] = useState();
  const [loader , setLoader] = useState(true)
    
  const toggleSearch = () => {
setIsSearching(!isSearching);
setSearchTerm("");
setIsSearchOpen(!isSearchOpen);
if (!isSearchOpen) {
  setSearchTerm('');
}
};
const handleCancel = () => {
setSearchTerm('');
setIsSearchOpen(false);
};


    const handleNextPage=()=>{
      setCurrentPage((prevPage)=>Math.min(prevPage+1,totalpages))
      console.log("next button clicked and the value now is "+ currentPage)
      }
    
     const handlePrevPage=()=>{
     setCurrentPage((prevPage)=>Math.max(prevPage-1,1))
     }
    
     const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
       setCurrentPage(value);
     };

     const handleSort = () => {
      setSortUsing("academicYear");
      setSort(!sort);
    };

    const sortOrder = sort? "asc":"desc"

    const getDatas = () => {
      
    const data = {
      empID:sessionStorage.getItem('empId'),
      page:currentPage-1,
      sort:sortOrder,
      sordId:sortusing,
      keyword:searchTerm,
    }

        honourGetTable(data)
     .then((res) => {
      const formattedData = res.data.datas.content.map((item: any) => {
        let formattedCreatedDate = "";
        let formattedUpdatedDate = "";
        let formattedSubmittedOn = "";
        let formattedValidatedOn = ""
      
        if (item.createdOn) {
          const createdOnDate = new Date(item.createdOn);
          formattedCreatedDate = formatDate(createdOnDate, "dd MMM yyyy hh:mm a");
        }
      
        if (item.updatedOn) {
          const updatedOnDate = new Date(item.updatedOn);
          formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
        }

        if(item.submittedOn){
          const submittedOnDate = new Date(item.submittedOn)
          formattedSubmittedOn = formatDate(submittedOnDate, "dd MMM yyyy hh:mm a")
        }


        if(item.validatedOn){
          const validatedOnDate = new Date(item.validatedOn)
          formattedValidatedOn = formatDate(validatedOnDate, "dd MMM yyyy hh:mm a")
        }

      
        return {
          ...item,
          createdOn: formattedCreatedDate,
          updatedOn: formattedUpdatedDate,
          submittedOn:formattedSubmittedOn,
          validatedOn:formattedValidatedOn
        };
      });

       settableDatas(formattedData);
       setLoader(false)
       setGetData(res.data.data[0]);
       console.log(res.data.datas.content, "");
       setTotalElements(res.data.datas.totalElements)
       settotalpages(res.data.datas.totalPages)
     })
     .catch((err) => {
       console.log(err);
     });
 };
 const Header = [
  { label: "Academic Year", key: "academicYear" },
  { label: "Type", key: "type" },
  { label: "Title", key: "awardReceived" },
  { label: "Submitted on", key: "submittedOn" },
  { label: "Status", key: "status" },
  { label: "Validated on", key: "validatedOn" },
];

 const HonoursViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  {
    "title": "Type",
    "key":"type"
  },
]
if (viewType === "Awards") {
  HonoursViewHeaders.push(
    {
      "title": "Award Title",
      "key": "awardTitle"
    },
    {
      "title": "Award Presented by",
      "key": "presentedBy"
    },
    {
      "title": "Proof Document",
      "key": "uploadFile"
    }
  );
} else if(viewType === "Fellowships"){
  HonoursViewHeaders.push(
    {
      "title": "Fellowship Title",
      "key": "fellowTitle"
    },
    {
      "title": "Fellowship Awarded by",
      "key": "fellowPresentedBy"
    },
    {
      "title": "Proof Document",
      "key": "uploadFile"
    }
  );
}else if(viewType === "Honours"){
  HonoursViewHeaders.push(
    {
      "title": "Honours Title",
      "key": "honourTitle"
    },
    {
      "title": "Honours Presented by",
      "key": "honourPresentedBy"
    },
    {
      "title": "Proof Document",
      "key": "uploadFile"
    }
  );
}else if(viewType === "Memberships"){
  HonoursViewHeaders.push(
    {
      "title": "Organisation Name",
      "key": "organisationName"
    },
    {
      "title": "Role",
      "key": "organisationRole"
    },
    {
      "title": "Current Status",
      "key": "currentStatus"
    },
    {
     "title":"Tenure",
      "key" :"period"
    },
    {
      "title": "Proof Document",
      "key": "uploadFile"
    },
  );
}
console.log(period,"period");

function navigateView(item: any){
  honourGetTableData(item.honId).then((res:any)=>{
    setselectedItem(res.data.data[0]);
    setViewType(res.data.data[0].proficiency.type)
    setViewtitle(res.data.data[0].proficiency.awardReceived);
    setPubIdView(res.data.data[0].proficiency.honId)
    if( res.data.data[0].proficiency.memberSince !== null){
   setperiod(format(parseISO(res.data.data[0].proficiency.memberSince), 'dd MMM yyyy') + " to " + format(parseISO(res.data.data[0].proficiency.memberTill), 'dd MMM yyyy'))
    }
    const formattedCreatedDate = new Date(res.data.data[0].proficiency.createdOn);
    const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");
    let updatedOn = "";
    if (res.data.data[0].proficiency.updatedOn !== null) {
        const formattedUpdatedDate = new Date(res.data.data[0].proficiency.updatedOn);
        updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
    }         
    setCreatedOn(createdOn);
    setUpdatedOn(updatedOn);
    setViewSingle(true)
  }).catch((err:any)=>console.log(err))
}

function editCourse(item:any){
  console.log(item,"ssbiki")
  const data = item.honId?item.honId:item.proficiency.honId;
  console.log(data);
  navigate("editHonours/"+data)
}

const deleteApi=(data: any) =>{
  const url = "/honours/awards?honId=" + data.honId;
  DeleteTabledata(url)
    .then((res) => {
      console.log(res.data.status);
      toast.success(res.data.status);
      getDatas()
    })
    .catch((err) => {
      console.log(err)
    });
}
const deleteApiView=() =>{
  const url = "/honours/awards?honId=" + pubIdView;
  DeleteTabledata(url)
    .then((res) => {
      console.log(res.data.status);   
      setViewSingle(false)     
      toast.success(res.data.status);
      // alert(res.data.status);
      getDatas()
    })
    .catch((err) => {
      console.log(err)
    });
}
const FileDownload = () => {
  const url = host+"proficiency/get/honours/awards?honId=" + pubIdView + "&keyword=file";
  fileDownload(url)
    .then((res) => {             
      toast.success(res.data.status);
      window.open(url, '_blank');
      getDatas();
    })
    .catch((err) => {
      console.log(err);
    });
}

 const backtoview = "to Honours & Awards"
 let deleteCont;
 switch (viewType) {
   case "Awards":
     deleteCont = "award";
     break;
   case "Honours":
     deleteCont = "honour";
     break;
   case "Memberships":
     deleteCont = "membership";
     break;
   case "Fellowships":
     deleteCont = "fellowship";
     break;
   default:
     deleteCont = "";
     break;
 } 
 const heading = viewTitle
 let subheading = "View details of the Honours & Awards received";  
//  switch (viewType) {
//   case "Awards":
//     subheading = "View details of Award received by Faculty";
//     break;
//   case "Honours":
//     subheading = "View details of Honour received by Faculty";
//     break;
//   case "Memberships":
//     subheading = "View details of Membership";
//     break;
//   case "Fellowships":
//     subheading = "View details of Fellowship received by Faculty";
//     break;
//   default:
//     subheading = "";
//     break;
// } 

const TableSubmit =(item:any)=>{
  console.log("tablesumbit toggled ")
  const payload = {
    empId:sessionStorage.getItem('empId'),
    sectionId:item.honId,
    status :"Pending",
    section:"Honours & Awards",
   academicYear:item.academicYear
}
console.log(payload,"hkjgkjgh");

TableSubmitCall(payload)
.then((res)=>{
  console.log(res)
  getDatas();
  toast.success(res.data.responseMessage)
})
.catch((err)=>{
  console.log(err)
})
console.log(payload , "am the payload")
// window.location.reload()
setViewSingle(false)
}

useEffect(() => {
  getDatas();
}, [currentPage,sort,searchTerm]);

 const moduleName = "Honours & award"
  const AddNewPath = "/faculty/secondsidebar/proficiency/honoursawards/honoursawardscreate"

  return (
    <>
    <ToastContainer/>

    {!viewSingle?
    <>
     <div className="">
            {/* <div className='position-relative  d-flex justify-content-between align-items-center'>
            <p className="mt-3">Honours & Awards<br></br>List of Honours, Awards, Fellowship, Membership Received in this Academic Year.</p>
            <Link to="/faculty/secondsidebar/proficiency/honoursawardscreate" className="btn btn-primary CreateBtn">Add New</Link>
            </div> */}
             <div className="row">
             <div className="col-md-12 col-lg-7 col-12">
            <div className="mainheader">Honours & Awards</div>
            <div className="greyfont mt-2">List of Honours, Awards, Fellowship, Membership</div>
            </div>
            <div className="col-md-12 col-lg-5 col-12 AddNewBtnDiv">
            {getData === true && (
            <div className="float-end ">
            <Link to="/faculty/secondsidebar/proficiency/honoursawards/honoursawardscreate" className="link">
                <span className='bacdept cursorPointer'>
                    <button  className=" ms-3 addnewbtn"style={{ color: 'white' }}>Add New</button>
                    </span>
                    </Link>           
    </div>        
            )}
            </div>
            </div>
            <div className="">
                {/* <ul className="nav mt-4">
                    <li className="nav-item">
                        <span className="active tablink pb-1 bg addresult">
                            All Results
                        </span>

                    </li>

                </ul> */}
            {getData  && (
                 <div className="addresult mt-4">        
        <span className="active tablink pb-1 bg addresult mb-3">All Results</span>
        <div className={`${isSearchOpen ? 'me-0' : 'me-5'} search`} style={{boxShadow:'none'}}>
  <img
    alt="search"
    className={`${isSearchOpen ? 'ms-0' : 'ms-3'} fasearch mb-2`}
    src={searchIcon}
    onClick={toggleSearch}
    style={{cursor:'pointer'}}
  />
  {isSearchOpen && (
    <div className="search-input-container">
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
      />
      <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }}/>
    </div>
  )}
</div>
      </div>
            )}

                  {loader ?
                  <SkeletonLoader/> :
                  getData? 
                  <DynamicTables data={tableDatas} headers={Header}  currentPage={currentPage} pages={totalpages} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} navigateView={navigateView} editCourse={editCourse}  deleteApi={deleteApi} TableSubmit={TableSubmit} />
                  :
                  <NoData  name={moduleName} path={AddNewPath} />
                  }
        </div>
        </div>
    </>
    
    :
    <CourseTable deleteCont={deleteCont} data={[selectedItem]} backtoview={backtoview} back={()=>{setViewSingle(!viewSingle)}} headers={HonoursViewHeaders}
    heading = {heading}
    subheading = {subheading}
    deleteApiView = {deleteApiView}
    FileDownload = {FileDownload}
    createdOn = {createdOn}
    updatedOn = {updatedOn}
    period = {period}
    TableSubmit={TableSubmit}
    editCourse={editCourse}
    />
  }  
    </>
  )
}

export default Honours;