import React from "react";
import kingsIcon from "../../assets/NavbarAssets/KINGS-NEW-LOGO.png";
import kingsIcon2 from "../../assets/NavbarAssets/KINGS-NEW-LOGO@2x.png";
import logout from "../../assets/NavbarAssets/default avatar/default avatar.png";
import logout2 from "../../assets/NavbarAssets/default avatar/default avatar@2x.png";
import profile from "../../assets/profile/Icon awesome-user-edit.png";
import lock from "../../assets/profile/Icon awesome-lock.png";
import logoutpk from "../../assets/profile/logout.png";
import "./Header.css";
import { Link } from "react-router-dom";
import keycloak from "../../keycloak";
import Keycloak from "keycloak-js";
import { useKeycloak } from "@react-keycloak/web";
// type Props = {
//   pageurl?: string;
// };
type Props = {
  keycloak: Keycloak;
};

const Header = () => {
  const keycloak_init = useKeycloak();
  return (
    <>
      <div className="packagewidthheader sticky-top">
        <div className="d-flex ">
          <div className="col-md-10 col-lg-11 text-center my-2 IMGWidth ps-3 ps-md-0">
            <img
              src={kingsIcon2}
              alt=""
              className=""
              style={{ width: "141px", height: "60px" }}
            />
          </div>
          {/* <div className="col my-auto me-3 me-md-5">
            <div className="float-end cursorPointer">
              <div className="">
                <img
                  src={logout2}
                  alt="logout"
                  className="logoutImg"
                  // type="button"
                  id="dropdownBasic1"
                />
              </div>
            </div>
          </div> */}
          <div className="col my-auto me-3 me-md-5">
            <div className="float-end cursorPointer">
              <div className="dropdown">
                <img
                  src={logout2}
                  alt="logout"
                  className="logoutImg log"
                  id="dropdownBasic1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />

                <ul
                  className="dropdown-menu mt-2"
                  aria-labelledby="dropdownBasic1"
                >
                  <li className="d-flex align-items-center ">
                  <p className="dropdown-item mb-0" style={{fontSize:13,fontFamily:'Satoshi Medium'}}>Hello {sessionStorage.getItem('name')?sessionStorage.getItem('name'):""}!</p>
                    {/* <p className="align-items-center">Johndoe@kecedu.ac.in</p> */}
                  </li>
                  <hr className="my-2"></hr>
                  <li className="d-flex align-items-center">
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        sessionStorage.clear();
                        localStorage.clear();
                        keycloak_init.keycloak.logout();
                      }}
                    >
                      <img src={logoutpk} />
                      <Link
                        to="/"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <span className="ms-1" style={{fontSize:13,fontFamily:'Satoshi Medium'}}>Log Out</span>
                      </Link>
                    </a>
                  </li>
                  {/* <li className="d-flex align-items-center">
                    <a className="dropdown-item" href="#">
                      <img src={profile} />
                      <span className="ms-1" style={{fontSize:13,fontFamily:'Satoshi Medium'}}>My profile</span>
                    </a>
                  </li> */}
                  {/* <li className="d-flex align-items-center">
                    <a className="dropdown-item">
                      <img src={lock} />
                      <Link
                        to="/faculty/update"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <span className="ms-1" style={{fontSize:13,fontFamily:'Satoshi Medium'}}>Update Password</span>
                      </Link>
                    </a>
                  </li> */}
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
      </div>
    </>
  );
};

export default Header;
