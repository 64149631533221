import React from 'react'

type Props = {}

const PrevyearsQA = (props: Props) => {
  return (
    <div>PrevyearsQA</div>
  )
}

export default PrevyearsQA