import * as React from 'react';
import { Outlet } from 'react-router-dom';

export interface IAppProps {
}

export function HonoursEmp (props: IAppProps) {
  return (
    <div>
      <Outlet/>
    </div>
  );
}
