import React from 'react'
import { Outlet } from 'react-router-dom'

type Props = {}

const ResponsibilitiesEmp = (props: Props) => {
  return (
    <>
    <Outlet/>
    </>
  )
}
export default ResponsibilitiesEmp