import React from 'react'

function Account() {
  return (
    <div>
      account
    </div>
  )
}

export default Account
