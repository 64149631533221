import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Link, Outlet, useNavigate ,useParams } from "react-router-dom";
import "../../OnlineCourse/AddNew/AddNew.css";
import { GoArrowLeft } from "react-icons/go";
import { SlArrowDown } from "react-icons/sl";
import { PerformanceCreate ,PerformanceGetTableData} from "../../../../../ApiServices";
import back from "../../../../../assets/back.png"
import {toast} from "react-toastify"

type Props = {}

const EditPerformance = (props: Props) => {
    const { id } = useParams();
    const navigate =useNavigate();
    const [formDatas, setformDatas] = useState({
    perfId:"",
    academicYear: "",
    year:"",
    semester: "",
    examType: "",
    department: "",
    subHandled: "",
    totalStudents: "",
    studentsAppeared: "",
    studentsPassed:"",
    passedPercentage:""
    })
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [percentage, setPercentage] = useState<string>('');
    const EditPerformance = (e: FormEvent<HTMLButtonElement>) =>{
      e.preventDefault();
      setIsSubmitted(true);
      if (
        !formDatas.academicYear ||
        !formDatas.year ||
        !formDatas.semester ||
        !formDatas.examType ||
        !formDatas.department ||
        !formDatas.subHandled ||
        !formDatas.totalStudents ||
        !formDatas.studentsAppeared ||
        !formDatas.studentsPassed ||
        !formDatas.passedPercentage
        
      ) {
        console.log("All fields are required");
        return;
      }
  
      const payload = {
      perfId:formDatas.perfId,
      empId:sessionStorage.getItem('empId'),
      academicYear:formDatas.academicYear,
      createdBy: "Jebarson Samuel",
      year:formDatas.year,
      semester:formDatas.semester,
      examType:formDatas.examType,
      department:formDatas.department,
      subHandled:formDatas.subHandled,
      totalStudents: formDatas.totalStudents,
      studentsAppeared:formDatas.studentsAppeared ,
      studentsPassed:formDatas.studentsPassed,
      passedPercentage:formDatas.passedPercentage,
      fileUpload:""
      };
      console.log(payload,"test");
      PerformanceCreate(payload)
        .then((res) => {
          setIsButtonClicked(true)
         navigate("/faculty/secondsidebar/proficiency/performance")
         setTimeout(()=>{
          toast.success(res.data.status);
        } , 200) 
        })
        .catch((err) => {
          console.log(err);
        });
        console.log(payload)
    };
  
    const handleChange = (e: any) => {
      const { name, value } = e.target;
      const trimmedValue = value.replace(/^\s+/,"");
    
      if (name === 'year') {
        handleEmptyValue();
      }
    
      if (name === 'studentsPassed' || name === 'studentsAppeared') {
        let passedPercentage = '';
        const studentsPassed = name === 'studentsPassed' ? trimmedValue : formDatas.studentsPassed;
        const studentsAppeared = name === 'studentsAppeared' ? trimmedValue : formDatas.studentsAppeared;
    
        if (studentsPassed && studentsAppeared) {
          const percentage = (parseInt(studentsPassed) / parseInt(studentsAppeared)) * 100;
          passedPercentage = Math.round(percentage) + '%';
        }
    
        setformDatas(prevState => ({
          ...prevState,
          [name]: trimmedValue,
          passedPercentage: passedPercentage
        }));
    
        if (name === "studentsAppeared" && parseInt(value) > parseInt(formDatas.totalStudents)) {
          setformDatas({ ...formDatas, studentsAppeared: '', studentsPassed: '' ,passedPercentage:''});
        } else if(name === "studentsPassed" && parseInt(value) > parseInt(formDatas.studentsAppeared)){
          setformDatas({ ...formDatas, studentsPassed: '' ,passedPercentage:''});
        }
      } else {
        let updatedValue = value;
        if (value.length > 0) {
          var words = value.split(' ');
          for (var i = 0; i < words.length; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
          }
          updatedValue = words.join(' ');
      }
        setformDatas({
            ...formDatas,
            [e.target.name]: e.target.value,
            [name]: updatedValue 
        })
      }
    }
    const getSingleData = () => {
      PerformanceGetTableData(id)
        .then((res) => {
          setformDatas(res.data.data[0]);
        })
        .catch((err) => console.log(err));
    };
    
    useEffect(() => {
      getSingleData();
    }, [id]);
  
    const handleBackButtonClick = () => {
      setShowModal(true);
    };
    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const allowedCharacters = /[0-9]/;
      if (!allowedCharacters.test(e.key) && e.key !== 'Backspace') {
          e.preventDefault();
      }
  };
  const handleEmptyValue = () => {
    setformDatas({ ...formDatas, semester: ''});
    setIsSubmitted(false)
  }
  
  return (
    <form>
      <div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition">
          <div className="modal-content">
          <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header">
              <h5 className="modal-title"> Unsaved Changes </h5>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
            </div>
            <div className="modal-body mx-5 fontModel">
            Are you sure you want to discard the changes? Your changes will be lost.
            </div>
            <div className="modal-footer mb-3">
              <button
                type="button"
                className="btn cancelBtn"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/performance"); 
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    {/* <h4 className="mainheader">Add New Performance</h4>
      <div className="row greytext">
      <span className="col-8">
      New Performance or Result Analysis recently.
      </span>
      <div className="col-4 d-flex justify-content-between align-items-center">
      <Link to="/faculty/secondsidebar/proficiency/performance" style={{textDecoration:"none" , color:"black" }}>
      <p className="backtext">
     <GoArrowLeft />
     Back to Performance
   </p>
 </Link>
 <button className="col-3 bg-primary text-white btn" type="submit" onClick={createPerformance}>
   Save
 </button>
</div>
</div> */}
   <div className="row">
    <div className="col-md-12 col-lg-5 col-12">
    <div className="mainheader">Edit details of Result analysis</div>
    </div>
    <div className="col-md-12 col-lg-7 col-12">
    <div className="float-end">
    <span className='bacdept cursorPointer labelinput'
       
       >
         <span  onClick={handleBackButtonClick}
       data-bs-toggle="modal"
       data-bs-target="#myModal">
          <img src={back} className="img-fluid me-2 " />
           Back to Result Analysis
       </span>
          
           <button  className=" ms-3 addnewbtn"style={{ color: 'white' }} onClick={EditPerformance} disabled={isButtonClicked}
>Save</button>
           </span>          
    </div>        
    </div>
</div>

<div className="form row"style={{ marginTop: "36px" ,fontSize: '14px', fontFamily: 'Satoshi medium'}}>
 <div className="form-group col-md-6 col-lg-2">
   <label className="labelinput">Academic Year</label>
   <select className="form-select" id=""  name="academicYear" value={formDatas.academicYear} onChange={handleChange} required>
   <option value="" disabled className="select">--Select--</option> 
   <option value="2023-2024">2023-2024</option>
                <option value="2024-2025">2024-2025</option>
 </select>
 {isSubmitted && !formDatas.academicYear && (
                <div className="is-invalid">*Required</div>
              )}
 </div>
 <div className="form-group col-md-6 col-lg-2">
  <label htmlFor="year-select" className="labelinput">
    Year
  </label>
  <select
    className="form-select"
    id="year-select"
    name="year"
    value={formDatas.year}
    onChange={e => {handleChange(e)}}
    required
    // style={{
    //   fontSize: formDatas.year ? '14px' : '12px',
    //   height: "38px",
    //   fontFamily: 'Satoshi medium',
    //   color: formDatas.year ? 'black' : 'grey', 
    // }}
  >
    <option value="" disabled className="select">
      --Select--
    </option>
    <option value="First Year" style={{ color: "black", fontSize: "14px" }}>
      First Year
    </option>
    <option value="Second Year" style={{ color: "black", fontSize: "14px" }}>
      Second Year
    </option>
    <option value="Third Year" style={{ color: "black", fontSize: "14px" }}>
      Third Year
    </option>
    <option value="Final Year" style={{ color: "black", fontSize: "14px" }}>
      Final Year
    </option>
  </select>
  {isSubmitted && !formDatas.year && (
    <div className="is-invalid">*Required</div>
  )}
</div>

<div className="form-group col-md-6 col-lg-2">
  <label className="labelinput">Semester</label>
  {formDatas.year === "" && (
    <select
      className="form-select"
      name="semester"
      onChange={handleChange}
      required
      style={{
        fontSize: formDatas.semester ? '14px' : '12px',
        height: "38px",
        fontFamily: 'Satoshi medium',
        color: formDatas.semester ? 'black' : 'grey', 
      }}
    >
      <option value="" selected disabled hidden>
        --Select--
      </option>
    </select>
  )}
  {formDatas.year === "First Year" && (
    <select
      className="form-select"
      name="semester"
      onChange={handleChange}
      required
      value={formDatas.semester}
      style={{
        fontSize: formDatas.year ? '14px' : '12px',
        height: "38px",
        fontFamily: 'Satoshi medium',
        color: formDatas.year ? 'black' : 'grey', 
      }}
    >
      <option value="" disabled>
        --Select--
      </option>
      <option value="1">1</option>
      <option value="2">2</option>
    </select>
  )}
  {formDatas.year === "Second Year" && (
    <select
      className="form-select"
      name="semester"
      onChange={handleChange}
      value={formDatas.semester}
      required
      style={{
        fontSize: formDatas.year ? '14px' : '12px',
        height: "38px",
        fontFamily: 'Satoshi medium',
        color: formDatas.year ? 'black' : 'grey', 
      }}
    >
      <option value="" disabled>
        --Select--
      </option>
      <option value="3">3</option>
      <option value="4">4</option>
    </select>
  )}
  {formDatas.year === "Third Year" && (
    <select
      className="form-select"
      name="semester"
      onChange={handleChange}
      required
      value={formDatas.semester}
      style={{
        fontSize: formDatas.year ? '14px' : '12px',
        height: "38px",
        fontFamily: 'Satoshi medium',
        color: formDatas.year ? 'black' : 'grey', 
      }}
    >
      <option value="" disabled>
        --Select--
      </option>
      <option value="5">5</option>
      <option value="6">6</option>
    </select>
  )}
  {formDatas.year === "Final Year" && (
    <select
      className="form-select"
      name="semester"
      onChange={handleChange}
      value={formDatas.semester}
      required
      style={{
        fontSize: formDatas.year ? '14px' : '12px',
        height: "38px",
        fontFamily: 'Satoshi medium',
        color: formDatas.year ? 'black' : 'grey', 
      }}
    >
      <option value="" disabled>
        --Select--
      </option>
      <option value="7">7</option>
      <option value="8">8</option>
    </select>
  )}
  {isSubmitted && !formDatas.semester && (
    <div className="is-invalid">*Required</div>
  )}
</div>
  <div className="form-group col-md-6 col-lg-2">
   <label className="labelinput">Exam Type</label>
   <select className="form-select" id=""  name="examType" value={formDatas.examType} onChange={handleChange} required>
   <option value="" disabled className="select">--Select--</option>
   <option value="Theory" style={{color:"black" ,fontSize:"14px"}}>Theory</option>
    <option value="Practical" style={{color:"black" ,fontSize:"14px"}}>Practical</option>
 </select>
 {isSubmitted && !formDatas.examType && (
                <div className="is-invalid">*Required</div>
              )}
 </div>
</div>

<div className="form row" style={{ paddingTop: "25px" ,fontSize: '14px', fontFamily: 'Satoshi medium'}}>
 <div className="form-group col-md-6 col-lg-4">
   <label className="labelinput">Department</label>
   <select className="form-select" id="" name ="department" value={formDatas.department} onChange={handleChange} required>
   <option selected>--Select--</option>
          <option value="Robotics and Automation" style={{color:"black" ,fontSize:"14px"}}> Robotics and Automation</option>
          <option value="Mechanical Engineering" style={{color:"black" ,fontSize:"14px"}}> Mechanical Engineering</option>
          <option value="Information Technology" style={{color:"black" ,fontSize:"14px"}}> Information Technology</option>
          <option value="Electronics and Communication Engineering" style={{color:"black" ,fontSize:"14px"}}> Electronics and Communication Engineering</option>
          <option value="Computer Science and Engineering" style={{color:"black" ,fontSize:"14px"}}> Computer Science and Engineering</option>
          <option value="Biomedical Engineering" style={{color:"black" ,fontSize:"14px"}}> Biomedical Engineering</option>
          <option value="Artificial Intelligence & Machine Learning" style={{color:"black" ,fontSize:"14px"}}> Artificial Intelligence & Machine Learning</option>
          <option value="Artificial Intelligence & Data Science" style={{color:"black" ,fontSize:"14px"}}> Artificial Intelligence & Data Science</option>
          {/* <option value="Physics" style={{color:"black" ,fontSize:"14px"}}> Physics</option>
          <option value="Chemistry" style={{color:"black" ,fontSize:"14px"}}> Chemistry </option>
          <option value="Mathematics" style={{color:"black" ,fontSize:"14px"}}> Mathematics </option>
          <option value="English"style={{color:"black" ,fontSize:"14px"}} > English </option> */}
 </select>
 {isSubmitted && !formDatas.department && (
                <div className="is-invalid">*Required</div>
              )}
 </div>
 <div className="form-group col-md-6 col-lg-4">
   <label className="labelinput">Subject Handled</label>
   <input className="form-control"  name ="subHandled" value={formDatas.subHandled} onChange={handleChange} required/>
   {/* <option>--Select--</option>
   <option>1</option>
   <option>2</option>
   <option>3</option>
   <option>4</option>
   <option>5</option>
 </select> */}
 {isSubmitted && !formDatas.subHandled && (
                <div className="is-invalid">*Required</div>
              )}
 </div>
</div>
<div className="form row" style={{ paddingTop: "25px",fontSize: '14px', fontFamily: 'Satoshi medium' }}>
<div className="form-group col-md-6 col-lg-2">
   <label className="labelinput">Total No. of Students</label>
   <input className="form-control" type="text" placeholder="Enter Value" name="totalStudents" value={formDatas.totalStudents} maxLength={3} onChange={handleChange} required onKeyDown={handleInputKeyDown}/>
   {isSubmitted && !formDatas.totalStudents && (
                <div className="is-invalid">*Required</div>
              )}
 </div>
 <div className="form-group col-md-6 col-lg-2">
   <label className="labelinput">No. of Students Appeared</label>
   <input className="form-control" type="text" placeholder="Enter Value" name="studentsAppeared" value={formDatas.studentsAppeared} maxLength={3} onChange={handleChange} required onKeyDown={handleInputKeyDown}/>
   {isSubmitted && !formDatas.studentsAppeared && (
                <div className="is-invalid">*Required</div>
              )}
 </div>
 <div className="form-group col-md-6 col-lg-2">
   <label className="labelinput">No. of Students Passed</label>
   <input className="form-control" type="text" placeholder="Enter Value" name="studentsPassed" value={formDatas.studentsPassed} maxLength={3} onChange={handleChange} required onKeyDown={handleInputKeyDown}/>
   {isSubmitted && !formDatas.studentsPassed && (
                <div className="is-invalid">*Required</div>
              )}
 </div>
 <div className="form-group col-md-6 col-lg-2">
   <label className="labelinput">Pass Percentage</label>
   <input className="form-control" type="text" placeholder="" name="passedPercentage" value={formDatas.passedPercentage} maxLength={3} onChange={handleChange} required disabled/>
   {isSubmitted && !formDatas.passedPercentage && (
                <div className="is-invalid">*Required</div>
              )}
 </div>
</div>
</form>
  )
}
export default EditPerformance