import React, { useState, ChangeEvent, FormEvent,useEffect } from 'react'
import { Link, Outlet, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import "../../OnlineCourse/AddNew/AddNew.css";
import { BooksAndChapterCreate,AcademicYear } from "../../../../../ApiServices";
import back from "../../../../../assets/back.png"
import { ToastContainer , toast  } from "react-toastify";
import image from "../../../../../assets/image.png";
import yearList from "../../../../../YearList.json" ;

type Props = {}

const AddNewBooks = (props: Props) => {

    const navigate = useNavigate();
    const [formDatas, setformDatas] = useState({
        academicYear: "",
        BookTitle: "",
        Authors: "",
        Publisher: "",
        yearOfPublications: "",
        authorPosition: "",
        isbnNo: "",
        uploadFile: "",
        type:"Book",
        bookFrontPage:""
    })
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [uploadFiles, setUploadFiles] = useState<string>('');
    const [noImage, setNoImage] = useState(true);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [imageBase, setBase64String] = useState<string>('');
    const [fileVal, setFileVal] = useState<string>('');
    const [toggleSubMod , setToggleSubMod] = useState(false)


    const handleSave=(e: FormEvent<HTMLButtonElement> )=>{
      setToggleSubMod(false)
      createBooksAndChapter(e,"Yet to submit")
    }
  
    const handleSubmit=(e: FormEvent<HTMLButtonElement> )=>{
      if(validation()=== true){
        if (!toggleSubMod) {
          setToggleSubMod(true);
        } else {
          setToggleSubMod(false);
          createBooksAndChapter(e,"Pending")
        }
      }else{}
    }

    const validation=()=>{
      setIsSubmitted(true);
      if (
          !formDatas.academicYear ||
          !formDatas.BookTitle ||
          !formDatas.Authors ||
          !formDatas.Publisher ||
          !formDatas.yearOfPublications ||
          !formDatas.authorPosition ||
          !formDatas.isbnNo ||
          !formDatas.type            
        ) {
          console.log("All fields are required");
          return;
        }else return true
    }
    
    const createBooksAndChapter = (e: FormEvent<HTMLButtonElement> , status:any) =>{
        e.preventDefault();
        setIsSubmitted(true);
        if (
            !formDatas.academicYear ||
            !formDatas.BookTitle ||
            !formDatas.Authors ||
            !formDatas.Publisher ||
            !formDatas.yearOfPublications ||
            !formDatas.authorPosition ||
            !formDatas.isbnNo ||
            !formDatas.type            
          ) {
            console.log("All fields are required");
            return;
          }
        const payload = {
            "empId": sessionStorage.getItem('empId'),
            "academicYear": formDatas.academicYear,
            "createdBy": "",
            "titleOfBook": formDatas.BookTitle,
            "authors": formDatas.Authors,
            "publisher": formDatas.Publisher,
            "yearOfPublications": formDatas.yearOfPublications,
            "authorPosition": formDatas.authorPosition,
            "isbnNo": formDatas.isbnNo,
            "uploadFile": uploadFiles,
            "updatedBy": "",
            "type":formDatas.type,
            bookFrontPage:imageBase,
            status:status
        };
        console.log(payload, "test");
        BooksAndChapterCreate(payload)
            .then((res) => {
              setIsButtonClicked(true)
                // alert(res.data.status);
                navigate("/faculty/secondsidebar/proficiency/bookschapters")
                setTimeout(()=>{
                    toast.success(res.data.status);
                  } , 200)
            })
            .catch((err) => {
                console.log(err);
            });
        console.log(payload)
    };

    const handleChange = (e: any) => {
      const { name, value } = e.target;
      const trimmedValue = value.replace(/^\s+/, "");
      let updatedValue = value;
      
          if (trimmedValue.length > 0) {
              var words = trimmedValue.split(' ');
              for (var i = 0; i < words.length; i++) {
                  words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
              }
              updatedValue = words.join(' ');
          }
  
      setformDatas({
          ...formDatas,
          [name]: updatedValue
      });
  };
  
  const formatISBN = (isbn: string): string => {
      isbn = isbn.replace(/[^0-9-]/g, '');
      
      isbn = isbn.replace(/-/g, '');
  
      if (isbn.length <= 3) {
          return isbn;
      } else if (isbn.length <= 4) {
          return isbn.slice(0, 3) + '-' + isbn.slice(3);
      } else if (isbn.length <= 7) {
          return isbn.slice(0, 3) + '-' + isbn.slice(3, 7) + '-' + isbn.slice(7);
      } else {
          return isbn.slice(0, 3) + '-' + isbn.slice(3, 7) + '-' + isbn.slice(7, 12) + '-' + isbn.slice(12);
      }
  };
  

    const handleBackButtonClick = () => {
        setShowModal(true);
      };
    
      var base64CodePhoto = "";
      const base64ConverterFile = (e:any) => {
        const files = e.target.files;
        const file = files[0];
        if (file.size > 2 * 1024 * 1024) {
          toast.error('File size exceeds 2 MB limit. Please upload a smaller file.');
        }else{
          if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
            toast.error('File format not supported. Please try uploading PDF or JPG files.');
            return;
          }else{
            getBase64(file);
            handleChange(e);
            setFileVal(file.name);
          }
      }
    }
     
      const onLoad = (fileString :any) =>{
      base64CodePhoto = fileString;  
      setUploadFiles(fileString)
      }
      const getBase64 = (file:any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>{
          onLoad(reader.result);
        }
      }   
      const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedCharacters = /[0-9-]/;
        if (!allowedCharacters.test(e.key) && e.key !== 'Backspace' && e.key !== 'a' && e.key !== 'v' && e.key !== 'c' && e.key !== 'x' ) {
            e.preventDefault();
        }
    };
    const removeImage = () => {
      setImageUrl('');
      setNoImage(true);
      setBase64String("none")
    };
   const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const maxSize = 2 * 1024 * 1024;

  if (file && allowedTypes.includes(file.type)) {
    if (file.size <= maxSize) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result as string;
        setImageUrl(base64Image);
        setNoImage(false);
        setBase64String(base64Image);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('File size exceeds the maximum limit of 2 MB');
      event.target.value = '';
    }
  } else {
    toast.error('File format not supported. Please upload image files');
    event.target.value = '';
  }
};


const [academicYears,setAcademicYear] = useState([]);
    interface AcademicYear {
      id: number;
      academicYear: string;
      term: number;
      startDate: string;
      endDate: string;
    }
    const acad = ()=>{
      AcademicYear()
      .then((res =>{
        console.log(res.data.data);
        const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
          index === self.findIndex((t:any) => (
            t.academicYear === item.academicYear
          ))
        );
        setAcademicYear(uniqueAcademicYears)
      })) 
    }
    const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
    useEffect(()=>{
      acad()
      },[])
      const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text');
      
        handleChange({
          target: {
            name: 'isbnNo',
            value: pastedData.slice(0, 17) // Ensure the pasted data respects the maxLength
          }
        });
      };
      
    return (
        <div>
  <ToastContainer/>
<div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/bookschapters"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
       <form>
                {/* <h4 className="mainheader">Add New Books & Chapters</h4>
                <div className="row greytext">
                    <span className="col-8">
                        Books & Chapters Published recently.
                    </span>
                    <div className="col-4 d-flex justify-content-between align-items-center">
                        <Link to="/faculty/secondsidebar/proficiency/bookschapters" style={{ textDecoration: "none", color: "black" }}>
                            <p className="backtext">
                                <GoArrowLeft />
                                Back to Books & Chapters
                            </p>
                        </Link>
                        <button className="col-3 bg-primary text-white btn" type="submit" onClick={createBooksAndChapter}>
                            Save
                        </button>
                    </div>
                </div> */}
   <div className="row">
    <div className="col-md-12 col-lg-5 col-12">
    <div className="mainheader">Add New Book / Chapter</div>
    <div className="greyfont mt-2">New book / chapter published</div>
    </div>
    <div className="col-md-12 col-lg-7 col-12">
    <div className="float-end">
    {/* <Link to="/faculty/secondsidebar/proficiency/bookschapters" className="link"> */}
        <span className='bacdept cursorPointer labelinput'
        
        >
          <span
           onClick={handleBackButtonClick}
           data-bs-toggle="modal"
           data-bs-target="#myModal"
          >
          <img src={back} className="img-fluid me-2 " />
            Back to Books & Chapters
          </span>
            
            <button  className=" ms-3 saveBtn"style={{ color: 'white' }} onClick={handleSave} disabled={isButtonClicked}
>Save</button>
<button type='button'  className=" ms-3 addnewbtn"style={{ color: 'white' }} onClick={handleSubmit} disabled={isButtonClicked}
>Save & Submit</button>

{toggleSubMod && (
                      <>
                      <div className="modal fade show" tabIndex={-1} style={{ display: "block" }}>
            <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content mt-2 mb-2">
              <div className="d-flex justify-content-end ">
            <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setToggleSubMod(false);
                  }}
                ></button>
                </div>
              <div className="modal-header d-flex justify-content-center">
                <h5 className="modal-title submitHead">Submit for validation</h5> 
              </div>
              <div className="">
                <p className="fontModel items-center px-5">
                  This entry will be sent to admin for validation. Click 'Save' if you don't want to submit now.
                </p>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="button"
                  className="btn bg-white text-black cancelBtn"
                  // onClick={() => setToggleSubMod(false)}
                  onClick={handleSave}
                >
                  Save  
                </button>
                <button
                  type="button"
                  className="btn btnBack"
                  onClick={handleSubmit}
                >
                 Save & Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {toggleSubMod && <div className="modal-backdrop fade show"></div> }
                      </>
                    )
                  }
                  
            </span>
            {/* </Link>            */}
</div>        
    </div>
</div>
                <div className="form row" style={{ marginTop: "36px",fontSize: '14px', fontFamily: 'Satoshi medium' }}>
                <div className="d-flex align-items-end mb-4">
          {noImage && <img src={image} alt="" className="me-3 img-fluid" />}
          {!noImage && (
            <img src={imageUrl} alt="Preview" className="me-3 img-fluid imgWidth" />
          )}
          <div className=''>
            <div className="mb-2 labelinput">First Page of the book</div>
            <div className=" ">
              <button
                onClick={() => document.getElementById("fileInputPhoto")?.click()}
                className="btn uploadBtn me-2 labelinput"
                style={{ border: "1px solid #E8EBF0"}}
              >
                <span className="labelinput">
                Upload
                </span>
              </button>
              <input
                type="file"
                id="fileInputPhoto"
                style={{ display: "none" }}
                onChange={onFileSelected}
              />
              <button
                className="btn uploadBtn labelinput"
                onClick={removeImage}
                style={{ border: "1px solid #E8EBF0" }}
              >
                <span className="labelinput">
                Remove
                </span>
              </button>
            </div>
          </div>
        </div>
                    <div className="form-group col-md-6 col-lg-2 mt-2">
                        <label className="labelinput">Academic Year</label>
                        <select className="form-select inputWidth" id="" name="academicYear" value={formDatas.academicYear} onChange={handleChange} required
                        style={{
                          fontSize: formDatas.academicYear ? '14px' : '12px',
                          height: "38px",
                          fontFamily: 'Satoshi medium',
                          color: formDatas.academicYear ? 'black' : 'grey', 
                        }}
                  >
                      <option value="" disabled className="select">--Select--</option>
                      {yearList.map((item) => (
    <option key={item} value={item} style={{ color: "black", fontSize: "14px" }}>
      {item}
    </option>
  ))}
                      </select>
                        {isSubmitted && !formDatas.academicYear && (
                <div className="is-invalid">*Required</div>
              )}
                    </div>
                    <div className="form-group col-md-6 col-lg-2 mt-2">
        <label className="labelinput ms-2"> Choose a Type </label>
        <div className="d-flex mt-2" style={{ fontSize: '14px', fontFamily: 'Satoshi medium' }}>
          <div className="form-check d-flex align-items-center me-4 labelinput">
            <input
              type="radio"
              className="form-check-input me-2 mb-1"
              name="type"
              value="Book"
              checked={formDatas.type === 'Book'}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="Book">Book</label>
          </div>
          <div className="form-check d-flex align-items-center me-4 labelinput">
            <input
              type="radio"
              className="form-check-input me-2 mb-1"
              name="type"
              value="Chapter"
              checked={formDatas.type === 'Chapter'}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="Chapter">Chapter</label>
          </div>
        </div>
      </div>
                </div>

                <div className="form row mt-md-3 mt-2" style={{ marginTop: "25px",fontSize: '14px', fontFamily: 'Satoshi medium' }}>
                    <div className="form-group col-md-6 col-lg-4">
                    <label className="labelinput">
          {formDatas.type === 'Book' ? 'Title of the book' : 'Title of the chapter'}
        </label>
        <input
          className="form-control inputWidth"
          placeholder="Enter Title"
          name="BookTitle"
          value={formDatas.BookTitle}
          onChange={handleChange}
          required
        />
                        {isSubmitted && !formDatas.BookTitle && (
                <div className="is-invalid">*Required</div>
              )}
                    </div>
                    <div className="form-group col-md-6 col-lg-6">
                        <label className="labelinput">Authors</label>
                        <input className="form-control inputWidth" placeholder="Enter Authors" name="Authors" value={formDatas.Authors} onChange={handleChange} required />
                        {isSubmitted && !formDatas.Authors && (
                <div className="is-invalid">*Required</div>
              )}
                    </div>
                </div>

                <div className="form row pt-md-3 pt-2" style={{ paddingTop: "25px" ,fontSize: '14px', fontFamily: 'Satoshi medium'}}>
                    <div className="form-group col-md-6 col-lg-4">
                        <label className="labelinput">Publisher</label>
                        <input className="form-control inputWidth" placeholder="Enter Publisher" name="Publisher" value={formDatas.Publisher} onChange={handleChange} required />
                        {isSubmitted && !formDatas.Publisher && (
                <div className="is-invalid">*Required</div>
              )}
                    </div>
                    <div className="form-group col-md-6 col-lg-3">
                        <label className="labelinput">Year of Publication</label>
                        <input type='text' className="form-control inputWidth" maxLength={4} placeholder="Enter Year" name="yearOfPublications" value={formDatas.yearOfPublications} onChange={handleChange} required
                        onKeyDown={handleInputKeyDown}
                         />
                        {isSubmitted && !formDatas.yearOfPublications && (
                <div className="is-invalid">*Required</div>
              )}
                    </div>
                    <div className="form-group col-md-6 col-lg-3">
        {/* <label className="labelinput">Author Position</label> */}
        {/* <select
          className="form-select labelFont"
          value={formDatas.authorPosition}
          onChange={handleChange}
          name="authorPosition"
          style={{
            fontSize: formDatas.authorPosition ? '14px' : '12px',
            height: "38px",
            fontFamily: 'Satoshi medium',
            color: formDatas.authorPosition ? 'black' : 'grey',
          }}
        >
          <option value="" disabled>--Select--</option>
          <option value="Single author" style={{ color: "black", fontSize: "14px" }}>Single author</option>
          <option value="First author" style={{ color: "black", fontSize: "14px" }}>First author</option>
          <option value="Co-author" style={{ color: "black", fontSize: "14px" }}>Co-author</option>
          <option value="Senior author" style={{ color: "black", fontSize: "14px" }}>Senior author</option>
          <option value="Equal contribution" style={{ color: "black", fontSize: "14px" }}>Equal contribution</option>
          <option value="Collaborative authorship" style={{ color: "black", fontSize: "14px" }}>Collaborative authorship</option>
        </select> */}
         <label className="form-label">Author Position</label>
                                        <select 
                                         className="form-select labelFont inputWidth"
                                         value={formDatas.authorPosition}
                                         onChange={handleChange}
                                         name="authorPosition"
                                         style={{
                                           fontSize: formDatas.authorPosition ? '14px' : '12px',
                                           height: "38px",
                                           fontFamily: 'Satoshi medium',
                                           color: formDatas.authorPosition ? 'black' : 'grey',
                                         }}
                                        >
                                           <option value="" disabled className="select">--Select--</option>
                                           <option value="Single Author" style={{ color: "black", fontSize: "14px" }}>Single Author</option>
          <option value="First Author" style={{ color: "black", fontSize: "14px" }}>First Author</option>
          <option value="Co-author" style={{ color: "black", fontSize: "14px" }}>Co-author</option>
          <option value="Senior Author" style={{ color: "black", fontSize: "14px" }}>Senior Author</option>
          <option value="Equal Contribution" style={{ color: "black", fontSize: "14px" }}>Equal Contribution</option>
          <option value="Collaborative Authorship" style={{ color: "black", fontSize: "14px" }}>Collaborative Authorship</option>
                                        </select>
        {isSubmitted && !formDatas.authorPosition && (
          <div className="is-invalid">*Required</div>
        )}
      </div>
                </div>

                <div className="form row pt-md-3 pt-2" style={{ paddingTop: "25px" ,fontSize: '14px', fontFamily: 'Satoshi medium'}}>
                    <div className="form-group col-md-6 col-lg-4">
                    <label className="labelinput">ISBN Number</label>
                    <input
    className="form-control inputWidth"
    maxLength={13}
    placeholder="Enter ISBN Number"
    name="isbnNo"
    type="tel"
    value={formDatas.isbnNo}
    onChange={handleChange}
    required
    onKeyDown={handleInputKeyDown}
      />
                        {isSubmitted && !formDatas.isbnNo && (
                <div className="is-invalid">*Required</div>
              )}
                    </div>
                    <div className="col-sm-6 col-md-10 col-lg-6 col-xl-4">
                    <label className="labelinput mt-2 d-flex justify-content-between">
                    <span className="" style={{ color: "#1B1922" }}>
                      Proof Document
                    </span>{" "}
                    <span className="" style={{ color: "#7784A1" }}>
                      Only pdf/jpg file under 2MB
                    </span>
                  </label>
                        {/* <input className="form-control" type="file" placeholder="uploadFile" name="uploadFile" value={formDatas.uploadFile} onChange={e => {base64ConverterFile(e)}}/> */}
                        <div className="input-group">
  <label className="form-control boxText boxtextellipse browsetext">
  {fileVal}
    <input
      type="file"
      id="fileInput2"
      className="invisible"
      onChange={(e) => base64ConverterFile(e)} 
    />
  </label>
  <span className="input-group-text lname cursorPointer"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
</div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default AddNewBooks;