// import React, { useState, ChangeEvent, FormEvent  } from "react";
// import { Link, Outlet, useNavigate } from "react-router-dom";
// import "../../OnlineCourse/AddNew/AddNew.css";
// import { GoArrowLeft } from "react-icons/go";
// import { SlArrowDown } from "react-icons/sl";
// import { OnlineCourseCreate } from "../../../../../ApiServices";
// import back from "../../../../../assets/back.png"
// import { ToastContainer , toast  } from "react-toastify";

// function NewOnlineCourse() {
//   const navigate =useNavigate();
//   const [save , setSave]= useState(false);
//   const [formDatas, setformDatas] = useState({
//   academicYear: "",
//   courseDuration: "",
//   courseTitle: "",
//   grade: "",
//   completionOn: "",
//   awardingInstitution: "",
//   uploadFile: "",
//   })
//   const [isSubmitted, setIsSubmitted] = useState(false);

//   const createonlineclass = (e: FormEvent<HTMLButtonElement>) =>{
//     e.preventDefault();
//     setIsSubmitted(true);
//     if (
//       !formDatas.academicYear ||
//       !formDatas.courseTitle ||
//       !formDatas.completionOn ||
//       !formDatas.awardingInstitution ||
//       !formDatas.grade ||
//       !formDatas.courseDuration 
      
//     ) {
//       console.log("All fields are required");
//       return;
//     }
//     const payload = {
//       empId:  sessionStorage.getItem('empId'),
//       academicYear: formDatas.academicYear,
//       createdBy:"",
//       courseTitle: formDatas.courseTitle,
//       completionOn: formDatas.completionOn,
//       awardingInstitution: formDatas.awardingInstitution,
//       grade: formDatas.grade,
//       completionPercentage: "10%",
//       courseDuration: formDatas.courseDuration,
//       uploadFile: formDatas.uploadFile,
//     };
//     console.log(payload,"test");
//     OnlineCourseCreate(payload)
//       .then((res) => {
//       // alert(res.data.status);
//        navigate("/faculty/secondsidebar/proficiency/onlinecourses")
//        setTimeout(()=>{
//         toast.success("publication Added");
//       } , 200)
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//       console.log(payload)
//   };

//   // const handleChange =(e:any)=>{
//   //   setformDatas({
//   //     ...formDatas,
//   //     [e.target.name]:e.target.value
//   // })
//   // }

//   const handleChange =(e:any)=>{
//     const { name, value } = e.target;
//     const trimmedValue = value.replace(/^\s+/,"");
//     setformDatas({
//       ...formDatas,
//       [e.target.name]:e.target.value,
//       [name]: trimmedValue 
//   })
//   }

//   return (
//     <>
//     <form>
//        {/* <h4 className="mainheader">Add New Online Courses</h4>
//          <div className="row greytext">
//          <span className="col-8">
//           New Online Certification Courses recently.
//          </span>
//          <div className="col-4 d-flex justify-content-between align-items-center">
//          <Link to="/faculty/secondsidebar/proficiency/onlinecourses" style={{textDecoration:"none" , color:"black" }}>
//          <p className="backtext">
//         <GoArrowLeft />
//         Back to Online Courses
//       </p>
//     </Link>
//     <button className=" ms-3 addnewbtn"style={{ color: 'white' }} type="submit" onClick={createonlineclass}>
//       Save
//     </button>
//   </div>
// </div> */}
//     <div className="row">
//     <div className="col-md-12 col-lg-5 col-12">
//     <div className="mainheader">Add New Online Courses</div>
//     <div className="greyfont mt-2">New Online Certification Courses recently.</div>
//     </div>
//     <div className="col-md-12 col-lg-7 col-12">
//     <div className="float-end">
//     <Link to="/faculty/secondsidebar/proficiency/onlinecourses" className="link">
//         <span className='bacdept cursorPointer labelinput'>
//             <img src={back} className="img-fluid me-2 " />
//             Back to Online Courses
//             <button  className=" ms-3 addnewbtn"style={{ color: 'white' }} onClick={createonlineclass}>Save</button>
//             </span>
//             </Link>           
// </div>        
//     </div>
// </div>
//   <div className="form row"style={{ marginTop: "36px" }}>
//     <div className="form-group col-md-6 col-lg-2">
//       <label className="labelinput">Academic Year</label>
//       <select className="form-control" id=""  name="academicYear" value={formDatas.academicYear} onChange={handleChange} 
//       style={{ fontSize: '14px', fontFamily: 'Satoshi medium' }}
//       >
//       <option value="">--Select--</option>
//                 <option value="2023-2024">2023-2024</option>
//                 <option value="2024-2025">2024-2025</option>
//     </select>
//     {isSubmitted && !formDatas.academicYear && (
//                 <div className="is-invalid">*Required</div>
//               )}
//     </div>
//     <div className="form-group col-md-6 col-lg-2">
//       <label className="labelinput">Course duration</label>
//       <input className="form-control" placeholder="Enter Year" type="number" name="courseDuration" value={formDatas.courseDuration} onChange={handleChange}  />
//       {isSubmitted && !formDatas.courseDuration && (
//                 <div className="is-invalid">*Required</div>
//               )}
//     </div>
//     <div className="form-group col-md-6 col-lg-4">
//       <label className="labelinput">Course Title</label>
//       <input  className="form-control" placeholder="Enter Course Tilte" name ="courseTitle" value={formDatas.courseTitle} onChange={handleChange} />
//       {isSubmitted && !formDatas.courseTitle && (
//                 <div className="is-invalid">*Required</div>
//               )}
//     </div>
//   </div>
  
//   <div className="form row" style={{ paddingTop: "25px" }}>
//     <div className="form-group col-md-6 col-lg-2">
//       <label className="labelinput">Grade</label>
//       <input  className="form-control" placeholder="Enter Grade" name="grade" value={formDatas.grade} onChange={handleChange} maxLength={1} style={{ textTransform: 'uppercase' }}/>
//       {isSubmitted && !formDatas.grade && (
//                 <div className="is-invalid">*Required</div>
//               )}
//     </div>
//     <div className="form-group col-md-6 col-lg-2">
//       <label className="labelinput">Course Completed on</label>
//       <select className="form-control" id="" name ="completionOn" value={formDatas.completionOn} onChange={handleChange} 
//       style={{ fontSize: '14px', fontFamily: 'Satoshi medium' }}
//       >
//       <option>--Select--</option>
//       <option>1</option>
//       <option>2</option>
//       <option>3</option>
//       <option>4</option>
//       <option>5</option>
//     </select>
//     {isSubmitted && !formDatas.completionOn && (
//                 <div className="is-invalid">*Required</div>
//               )}
//     </div>
//     <div className="form-group col-md-6 col-lg-4">
//       <label className="labelinput">Awarding Institution</label>
//       <input  className="form-control" placeholder="Enter Awarding Institution" name="awardingInstitution" value={formDatas.awardingInstitution} onChange={handleChange} />
//       {isSubmitted && !formDatas.awardingInstitution && (
//                 <div className="is-invalid">*Required</div>
//               )}
//     </div>
//   </div>

// <div className="form row" style={{ paddingTop: "25px" }}>
//     <div className=" col-md-6 col-lg-4">
//       <label className="labelinput">Upload File</label>
//       <input  className="form-control" type="file" placeholder="Email" value={formDatas.uploadFile} onChange={handleChange} />
//     </div>
//   </div>
// </form>
       
//     </>
//   );
// }

// export default NewOnlineCourse;



import React, { useState, ChangeEvent, FormEvent,useEffect  } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "../../OnlineCourse/AddNew/AddNew.css";
import { GoArrowLeft } from "react-icons/go";
import { SlArrowDown } from "react-icons/sl";
import { OnlineCourseCreate,AcademicYear } from "../../../../../ApiServices";
import back from "../../../../../assets/back.png"
import { ToastContainer , toast  } from "react-toastify";
import yearList from "../../../../../YearList.json";

function NewOnlineCourse() {
  const navigate =useNavigate();
  const [save , setSave]= useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [formDatas, setformDatas] = useState({
  academicYear: "",
  courseDuration: "",
  courseTitle: "",
  grade: "",
  completionOn: "",
  awardingInstitution: "",
  uploadFile: "",
  })
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileVal, setFileVal] = useState<string>('');
  const [toggleSubMod , setToggleSubMod] = useState(false)

  const handleSave=(e: FormEvent<HTMLButtonElement> )=>{
    setToggleSubMod(false)
    createonlineclass(e,"Yet to submit")
  }

  const handleSubmit=(e: FormEvent<HTMLButtonElement> )=>{
    if(validation()===true){
      if (!toggleSubMod) {
        setToggleSubMod(true);
      } else {
        setToggleSubMod(false);
        createonlineclass(e,"Pending")
      }
    } 
  }

  const validation=()=>{
    setIsSubmitted(true);
    if (
      !formDatas.academicYear ||
      !formDatas.courseTitle ||
      !formDatas.completionOn ||
      !formDatas.awardingInstitution ||
      !formDatas.grade ||
      !formDatas.courseDuration 
      
    ) {
      console.log("All fields are required");
      return;
    }else return true
  }

  const createonlineclass = (e: FormEvent<HTMLButtonElement> , status:any) =>{
    e.preventDefault();
    setIsSubmitted(true);
    if (
      !formDatas.academicYear ||
      !formDatas.courseTitle ||
      !formDatas.completionOn ||
      !formDatas.awardingInstitution ||
      !formDatas.grade ||
      !formDatas.courseDuration 
      
    ) {
      console.log("All fields are required");
      return;
    }
    const payload = {
      empId:  sessionStorage.getItem('empId'),
      academicYear: formDatas.academicYear,
      createdBy:"",
      courseTitle: formDatas.courseTitle,
      completionOn: formDatas.completionOn,
      awardingInstitution: formDatas.awardingInstitution,
      grade: formDatas.grade,
      completionPercentage: "10%",
      courseDuration: formDatas.courseDuration,
      uploadFile: uploadFiles,
      status:status
    };
    console.log(payload,"test");
    OnlineCourseCreate(payload)
      .then((res) => {
        setIsButtonClicked(true)
      // alert(res.data.status);
       navigate("/faculty/secondsidebar/proficiency/onlinecourses")
       setTimeout(()=>{
        toast.success(res.data.status);
      } , 200)
      })
      .catch((err) => {
        console.log(err);
      });
      console.log(payload)
  };

  // const handleChange =(e:any)=>{
  //   setformDatas({
  //     ...formDatas,
  //     [e.target.name]:e.target.value
  // })
  // }

  const handleChange =(e:any)=>{
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/,"");
    let updatedValue = value;
    if (value.length > 0) {
      var words = value.split(' ');
      for (var i = 0; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      updatedValue = words.join(' ');
  }
    setformDatas({
      ...formDatas,
      [e.target.name]:e.target.value,
      [name]: updatedValue 
  })
  }

  const today = new Date().toISOString().split('T')[0];
  const handleBackButtonClick = () => {
    setShowModal(true);
  };
  var base64CodePhoto = "";
  const base64ConverterFile = (e:any) => {
    const files = e.target.files;
    const file = files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error('File size exceeds 2 MB limit. Please upload a smaller file.');
    }else{
      if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
        toast.error('File format not supported. Please try uploading PDF or JPG files.');
        return;
      }else{
        getBase64(file);
        handleChange(e);
      }
      setFileVal(file.name);
  }
}
 
  const onLoad = (fileString :any) =>{
  base64CodePhoto = fileString;  
  setUploadFiles(fileString)
  }
  const getBase64 = (file:any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>{
      onLoad(reader.result);
    }
  }   
  const getMaxDate = (academicYear:any) => {
    try {
      const endDates = academicYear.substring(5,9); 
      console.log('End Year:', endDates);
      const endDate = new Date(`${endDates}-07-01`);
      console.log('End Date:', endDate.toISOString().split('T')[0]);
      return endDate.toISOString().split('T')[0];
    } catch (error) {
      console.error('Error in getMaxDate:', error);
      return '';
    }
  };
  const getMinDate = (academicYear:any) => {
    try {
     const startDates = academicYear.substring(0, 4);
      console.log('Start Year:', startDates); 
      const startDate = new Date(`${startDates}-07-01`);
      console.log('Start Date:', startDate.toISOString().split('T')[0]);
      return startDate.toISOString().split('T')[0];
    } catch (error) {
      console.error('Error in getMinDate:', error);
      return '';
    }
  };
  console.log(formDatas.academicYear,"jeba");
  
  const maxCompletedOn = formDatas.academicYear === "" ? today : getMaxDate(formDatas.academicYear);
  const minCompletedOn = formDatas.academicYear === "" ? "" : getMinDate(formDatas.academicYear);
  const updateType =(e:any) =>{
    setformDatas(prevState => ({
      ...prevState,
      completionOn: "",
  }));
  }
  const [academicYears,setAcademicYear] = useState([]);
  interface AcademicYear {
    id: number;
    academicYear: string;
    term: number;
    startDate: string;
    endDate: string;
  }
  const acad = ()=>{
    AcademicYear()
    .then((res =>{
      console.log(res.data.data);
      const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
        index === self.findIndex((t:any) => (
          t.academicYear === item.academicYear
        ))
      );
      setAcademicYear(uniqueAcademicYears)
    })) 
  }
  const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
  useEffect(()=>{
    acad()
    },[])
    
  return (
    <>
<ToastContainer/>
<div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/onlinecourses"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
    <form>
       {/* <h4 className="mainheader">Add New Online Courses</h4>
         <div className="row greytext">
         <span className="col-8">
          New Online Certification Courses recently.
         </span>
         <div className="col-4 d-flex justify-content-between align-items-center">
         <Link to="/faculty/secondsidebar/proficiency/onlinecourses" style={{textDecoration:"none" , color:"black" }}>
         <p className="backtext">
        <GoArrowLeft />
        Back to Online Courses
      </p>
    </Link>
    <button className=" ms-3 addnewbtn"style={{ color: 'white' }} type="submit" onClick={createonlineclass}>
      Save
    </button>
  </div>
</div> */}
    <div className="row">
    <div className="col-md-12 col-lg-5 col-12">
    <div className="mainheader">Add New Online Course</div>
    <div className="greyfont mt-2">Enter details of Completed online Certificate course</div>
    </div>
    <div className="col-md-12 col-lg-7 col-12">
    <div className="float-end">
   
        <span className='bacdept cursorPointer labelinput'>

            <span  onClick={handleBackButtonClick}
            data-bs-toggle="modal"
            data-bs-target="#myModal">
            <img src={back} className="img-fluid me-2 " />
            Back to Online Courses
            </span>
          
            <button  className="ms-3 saveBtn"style={{ color: 'white' }} onClick={handleSave} disabled={isButtonClicked}>Save</button>
            <button type="button" className=" ms-3 addnewbtn"style={{ color: 'white' }} onClick={handleSubmit} >Save & Submit</button>

            {toggleSubMod && (
                      <>
                      <div className="modal fade show" tabIndex={-1} style={{ display: "block" }}>
            <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content mt-2 mb-2">
              <div className="d-flex justify-content-end ">
            <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setToggleSubMod(false);
                  }}
                ></button>
                </div>
              <div className="modal-header d-flex justify-content-center">
                <h5 className="modal-title submitHead">Submit for validation</h5> 
              </div>
              <div className="">
                <p className="fontModel items-center px-5">
                  This entry will be sent to admin for validation. Click 'Save' if you don't want to submit now.
                </p>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="button"
                  className="btn bg-white text-black cancelBtn"
                  onClick={handleSave}
                >
                  Save  
                </button>
                <button
                  type="button"
                  className="btn btnBack"
                  onClick={handleSubmit}
                >
                 Save & Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {toggleSubMod && <div className="modal-backdrop fade show"></div> }
                      </>
                    )
                  }
            </span>
</div>        
    </div>
</div>
  <div className="form row mt-2 mt-md-3"style={{ marginTop: "36px" ,fontSize: '14px', fontFamily: 'Satoshi medium' }}>
    <div className="form-group col-md-6 col-lg-2">
      <label className="labelinput">Academic Year</label>
      <select
                                className="form-select  labelFont inputWidth"
                                value={formDatas.academicYear}
                                onChange={e => {handleChange(e); updateType(e)}}
                                name="academicYear"
                                style={{
                                    fontSize: formDatas.academicYear ? '14px' : '12px',
                                    height: "38px",
                                    fontFamily: 'Satoshi medium',
                                    color: formDatas.academicYear ? 'black' : 'grey', 
                                  }}
                            >
                                <option value="" disabled className="select">--Select--</option>
                                {yearList.map((item) => (
    <option key={item} value={item} style={{ color: "black", fontSize: "14px" }}>
      {item}
    </option>
  ))}
                            </select>
    {isSubmitted && !formDatas.academicYear && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
    <div className="form-group col-md-6 col-lg-2">
    <div style={{position:'relative'}}>
      <label className="labelinput">Course Completed on</label>
    <input
    type="date"
    className="form-control labelFont inputWidth"
    placeholder="15 May 2019"
    max={ maxCompletedOn}
    min={formDatas.completionOn ? minCompletedOn : "1950-12-31"}
   id="" name ="completionOn" value={formDatas.completionOn} onChange={handleChange} 
      // style={{ fontSize: '14px', fontFamily: 'Satoshi medium' }}
      style={{
        // fontSize: '14px',
        height: '38px',
        color: formDatas.completionOn ? '#000' : '#7784A1', 
        fontSize: formDatas.completionOn ? '14px' : '12px',
      }}
    />
    <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
    </div>
    {isSubmitted && !formDatas.completionOn && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
    <div className="form-group col-md-6 col-lg-4">
      <label className="labelinput">Course Title</label>
      <input  className="form-control inputWidth" placeholder="Enter Course Tilte" name ="courseTitle" value={formDatas.courseTitle} onChange={handleChange} />
      {isSubmitted && !formDatas.courseTitle && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
  </div>
  
  <div className="form row pt-2 pt-md-3" style={{ paddingTop: "25px",fontSize: '14px', fontFamily: 'Satoshi medium' }}>
  <div className="form-group col-md-6 col-lg-4">
      <label className="labelinput ">Awarding Institution</label>
      <input  className="form-control inputWidth" placeholder="Enter Awarding Institution" name="awardingInstitution" value={formDatas.awardingInstitution} onChange={handleChange} />
      {isSubmitted && !formDatas.awardingInstitution && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
    <div className="form-group col-md-6 col-lg-2">
      <label className="labelinput">Grade or % of marks</label>
      {/* <input  className="form-control" placeholder="Enter Grade" name="grade" value={formDatas.grade} onChange={handleChange} maxLength={1} style={{ textTransform: 'uppercase' }}/> */}
      <input  className="form-control inputWidth" placeholder="Enter Grade" name="grade" value={formDatas.grade} onChange={handleChange} maxLength={4} />
      {isSubmitted && !formDatas.grade && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
  
 
    <div className="form-group col-md-6 col-lg-2" >
      <label className="labelinput">Course Duration</label>
      <input className="form-control inputWidth" placeholder="Enter Duration" type="text" name="courseDuration" value={formDatas.courseDuration} onChange={handleChange} maxLength={15}  />
      {isSubmitted && !formDatas.courseDuration && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
  </div>

<div className="form row pt-2 pt-md-3" style={{ paddingTop: "25px",fontSize: '14px', fontFamily: 'Satoshi medium' }}>
<div className="col-sm-6 col-md-10 col-lg-8 col-xl-4">
                  <label className="labelinput mt-2 d-flex justify-content-between">
                    <span className="" style={{ color: "#1B1922" }}>
                      Proof Document
                    </span>{" "}
                    <span className="" style={{ color: "#7784A1" }}>
                      Only pdf/jpg file under 2MB
                    </span>
                  </label>
                  <div className="input-group">
  <label className="form-control boxText boxtextellipse browsetext">
  {fileVal}
    <input
      type="file"
      id="fileInput2"
      className="invisible"
      onChange={(e) => base64ConverterFile(e)} 
    />
  </label>
  <span className="input-group-text lname cursorPointer"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
</div>
    </div>
  </div>
</form> 
    </>
  );
}

export default NewOnlineCourse;
