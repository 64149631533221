import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
// import './AddNew.css'
import { Link, useNavigate, useParams } from "react-router-dom";
import back from "../../../../../assets/back.png";
import { honourCreate, honourGetTableData ,AcademicYear} from "../../../../../ApiServices";
import { toast, ToastContainer } from "react-toastify";
import yearList from "../../../../../YearList.json" 

function HonoursAwardsEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inputType, setInputType] = useState("text");
  const [fileVal, setFileVal] = useState<string>('');
  const [HonoursAwardsCreateForm, setHonoursAwardsCreateForm] = useState({
    honId: "",
    academicYear: "",
    type: "",
    awardTitle: "",
    honourTitle: "",
    fellowTitle: "",
    memberTitle: "",
    memberPresentedBy: "",
    presentedBy: "",
    honourPresentedBy: "",
    fellowPresentedBy: "",
    uploadFile: "",
    currentStatus: "",
    organisationRole: "",
    organisationName: "",
    memberSince: "",
    memberTill: "",
  });
  const [uploadFiles, setUploadFiles] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showAwardInputs, setShowAwardInputs] = useState(true);
  const [showHonourInputs, setShowHonourInputs] = useState(false);
  const [dateError, setDateError] = React.useState(false);
  const [showFellowshipInputs, setShowFellowshipInputs] = useState(false);
  const [showMembershipInputs, setShowMembershipInputs] = useState(false);
  const updateType = (e: any) => {
    setHonoursAwardsCreateForm((prevState) => ({
      ...prevState,
      awardTitle: "",
      honourTitle: "",
      fellowTitle: "",
      memberTitle: "",
      memberPresentedBy: "",
      presentedBy: "",
      honourPresentedBy: "",
      fellowPresentedBy: "",
      uploadFile: "",
      currentStatus: "",
      organisationRole: "",
      organisationName: "",
      memberSince: "",
      memberTill: "",
    }));
    setUploadFiles("none");
    setIsSubmitted(false);
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");
    let updatedValue = value;
    if (value.length > 0) {
      var words = value.split(" ");
      for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      updatedValue = words.join(" ");
    }
    setHonoursAwardsCreateForm({
      ...HonoursAwardsCreateForm,
      [e.target.name]: e.target.value,
      [name]: updatedValue,
    });
    if (
      HonoursAwardsCreateForm.memberSince &&
      HonoursAwardsCreateForm.memberTill
    ) {
      const memberSinceDate = new Date(HonoursAwardsCreateForm.memberSince);
      const memberTillDate = new Date(HonoursAwardsCreateForm.memberTill);

      if (memberSinceDate.toDateString() === memberTillDate.toDateString()) {
        setDateError(true);
      } else {
        setDateError(false);
      }
    }
  };

  const handleSave = (e: FormEvent<HTMLButtonElement>) => {
    EditHonour(e, "Yet to submit");
  };

  const handleSubmit = (e: FormEvent<HTMLButtonElement>) => {
    EditHonour(e, "Pending");
  };

  const EditHonour = (e: FormEvent<HTMLButtonElement>, status: any) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (
      !HonoursAwardsCreateForm.academicYear ||
      !HonoursAwardsCreateForm.type
    ) {
      console.log("Academic Year and Type are required");
      return;
    }

    switch (HonoursAwardsCreateForm.type) {
      case "Awards":
        if (
          !HonoursAwardsCreateForm.awardTitle ||
          !HonoursAwardsCreateForm.presentedBy
        ) {
          console.log("Award Title and Presented By are required");
          return;
        }
        break;
      case "Honours":
        if (
          !HonoursAwardsCreateForm.honourTitle ||
          !HonoursAwardsCreateForm.honourPresentedBy
        ) {
          console.log("Honour Title and Honour Presented By are required");
          return;
        }
        break;
      case "Fellowships":
        if (
          !HonoursAwardsCreateForm.fellowTitle ||
          !HonoursAwardsCreateForm.fellowPresentedBy
        ) {
          console.log(
            "Fellowship Title and Fellowship Presented By are required"
          );
          return;
        }
        break;
      case "Memberships":
        if (
          !HonoursAwardsCreateForm.organisationName ||
          !HonoursAwardsCreateForm.organisationRole ||
          !HonoursAwardsCreateForm.currentStatus ||
          !HonoursAwardsCreateForm.memberSince ||
          !HonoursAwardsCreateForm.memberTill
        ) {
          console.log(
            "Organisation Name, Role, Current Status, Member Since, and Member Till are required"
          );
          return;
        }
        break;
      default:
        break;
    }

    const payload = {
      honId: HonoursAwardsCreateForm.honId,
      empId: sessionStorage.getItem("empId"),
      academicYear: HonoursAwardsCreateForm.academicYear,
      createdBy: "",
      type: HonoursAwardsCreateForm.type,
      awardTitle: HonoursAwardsCreateForm.awardTitle,
      honourTitle: HonoursAwardsCreateForm.honourTitle,
      presentedBy: HonoursAwardsCreateForm.presentedBy,
      honourPresentedBy: HonoursAwardsCreateForm.honourPresentedBy,
      uploadFile: uploadFiles,
      fellowTitle: HonoursAwardsCreateForm.fellowTitle,
      fellowPresentedBy: HonoursAwardsCreateForm.fellowPresentedBy,
      memberTitle: HonoursAwardsCreateForm.memberTitle,
      memberPresentedBy: HonoursAwardsCreateForm.memberPresentedBy,
      currentStatus: HonoursAwardsCreateForm.currentStatus,
      organisationRole: HonoursAwardsCreateForm.organisationRole,
      organisationName: HonoursAwardsCreateForm.organisationName,
      memberSince: HonoursAwardsCreateForm.memberSince,
      memberTill: HonoursAwardsCreateForm.memberTill,
      status: status,
    };
    console.log(payload, "honourCreate");
    honourCreate(payload)
      .then((res) => {
        setIsButtonClicked(true);
        console.log(res.data.status);
        navigate(`/faculty/secondsidebar/proficiency/honoursawards`);
        setTimeout(() => {
          toast.success(res.data.status);
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(payload);
  };

  const getSingleData = () => {
    honourGetTableData(id)
      .then((res) => {
        setHonoursAwardsCreateForm(res.data.data[0].proficiency);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSingleData();
    setInputType("text");
  }, [id]);

  const handleBackButtonClick = () => {
    setShowModal(true);
  };

  const handleClick = () => {
    setInputType("file");
  };
  var base64CodePhoto = "";
  const base64ConverterFile = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error(
        "File size exceeds 2 MB limit. Please upload a smaller file."
      );
    } else {
      if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
        toast.error(
        "File format not supported. Please try uploading PDF or JPG files."
        );
        return;
      } else {
        getBase64(file);
        handleChange(e);
        setFileVal(file.name);
      }
    }
  };

  const onLoad = (fileString: any) => {
    base64CodePhoto = fileString;
    setUploadFiles(fileString);
  };
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const handleMemberSinceChange = (e: any) => {
    setHonoursAwardsCreateForm((prevState) => ({
      ...prevState,
      memberTill: "",
    }));
  };
  const today = new Date().toISOString().split("T")[0];
  const [academicYears,setAcademicYear] = useState([]);
  interface AcademicYear {
    id: number;
    academicYear: string;
    term: number;
    startDate: string;
    endDate: string;
  }
  const acad = ()=>{
    AcademicYear()
    .then((res =>{
      console.log(res.data.data);
      const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
        index === self.findIndex((t:any) => (
          t.academicYear === item.academicYear
        ))
      );
      setAcademicYear(uniqueAcademicYears)
    })) 
  }
  const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
  useEffect(()=>{
    acad()
    },[])
  return (
    <>
      {" "}
      <div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/honoursawards"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
      <ToastContainer />
      <div className="">
        <div className="row">
          <div className="col-md-12 col-lg-5 col-12">
            <div className="mainheader"> Edit Honours & Awards</div>
            {/* <div className="greyfont">New Honours & Awards received recently</div> */}
          </div>

          <div className="col-md-12 col-lg-7 col-12">
            <div className="float-end">
              {/* <Link to="/faculty/secondsidebar/proficiency/honoursawards" className="link"> */}
              <span className="bacdept cursorPointer labelinput">
                <span
                  onClick={handleBackButtonClick}
                  data-bs-toggle="modal"
                  data-bs-target="#myModal"
                >
                  <img src={back} className="img-fluid me-2 " />
                  Back to Honours & Awards
                </span>
                <button
                  className=" ms-3 saveBtn"
                  style={{ color: "white" }}
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className=" ms-3 addnewbtn"
                  style={{ color: "white" }}
                  onClick={handleSubmit}
                >
                  Save & Submit
                </button>
              </span>
              {/* </Link> */}
            </div>
          </div>
        </div>
        <form
          className="mt-2"
          style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
        >
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-2 mt-2">
              <label className="labelinput">Academic Year</label>
              <select
                className="form-select labelFont inputWidth"
                value={HonoursAwardsCreateForm.academicYear}
                onChange={handleChange}
                name="academicYear"
                style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
              >
                <option value="" disabled className="select">
                  --Select--
                </option>
                {yearList.map((item) => (
    <option key={item} value={item} style={{ color: "black", fontSize: "14px" }}>
      {item}
    </option>
  ))}
              </select>
            </div>
            {isSubmitted && !HonoursAwardsCreateForm.academicYear && (
              <div className="is-invalid">*Required</div>
            )}
          </div>

          <div className="mt-2">
            <label className="labelinput">Choose the type</label>
          </div>
          <div className="d-flex flex-wrap">
            <div className="form-check d-flex align-items-center me-4 labelinput">
              <input
                type="radio"
                className="form-check-input me-2 mb-1"
                name="type"
                value="Awards"
                checked={HonoursAwardsCreateForm.type === "Awards"}
                onChange={(e) => {
                  handleChange(e);
                  updateType(e);
                }}
              />
              <label className="form-check-label" htmlFor="Awards">
                Awards
              </label>
            </div>
            <div className="form-check d-flex align-items-center me-4 labelinput">
              <input
                type="radio"
                className="form-check-input me-2 mb-1"
                name="type"
                value="Honours"
                checked={HonoursAwardsCreateForm.type === "Honours"}
                onChange={(e) => {
                  handleChange(e);
                  updateType(e);
                }}
              />
              <label className="form-check-label" htmlFor="Honours">
                Honours
              </label>
            </div>
            <div className="form-check d-flex align-items-center me-4 labelinput">
              <input
                type="radio"
                className="form-check-input me-2 mb-1"
                name="type"
                value="Fellowships"
                checked={HonoursAwardsCreateForm.type === "Fellowships"}
                onChange={(e) => {
                  handleChange(e);
                  updateType(e);
                }}
              />
              <label className="form-check-label" htmlFor="Fellowships">
                Fellowships
              </label>
            </div>
            <div className="form-check d-flex align-items-center me-4 labelinput">
              <input
                type="radio"
                className="form-check-input me-2 mb-1"
                name="type"
                value="Memberships"
                checked={HonoursAwardsCreateForm.type === "Memberships"}
                onChange={(e) => {
                  handleChange(e);
                  updateType(e);
                }}
              />
              <label className="form-check-label" htmlFor="Memberships">
                Memberships
              </label>
            </div>
          </div>
          {isSubmitted && !HonoursAwardsCreateForm.type && (
            <div className="is-invalid">*Required</div>
          )}
        </form>
        {HonoursAwardsCreateForm.type === "Awards" && (
          <form style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}>
            <div className="row">
              <div className="col-sm-4 col-md-6 col-lg-4 mt-1">
                <label className="labelinput">Award Title</label>
                <input
                  type="text"
                  className="form-control labelinput test inputWidth"
                  placeholder="Enter Award Title"
                  value={HonoursAwardsCreateForm.awardTitle}
                  onChange={handleChange}
                  name="awardTitle"
                />
                {isSubmitted && !HonoursAwardsCreateForm.awardTitle && (
                  <div className="is-invalid">*Required</div>
                )}
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4 mt-1">
                <label className="labelinput">Award Presented by</label>
                <input
                  type="text"
                  className="form-control labelinput inputWidth"
                  placeholder="Presented by"
                  value={HonoursAwardsCreateForm.presentedBy}
                  onChange={handleChange}
                  name="presentedBy"
                />
                {isSubmitted && !HonoursAwardsCreateForm.presentedBy && (
                  <div className="is-invalid">*Required</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-10 col-lg-8 col-xl-4 mt-3">
                <label className="labelinput d-flex justify-content-between">
                  <span className="" style={{ color: "#1B1922" }}>
                    Proof Document
                  </span>{" "}
                  <span className="" style={{ color: "#7784A1" }}>
                    Only pdf/jpg file under 2MB
                  </span>
                </label>
                {/* <input
                  type={inputType}
                  className="form-control mt-2 labelFont"
                  value={
                    inputType === "text"
                      ? HonoursAwardsCreateForm.uploadFile
                      : undefined
                  }
                  onChange={(e) => {
                    base64ConverterFile(e);
                  }}
                  name="uploadFile"
                  onClick={handleClick}
                /> */}
                <div className="input-group">
  <label className="form-control boxText boxtextellipse browsetext">
  {fileVal !== "" ? fileVal : HonoursAwardsCreateForm.uploadFile }
    <input
      type="file"
      id="fileInput2"
      className="invisible"
      onChange={(e) => base64ConverterFile(e)} 
    />
  </label>
  <span className="input-group-text lname cursorPointer"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
</div>
                {/* {isSubmitted && !HonoursAwardsCreateForm.uploadFile && <div className="is-invalid">*Required</div>} */}
              </div>
            </div>
          </form>
        )}
        {HonoursAwardsCreateForm.type === "Honours" && (
          <form
            className=""
            style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
          >
            <div className="row">
              <div className="row">
                <div className="col-sm-4 col-md-6 col-lg-4 mt-1">
                  <label className="labelinput">Honour Title</label>
                  <input
                    type="text"
                    className="form-control labelinput test"
                    placeholder="Enter Honour Title"
                    value={HonoursAwardsCreateForm.honourTitle}
                    onChange={handleChange}
                    name="honourTitle"
                  />
                  {isSubmitted && !HonoursAwardsCreateForm.honourTitle && (
                    <div className="is-invalid">*Required</div>
                  )}
                </div>
                <div className="col-sm-4 col-md-6 col-lg-4 mt-1">
                  <label className="labelinput">Honour Presented by</label>
                  <input
                    type="text"
                    className="form-control labelinput"
                    placeholder="Presented by"
                    value={HonoursAwardsCreateForm.honourPresentedBy}
                    onChange={handleChange}
                    name="honourPresentedBy"
                  />
                  {isSubmitted &&
                    !HonoursAwardsCreateForm.honourPresentedBy && (
                      <div className="is-invalid">*Required</div>
                    )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-10 col-lg-8 col-xl-4">
                <label className="labelinput mt-2 d-flex justify-content-between">
                  <span className="" style={{ color: "#1B1922" }}>
                    Proof Document
                  </span>{" "}
                  <span className="" style={{ color: "#7784A1" }}>
                    Only pdf/jpg file under 2MB
                  </span>
                </label>
                <div className="input-group">
  <label className="form-control boxText boxtextellipse browsetext">
  {fileVal !== "" ? fileVal : HonoursAwardsCreateForm.uploadFile }
    <input
      type="file"
      id="fileInput2"
      className="invisible"
      onChange={(e) => base64ConverterFile(e)} 
    />
  </label>
  <span className="input-group-text lname cursorPointer"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
</div>
                {/* {isSubmitted && !HonoursAwardsCreateForm.uploadFile && <div className="is-invalid">*Required</div>} */}
              </div>
            </div>
          </form>
        )}
        {HonoursAwardsCreateForm.type === "Fellowships" && (
          <form
            className=""
            style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
          >
            <div className="row">
              <div className="col-sm-4 col-md-6 col-lg-4 mt-1">
                <label className="labelinput">Fellowship Title</label>
                <input
                  type="text"
                  className="form-control labelinput test"
                  placeholder="Enter Fellowship Title"
                  value={HonoursAwardsCreateForm.fellowTitle}
                  onChange={handleChange}
                  name="fellowTitle"
                />
                {isSubmitted && !HonoursAwardsCreateForm.fellowTitle && (
                  <div className="is-invalid">*Required</div>
                )}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 mt-1">
                <label className="labelinput">Fellowship Presented by</label>
                <input
                  type="text"
                  className="form-control labelinput"
                  placeholder="Presented by"
                  value={HonoursAwardsCreateForm.fellowPresentedBy}
                  onChange={handleChange}
                  name="fellowPresentedBy"
                />
                {isSubmitted && !HonoursAwardsCreateForm.fellowPresentedBy && (
                  <div className="is-invalid">*Required</div>
                )}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-6 col-md-10 col-lg-8 col-xl-4">
                <label className="labelinput mt-2 d-flex justify-content-between">
                  <span className="" style={{ color: "#1B1922" }}>
                    Proof Document
                  </span>{" "}
                  <span className="" style={{ color: "#7784A1" }}>
                    Only pdf/jpg file under 2MB
                  </span>
                </label>
                <div className="input-group">
  <label className="form-control boxText boxtextellipse browsetext">
  {fileVal !== "" ? fileVal : HonoursAwardsCreateForm.uploadFile }
    <input
      type="file"
      id="fileInput2"
      className="invisible"
      onChange={(e) => base64ConverterFile(e)} 
    />
  </label>
  <span className="input-group-text lname cursorPointer"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
</div>
              </div>
              {/* {isSubmitted && !HonoursAwardsCreateForm.uploadFile && <div className="is-invalid">*Required</div>} */}
            </div>
          </form>
        )}
        {HonoursAwardsCreateForm.type === "Memberships" && (
          <form style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}>
            <div className="row">
              <div className="col-sm-4 col-md-5 col-lg-4 mt-1">
                <label className="labelinput">
                  Professional Societies/Bodies
                </label>
                <input
                  type="text"
                  placeholder="Enter society or body name"
                  className="form-control lableinput"
                  value={HonoursAwardsCreateForm.organisationName}
                  onChange={handleChange}
                  name="organisationName"
                />
                {isSubmitted && !HonoursAwardsCreateForm.organisationName && (
                  <div className="is-invalid">*Required</div>
                )}
              </div>

              <div className="col-sm-4 col-md-5 col-lg-4 mt-1">
                <label className="labelinput">Role</label>
                <input
                  type="text"
                  placeholder="Enter Role"
                  className="form-control lableinput"
                  value={HonoursAwardsCreateForm.organisationRole}
                  onChange={handleChange}
                  name="organisationRole"
                />
                {isSubmitted && !HonoursAwardsCreateForm.organisationRole && (
                  <div className="is-invalid">*Required</div>
                )}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-4 col-md-10 col-lg-4  mt-1">
                <label className="labelinput">Current Status</label>
                <div className="">
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="currentStatus"
                      value="Active"
                      checked={
                        HonoursAwardsCreateForm.currentStatus === "Active"
                      }
                      onChange={handleChange}
                    />
                    <label className="form-check-label">Active</label>
                  </div>
                  <div className="form-check form-check-inline labelinput">
                    <input
                      type="radio"
                      className="form-check-input me-2 labelinput"
                      name="currentStatus"
                      value="Inactive"
                      checked={
                        HonoursAwardsCreateForm.currentStatus === "Inactive"
                      }
                      onChange={handleChange}
                    />
                    <label className="form-check-label">Inactive</label>
                  </div>
                  {isSubmitted && !HonoursAwardsCreateForm.currentStatus && (
                    <div className="is-invalid">*Required</div>
                  )}
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2 mt-1">
              <div style={{position:'relative'}}>
                <label htmlFor="memberSince" className="labelinput">
                  Member Since
                </label>
                <input
                  type="date"
                  className="form-control labelinput"
                  id="memberSince"
                  value={HonoursAwardsCreateForm.memberSince}
                  max={today}
                  onChange={(e) => {
                    handleChange(e);
                    handleMemberSinceChange(e);
                  }}
                  name="memberSince"
                  autoComplete="off"
                />
                <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
                </div>
                {isSubmitted && !HonoursAwardsCreateForm.memberSince && (
                  <div className="text-danger">*Required</div>
                )}
              </div>

              <div className="col-sm-4 col-md-4 col-lg-2 mt-1">
              <div style={{position:'relative'}}>
                <label htmlFor="memberTill" className="labelinput">
                  Member Till
                </label>
                <input
                  type="date"
                  className="form-control labelinput"
                  id="memberTill"
                  value={HonoursAwardsCreateForm.memberTill}
                  onChange={handleChange}
                  name="memberTill"
                  min={HonoursAwardsCreateForm.memberSince}
                  autoComplete="off"
                />
                <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
                </div>
                {isSubmitted &&
                  (!HonoursAwardsCreateForm.memberTill ||
                    HonoursAwardsCreateForm.memberTill <
                      HonoursAwardsCreateForm.memberSince) && (
                    <div className="text-danger">*Required</div>
                  )}
              </div>
              <div className="row mt-2">
                <div className="col-sm-6 col-md-10 col-lg-8 col-xl-4">
                  <label className="labelinput mt-1 d-flex justify-content-between">
                    <span className="" style={{ color: "#1B1922" }}>
                      Proof Document
                    </span>{" "}
                    <span className="" style={{ color: "#7784A1" }}>
                      Only pdf/jpg file under 2MB
                    </span>
                  </label>
                  <div className="input-group">
  <label className="form-control boxText boxtextellipse browsetext">
  {fileVal !== "" ? fileVal : HonoursAwardsCreateForm.uploadFile }
    <input
      type="file"
      id="fileInput2"
      className="invisible"
      onChange={(e) => base64ConverterFile(e)} 
    />
  </label>
  <span className="input-group-text lname cursorPointer"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
</div>
                </div>
                {/* {isSubmitted && !HonoursAwardsCreateForm.uploadFile && <div className="is-invalid">*Required</div>} */}
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default HonoursAwardsEdit;
