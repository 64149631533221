
import React, { useEffect, useState } from "react";
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import DynamicTables from "../../../../components/DynamicTable/DynamicTables";
import './Publications.css'
import { Link, Outlet, useNavigate } from "react-router-dom";
import { PubliCationGetTable, PubliCationTableData, DeleteTabledata, fileDownload, TableSubmitCall, host } from '../../../../ApiServices';
import CourseTable from "../../../../components/CourseDetailTable/CourseTable";
import { MdCancel } from "react-icons/md";
import searchIcon from "../../../../assets/Icon search.svg"
import { ToastContainer, toast } from "react-toastify";
import { format as formatDate } from "date-fns";
import { date } from "yup";
import close from "../../../../assets/close.svg"
import noRecord from "../../../../assets/norecords.png"
import Skeleton from "react-loading-skeleton";
import { SkeletonLoader } from "../../../skeletonLoader";
import { NoData } from "../../../NoData";

type Props = {}

const Publications = (props: Props) => {
  const columnRedirectsOnlineCourse = {
    courseTitle: "/proficiency/responsibilities/newResponsiblities/new-add",
  };
  const navigate = useNavigate();
  const [tableDatas, settableDatas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [TotalElements, setTotalElements] = useState(0)
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalpages, settotalpages] = useState(0);
  const [sort, setSort] = useState(false);
  const [sortusing, setSortUsing] = useState("pubId")
  const [selectedItem, setselectedItem] = useState<any>([])
  const [viewSingle, setViewSingle] = useState(false)
  const [viewTitle, setViewTitle] = useState();
  const [pubIdView, setPubIdView] = useState();
  const [pubImageUrl, setPubImageUrl] = useState();
  const [submittedOn, setSubmittedOn] = useState<string>();
  const [createdOn, setCreatedOn] = useState<string>('');
  const [updatedOn, setUpdatedOn] = useState<string>('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [getData, setGetData] = useState();
  const [loader , setLoader] = useState(true)
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalpages))
    console.log("next button clicked and the value now is " + currentPage)
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const [searchValue, setSearchValue] = useState('');

  const searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const searchClose = () => {
    setSearchValue('');
  };


  const handleSort = () => {
    setSortUsing("academicYear")
    setSort(!sort);
  }

  const sortOrder = sort ? "asc" : "desc"

  const getDatas = () => {
    const data = {
      empID: sessionStorage.getItem('empId'),
      page: currentPage - 1,
      sort: sortOrder,
      sordId: sortusing,
      keyword: searchTerm,
    };

    PubliCationGetTable(data)
      .then((res) => {
        const formattedData = res.data.datas.content.map((item: any) => {
          let formattedCreatedDate = "";
          let formattedUpdatedDate = "";
          let formattedSubmittedOn = "";
          let formattedValidatedOn = "";

          if (item.createdOn) {
            const createdOnDate = new Date(item.createdOn);
            formattedCreatedDate = formatDate(createdOnDate, "dd MMM yyyy hh:mm a");
          }

          if (item.updatedOn) {
            const updatedOnDate = new Date(item.updatedOn);
            formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
          }

          if (item.submittedOn) {
            const submittedOnDate = new Date(item.submittedOn)
            formattedSubmittedOn = formatDate(submittedOnDate, "dd MMM yyyy hh:mm a")
          }

          if (item.validatedOn) {
            const validatedOnDate = new Date(item.validatedOn)
            formattedValidatedOn = formatDate(validatedOnDate, "dd MMM yyyy hh:mm a")
          }

          return {
            ...item,
            createdOn: formattedCreatedDate,
            updatedOn: formattedUpdatedDate,
            submittedOn: formattedSubmittedOn,
            validatedOn: formattedValidatedOn
          };
        });

        settableDatas(formattedData);
        setLoader(false)
        setGetData(res.data.data[0]);
        console.log(res.data.data[0], "val check")
        console.log(res.data.datas.content, "");
        setTotalElements(res.data.datas.totalElements);
        settotalpages(res.data.datas.totalPages);
        // setCreatedOn(formattedData.formattedCreatedDate)
        // console.log(createdon , "am created on data ")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Header = [
    { label: "Academic Year", key: "academicYear" },
    { label: "Title of the publication", key: "title" },
    { label: "Listed in", key: "listedIn" },
    { label: "Submitted on", key: "submittedOn" },
    { label: "Status", key: "status" },
    { label: "Validated on", key: "validatedOn" },
  ];

  const OnlineCourseViewHeaders = [
    // {
    //   "title": "",
    //   "key":"publicationPhoto"
    // },
    {
      "title": "Academic Year",
      "key": "academicYear"
    },
    {
      "title": "Listed in",
      "key": "listedIn"
    },
    {
      "title": "Title of the Publication",
      "key": "title"
    },
    {
      "title": "Name of the Journal",
      "key": "journalName"
    },
    {
      "title": "DOI",
      "key": "doi"
    },
    //  {
    //   "title": "h-index",
    //   "key":"hindex"
    // }, {
    //   "title": "i10-index",
    //   "key":"i10Index"
    // }, 
    {
      "title": "Impact Factor",
      "key":"impactFactor"
    },
    {
      "title": "Author Position",
      "key": "authorPosition"
    }, {
      "title": "Publication URL",
      "key": "publicationUrl"
    }, {
      "title": "Proof Document",
      "key": "uploadFile"
    }
  ]

  function editCourse(item: any) {
    console.log(item, "am toggled ")
    const data = item.pubId ? item.pubId : item.proficiency.pubId;
    console.log(data);
    navigate("editpublication/" + data)
  }

  function navigateView(item: any) {
    PubliCationTableData(item.pubId).then((res: any) => {
      setselectedItem(res.data.data[0]);
      setViewTitle(res.data.data[0].proficiency.title)
      setPubIdView(res.data.data[0].proficiency.pubId)
      setPubImageUrl(res.data.data[0].proficiency.publicationPhoto)
      setViewSingle(true)

      const formattedCreatedDate = new Date(res.data.data[0].createdOn);
      const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");

      let updatedOn = "";
      if (res.data.data[0].updatedOn !== null) {
        const formattedUpdatedDate = new Date(res.data.data[0].updatedOn);
        updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
      }
      setCreatedOn(createdOn);
      setUpdatedOn(updatedOn);
      // setViewSingle(true)
    }).catch((err: any) => console.log(err))
  }

  const deleteApi = (data: any) => {

    const url = "/publication?pubId=" + data.pubId;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);
        toast.success(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }
  const deleteApiView = () => {
    const url = "/publication?pubId=" + pubIdView;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);
        setViewSingle(false)
        toast.success(res.data.status);
        // alert(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }
  const FileDownload = () => {
    const url = host + "proficiency/get/publication?pubId=" + pubIdView + "&keyword=file";
    fileDownload(url)
      .then((res) => {
        toast.success(res.data.status);
        window.open(url, '_blank');
        getDatas();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const toggleSearch = () => {
    setIsSearching(!isSearching);
    setSearchTerm("");
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setSearchTerm('');
    }
  };
  const handleCancel = () => {
    setSearchTerm('');
    setIsSearchOpen(false);
  };


  useEffect(() => {
    console.log(selectedItem, "ehfhi");
  }, [selectedItem])
  const backtoview = " to Publications"
  const deleteCont = "Publication"
  const heading = viewTitle
  const subheading = "View details of the research published"

  const TableSubmit = (item: any) => {
    console.log("tablesumbit toggled ")
    const payload = {
      empId: sessionStorage.getItem('empId'),
      sectionId: item.pubId,
      status: "Pending",
      section: "Publications",
      academicYear: item.academicYear
    }

    console.log(payload, "hkjgkjgh");

    TableSubmitCall(payload)
      .then((res) => {
        console.log(res)
        setViewSingle(false);
        getDatas()
        toast.success(res.data.responseMessage)
        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000);
      })
      .catch((err) => {
        console.log(err)
      })

  }

  useEffect(() => {
    getDatas();
  }, [currentPage, sort, searchTerm]);

  const moduleName = "Publication"
  const AddNewPath = "/faculty/secondsidebar/proficiency/publications/publicationcreate"

  return (
    
    <>
      <ToastContainer/>
      {!viewSingle?
      <>
      <div className="">
        <div className="row">
          <div className="col-md-12 col-lg-7 col-12">
            <div className="mainheader">Publications</div>
            <div>
              <div className="greyfont mt-2">Research publications published in the name of KEC (SCI/SCOPUS/UGC listed)</div>
            </div>
          </div>
          
         {getData &&
         <div className="col-md-12 col-lg-5 col-12  AddNewBtnDiv">
         <div className="float-end">
           <Link to="/faculty/secondsidebar/proficiency/publications/publicationcreate" className="link">
             <span className='bacdept cursorPointer'>
               <button className=" ms-3 addnewbtn " style={{ color: "white" }} >Add New</button>
             </span>
           </Link>
         </div>
       </div>
          }
            
         
        </div>
        <div className="">
          {getData && (
            <div className="addresult mt-4">
              <span className="active tablink pb-1 bg addresult mb-3">All Results</span>
              <div className={`${isSearchOpen ? 'me-0' : 'me-5'} search`} style={{ boxShadow: 'none' }}>
                <img
                  alt="search"
                  className={`${isSearchOpen ? 'ms-0' : 'ms-3'} fasearch mb-2`}
                  src={searchIcon}
                  onClick={toggleSearch}
                  style={{ cursor: 'pointer' }}
                />
                {isSearchOpen && (
                  <div className="search-input-container ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                    />
                    <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }} />
                  </div>
                )}
              </div>
            </div>
           )} 
        </div>

      </div>
      {loader ?
      <SkeletonLoader/> : 
      getData? 
        <DynamicTables data={tableDatas} headers={Header} currentPage={currentPage}
      pages={totalpages} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage}
      TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} navigateView={navigateView} deleteApi={deleteApi} editCourse={editCourse} TableSubmit={TableSubmit} />
    : 
    <NoData name={moduleName} path={AddNewPath}/>
      }
      </> 
      :
         <CourseTable data={[selectedItem]} deleteCont={deleteCont} backtoview={backtoview}  back={()=>{setViewSingle(!viewSingle)}} headers={OnlineCourseViewHeaders}
    heading = {heading}
    pubIdView = {pubIdView}
    subheading = {subheading}
    createdOn = {createdOn}   
    updatedOn = {updatedOn}
    deleteApiView = {deleteApiView}
    FileDownload ={FileDownload} 
    editCourse={editCourse}
    TableSubmit={TableSubmit}
    // createdOn={createdon}
    />
    }
      
    </>
  )
}

export default Publications