import React from 'react'
import searchIcon from "../../../../assets/Icon search.svg";
import "../../../FacultyModule/Proficiency/OnlineCourse/OnlineCourse.css";
import { Link, Outlet ,useNavigate } from "react-router-dom";
import cross from "../../../../assets/crossimg.png";
import { useState , useEffect } from 'react';
import DynamicTables from '../../../../components/DynamicTable/DynamicTables';
import {BooksAndChapterGetTable ,BooksAndChapterGetTableData, fileDownload,DeleteTabledata,TableSubmitCall,host} from "../../../../ApiServices"
import CourseTable from '../../../../components/CourseDetailTable/CourseTable';
import { ToastContainer, toast } from "react-toastify";
import { format as formatDate } from "date-fns";
import close from "../../../../assets/close.svg";
import noRecord from "../../../../assets/norecords.png"
import { SkeletonLoader } from "../../../skeletonLoader";
import { NoData } from "../../../NoData";

function BooksChapterEdit() {
  const navigate = useNavigate();
  const columnRedirectsOnlineCourse = {
    courseTitle:"/proficiency/responsibilities/newResponsiblities/new-add",
  };

  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage,setCurrentPage] = useState(1)
  const [TotalElements , setTotalElements] = useState(0)
  const [totalpages,settotalpages] = useState(0);
  const [sort , setSort] = useState(false);
  const [sortusing,setSortUsing]= useState("bacId")
  const [viewSingle, setViewSingle] = useState(false)
  const [selectedItem, setselectedItem] = useState<any>([])
  const [viewTitle,setViewTitle] = useState();
  const [pubIdView,setPubIdView] = useState();
  const [createdOn , setCreatedOn] = useState<string>('');
  const [updatedOn , setUpdatedOn] = useState<string>('');
  const heading = viewTitle
  const subheading = "View details of the book published"
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [getData, setGetData] = useState();
  const [loader , setLoader] = useState(true)
    
  const toggleSearch = () => {
setIsSearching(!isSearching);
setSearchTerm("");
setIsSearchOpen(!isSearchOpen);
if (!isSearchOpen) {
  setSearchTerm('');
}
};
const handleCancel = () => {
setSearchTerm('');
setIsSearchOpen(false);
};

  const [tableDatas, settableDatas] = useState([]);

  const handleNextPage=()=>{
    setCurrentPage((prevPage)=>Math.min(prevPage+1,totalpages))
    console.log("next button clicked and the value now is "+ currentPage)
    }
  
   const handlePrevPage=()=>{
   setCurrentPage((prevPage)=>Math.max(prevPage-1,1))
   }
  
   const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
     setCurrentPage(value);
   };
   
 const handleSort =()=>{
  setSortUsing("academicYear")
  setSort(!sort);
}

const sortOrder = sort? "asc":"desc"

  const getDatas = () => {
    const data ={
      empID:sessionStorage.getItem('empId'),
      page:currentPage-1,
      sort:sortOrder,
      sordId:sortusing,
      keyword:searchTerm,
    }

    BooksAndChapterGetTable(data)
      .then((res) => {
        const formattedData = res.data.datas.content.map((item: any) => {
          let formattedCreatedDate = "";
          let formattedUpdatedDate = "";
          let formattedSubmittedOn = "";
          let formattedValidatedOn = "";  
        
          if (item.createdOn) {
            const createdOnDate = new Date(item.createdOn);
            formattedCreatedDate = formatDate(createdOnDate, "dd MMM yyyy hh:mm a");
          }
        
          if (item.updatedOn) {
            const updatedOnDate = new Date(item.updatedOn);
            formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
          }

          if (item.submittedOn) {
            const submittedOnDate = new Date(item.submittedOn);
            formattedSubmittedOn = formatDate(submittedOnDate, "dd MMM yyyy hh:mm a");
          }

          if(item.validatedOn){
            const validatedOnDate = new Date(item.validatedOn)
            formattedValidatedOn = formatDate(validatedOnDate, "dd MMM yyyy hh:mm a")
          }

        
          return {
            ...item,
            createdOn: formattedCreatedDate,
            updatedOn: formattedUpdatedDate,
            submittedOn:formattedSubmittedOn,
            validatedOn:formattedValidatedOn
          };
        });

        settableDatas(formattedData);
        setLoader(false)
        console.log(res.data.datas.content, "gopi");
        setGetData(res.data.data[0]);
        setTotalElements(res.data.datas.totalElements)
        settotalpages(res.data.datas.totalPages)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Header = [
    { label: "Academic Year", key: "academicYear" },
    { label: "Title of the book/chapter", key: "titleOfBook" },
    { label: "Type", key:"type"},
    { label: "Submitted on", key:"submittedOn"},
    { label: "Status", key: "status" },
    { label: "Validated on", key: "validatedOn" },
  ];

  const BooksChapterEditsViewHeaders=[
    {
      "title": "Academic Year",
      "key":"academicYear"
    },
    {
      "title": "Type",
      "key":"type"
    },
    {
      "title": "Title of the book/chapter",
      "key":"titleOfBook"
    },
    {
      "title": "Authors",
      "key":"authors"
    },
    {
      "title": "Publisher",
      "key":"publisher"
    },
    {
      "title": "Year of Publication",
      "key":"yearOfPublications"
    },
    {
      "title": "Author Position",
      "key":"authorPosition"
    },
    {
      "title": "ISBN Number",
      "key":"isbnNo"
    },
    {
      "title": "Proof Document",
      "key":"uploadFile"
    },

  ]

  function navigateView(item: any){
    BooksAndChapterGetTableData(item.bacId).then((res:any)=>{
      setselectedItem(res.data.data[0]);
      console.log(res.data.data[0], "selectedItem")
      setViewTitle(res.data.data[0].proficiency.titleOfBook);
      setPubIdView(res.data.data[0].proficiency.bacId);
      const formattedCreatedDate = new Date(res.data.data[0].proficiency.createdOn);
      const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");
      let updatedOn = "";
      if (res.data.data[0].proficiency.updatedOn !== null) {
          const formattedUpdatedDate = new Date(res.data.data[0].proficiency.updatedOn);
          updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
      }         
      setCreatedOn(createdOn);
      setUpdatedOn(updatedOn);
      setViewSingle(true)
    }).catch((err:any)=>console.log(err))
  }

  function editCourse(item:any){
    console.log(item,"ssbiki")
    const data = item.bacId?item.bacId:item.proficiency.bacId;
    console.log(data);
    navigate("editBooksAndChapter/"+data)
  }

  const deleteApi=(data: any) =>{
    const url = "/booksAndChapter?bacId=" + data.bacId;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);
        toast.success(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }

  const deleteApiView=() =>{
    const url = "/booksAndChapter?bacId="+ pubIdView;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);        
        toast.success(res.data.status);
        setViewSingle(false) 
        // alert(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }


 const backtoview =" to Books & Chapters"
 const deleteCont = "book/chapter"
 const FileDownload = () => {
  const url = host+"proficiency/get/booksAndChapter?bacId=" + pubIdView + "&keyword=file";
  fileDownload(url)
    .then((res) => {             
      toast.success(res.data.status);
      window.open(url, '_blank');
      getDatas();
    })
    .catch((err) => {
      console.log(err);
    });
}

const TableSubmit =(item:any)=>{
  console.log("tablesumbit toggled ")
  const payload = {
    empId:sessionStorage.getItem('empId'),
    sectionId:item.bacId,
    status :"Pending",
    section:"Books & Chapters",
   academicYear:item.academicYear
}
console.log(payload,"hkjgkjgh");

TableSubmitCall(payload)
.then((res)=>{
  console.log(res)
  getDatas()
  toast.success(res.data.responseMessage)
})
.catch((err)=>{
  console.log(err)
})
console.log(payload , "am the payload")
// window.location.reload()
setViewSingle(false)
}

useEffect(() => {
  getDatas();
}, [currentPage,sort,searchTerm]);

const moduleName = "Books & Chapters"
const AddNewPath =  "/faculty/secondsidebar/proficiency/bookschapters/newBooksAndChapter"

  return (
    <>
        <ToastContainer/>
    {
      !viewSingle?
      <div>
      <div className="mainheader">
      Books & Chapters
    </div>
    <div className="d-flex justify-content-between greytext">
      <span>List of Books & Chapters Published.</span>
      {getData === true && (
      <Link to="/faculty/secondsidebar/proficiency/bookschapters/newBooksAndChapter">
        <button className="text-white addnewbtn">Add New</button>
      </Link>
      )}
    </div>
    {getData === true && (
    <div className="addresult">
    <span className="active tablink pb-1 bg addresult mb-3">All Results</span>
    <div className={`${isSearchOpen ? 'me-0' : 'me-5'} search`} style={{boxShadow:'none'}}>
  <img
    alt="search"
    // className="fasearch mb-2"
    className={`${isSearchOpen ? 'ms-0' : 'ms-3'} fasearch mb-2`}
    src={searchIcon}
    onClick={toggleSearch}
    style={{cursor:'pointer'}}
  />
  {isSearchOpen && (
    <div className="search-input-container">
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
      />
      <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }}/>
    </div>
  )}
</div>
    </div>
    )}
    {/* {getData === false ? (
                    <div className="d-flex flex-column align-items-center" style={{ marginTop: "200px" }}>
                      <img src={noRecord} alt="No records" />
                      <p className="mt-4 greyfont" style={{ textAlign: 'center' }}>
                        You haven't added any books & chapters yet.<br />Start by adding a book / chapter.
                      </p>
                      <Link to="/faculty/secondsidebar/proficiency/bookschapters/newBooksAndChapter">
                        <button className="text-white addnewbtn">Add New</button>
                      </Link>
                    </div>
                  ) : (
    <DynamicTables currentPage={currentPage} pages={totalpages} data={tableDatas} headers={Header} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} navigateView={navigateView} deleteApi={deleteApi} editCourse={editCourse} TableSubmit={TableSubmit}/>
                  )} */}

                  {
                    loader ?
                    <SkeletonLoader/> :
                    getData ?
                    <DynamicTables currentPage={currentPage} pages={totalpages} data={tableDatas} headers={Header} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} navigateView={navigateView} deleteApi={deleteApi} editCourse={editCourse} TableSubmit={TableSubmit}/>
                    :
                    <NoData name={moduleName} path={AddNewPath}/>
                  }
    </div>
    
    :
  <>

  <CourseTable data={[selectedItem]} deleteCont={deleteCont} backtoview={backtoview} back={()=>{setViewSingle(!viewSingle)}} headers={BooksChapterEditsViewHeaders} deleteApiView = {deleteApiView}
  heading = {heading} FileDownload = {FileDownload}
  subheading = {subheading}
  createdOn = {createdOn}
  updatedOn = {updatedOn}
  pubIdView={pubIdView}
  TableSubmit={TableSubmit}
  editCourse={editCourse}
  />
  </>
    }
    </>
  
  )
}

export default BooksChapterEdit;
