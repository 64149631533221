
import { Outlet } from "react-router-dom";

function Academics() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default Academics;
