import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Login from "./pages/Login/Login";
import Profiency from "./pages/FacultyModule/Proficiency/Profiency";
import Academics from "./pages/FacultyModule/Academics/Academics";
import SecondarySideBarWeb, {
  SecondarySideBarMobile,
} from "./components/SecondarySidebar/SecondarySidebar";
import Account from "./pages/FacultyModule/Account/Accout";
import Report from "./pages/FacultyModule/Report/Report";
import Collections from "./pages/FacultyModule/Collections/Collections";
import Students from "./pages/FacultyModule/Collections/Students/Students";
import Employees from "./pages/FacultyModule/Collections/Employees/Employees";
import StudyMaterials from "./pages/FacultyModule/Academics/StudyMaterials/StudyMaterials";
import PrevyearsQA from "./pages/FacultyModule/Academics/PrevyearsQA/PrevyearsQA";
import Publications from "./pages/FacultyModule/Proficiency/Publications/Publications";
import Patents from "./pages/FacultyModule/Proficiency/Patents/Patents";
import Home from "./pages/FacultyModule/Home/Home";
import Honours from "./pages/FacultyModule/Proficiency/Honours/Honours";
import Trainings from "./pages/FacultyModule/Proficiency/Trainings/Trainings";
import Guidance from "./pages/FacultyModule/Proficiency/Guidance/Guidance";
import SponsoredResponse from "./pages/FacultyModule/Proficiency/SponsoredResearch/SponsoredResponse";
import OnlineCourse from "./pages/FacultyModule/Proficiency/OnlineCourse/OnlineCourse";
import Responsisbilities from "./pages/FacultyModule/Proficiency/Responsibilities/Responsisbilities";
import BooksChapter from "./pages/FacultyModule/Proficiency/BooksChapter/BooksChapter";
import Consultancy from "./pages/FacultyModule/Proficiency/Consultancy/Consultancy";
import Appraisals from "./pages/FacultyModule/Proficiency/Appraisals/Appraisals";
import Performances from "./pages/FacultyModule/Proficiency/Performance/Performance";
import NewOnlineCourse from "./pages/FacultyModule/Proficiency/OnlineCourse/AddNew/AddNew";
import { OnlineClass } from "./pages/FacultyModule/Proficiency/OnlineCourse/OnlineClass";
import CourseTable from "./components/CourseDetailTable/CourseTable";
import AddNew from "./pages/FacultyModule/Proficiency/Publications/AddNew/AddNew";
import PublicationCreate from "./pages/FacultyModule/Proficiency/Publications/AddNew/AddNew";
import PatentsCreate from "./pages/FacultyModule/Proficiency/Patents/AddNew/AddNew";
import HonoursAwardsCreate from "./pages/FacultyModule/Proficiency/Honours/AddNew/AddNew";
import TrainingsCreate from "./pages/FacultyModule/Proficiency/Trainings/AddNew/AddNew";
import EmployeeCreate from "./pages/AdminModule/Employees/CreateEmployee/CreateEmployee";
import GuidanceCreate from "./pages/FacultyModule/Proficiency/Guidance/AddNew/AddNew";
import SponsoredResearchCreate from "./pages/FacultyModule/Proficiency/SponsoredResearch/AddNew/AddNew";
import UpdatePassword from "./pages/UpdatePassword/UpdatePassword";
import PDFForm from "./pages/PdfService/PdfService";
import ResponsibilitiesEmp from "./pages/FacultyModule/Proficiency/Responsibilities/ResponsibilitiesEmp";
import AddNewResponsibilities from "./pages/FacultyModule/Proficiency/Responsibilities/AddNewResponsibilities/AddNewResponsibilities";
import BooksChapterEmp from "./pages/FacultyModule/Proficiency/BooksChapter/BooksChapterEmp"
import ConsultancyEmp from "./pages/FacultyModule/Proficiency/Consultancy/ConsultancyEmp";
import AddNewBooks from "./pages/FacultyModule/Proficiency/BooksChapter/AddNewBooks/AddNewBooks";
import AddConsultancy from "./pages/FacultyModule/Proficiency/Consultancy/AddConsultancy/AddConsultancy"
import AddNewPerformance from "./pages/FacultyModule/Proficiency/Performance/AddNewPerformance/AddNewPerformance";
import PerformanceEmp from "./pages/FacultyModule/Proficiency/Performance/PerformanceEmp";
import ViewEmployee from "./pages/AdminModule/Employees/ViewEmployees/ViewEmployees";
import EditOnlineCourse from "./pages/FacultyModule/Proficiency/OnlineCourse/EditCourse/EditCourse";
import EditNewResponsibilities from "./pages/FacultyModule/Proficiency/Responsibilities/EditResponsibilities/EditResponsibilities";
import BooksChapterEdit from "./pages/FacultyModule/Proficiency/BooksChapter/BooksChapter";
import EditBooks from "./pages/FacultyModule/Proficiency/BooksChapter/EditBooks/EditBooks";
import EditEmployee from "./pages/FacultyModule/Collections/Employees/EditEmployee/EditEmployee";
import EditConsultancy from "./pages/FacultyModule/Proficiency/Consultancy/EditConsultancy/EditConsultancy";
import EditPerformance from "./pages/FacultyModule/Proficiency/Performance/EditPerformance/EditPerformance";
import PatentsEdit from "./pages/FacultyModule/Proficiency/Patents/EditPatents/EditPatents";
import HonoursEdit from "./pages/FacultyModule/Proficiency/Honours/Honours";
import TrainingsEdit from "./pages/FacultyModule/Proficiency/Trainings/EditTrainings/EditTrainings";
import { PatentSEmp } from "./pages/FacultyModule/Proficiency/Patents/PatentsEmp";
import EditPublication from "./pages/FacultyModule/Proficiency/Publications/EditPublication";
import { PublicationsEmp } from "./pages/FacultyModule/Proficiency/Publications/PublicationsEmp";
import { GuidanceEmp } from "./pages/FacultyModule/Proficiency/Guidance/GuidanceEmp";
import EditGuidance from "./pages/FacultyModule/Proficiency/Guidance/EditGuidance";
import EditSponsoredResearch from "./pages/FacultyModule/Proficiency/SponsoredResearch/EditSponsoredResearch";
import { SponsoredResearchEmp } from "./pages/FacultyModule/Proficiency/SponsoredResearch/SponsoredResearchEmp";
// import { EmployeeEmp } from "./pages/FacultyModule/Collections/Employees/Employeeemp";
import { HonoursEmp } from "./pages/FacultyModule/Proficiency/Honours/HonoursEmp";
import HonoursAwardsEdit from "./pages/FacultyModule/Proficiency/Honours/EditHonours/EditHonours";
import { TrainingEmp } from "./pages/FacultyModule/Proficiency/Trainings/TrainingEmp";
import { EmployeeEmp } from "./pages/FacultyModule/Collections/Employees/EmployeeEmp";
import PrivateRoute from "./pages/PrivateRoute";
import AppraisalsView from "./pages/FacultyModule/Proficiency/Appraisals/AppraisalsView";
import AppraisalsLayout from "./pages/FacultyModule/Proficiency/Appraisals/AppraisalsLayout";
import Admin from "../src/pages/AdminModule/Admin/Admin"
import { ToastContainer } from 'react-toastify';
import Department from "./pages/AdminModule/Admin/Department/Department";

import AcademicYear from "../src/pages/AdminModule/Admin/AcademicYear/AcademicYear"
import NewDepartment from "./pages/AdminModule/Admin/Department/CreateDepartment/CreateDepartment";
import ViewSingleEmployee from "./pages/ViewSingleEmployee/ViewSingleEmployee";
import { ViewProficiancy } from "./pages/AdminModule/Employees/ViewProficiancy/ViewProficiancy";
import ProficiencyValidation from "./pages/AdminModule/Admin/ProficiencyValidation/ProficiencyValidation";

import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import EmployeeBio from "./pages/AdminModule/Employees/EmployeeBio/EmployeeBio";



const App: React.FC = () => {
  const isAuthenticated = true; 
   return (
    <>
    {/* <PrivateRoute
    path="/faculty"
    isAuthenticated={isAuthenticated}
    element={<Layout />}
/> */}
{/* <PrivateRoute
path="/faculty"
element={<Layout/>}
/> */}
{/* <Route path="/faculty" element={<PrivateRoute><Layout /></PrivateRoute>}></Route> */}
    <Routes>
      {/* <Route path="forgot" element={<ForgotPassword/>}/> */}
      <Route>
      <Route path="/" element={<Login/>} />
      </Route>
      
      
    
      <Route path="pdf" element={<PDFForm />} />
      <Route path="/" element={<Login/>} />
     
      <Route path="/faculty" element={ <PrivateRoute> <Layout/></PrivateRoute>}>
   
        <Route path="update" element={<UpdatePassword />} />
        <Route path="home" element={<Home />} />
        {/* </PrivateRoute> */}
        <Route path="secondSidebar" element={<SecondarySideBarWeb />}>
          
          {/* Academics Routes*/}
          <Route path="academics" element={<Academics />}>
            <Route path="studyMaterials" element={<StudyMaterials />} />
            <Route path="prevYearQA" element={<PrevyearsQA />} />
          </Route>
          {/* collections Routes */}  
          <Route path="collections" element={<Collections />}>
            <Route path="students" element={<Students />} />
            
            <Route path="employees" element={<EmployeeEmp />} >
           
            <Route path="viewsingleemployee/:employeeId" element={<ViewSingleEmployee/>}/>  
            {/* <Route path="employeebio" element={<EmployeeBio/>}/>   */}
            <Route path="employeebio/:employeeId" element={<EmployeeBio/>}/>  
            <Route path="viewproficiancy/:id" element={<ViewProficiancy/>} /> 
            <Route path="viewemployee/:id" element={<ViewEmployee />} />
            <Route path="" element={<Employees />} >
            </Route>
            <Route path="employeecreate" element={<EmployeeCreate />} />
            <Route path="editemploye/:id" element={<EditEmployee />} />
            </Route>
          </Route>

          {/* admin Routes */}
          <Route path ="admin" element={<Admin/>}>
            <Route path="department" element={<Department/>}>
            <Route path="newDepartment" element={<NewDepartment />} />
            </Route>

            <Route path="academicYear" element={<AcademicYear/>}></Route>
            <Route path="proficiencyValidation" element={<ProficiencyValidation/>}/>
          </Route>

          {/* Proficency Routes */}
          <Route path="proficiency" element={<PrivateRoute><Profiency /></PrivateRoute>}>


            <Route path="publications" element={<PublicationsEmp />}>
              <Route path="" element={<Publications />} />
              <Route path="publicationcreate" element={<PublicationCreate />} />
              <Route path="editpublication/:id" element={<EditPublication />} />
            </Route>
            <Route path="patents" element={<PatentSEmp />}>
            <Route path="" element={<Patents />} />
            <Route path="patentscreate" element={<PatentsCreate />} />
              <Route path="editPatents/:id" element={<PatentsEdit />} />
            </Route>

            <Route path="honoursawards" element={<HonoursEmp />}>
              <Route path="" element={<Honours />} />
              <Route path="honoursawardscreate" element={<HonoursAwardsCreate />} />
              <Route path="editHonours/:id" element={<HonoursAwardsEdit />} />
            </Route>

            <Route path="trainings" element={<TrainingEmp />}>
              <Route path="" element={<Trainings />} />
              <Route path="trainingscreate" element={<TrainingsCreate />} />
              <Route path="editTrainings/:id" element={<TrainingsEdit />} />
              </Route>
              {/* 
            <Route path="guidance" element={<GuidanceEmp/>}>
            <Route path="" element={<Guidance />} />

            </Route> */}

              <Route path="guidance" element={<GuidanceEmp />} >
              <Route path="" element={<Guidance />} />
              <Route path="guidancecreate" element={<GuidanceCreate />} />
              <Route path="editguidance/:id" element={<EditGuidance />} />
            </Route>

            <Route path="sponsoredresearch" element={<SponsoredResearchEmp />} >
              <Route path="" element={<SponsoredResponse />} />
              <Route path="sponsoredresearchcreate" element={<SponsoredResearchCreate />} />
              <Route path="editSponsoredResearch/:id" element={<EditSponsoredResearch />} />
            </Route>

            {/* <Route path="onlinecourses" element={<OnlineCourse />} /> */}
            <Route path="onlinecourses" element={<OnlineClass />}>
              <Route path="" element={<OnlineCourse />} />
              <Route path="newOnlineCourse" element={<NewOnlineCourse />} />
              <Route path="editOnlineCourse/:id" element={<EditOnlineCourse />} />
              {/* <Route path="courseDetailsTable/:id" element={<CourseTable/>}/> */}
            </Route>

            <Route path="responsibilities" element={<ResponsibilitiesEmp />}>
              <Route path="" element={<Responsisbilities />} />
              <Route path="newResponsiblities" element={<AddNewResponsibilities />} />
              <Route path="editResponsibilities/:id" element={<EditNewResponsibilities />} />
            </Route>

            <Route path="bookschapters" element={<BooksChapterEmp />}>
              <Route path="" element={<BooksChapter />} />
              <Route path="newBooksAndChapter" element={<AddNewBooks />} />
              <Route path="editBooksAndChapter/:id" element={<EditBooks />} />
            </Route>

            <Route path="consultancy" element={<ConsultancyEmp />}>
              <Route path="" element={<Consultancy />} />
              <Route path="newConsultancy" element={<AddConsultancy />} />
              <Route path="editConsultancy/:id" element={<EditConsultancy />} />
            </Route>

            <Route path="performance" element={<PerformanceEmp />}>
              <Route path="" element={<Performances />} />
              <Route path="newperformance" element={<AddNewPerformance />} />
              <Route path="editPerformance/:id" element={<EditPerformance />} />
            </Route>

            <Route path="appraisals" element={<AppraisalsLayout />}>
            <Route path="" element={<Appraisals />}/>
            <Route path ="appraisalsView/:id" element={<AppraisalsView/>}/>
            </Route>

            {/* <Route path="appraisals" element={<Appraisals />} /> */}
            {/* <Route path ="appraisalsView" element={<AppraisalsView/>}/> */}
          </Route>

          <Route path="account" element={<Account />} />
          <Route path="reports" element={<Report />} />
        </Route>
      </Route>
    </Routes>
    <ToastContainer />
    </>
  );
}

export default App;