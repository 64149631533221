import React, { useEffect,useState,useRef} from "react";
import DynamicTables from "../../../../components/DynamicTable/DynamicTables";
import {host, ProficiencyTableCall , ProficiencyValidationView} from "../../../../ApiServices"
import "../../Admin/ProficiencyValidation/ProficiencyValidation.css"
import close from "../../../../assets/close.svg"
import searchIcon from "../../../../assets/Icon search.svg"
import { format as formatDate } from "date-fns";
import CourseTable from "../../../../components/CourseDetailTable/CourseTable";
import {Outlet} from "react-router-dom"
import { Item } from "react-bootstrap/lib/Breadcrumb";
import { format, parseISO } from 'date-fns';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

type props = {
  handleSort?: () => void;  
};

const ProficiencyValidation=()=>{

    const [data , setData]= useState([])
    const [status,setStatus] = useState("Pending")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpages, settotalpages] = useState(0);
    const [TotalElements, setTotalElements] = useState(0);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isSearching, setIsSearching] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [viewSingle, setViewSingle] = useState(false)
    const [selectedItem, setselectedItem] = useState<any>([])
    const [viewTitle,setViewTitle] = useState();
    // const [viewType, setViewType] = useState<string>('');
    const [image , setImage] = useState("")
    const [imagePath , setImagePath] = useState("")
    const [selectedId , setSelectedId] = useState("")
    const [period,setperiod]  = useState<string>('');
    const [pubIdView,setPubIdView] = useState();
    const [sectionForView,setSectionForView]  = useState<string>('');
    const [loader,setLoader] = useState<any>(false)
    const [sorts , setSort] = useState("updated_on,desc")
   
    
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const heading = viewTitle

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalpages));
    console.log("next button clicked and the value now is " + currentPage);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleSort = (sortKey: any) => {
    console.log("triggered");
  
    if (sorts === "") {
      setSort(`${sortKey},asc`);
    }
    
    else if (sortKey === 'name') {
      if(sorts ===`${sortKey},asc`){
        setSort(`${sortKey},desc`);
      }else{
      setSort(`${sortKey},asc`);
      }
    } 
    
    else if (sortKey === 'department') {
      if(sorts ===`${sortKey},asc`){
        setSort(`${sortKey},desc`);
      }else{
      setSort(`${sortKey},asc`);
      }
    } 
    
    
    
    
    
    
    
    // else if (sorts === `${sortKey},desc`) {
    //   setSort(`${sortKey},asc`);
    // }
  };
    
 const PendingHeader = [
    { label: "Employee ID", key: "empId" },
    { label: "Name", key: "name" },
    { label: "Department", key: "department" },
    { label: "Section", key: "section" },
    { label: "Academic Year", key: "academicYear" },
    { label: "Last updated on", key: "updatedOn" },
  ];

  const RejectedHeader = [
    { label: "Employee ID", key: "empId" },
    { label: "Name", key: "name" },
    { label: "Department", key: "department" },
    { label: "Section", key: "section" },
    { label: "Academic Year", key: "academicYear" },
    { label: "Status", key: "status" },
    { label: "Received On", key: "receivedOn" },
    { label: "Actioned On", key: "actionedOn" },
  ];

  const Header = status === "Pending" ?  PendingHeader : RejectedHeader
  const sort = status === "Pending" ?  "name,desc" : "actioned_on,desc"

 const imagePathsss = "/Images/KECEMP21080004/Image(10).jpeg "

 const id = sessionStorage.getItem("empId")
 console.log(id ," am id ")

//  useEffect(()=>{
//   setLoader(true)
//  },[])

  useEffect(()=>{
    tableCall();
  },[status ,currentPage , searchTerm , imagePath,viewSingle , sorts ])
  const tableCall = () =>{
    const data = {
    status:status,
    page: currentPage - 1,
    keyword:searchTerm,
    sort:sorts,
    }
    ProficiencyTableCall(data)  
  
    .then((res:any)=>{
        console.log(res.data.datas.content , "")
        console.log(res, "dataa")

      const   formattedData = res.data.datas.content.map((item: any) => {
        // let formattedCreatedDate = "";
        let formattedUpdatedDate = "";
        let formattedReceivedOn = ""
        let formattedActionedOn = ""
      
        if (item.updatedOn) {
          const updatedOnDate = new Date(item.updatedOn);
          formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
        }

        if(item.receivedOn){
          const receivedOnDate = new Date(item.receivedOn)
          formattedReceivedOn = formatDate(receivedOnDate, "dd MMM yyyy hh:mm a")
        }

          if(item.actionedOn){
            const actionedOnDate = new Date(item.actionedOn)
            formattedActionedOn = formatDate(actionedOnDate, "dd MMM yyyy hh:mm a");
          }
        
          return {
            ...item,
            updatedOn:formattedUpdatedDate,
            receivedOn:formattedReceivedOn,
            actionedOn:formattedActionedOn
          };
        });

        // setData(res.data.datas.content)
        setData(formattedData)
        setTotalElements(res.data.datas.totalElements);
        settotalpages(res.data.datas.totalPages);   
        // setperiod(format(parseISO(res.data.data[0].proficiency.memberSince), 'dd MMM yyyy') + " to " + format(parseISO(res.data.data[0].proficiency.memberTill), 'dd MMM yyyy'))
        // fetchData()
        setTimeout(() => {
          setLoader(false)
        }, 200);
    })
  }

  useEffect(()=>{
    if(buttonRef.current){
 buttonRef.current.focus()
    }
  },[])

 const backtoview = status === "Pending" ? "Pending Requests" : "Closed Requests" 

//  useEffect(()=>{

//  },[])\

useEffect(() => {
    if (imagePath) {
           const fetchData = async () => {
            console.log("ghikhkihuki");
            
            const imageUrls = host+"employee/get/uploaded/file?fileName=" + imagePath;
            try {
                const response = await fetch(imageUrls);
                if (response.ok) {
                    setImage(imageUrls);
                }
            } catch (error) {
                console.error("Error making HTTP call:", error);
            }
        };
        fetchData();
    }
}, [imagePath]);

function navigateView(item:any){
  console.log(item ,"section ")
  const data = {
   sectionId :item.sectionId ,
   section: item.section
  }
  console.log(data , " am the dataaaaaaaaaaaaaaa")
  ProficiencyValidationView(data)
  .then((res:any)=>{
    setselectedItem(res.data.data[0])
    console.log(res.data.data[0] , "am the proficiencyVlidation single view")
    console.log(res.data.data[0].proficiency , "pubId")
    const idKey = Object.keys(res.data.data[0].proficiency).find(key=>key.endsWith('Id'))
    console.log(idKey ,"keyyyyy")
    // console.log(res.data.data[0].proficiency.Object.keys.find((key:any)=>key.endsWith('Id')),"valuesss")
    setSectionForView(res.data.data[0].validation?.section);
    setPubIdView(res.data.data[0].validation?.sectionId);
    if (idKey) {
      setSelectedId(res.data.data[0].proficiency[idKey]);
    }
    setViewSingle(true)
    fetchImage(res.data.data[0].validation.image)
    console.log(res.data.data[0] , "member")
    setperiod(format(parseISO(res.data.data[0].proficiency.memberSince), 'dd MMM yyyy') + " to " + format(parseISO(res.data.data[0].proficiency.memberTill), 'dd MMM yyyy'))
  })
  .catch((err:any)=>console.log(err))
}

const SelectedSection = selectedItem.validation?.section
console.log(SelectedSection,"Jeba");

const viewT = SelectedSection === 'Publications' ? selectedItem.proficiency?.title :
              SelectedSection === 'Online Courses' ? selectedItem.proficiency?.courseTitle :
              SelectedSection === 'Patents' ? selectedItem.proficiency?.patentTitle :
              SelectedSection === "Trainings" ? selectedItem.proficiency?.programTitle :
              SelectedSection === "Research Guidance" ? selectedItem.proficiency?.universityName :
              SelectedSection === "Responsibilities" ? selectedItem?.proficiency?.responsibilityName : 
              SelectedSection === "Books & Chapters" ? selectedItem?.proficiency?.titleOfBook :
              SelectedSection === "Honours & Awards" ? selectedItem?.proficiency?.type : selectedItem?.proficiency?.category;
const viewType = SelectedSection === 'Honours & Awards' ? selectedItem?.proficiency?.type : ''

console.log(viewT,"fjlfj")


const fetchData = async() =>{
  console.log(SelectedSection)
  // const url = SelectedSection ==="Publications"?"publication?pubId"||"Patents" ? "patent?patId"||"Trainings" ? "trainings?trainId"||"Research Guidance"?"guidance?guidId"||"Responsibilities" ? "responsibilities?resId"||"Books & Chapters"?"booksAndChapter?bacId"||"Honours & Awards"?"honours/awards?honId"||"Online Courses"?"onlineCourses?onId":"" ;

  const url = 
  SelectedSection === "Publications" ? "publication?pubId" :
  SelectedSection === "Patents" ? "patent?patId" :
  SelectedSection === "Trainings" ? "trainings?trainId" :
  SelectedSection === "Research Guidance" ? "guidance?guidId" :
  SelectedSection === "Responsibilities" ? "responsibilities?resId" :
  SelectedSection === "Books & Chapters" ? "booksAndChapter?bacId" :
  SelectedSection === "Honours & Awards" ? "honours/awards?honId" :
  SelectedSection === "Online Courses" ? "online/courses?coId" : 
  SelectedSection === "Sponsored Research" ? "sponsoredResearch?sprId" : 
  SelectedSection === "Consultancy" ? "consultancy?contId" : "";


  const imageUrls =host+`proficiency/get/${url}=${selectedId}&keyword=file`
  console.log(imageUrls,"urlsss")
   try{
    const response = await fetch(imageUrls)
    if(response.ok){
      //setImage(imageUrls)
      window.open(imageUrls, '_blank');
    }
   }catch(error){
    console.error("Error making HTTP call:", error);
   }
}


const fetchImage = async (data: string) => {
  const imageUrls = host+"employee/get/uploaded/file?fileName=" + data;
  try {
      const response = await fetch(imageUrls);
      if (response.ok) {
          setImage(imageUrls);
      }
  } catch (error) {
      console.error("Error making HTTP call:", error);
  }
};
const FileDownload = ()=>{
  console.log("toggled")
  fetchData()
}

const forceTableCall = () => {
  tableCall();
};


const toggleStatus = (item: any) => {
  setLoader(true);
  if (status === item) {
    forceTableCall();
  } else {
    setStatus(item);
    setCurrentPage(1);
  }
};

  
  const toggleSearch = () => {
    setIsSearching(!isSearching);
    setSearchTerm("");
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setSearchTerm('');
    }
    };
    const handleCancel = () => {
    setSearchTerm('');
    setIsSearchOpen(false);
    };

    const PublicationHeader=[
      {
        "title": "Academic Year",
        "key":"academicYear"
      },
      {
        "title": "Listed in",
        "key":"listedIn"
      },
      {
        "title": "Title of the Publication",
        "key":"title"
      }, 
      {
        "title": "Name of the Journal",
        "key":"journalName"
      },
      {
        "title": "DOI",
        "key":"doi"
      },  
      // {
      //   "title": "h-index",
      //   "key":"hindex"
      // }, 
      // {
      //   "title": "i10-index",
      //   "key":"i10Index"
      // }, 
      {
        "title": "Impact Factor",
        "key":"impactFactor"
      },
      {
        "title": "Author Position",
        "key":"authorPosition"
      }, {
        "title": "Publication URL",
        "key":"publicationUrl"
      }, {
        "title": "Proof Document",
        "key":"uploadFile"
      }
    ]

    const PatentViewHeaders=[
      {
        "title": "Academic Year",
        "key":"academicYear"
      },
      {
        "title": "File Number",
        "key":"fileNumber"
      }, {
        "title": "Title of the patent",
        "key":"patentTitle"
      }, {
        "title": "Agency Name",
        "key":"agencyName"
      }, {
        "title": "Status",
        "key":"patentStatus"
      }, {
        "title": "Year",
        "key":"year"
      },{
          "title": "Proof Document",
          "key":"uploadFile"
      }
    ]


 const TrainingViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  { title: "Type",
     key: "values"
  },{
    "title": "Title of the programme",
    "key":"programTitle"
  }, {
    "title": "Organization (AICTE/AU..)",
    "key":"organization"
  }, {
    "title": "Acted as",
    "key":"actedAs"
  }, {
    "title": "Duration",
    "key":"duration"
  }, {
    "title": "Period",
    "key":"period"
  }, {
    "title": "Proof Document",
    "key":"uploadFile"
  }
]

const GuidanceViewHeaders=[
  {
    title: "Academic Year",
    key:"academicYear",
  },
  {
    title: "Name of the University",
    key:"universityName"
  },
  {
    title: "No. of Past Scholars",
    key:"noOfScholarsPast"
  }, 
  ,
  {
    title: "No. of Present Scholars",
    key:"noOfScholars"
  }, 
  {
    title: "Proof Document",
    key:"uploadFile"
  }, 
]

const SponsoredViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  // , {
  //   "title": "Title",
  //   "key":"tittle"
  // },
  {
    "title": "Category",
    "key":"category"
  }, {
    "title": "Agency",
    "key":"agency"
  }, {
    "title": "Sanctioned Amount in Rupees",
    "key":"amountSanction"
  },  {
    "title": "Completion Percentage",
    "key":"completionPercentage"
  }, {
    "title": "Sanctioned Letter",
    "key":"fileUpload"
  },
]

const OnlineCourseViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  {
    "title": "Course Title",
    "key":"courseTitle"
  },
  {
    "title": "Awarding Institution",
    "key":"awardingInstitution"
  },
  {
    "title": "Grade or % of marks",
    "key":"grade"
  },
  {
    "title": "Course Completed on",
    "key":"completionOn"
  },
  {
    "title": "Course duration",
    "key":"courseDuration"
  }, 
   
  {
    "title": "Proof Document", 
    "key":"uploadFile"
  }
]

const ResponsibilitiesViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  {
    "title": "Name of the Responsibility",
    "key":"responsibilityName"
  }, {
    "title": "Role",
    "key":"role"
  }, 
  {
    "title": "Level",
    "key":"type"
  },
  {
    "title": "Duration",
    "key":"duration"
  }, {
    "title": "In-charge / HOD",
    "key":"inCharge"
  }, {
    "title": "Certificate, if any",
    "key":"uploadFile"
  }
]

const BooksChapterEditsViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  {
    "title": "Type",
    "key":"type"
  },
  {
    "title": "Title of the book/chapter",
    "key":"titleOfBook"
  },
  {
    "title": "Authors",
    "key":"authors"
  },
  {
    "title": "Publisher",
    "key":"publisher"
  },
  {
    "title": "Year of Publication",
    "key":"yearOfPublications"
  },
  {
    "title": "Author Position",
    "key":"authorPosition"
  },
  {
    "title": "ISBN Number",
    "key":"isbnNo"
  },
  {
    "title": "Proof Document",
    "key":"uploadFile"
  }
 ]

 const HonoursViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  {
    "title": "Type",
    "key":"type"
  },
]
if (viewType === "Awards") {
  HonoursViewHeaders.push(
    {
      "title": "Award Title",
      "key": "awardTitle"
    },
    {
      "title": "Award Presented by",
      "key": "presentedBy"
    },
    {
      "title": "Proof Document",
      "key": "uploadFile"
    }
  );
} else if(viewType === "Fellowships"){
  HonoursViewHeaders.push(
    {
      "title": "Fellowship Title",
      "key": "fellowTitle"
    },
    {
      "title": "Fellowship Awarded by",
      "key": "fellowPresentedBy"
    },
    {
      "title": "Proof Document",
      "key": "uploadFile"
    }
  );
}else if(viewType === "Honours"){
  HonoursViewHeaders.push(
    {
      "title": "Honours Title",
      "key": "honourTitle"
    },
    {
      "title": "Honours Presented by",
      "key": "honourPresentedBy"
    },
    {
      "title": "Proof Document",
      "key": "uploadFile"
    }
  );
}else if(viewType === "Memberships"){
  HonoursViewHeaders.push(
    {
      "title": "Organisation Name",
      "key": "organisationName"
    },
    {
      "title": "Role",
      "key": "organisationRole"
    },
    {
      "title": "Current Status",
      "key": "currentStatus"
    },
    {
     "title":"Tenure",
      "key" :"period"
    },
    {
      "title": "Proof Document",
      "key": "uploadFile"
    },
  );
}

const ConsultancyViewHeaders = [
  {
    "title": "Academic Year",
      "key":"academicYear"
   },
   {
    "title": "Category",
      "key":"category"
   },
   {
    "title": "Agency",
      "key":"agency"
   },
   {
    "title": "Sanctioned Amount in Rupees",
      "key":"amountSanction"
   },
   {
    "title": "Completion Percentage",
      "key":"completionPercentage"
   },
   {
    "title": "Proof Document",
      "key":"fileUpload"
   },
  ]

 console.log("Selected section:", SelectedSection);


// const HeadersView = selectedItem?.Validation?.section === "Publication" ? PublicationHeader : TrainingViewHeaders
const HeadersView = SelectedSection === "Publications" ? PublicationHeader :
                     SelectedSection === "Patents" ? PatentViewHeaders : 
                     SelectedSection === "Trainings" ? TrainingViewHeaders :
                     SelectedSection === "Research Guidance" ? GuidanceViewHeaders :
                     SelectedSection === "Sponsored Research" ? SponsoredViewHeaders : 
                     SelectedSection === "Online Courses" ? OnlineCourseViewHeaders :
                     SelectedSection === "Responsibilities" ? ResponsibilitiesViewHeaders : 
                     SelectedSection === "Books & Chapters" ? BooksChapterEditsViewHeaders :
                     SelectedSection === "Honours & Awards" ? HonoursViewHeaders : ConsultancyViewHeaders;
return (

<>


  {
   loader ? 
<>
<div className="start">
        <div className="p-4 mt-3">
          <Skeleton count={1} height={20} width={150} className="mb-5" />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Skeleton
              count={1}
              height={20}
              width={150}
              className="mb-4"
              style={{ marginRight: 20 }}
            />
            <Skeleton
              count={1}
              height={20}
              width={150}
              className="mb-4"
              style={{ marginRight: 20 }}
            />
          </div>
          <Skeleton count={1} height={60} />
          <Skeleton count={6} height={20} className="mt-3" />
        </div>
      </div>
</> :
<>
{!viewSingle ?
  <div className="">
  <div className="row">
  <div className="col-md-12 col-lg-7 col-12">
 <div className="mainheader">Proficiency Verification</div>
 <div className="greyfont mt-2">View all the Proficiency Verification Requests</div>
 </div>
 <div className="col-md-12 col-lg-5 col-12">  
 </div>
 <div className="d-flex justify-content-between gap-4 mt-4">
   <div>
   <button className={`RequestStatus ${status== "Pending" ? 'clicked' : ''}`} onClick={()=>toggleStatus("Pending")} ref={buttonRef}>Pending Requests {status === "Pending" && `(${TotalElements})`}</button>
   <button className={`RequestStatus ${status== "closed" ? 'clicked' : ''}`} onClick={()=>toggleStatus("closed")}>Closed Requests {status === "closed" && `(${TotalElements})`}</button>
   </div>
   <div className="search me-5 ">
<img
alt="search"  
className="fasearch mb-2"
src={searchIcon}
onClick={toggleSearch}  
style={{cursor:'pointer'}}
/>
{isSearchOpen && (
<div className="search-input-container">
<input
type="text"
className="form-control"
placeholder="Search"
value={searchTerm}
onChange={(e) => setSearchTerm(e.target.value)}
style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
/>
<img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }}/>
</div>
)}
</div>
 </div>
 <DynamicTables
 heading = {heading}
 headers={Header}  
 data={data}
 showSNo ={false}
 handleNextPage={handleNextPage}
 handlePrevPage={handlePrevPage}
 TotalElements={TotalElements}
 handleChangePage={handleChangePage}
 currentPage={currentPage}
 pages={totalpages}
 navigateView={navigateView}
 handleSort={handleSort}
 />
 </div>
</div> 
:
<CourseTable data ={[selectedItem]}
period = {period}
headers={HeadersView}
showProficiencyVlidation={true}
heading = {viewT}
backtoview={backtoview}
back={() => {
  setViewSingle(!viewSingle);
  tableCall();
}}
profileImg = {image}
FileDownload={FileDownload}
pubIdView = {pubIdView}
sectionForView = {sectionForView} 
/>
}
<Outlet/>
</>
  } 
  
  
  </>
)
}

export default ProficiencyValidation    