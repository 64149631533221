import React,{useState,useEffect} from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image,Font } from '@react-pdf/renderer';
import { Link,useParams } from 'react-router-dom'
import logo from "../../assets/pdf/Logo Icon.png"
import king from "../../assets/pdf/Wordmark.png"
// import { patentsCreates ,patentsGetTableData } from "../../../../../ApiServices";
import { patentsCreates } from '../../ApiServices';
// import {AppraisalSingleCallPublication ,AppraisalSingleCallConsultancy ,AppraisalSingleCallBooksandChapter ,AppraisalSingleCallResponsibilities ,AppraisalSingleCallperformance ,AppraisalSingleCallsponsoredResearch ,AppraisalSingleCallGuidance ,AppraisalSingleCallTraining ,AppraisalSingleCallpatent, AppraisalGetTableData ,AppraisalSingleCallonlinecourse ,AppraisalSingleCallHonoursAndAwards } from '../../../../ApiServices'
import { PrintSingleCall } from '../../ApiServices';
import "./PdfService.css";
import {Table, TR, TH, TD} from '@ag-media/react-pdf-table';
// import sathosi from "../../../src/assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium-ttf"
// import satoshiTtf from "../../../src/assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium-ttf.ttf"

// import source from "../../../src/assets/fonts/ABeeZee/ABeeZee-Italic"
// Font.register({

//   family: 'Satoshi medium', src: "../../../src/assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf" 
// })
// Font.register({
//   family: 'Satoshi medium',
//   fonts: [
//     {
//       src: '../../../src/assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf',
      
//     },
   
//   ]
// })


// Font.register({ family: 'Satoshi-Bold', src: "../../../src/assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf", fontStyle: 'normal', fontWeight: 'normal',});
// Font.register({
//   family: "Satoshi Medium",
//   src: "/fonts/Satoshi-Medium.otf",
// });

// Font.register({
//   family: "Satoshi Bold",
//   src: "/fonts/Satoshi-Bold.otf",
// });
 Font.register({ family: 'ABeeZee', src: "../../assets/fonts/ABeeZee/ABeezee-Italic.ttf" });
 Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    // {
    //   src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    //   fontWeight: 'bold',
    // },
    // {
    //   src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    //   fontWeight: 'normal',
    //   fontStyle: 'italic',
    // },
  ],
});
  const styles = StyleSheet.create({
    
    title: {
      fontFamily: 'Satoshi-bold'
    },

    textStyle: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    page: {
      flexDirection: 'row',
      backgroundColor: 'white',
      padding: 20,
      fontSize: '10px',
    },
    page1: {
      // flexDirection: 'row',
      backgroundColor: 'white',
      padding: 20,
      fontSize: '10px',
    },
    formGroup: {
      marginBottom: 4,
    },
    label: {
      marginBottom: 5,
      fontSize: '10px',
    },
    labels: {
      marginBottom: 4,
      fontSize: '6px',
    },

    labelstab: {
      // marginBottom: 4,
      fontSize: 10,
    },
    labels1: {
      marginTop: 20,
      fontSize: '6px',
      color: "#7784A1",
    },
    labels2: {
      marginTop: 4,
      marginLeft: "420",
      fontSize: '6px',
      color: "#7784A1"
    },
    labels3: {
      marginTop: 4,
      marginLeft: "420",
      fontSize: '6px',
    },
    fontsize: {
      fontSize: '6px',
    },
    input: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      width: '150%',
      fontSize: '10px',
    },
    inputmd: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      width: '150%',
      fontSize: '10px',
      marginRight: '10px'
    },
    inputsm: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      width: '150%',
      fontSize: '10px',
      marginLeft: 50
    },
    inputpdf: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      width: '165px',
      height: '60px',
      fontSize: '10px',
    },
    inputfact: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 3,
      width: '163px',
      // height: '15px',
      fontSize: '10px',
    },
    inputfinal: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      width: '150px',
      height: '60px',
      fontSize: '10px',
    },
    inputleft: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      marginRight: '50%',
      fontSize: '10px',
    },
    inputleft1: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      marginRight: '30%',
      fontSize: '10px',
    },
    input1: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      // width: '225%',
      // height:'100%',
      fontSize: '8px',
    },
    input2: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      width: '350%',
      // height:'100%',
      fontSize: '10px',
      marginRight: 50,
    },
    input3: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 6,
      width: '263px',
      // height:'100%',
      fontSize: '10px',
      marginRight: 50,
    },
    input4: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 6,
      width: '263px',
      height: '55px',
      fontSize: '10px',
      marginRight: 50,
    },
    input5: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      width: '250px',
      height: '60px',
      fontSize: '10px',
      marginRight: 50,
      marginBottom: '150px',
    },
    input6: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      width: '555px',
      height: '60px',
      fontSize: '10px',
      marginRight: 50,
    },
    button: {
      backgroundColor: '#007BFF',
      color: 'white',
      padding: 10,
      textAlign: 'center',
      borderRadius: 5,
      marginTop: 15,
    },
    imageContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    image: {
      width: 60,
      height: 75,
      marginRight:10,
    },
    imageset: {
      marginTop: 10
    },
    imageking: {
      width: 100,
      height: 35,
      marginTop:10,
      // marginBottom: 30,
      marginRight: 150,
      // position:"absolute"
      // marginLeft: 1,
    },
    text: {
      textAlign: 'center',
      marginTop: 5,
      fontSize: 12,
    },
    textB: {
      textAlign: 'center',
      marginTop:10,
      marginBottom: 10,
      fontSize: 12,
      fontWeight: 'bold',
    },
    texts: {
      textAlign: 'center',
      fontSize: '8px',
    },
    texts1: {
      textAlign: 'center',
      fontSize: '15px',
      marginLeft: 90,
      color: '#002B41',
      marginBottom:10,
      fontWeight: 'bold',
    },
    view:{
      marginBottom:50
    },
    dottedLine: {
      width: '100%',
      border: 0,
      borderBottomWidth: 1,
      borderBottomStyle: 'dashed',
      borderBottomColor: '#909CA8',  
      marginBottom: 10,
    },
    inputContainer: {
      flexDirection: 'column',
      marginRight: 50,

    },
    margins: {
      marginRight: 50,

    },
    margins1: {
      marginLeft: 30,

    },
    margins2: {
      // marginLeft: 0,

    },
    // table: {
    //   display: 'flex',
    //   width: 'auto',
    //   borderStyle: 'solid',
    //   border: '1px solid ',
    //   bordercollapse: "collapse",
      
    // },
    // tableRow: {
    //   flexDirection: 'row'
      
    // },
    // tableCellHeader: {
    //   textAlign: 'center',
    //   fontWeight: 'bold',
    //   borderWidth: 1,
    //   padding: 5,
    //   flex: 1,
    //   fontSize: 7,
    // },
    // tableCell: {
    //   flex: 1,
    //   textAlign: 'center',
    //   borderWidth: 1,
    //   fontSize: 5,
    //   padding: 7,

    // },
    table: { 
      display: "flex", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 ,
      borderColor:'#909CA8',
    }, 
    tableRow: { 
      flexDirection: "row" 
    }, 
    tableCellHeader: { 
      textAlign: 'center',
        fontWeight: 'bold',
        borderWidth: 1,
        padding: 5,
        flex: 1,
        fontSize: 7,
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
      borderColor:'#909CA8',
    }, 
    tableCellHeaders: { 
      textAlign: 'center',
        fontWeight: 'bold',
        borderWidth: 1,
        padding: 1,
        flex: 1,
        fontSize: 7,
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
      borderColor:'#909CA8',
    }, 
    tableCell: { 
      flex: 1,
        textAlign: 'center',
        borderWidth: 1,
        fontSize: 5,
        padding: 7,
        borderLeftWidth: 0, 
        borderTopWidth: 0 ,
        borderColor:'#909CA8',
    },
        

    top: {
      fontSize: '6px',
      marginRight: '15px',
      marginBottom: "40px"
    },

    topinput: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 5,
      width: '80px',
      // height: '10px',
      fontSize: '5px',
      marginLeft: "10px",
      marginRight: 10,
      marginBottom: '80px'
    },
    top1: {
      marginTop: '20px',
      fontSize: '6px',
      marginRight: '10px'
    },
    topinput1: {
      marginTop: '20px',
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 8,
      width: '150px',
      height: '10px',
      fontSize: '10px',
      marginLeft: "10px"
    },
    // profile: {
    //   borderColor: 'black',
    //   borderRadius:'black',
    //   width:'100px',
    //   height:"100px",
    //   color:"black",

    // }
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rightBox: {
      // borderWidth: 1,
      // borderColor: '#909CA8',
      // borderStyle: 'solid',
      // padding: 8,
      // width: '70px',
      // height:'10px',
      // fontSize:'10px',
      // marginBottom: 4
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 3,
      width: '70px',
      // height:"10px",
      fontSize: '8px',
      marginBottom: 4
    },
    imagebox: {
      width: 80,
      height: 100,
      backgroundColor: '#D0D0E0',
    },
    headtop: {
      marginTop: 10,
      // fontFamily:"Satoshi-ttf"
    },
    headtops: {
      marginTop: 1,
    }, 
    headbottom: {
      marginBottom: 10,
    },
    inputbasic: {
      // borderWidth: 1,
      // borderColor: '#909CA8',
      // borderStyle: 'solid',
      // padding: 8,
      // width: '160px',
      // height:'20px',
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 6,
      width: '160px',
      fontSize: '8px',
      fontFamily: "Ubuntu",    
    },
    inputbasicsec: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 6,
      width: '80px',
      fontSize: '8px',
    },
    inputbasicsecs: {
      borderWidth: 1,
      borderColor: '#909CA8',
      borderStyle: 'solid',
      padding: 6,
      width: '115px',
      fontSize: '8px',
    },
    leftText: {
      textAlign: 'left',
      fontSize: '6px',
      color: "#7784A1",
      // marginTop: 10,
      marginBottom:30
    },
    center: {
      flex: 1,
      alignItems: 'center',
      fontSize: '6px',
      color: "#7784A1",
      // marginTop: 10,
      marginBottom:30
    },
    right: {
      alignItems: 'flex-end',
      fontSize: '6px',
      color: "#7784A1",
      // marginTop: 30,
      marginBottom:30
    },
    // secound:{
    //   marginTop:"40px",
    //   marginRight:"150px"
    // }
    pradeep: {
      marginTop: 30,
      // marginRight: 200,
      marginLeft: 30,
    },

  });


const PdfService = () => {
  // const [data, setData] = useState(null);

  // useEffect(() => {
  //   const printCall = async () => {
  //     try {
  //       const response = await PrintSingleCall(); 
  //       setData(response.data);
  //       console.log('pradeep:', response.data);
  //     } catch (error) {
  //       console.error('Error ing data:', error);
  //     }
  //   };

  //   printCall();
  // }, []); 
  const [data, setData] = useState<any>(null);
  const {empId}= useParams()
  console.log(empId ,"am id ")
  const [apiCheck , setApiCheck] = useState({
          pubId: "",
          empId: "",
          publicationPhoto: "",
          academicYear: "",
          doi: "",
          listedIn: "",
          title: "",
          journalName: "",
          i10Index: "",
          authorPosition: " ",
          publicationUrl: "",
          uploadFile: "",
          status: "",
          createdBy: "",
          updatedBy: null,
          createdOn: "",
          updatedOn: "",
          hindex: null
  })

  const [publicationLists, setPublicationLists] = useState({
    
      employeeModal: {
          employeeId: "",
          addressModal: {
              employeeId: 878567856,
              presentAddress: {
                  addressLine1: "",
                  addressLine2: "",
                  state: "",
                  city: "",
                  pincode: "",
                  file: ""
              },
              permanentAddress: {
                  addressLine1: "",
                  addressLine2: "",
                  state: "",
                  city: "",
                  pincode: "",
                  file: ""
              },
              emergencyContact1: {
                  name: "",
                  relationShip: "",
                  email: "",
                  mobileNumber: ""
              },
              emergencyContact2: {
                  name: "",
                  relationShip: "",
                  email: "",
                  mobileNumber: ""
              }
          },
          empName: "",
          designation: "",
          deptId: "",
          reportingTo: "",
          joinedOn: "",
          empType: "",
          previouslyWorkedInKings: "",
          academicDetails: {
              academics: [
                  {
                      id: "10th",
                      yearOfComplete: "",
                      mode: "",
                      classOfComplete: "",
                      classOfHoldingCertificate: null,
                      cgpa: null,
                      institutionName: "",
                      university: null,
                      specialization: "",
                      itUpdated: false
                  },
                  {
                      id: "12th",
                      yearOfComplete: "2024-04",
                      mode: "",
                      classOfComplete: "",
                      classOfHoldingCertificate: null,
                      cgpa: null,
                      institutionName: "",
                      university: " ",
                      specialization: "",
                      itUpdated: false
                  },
                  {
                      id: "UG",
                      yearOfComplete: "",
                      mode: "",
                      classOfComplete: "",
                      classOfHoldingCertificate: null,
                      cgpa: null,
                      institutionName: "",
                      university: "",
                      specialization: "",
                      itUpdated: false
                  },
                  {
                      id: "PG",
                      yearOfComplete: "",
                      mode: "",
                      classOfComplete: "",
                      classOfHoldingCertificate: null,
                      cgpa: null,
                      institutionName: "",
                      university: "",
                      specialization: "",
                      itUpdated: false
                  },
                  {
                      id: "OTHER",
                      yearOfComplete: null,
                      mode: "",
                      classOfComplete: "",
                      classOfHoldingCertificate: null,
                      cgpa: null,
                      institutionName: "",
                      university: "",
                      specialization: "",
                      itUpdated: false
                  }
              ]
          },
          teachingExperiences: {
              details: [
                  {
                      id: null,
                      institution: "",
                      designation: "",
                      fromDate: null,
                      toDate: null,
                      experience: null,
                      file: "",
                      itUpdated: false
                  }
              ]
          },
          dob: "",
          gender: "",
          bloodGroup: "",
          religion: "",
          community: "",
          nationality: "",
          aadhaarNumber: "",
          martialStatus: "",
          typeOfIdProof: "",
          idProof: "",
          email: "",
          mobileNumber: "",
          empStatus: "",
          updatedOn: null,
          createdOn: "",
          image: "",
          updatedBy: "",
          autoGeneratedPassword: null
      },
    publicationList: [
      {
          pubId: "",
          empId: "",
          publicationPhoto: "",
          academicYear: "",
          doi: "",
          listedIn: "",
          title: "",
          journalName: "",
          i10Index: "",
          authorPosition: " ",
          publicationUrl: "",
          uploadFile: "",
          status: "",
          createdBy: "",
          updatedBy: null,
          createdOn: "",
          updatedOn: "",
          hindex: null
      },
],
patentList: [
    {
        patId: "",
        empId: "",
        academicYear: "",
        fileNumber: null,
        patentTitle: "",
        agencyName: "",
        patentStatus: "",
        year: null,
        uploadFile: "",
        status: "",
        createdBy: "",
        updatedBy: null,
        createdOn: null,
        updatedOn: null
    },
   
    
],
honoursAndAwardsList: [
    {
        honId: "",
        academicYear: "",
        type: "",
        awardTitle: "",
        awardPresentedBy: null,
        honourTitle: "",
        honourPresentedBy: "",
        fellowTitle: "",
        fellowPresentedBy: "",
        memberTitle: "",
        presentedBy: "",
        awardReceived: "",
        memberPresentedBy: "",
        organisationName: "",
        organisationRole: "",
        currentStatus: "",
        memberSince: null,
        memberTill: null,
        uploadFile: "",
        createdBy: "",
        updatedBy: null,
        createdOn: "",
        updatedOn: null,
        status: "",
        empId: ""
    },
    {
        honId: "",
        academicYear: "",
        type: "",
        awardTitle: "",
        awardPresentedBy: null,
        honourTitle: "",
        honourPresentedBy: "",
        fellowTitle: "",
        fellowPresentedBy: "",
        memberTitle: "",
        presentedBy: "",
        awardReceived: "",
        memberPresentedBy: "",
        organisationName: "",
        organisationRole: "",
        currentStatus: "",
        memberSince: null,
        memberTill: null,
        uploadFile: "",
        createdBy: "",
        updatedBy: null,
        createdOn: "",
        updatedOn: "",
        status: "",
        empId: ""
    },

],
  trainingsList: [
    {
        trainId: "",
        empId: "",
        academicYear: "",
        duration: "",
        programTitle: "",
        actedAs: "",
        organization: "",
        startDate: "",
        endDate: "",
        uploadFile: "",
        status: "",
        createdBy: "",
        updatedBy: null,
        createdOn: "",
        updatedOn: null
    },

],
guidanceList: [
    {
        guidId: "",
        academicYear: "",
        universityName: "",
        noOfScholars: "",
        uploadFile: "",
        createdBy: "",
        updatedBy: null,
        createdOn: "",
        updatedOn: "",
        status: "",
        empId: ""
    },

],
sponsoredResearchList: [
    {
        sprId: "",
        academicYear: "",
        category: "",
        agency: "",
        amountSanction: null,
        completionPercentage: "",
        fileUpload: "",
        status: "",
        empId: "",
        createdBy: "",
        updatedBy: null,
        createdOn: "",
        updatedOn: null
    },
 
],
onlineCoursesList: [
    {
        coId: "",
        academicYear: "",
        courseTitle: "",
        awardingInstitution: "",
        grade: "",
        completionOn: "",
        courseDuration: "",
        uploadFile: "",
        createdOn: "",
        updatedOn: null,
        createdBy: "",
        updatedBy: null,
        status: "",
        empId: ""
    },

],
responsibilitiesList: [
    {
        resId: "",
        academicYear: "",
        responsibilityName: "",
        type: "",
        role: "",
        duration: "",
        inCharge: "",
        uploadFile: "",
        empId: "",
        createdBy: "",
        updatedBy: null,
        createdOn: "",
        updatedOn: null,
        status: ""
    }
],
booksAndChapterList: [
 { 
  bacId: " ",
  academicYear: " ",
  titleOfBook: "",
  authors: "",
  publisher: "",
  yearOfPublications: null,
  authorPosition: "",
  createdBy: "",
  updatedBy: "",
  uploadFile: "",
  createdOn: " ",
  updatedOn: "",
  status: "",
  empId: "",
  isbnNo: ""
},

],
consultancyList: [
    {
        contId: "",
        academicYear: "",
        category: "",
        agency: "",
        amountSanction: "",
        completionPercentage: null,
        fileUpload: "",
        status: "",
        createdBy: " ",
        updatedBy: "",
        createdOn: "",
        updatedOn: null,
        empId: ""
    }
],
performanceList: [
    {
        perfId: "",
        academicYear: "",
        year: " ",
        semester: "",
        examType: "",
        department: "  ",
        subHandled: "",
        totalStudents: null,
        studentsAppeared: null,
        studentsPassed: null,
        passedPercentage: null,
        status: "",
        createdBy: " ",
        updatedBy: null,
        createdOn: "",
        updatedOn: null,
        empId: ""
    }
]
  })

  useEffect(() => {
    const datas = {
      empId:sessionStorage.getItem('empId')
    }

    const printCall = async () => {
      try {
        const response = await PrintSingleCall(datas); 
        setApiCheck(response.data);
        setData(response.data);
        // console.log(data , "am data")
        console.log(response.data.data[0].publicationList ,"hjdwdwy")
        setPublicationLists(response.data.data[0]);
        console.log(publicationLists , "hello its me missing data ")
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    printCall();
  }, []); 
  const formatDate = () => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date().toLocaleDateString('en-US', options);
  };
  return (
    
    <Document>
      <Page style={styles.page} wrap={false}>
        <View >
          {/* <View style={styles.imageContainer}>
            <Image src={logo} style={styles.image} />
            <Image src={king} style={styles.imageking} />
            <Text style={styles.top}>Academic Year</Text>
            <Text style={styles.topinput}></Text>
  

          </View> */}
          <View style={styles.header}>
            <View style={{ flexDirection: "row" }}>
              <Image src={logo} style={styles.image} />
              <View>
                <Image src={king} style={styles.imageking} />
                
                <Text style={{ fontSize: 6, color: "black", position: "absolute" ,marginTop: "50px" , marginLeft: "3px",}}>ACCREDITTED WITH NAAC & NBA, AFFILIATED</Text>
                <Text style={{ fontSize: 6, color: "black", position: "absolute" ,marginTop: "59px", marginRight:"10px",}}>TO ANNA UNIVERSITY and APPROVED BY AICTE</Text>
                
              </View>

            </View>
<View>
<Text style={{ fontSize: 6, color: "#002B41", position: "absolute" ,marginTop: "56px" , marginRight:100 }}>Academic Year</Text>
<Text style={{...styles.topinput,marginTop:80,position: "absolute",marginLeft:"50px", fontSize:7,textAlign: "center" }}>{formatDate()}</Text>
<Text style={{ fontSize: 6, color: "#002B41", position: "absolute" ,marginTop: "86px" , marginRight: "15px", textAlign: "center",marginLeft:"25px"}}>Date</Text>
<View style={{ ...styles.topinput, marginTop: 50, marginLeft: "50px", fontSize: 7, display: "flex", justifyContent: "center", alignItems: "center" }}>
  <Text>{publicationLists.publicationList[0].academicYear || "--"}</Text>
</View>
</View>
            <View style={styles.imagebox}></View>

          </View>

          <View style={styles.headtop}>
            <Text style={styles.texts1}>TEACHING FACULTY PERFORMANCE EVALUATION FORM</Text>
            <Text style={styles.texts}>Instructions: This form aims to evaluate the performance and effectiveness of the faculty member. Please provide specific comments wherever applicable. All responses will be kept confidential. Wherever applicable, you are requested to provide proof for the information provided and get signature from the concerned coordinator/ In-charge / HOD; otherwise, your information will become void.</Text>
          </View>
          <Text style={styles.textB}>Basic Info</Text>
          <Text style={styles.dottedLine}></Text>
          <View style={[styles.formGroup, { flexDirection: 'row', justifyContent: 'space-between'}]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>Faculty Name</Text>
              <Text style={styles.inputbasic}>{publicationLists.employeeModal.empName || "--"}</Text>
            </View>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>Designation</Text>
              <Text style={styles.inputbasic}>{publicationLists.employeeModal.designation || "--"}</Text>
            </View>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>Department</Text>
              <Text style={styles.inputbasic}>{publicationLists.employeeModal.deptId || "--"} </Text>
            </View>
            {/* <Text style={styles.text}>A</Text>
            <Text style={styles.text}>B</Text>
            <Text style={styles.text}>C</Text> */}
          </View>
          <View style={[styles.formGroup, { flexDirection: 'row', justifyContent: 'space-between' }]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>Qualification</Text>
              <Text style={styles.inputbasic}>--</Text>
            </View>
            <View style={styles.formGroup}>
              <Text style={{...styles.labels,marginLeft:25}}>Experience in KEC</Text>
              <Text style={{...styles.inputbasicsec,marginLeft:25}}>5 years </Text>
            </View>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>Total Experience</Text>
              <Text style={styles.inputbasicsec}>{publicationLists.employeeModal.teachingExperiences.details[0].experience ||"--"} year</Text>
            </View>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>Salary at the time of join</Text>
              <Text style={styles.inputbasicsec}>test</Text>
            </View>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>Current Salary</Text>
              <Text style={styles.inputbasicsec}>test</Text>
            </View>
            {/* <Text style={styles.text}>A</Text>
            <Text style={styles.text}>B</Text>
            <Text style={styles.text}>C</Text> */}
          </View>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>No. of Papers published in SCI indexed Journals (only in KEC)</Text>
              <Text style={styles.input3}>--</Text>
            </View>
            <View style={styles.margins1}>
              <Text style={styles.labels}>As first author</Text>
              <Text style={styles.input3}>--</Text>
            </View>
          </View>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>No. of Papers published in Scopus indexed Journals (only in KEC)</Text>
              <Text style={styles.input3}>--</Text>
            </View>
            <View style={styles.margins1}>
              <Text style={styles.labels}>As first author</Text>
              <Text style={styles.input3}>--</Text>
            </View>
          </View>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>No. of Papers published in UGC Listed Journals (only in KEC)</Text>
              <Text style={styles.input3}>--</Text>
            </View>
            <View style={styles.margins1}>
              <Text style={styles.labels}>As first author</Text>
              <Text style={styles.input3}>--</Text>
            </View>
          </View>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>No. of Patterns (only in KEC)</Text>
              <Text style={styles.input3}>--</Text>
              <View style={styles.formGroup}>
                <Text style={styles.labels}></Text>
                <Text style={styles.input3}>--</Text>
              </View>
            </View>
            <View style={styles.margins1}>
              <Text style={styles.labels}>No. of Books Published (only in KEC)</Text>
              <Text style={styles.input4}>--</Text>
            </View>
          </View>
          {/* <View style={[styles.formGroup, { flexDirection: 'row', justifyContent: 'space-between' }]}>
            <View style={styles.inputContainer}>
              <Text style={styles.labels}>If completed Ph.D - Name of the University</Text>
              <Text style={styles.input3}>--</Text>
            </View>
            <View style={{...styles.margins,marginLeft:30}}>
              <Text style={styles.labels}>Ph.D -Year of Completion</Text>
              <Text style={styles.inputmd}>--</Text>
            </View>
            <View style={styles.inputContainer}>
              <Text style={{...styles.labels,marginRight:20}}>Guideship</Text>
              <Text style={{...styles.input,marginRight:50}}>--</Text>
            </View>
          </View> */}
           <View style={[styles.formGroup, { flexDirection: 'row', justifyContent: 'space-between' }]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>If completed Ph.D - Name of the University</Text>
              <Text style={styles.input3}>--</Text>
            </View>
            <View style={styles.formGroup}>
              <Text style={{...styles.labels,marginRight:225,marginLeft:97}}>Ph.D -Year of Completion</Text>
              <Text style={{...styles.inputbasicsecs,marginLeft:96}}>--</Text>
            </View>
            <View style={styles.formGroup}>
              <Text style={{...styles.labels,marginRight:150,}}>Guideship</Text>
              <Text style={styles.inputbasicsecs}>--</Text>
            </View>
          </View>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>No. of Scholars</Text>
              <Text style={styles.input3}>--</Text>
              <View style={styles.formGroup}>
                <Text style={styles.labels}></Text>
                <Text style={styles.input3}>--</Text>
              </View>
            </View>
            <View style={styles.margins1}>
              <Text style={styles.labels}>Name of the University</Text>
              <Text style={styles.input4}>--</Text>
            </View>
          </View>
          {/* <view></view> */}
          {/* <Text style={styles.labels}>Additional Responsibilities</Text> */}
          <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtops]}>Additional Responsibilities</Text>
            <Text style={[styles.headtops, styles.rightBox]}> </Text>
          </View>
          {/* <View style={{...styles.tables}}>
            <View style={styles.tableRows}>
              <Text style={styles.tableCol}>S.no</Text>
              <Text style={styles.tableCol}>Academic Year </Text>
              <Text style={styles.tableCol}>Name of the Responsibility</Text>
              <Text style={styles.tableCol}>Institute / Department Level</Text>
              <Text style={styles.tableCol}>Role</Text>
              <Text style={styles.tableCol}>Duration</Text>
              <Text style={styles.tableCol}>In-charge / HOD Signature</Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableCells}>1</Text>
              <Text style={styles.tableCells}>2023 - 2024</Text>
              <Text style={styles.tableCells}>Researching topics</Text>
              <Text style={styles.tableCells}>Department</Text>
              <Text style={styles.tableCells}>Mentoring</Text>
              <Text style={styles.tableCells}>4 Months</Text>
              <Text style={styles.tableCells}>..</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCells}>..</Text>
              <Text style={styles.tableCells}>.. </Text>
              <Text style={styles.tableCells}>.. </Text>
              <Text style={styles.tableCells}>..</Text>
              <Text style={styles.tableCells}>..</Text>
              <Text style={styles.tableCells}>..</Text>
              <Text style={styles.tableCells}>..</Text>
            </View>

          </View> */}
           <Table 
          //  style={{fontSize:8 , padding:5,}}
           >
        <TH >
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center" ,fontSize:7 , padding:1,borderColor: '#909CA8',borderWidth: 1}}>S.No</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center" ,fontSize:7 , padding:5,borderColor: '#909CA8',}}>Academic Year</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center' ,textAlign:"center" ,fontSize:7 , padding:5,borderColor: '#909CA8',}}>Name of the Responsibility</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center" ,fontSize:7 , padding:5,borderColor: '#909CA8',}}>Institute / Department Level</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center" ,fontSize:7 , padding:5,borderColor: '#909CA8',}}>Role</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center" ,fontSize:7 , padding:5,borderColor: '#909CA8',}}>Duration</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center" ,fontSize:7 , padding:5,borderColor: '#909CA8',}}>In-charge / HOD Signature</TD>
        </TH>
        
        {/* <TR>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,}}>1</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,}}>2023 - 2024</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,}}>Department</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,}}>Researching topics</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,}}>Department</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,}}>Mentoring</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,  }}>4 Months</TD>
        </TR> */}
         {publicationLists.responsibilitiesList.length > 0 ? (
    publicationLists.responsibilitiesList.map((item, index) => (
<TR key={index}>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:1,borderColor: '#909CA8',}}>{index + 1}</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,borderColor: '#909CA8',}}>{item.academicYear || "--"}</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,borderColor: '#909CA8',}}>{item.responsibilityName || "--"}</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,borderColor: '#909CA8',}}>{item.type || "--"} </TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,borderColor: '#909CA8',}}>{item.role || "--"}</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5,borderColor: '#909CA8',}}>{item.duration || "--"}</TD>
            <TD style={{display: 'flex', alignItems: 'center', justifyContent: 'center',textAlign:"center",fontSize:5 , padding:5, borderColor: '#909CA8', }}>{item.inCharge || "--"}</TD>
        </TR>
 ))
  ) : (
    <View style={[styles.tableRow, { justifyContent: 'center' }]}>
      <Text style={[styles.tableCell, { flex: 10 }]}>No data available</Text>
    </View>
  )}
    </Table>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
          <Text style={{textAlign: "left",color:"#7784A1",fontSize:"5px",marginTop:"5px" }}>DEPT. OF. HUMAN RESOURCES</Text>
          <Text style={{marginLeft:200 ,color:"#7784A1",fontSize:"5px",marginTop:"5px"}}>1</Text>
          <Text style={{marginLeft:170, color:"#7784A1",fontSize:"5px",marginTop:"5px"}}>KINGS ENGINEERING COLLEGE, CHENNAI</Text>
          </View>
          
        </View>

      </Page>
      <Page style={styles.page1}  wrap={false}  >
         <View style={styles.header}>
            <View style={{ flexDirection: "row" }}>
              <Image src={logo} style={styles.image} />
              <View>
                <Image src={king} style={styles.imageking} />
                
                <Text style={{ fontSize: 6, color: "black", position: "absolute" ,marginTop: "50px" , marginLeft: "3px"}}>ACCREDITTED WITH NAAC & NBA, AFFILIATED</Text>
                <Text style={{ fontSize: 6, color: "black", position: "absolute" ,marginTop: "59px", }}>TO ANNA UNIVERSITY and APPROVED BY AICTE</Text>
                
              </View>

            </View>
            <View>
<Text style={{ fontSize: 6, color: "#002B41", position: "absolute" ,marginTop: "56px" , marginRight:100 }}>Academic Year</Text>
<Text style={{...styles.topinput,marginTop:80,position: "absolute",marginLeft:"50px", textAlign: "center"}}>{formatDate()}</Text>
<Text style={{ fontSize: 6, color: "#002B41", position: "absolute" ,marginTop: "86px" , marginRight: "15px",textAlign: "center"}}>Date</Text>
<View style={{ ...styles.topinput, marginTop: 50, marginLeft: "50px", fontSize: 7, display: "flex", justifyContent: "center", alignItems: "center" }}>
  <Text>{publicationLists.publicationList[0].academicYear || "--"}</Text>
</View>
</View>
        

          </View>

        <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Result Analysis</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
          <View style={styles.table}>
  <View style={styles.tableRow}>
    <Text style={styles.tableCellHeader}>S.No</Text>
    <Text style={styles.tableCellHeader}>Academic Year</Text>
    <Text style={styles.tableCellHeader}>Year </Text>
    <Text style={styles.tableCellHeader}>Department </Text>
    <Text style={styles.tableCellHeader}>Semester </Text>
    <Text style={styles.tableCellHeader}>Subject Handled</Text>
    <Text style={styles.tableCellHeader}>Total No. of Student</Text>
    <Text style={styles.tableCellHeader}>No. of Student Appeared</Text>
    <Text style={styles.tableCellHeader}>No. of Student Passed</Text>
    <Text style={styles.tableCellHeader}>Pass Percentage</Text>
  </View>
  {publicationLists.performanceList.length > 0 ? (
    publicationLists.performanceList.map((item, index) => (
      <View key={index} style={styles.tableRow}>
        <Text style={styles.tableCell}>{index + 1}</Text>
        <Text style={styles.tableCell}>{item.academicYear || "--"}</Text>
        <Text style={styles.tableCell}>{item.year || "--"}</Text>
        <Text style={styles.tableCell}>{item.department || "--"}</Text>
        <Text style={styles.tableCell}>{item.semester || "--"}</Text>
        <Text style={styles.tableCell}>{item.subHandled || "--"}</Text>
        <Text style={styles.tableCell}>{item.totalStudents || "--"}</Text>
        <Text style={styles.tableCell}>{item.studentsAppeared || "--"}</Text>
        <Text style={styles.tableCell}>{item.studentsPassed || "--"}</Text>
        <Text style={styles.tableCell}>{item.passedPercentage || "--"}</Text>
      </View>
    ))
  ) : (
    <View style={[styles.tableRow, { justifyContent: 'center' }]}>
      <Text style={[styles.tableCell, { flex: 10 }]}>No data available</Text>
    </View>
  )}
</View>

        
        <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Publications</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>S.No</Text>
            <Text style={styles.tableCellHeader}>Listed in</Text>
            <Text style={styles.tableCellHeader}>Title of the Publication</Text>
            <Text style={styles.tableCellHeader}>DOI</Text>
            <Text style={styles.tableCellHeader}>Name of the Journal</Text>
            <Text style={styles.tableCellHeader}>h-index</Text>
            <Text style={styles.tableCellHeader}>i10-index </Text>
            <Text style={styles.tableCellHeader}>Author Position</Text>
          </View>
{publicationLists.publicationList.map(( item , index) =>
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index+1}</Text>
            <Text style={styles.tableCell}>{item.updatedOn || "--"}</Text>
            <Text style={styles.tableCell}>{item.title || "--"}</Text>
            <Text style={styles.tableCell}>{item.doi || "--"}</Text>
            <Text style={styles.tableCell}>{item.journalName || "--"}</Text>
            <Text style={styles.tableCell}>{item.hindex || "--"}</Text>
            <Text style={styles.tableCell}>{item.i10Index || "--"}</Text>
            <Text style={styles.tableCell}>{item.authorPosition || "--"}</Text>
          </View>
          )}
        </View>

        
         <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Patent</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>S.No</Text>
            <Text style={styles.tableCellHeader}>Academic Year </Text>
            <Text style={styles.tableCellHeader}>Title of the patent</Text>
            <Text style={styles.tableCellHeader}>File Number</Text>
            <Text style={styles.tableCellHeader}>Agency Name</Text>
            <Text style={styles.tableCellHeader}>Status</Text>
            <Text style={styles.tableCellHeader}>Year</Text>
          </View>
{publicationLists.patentList.length > 0 ? (
publicationLists.patentList.map((item , index) =>(
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index+1}</Text>
            <Text style={styles.tableCell}>{item.academicYear || "--"}</Text>
            <Text style={styles.tableCell}>{item.patentTitle || "--"}</Text>
            <Text style={styles.tableCell}>{item.fileNumber || "--"}</Text>
            <Text style={styles.tableCell}>{item.agencyName || "--"}</Text>
            <Text style={styles.tableCell}>{item.status || "--"}</Text>
            <Text style={styles.tableCell}>{item.year || "--"}</Text>
          </View>
        ))
      ) : (
        <View style={[styles.tableRow, { justifyContent: 'center' }]}>
        <Text style={[styles.tableCell, { flex: 10 }]}>No Patent available</Text>
      </View>
    )}
        </View>
      
           <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Honours & Awards</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>S.No</Text>
            <Text style={styles.tableCellHeader}>Academic Year</Text>
            <Text style={styles.tableCellHeader}>Organisation Name</Text>
            <Text style={styles.tableCellHeader}>Type</Text>
            <Text style={styles.tableCellHeader}>Role</Text>
            <Text style={styles.tableCellHeader}>Current Status</Text>
            <Text style={styles.tableCellHeader}>Member Since</Text>
            <Text style={styles.tableCellHeader}>Member Till</Text>
          </View>
{publicationLists.honoursAndAwardsList.length > 0 ? (
publicationLists.honoursAndAwardsList.map((item ,index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index+1}</Text>
            <Text style={styles.tableCell}>{item.academicYear || "--"}</Text>
            <Text style={styles.tableCell}>{item.organisationName || "--"}</Text>
            <Text style={styles.tableCell}>{item.type || "--"}</Text>
            <Text style={styles.tableCell}>{item.organisationRole || "--"}</Text>
            <Text style={styles.tableCell}>{item.currentStatus || "--"}</Text>
            <Text style={styles.tableCell}>{item.memberSince || "--"}</Text>
            <Text style={styles.tableCell}>{item.memberTill || "--"}</Text>
          </View>

))
):(
  <View style={[styles.tableRow, { justifyContent: 'center' }]}>
  <Text style={[styles.tableCell, { flex: 10 }]}>No Honours & Awards available</Text>
</View>
)}
        </View>
       
          <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Online Course</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>S.No</Text>
            <Text style={styles.tableCellHeader}>Academic Year</Text>
            <Text style={styles.tableCellHeader}>Course Title</Text>
            <Text style={styles.tableCellHeader}>Awarding Institution</Text>
            <Text style={styles.tableCellHeader}>Grade</Text>
            <Text style={styles.tableCellHeader}>Course Completed on</Text>
            <Text style={styles.tableCellHeader}>Course duration</Text>
          </View>
{publicationLists.onlineCoursesList.map((item ,index) =>
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index+1}</Text>
            <Text style={styles.tableCell}>{item.academicYear || "--"}</Text>
            <Text style={styles.tableCell}>{item.courseTitle || "--"}</Text>
            <Text style={styles.tableCell}>{item.awardingInstitution || "--"}</Text>
            <Text style={styles.tableCell}>{item.grade || "--"}</Text>
            <Text style={styles.tableCell}>{item.completionOn || "--"}</Text>
            <Text style={styles.tableCell}>{item.courseDuration || "--"}</Text>
          </View>
          )}
        </View>
      
        <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Guidance</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={{...styles.tableCellHeader}}>S.No</Text>
            <Text style={styles.tableCellHeader}>Academic Year</Text>
            <Text style={styles.tableCellHeader}>Name of the University</Text>
            <Text style={styles.tableCellHeader}>Number of Scholars</Text>

          </View>
{publicationLists.guidanceList.length > 0 ?(
publicationLists.guidanceList.map((item, index) =>(
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index+1}</Text>
            <Text style={styles.tableCell}>{item.academicYear || "--"}</Text>
            <Text style={styles.tableCell}>{item.universityName || "--"}</Text>
            <Text style={styles.tableCell}>{item.noOfScholars || "--"}</Text>

          </View>
         ))
        ):(
          <View style={[styles.tableRow, { justifyContent: 'center' }]}>
  <Text style={[styles.tableCell, { flex: 10 }]}>No Guidance  available</Text>
</View>
)}
        </View>
       
        <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Sponsored Research</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
        <View style={[styles.table,]}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>S.No</Text>
            <Text style={styles.tableCellHeader}>Academic Year</Text>
            <Text style={styles.tableCellHeader}>Category</Text>
            <Text style={styles.tableCellHeader}>Agency</Text>
            <Text style={styles.tableCellHeader}>Sanctioned Amount in Rupees</Text>
            <Text style={styles.tableCellHeader}>Completion Percentage</Text>
          </View>
{publicationLists.sponsoredResearchList.length > 0 ? (
publicationLists.sponsoredResearchList.map((item ,index)=> (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index+1}</Text>
            <Text style={styles.tableCell}>{item.academicYear || "--"}</Text>
            <Text style={styles.tableCell}>{item.category || "--"}</Text>
            <Text style={styles.tableCell}>{item.agency || "--"}</Text>
            <Text style={styles.tableCell}>{item.amountSanction || "--"}</Text>
            <Text style={styles.tableCell}>{item.completionPercentage || "--"}</Text>
          </View>
))
):(
  <View style={[styles.tableRow, { justifyContent: 'center' }]}>
  <Text style={[styles.tableCell, { flex: 10 }]}>No Sponsored Research  available</Text>
</View>
)}
  

        </View>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
          <Text style={{textAlign: "left",color:"#7784A1",fontSize:"5px",marginTop:"15px"}}>DEPT. OF. HUMAN RESOURCES</Text>
          <Text style={{marginLeft:200 ,color:"#7784A1",fontSize:"5px",marginTop:"15px"}}>2</Text>
          <Text style={{marginLeft:170, color:"#7784A1",fontSize:"5px",marginTop:"15px"}}>KINGS ENGINEERING COLLEGE, CHENNAI</Text>
          </View>
      </Page>
      <Page style={styles.page1} wrap={false} >
      <View style={styles.header}>
            <View style={{ flexDirection: "row" }}>
              <Image src={logo} style={styles.image} />
              <View>
                <Image src={king} style={styles.imageking} />
                
                <Text style={{ fontSize: 6, color: "black", position: "absolute" ,marginTop: "50px" , marginLeft: "3px"}}>ACCREDITTED WITH NAAC & NBA, AFFILIATED</Text>
                <Text style={{ fontSize: 6, color: "black", position: "absolute" ,marginTop: "59px", marginRight:"3px"}}>TO ANNA UNIVERSITY and APPROVED BY AICTE</Text>
                
              </View>

            </View>
            <View>
<Text style={{ fontSize: 6, color: "#002B41", position: "absolute" ,marginTop: "56px" , marginRight:100 }}>Academic Year</Text>
<Text style={{...styles.topinput,marginTop:80,position: "absolute",marginLeft:"50px",textAlign: "center"}}>{formatDate()}</Text>
<Text style={{ fontSize: 6, color: "#002B41", position: "absolute" ,marginTop: "86px" , marginRight: "15px",textAlign: "center"}}>Date</Text>
<View style={{ ...styles.topinput, marginTop: 50, marginLeft: "50px", fontSize: 7, display: "flex", justifyContent: "center", alignItems: "center" }}>
  <Text>{publicationLists.publicationList[0].academicYear || "--"}</Text>
</View></View>
        

          </View>

        {/* <Text style={[styles.labelstab, styles.headtop]}>Consultancy</Text> */}
        <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Consultancy</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>S.No</Text>
            <Text style={styles.tableCellHeader}>Academic Year</Text>
            <Text style={styles.tableCellHeader}>Category </Text>
            <Text style={styles.tableCellHeader}>Agency </Text>
            <Text style={styles.tableCellHeader}>Sanctioned Amount in Rupees </Text>
            <Text style={styles.tableCellHeader}>Completion Percentage</Text>
          </View>
{publicationLists.consultancyList.length > 0 ? (
publicationLists.consultancyList.map((item ,index)=> (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index+1}</Text>
            <Text style={styles.tableCell}>{item.academicYear || "--"}</Text>
            <Text style={styles.tableCell}>{item.category || "--"}</Text>
            <Text style={styles.tableCell}>{item.agency || "--"}</Text>
            <Text style={styles.tableCell}>{item.amountSanction || "--"}</Text>
            <Text style={styles.tableCell}>{item.completionPercentage || "--"}</Text>
          </View>
))
):(
  <View style={[styles.tableRow, { justifyContent: 'center' }]}>
  <Text style={[styles.tableCell, { flex: 10 }]}>No Consultancy  available</Text>
</View>
)}
        </View>
        {/* <Text style={[styles.labelstab, styles.headtop]}>Books & Chapters</Text> */}
        <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Books & Chapters</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>S.No</Text>
            <Text style={styles.tableCellHeader}>Books & Chapters</Text>
            <Text style={styles.tableCellHeader}>Authors</Text>
            <Text style={styles.tableCellHeader}>Publisher </Text>
            <Text style={styles.tableCellHeader}>Year of Publication</Text>
            <Text style={styles.tableCellHeader}>Author Position</Text>
            <Text style={styles.tableCellHeader}>ISBN Number</Text>
          </View>
          {
          publicationLists.booksAndChapterList.length > 0 ? (
          publicationLists.booksAndChapterList.map((item, index) => (
    <View key={index} style={styles.tableRow}>
      <Text style={styles.tableCell}>{index + 1}</Text>
      <Text style={styles.tableCell}>{item.titleOfBook || "--"}</Text>
      <Text style={styles.tableCell}>{item.authors || "--"}</Text>
      <Text style={styles.tableCell}>{item.publisher || "--"}</Text>
      <Text style={styles.tableCell}>{item.yearOfPublications || "--"}</Text>
      <Text style={styles.tableCell}>{item.authorPosition || "--"}</Text>
      <Text style={styles.tableCell}>{item.isbnNo || "--"}</Text>
    </View>
  ))
):(
  <View style={[styles.tableRow, { justifyContent: 'center' }]}>
  <Text style={[styles.tableCell, { flex: 10 }]}>No Books & Chapters  available</Text>
</View>
)}
        </View>
        {/* <Text style={[styles.labelstab, styles.headtop]}>Trainings</Text> */}
        <View style={styles.header}>
            <Text style={[styles.labelstab, styles.headtop]}>Trainings</Text>
            <Text style={[styles.headtop, styles.rightBox]}> </Text>
          </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>S.No</Text>
            <Text style={styles.tableCellHeader}>Academic Year</Text>
            <Text style={styles.tableCellHeader}>Title of the programme </Text>
            <Text style={styles.tableCellHeader}>Organization (AICTE/AU..)</Text>
            <Text style={styles.tableCellHeader}>Acted as</Text>
            <Text style={styles.tableCellHeader}>Duration</Text>
            <Text style={styles.tableCellHeader}>Month</Text>
          </View>
{publicationLists.trainingsList.length > 0 ? (
publicationLists.trainingsList.map((item, index)=> (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index+1}</Text>
            <Text style={styles.tableCell}>{item.academicYear || "--"}</Text>
            <Text style={styles.tableCell}>{item.programTitle || "--"}</Text>
            <Text style={styles.tableCell}>{item.organization || "--"}</Text>
            <Text style={styles.tableCell}>{item.actedAs || "--"}</Text>
            <Text style={styles.tableCell}>{item.duration || "--"}</Text>
            <Text style={styles.tableCell}>{item.duration || "--"}</Text>
          </View>
            ))
          ):(
            <View style={[styles.tableRow, { justifyContent: 'center' }]}>
  <Text style={[styles.tableCell, { flex: 10 }]}>No Trainings available</Text>
</View>
)}
        </View>
   
        <View style={[styles.margins2, styles.headtop]}>
          <Text style={styles.labels}>Faculty Comments</Text>
          <Text style={styles.input6}>--</Text>
        </View>
        <Text style={[styles.dottedLine, styles.headtop]}></Text>
        <View style={[styles.margins2]}>
          <Text style={styles.labels}>HOD Comments</Text>
          <Text style={styles.input6}>--</Text>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>Strengths of Improvement</Text>
              <Text style={styles.input3}>--</Text>
            </View>
            <View style={styles.margins1}>
              <Text style={styles.labels}>Areas of Improvement</Text>
              <Text style={styles.input3}>--</Text>
            </View>
          </View>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
            <View style={styles.formGroup}>
              <Text style={styles.labels}>Signature of the Faculty</Text>
              <Text style={styles.inputpdf}>--</Text>
            </View>
            <View style={styles.margins1}>
              <Text style={styles.labels}>Signature of the HOD</Text>
              <Text style={styles.inputpdf}>--</Text>
            </View>
            <View style={styles.margins1}>
              <Text style={styles.labels}>PRINCIPAL</Text>
              <Text style={styles.inputpdf}>--</Text>
            </View>
          </View>
        </View>
        <View style={[styles.formGroup, { flexDirection: 'row' }]}>
          <View style={styles.formGroup}>
            <Text style={styles.labels}>Place</Text>
            <Text style={styles.inputfact}>--</Text>
          </View>
          <View style={styles.margins1}>
            <Text style={styles.labels}>Date</Text>
            <Text style={styles.inputfact}>--</Text>
          </View>

        </View>
        <Text style={styles.fontsize}>Declaration: Thank you for your valuable feedback. Your input will help us in enhancing the quality of education and the overall teaching and
          learning environment. Your information will remain confidential and will be used solely for evaluation purposes.</Text>
          <View style={[styles.formGroup, { flexDirection: 'row' }]}>
          <Text style={{textAlign: "left",color:"#7784A1",fontSize:"5px",marginTop:"45px"}}>DEPT. OF. HUMAN RESOURCES</Text>
          <Text style={{marginLeft:200 ,color:"#7784A1",fontSize:"5px",marginTop:"45px"}}>3</Text>
          <Text style={{marginLeft:170, color:"#7784A1",fontSize:"5px",marginTop:"45px"}}>KINGS ENGINEERING COLLEGE, CHENNAI</Text>
          </View>
      </Page>
    </Document>
  );
};
const PDFForm = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <PDFViewer width="100%" height="100%">
        <PdfService />
      </PDFViewer>
      <PDFDownloadLink document={<PdfService />} fileName="form.pdf">
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
      </PDFDownloadLink>
    </div>
  );
};
export default PDFForm;
