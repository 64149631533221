import React, { useState, useEffect } from 'react';
import DynamicTables from "../../../../components/DynamicTable/DynamicTables";
import searchIcon from "../../../../assets/Icon search.svg";
import noRecord from "../../../../assets/norecords.png"
import { Link, Outlet, useNavigate } from "react-router-dom";
import cross from "../../../../assets/crossimg.png";
import close from "../../../../assets/close.svg";
import { PerformanceGetTable, PerformanceGetTableData, DeleteTabledata } from "../../../../ApiServices";
import CourseTable from "../../../../components/CourseDetailTable/CourseTable";
import { ToastContainer, toast } from "react-toastify";
import { format as formatDate } from "date-fns";
import "../../../FacultyModule/Proficiency/OnlineCourse/OnlineCourse.css";

function Performances() {
  const navigate = useNavigate();
  const columnRedirectsOnlineCourse = {
    courseTitle: "/proficiency/responsibilities/newResponsiblities/new-add",
  };
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalElements, setTotalElements] = useState(0);
  const [totalpages, settotalpages] = useState(0);
  const [sort, setSort] = useState(false);
  const [sortusing, setSortUsing] = useState("perfId");
  const [selectedItem, setselectedItem] = useState<any>([]);
  const [viewSingle, setViewSingle] = useState(false);
  const [viewTitle, setViewTitle] = useState();
  const [pubIdView, setPubIdView] = useState();
  const [createdOn, setCreatedOn] = useState<string>('');
  const [updatedOn, setUpdatedOn] = useState<string>('');
  const heading = viewTitle;
  const subheading = "Result Analysis of the subject handled";
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [tableDatas, settableDatas] = useState([]);

  const toggleSearch = () => {
    setIsSearching(!isSearching);
    setSearchTerm("");
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setSearchTerm('');
    }
  };

  const handleCancel = () => {
    setSearchTerm('');
    setIsSearchOpen(false);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalpages));
    console.log("next button clicked and the value now is " + currentPage);
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleSort = () => {
    setSortUsing("academicYear");
    setSort(!sort);
  }

  function editCourse(item: any) {
    console.log(item, "ssbiki");
    navigate("editPerformance/" + item.perfId);
  }

  const sortOrder = sort ? "asc" : "desc";

  const getDatas = () => {
    const data = {
      empID: sessionStorage.getItem('empId'),
      page: currentPage - 1,
      sort: sortOrder,
      sordId: sortusing,
      keyword: searchTerm,
    }

    PerformanceGetTable(data)
      .then((res) => {
        settableDatas(res.data.datas.content);
        console.log(res.data.datas.content, "gopi");
        setTotalElements(res.data.datas.totalElements);
        settotalpages(res.data.datas.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Header = [
    { label: "Academic Year", key: "academicYear" },
    { label: "Year", key: "year" },
    { label: "Department", key: "department" },
    { label: "Semester", key: "semester" },
    { label: "Subject Handled", key: "subHandled" },
    { label: "Exam Type", key: "examType" },
    { label: "Pass Percentage", key: "passedPercentage" },
  ];

  const PerformanceViewHeader = [
    { "title": "Academic Year", "key": "academicYear" },
    { "title": "Year", "key": "year" },
    { "title": "Semester", "key": "semester" },
    { "title": "Department", "key": "department" },
    { "title": "Exam Type", "key": "examType" },
    { "title": "Subject Handled", "key": "subHandled" },
    { "title": "Total No. of Students", "key": "totalStudents" },
    { "title": "No. of Students Appeared", "key": "studentsAppeared" },
    { "title": "No. of Students Passed", "key": "studentsPassed" },
    { "title": "Pass Percentage", "key": "passedPercentage" },
  ];

  function navigateView(item: any) {
    PerformanceGetTableData(item.perfId).then((res: any) => {
      setselectedItem(res.data.data);
      setPubIdView(res.data.data[0].perfId);
      setViewTitle(res.data.data[0].subHandled);
      const formattedCreatedDate = new Date(res.data.data[0].createdOn);
      const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");
      let updatedOn = "";
      if (res.data.data[0].updatedOn !== null) {
        const formattedUpdatedDate = new Date(res.data.data[0].updatedOn);
        updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
      }
      setCreatedOn(createdOn);
      setUpdatedOn(updatedOn);
      setViewSingle(true);
    }).catch((err: any) => console.log(err));
  }

  const deleteApi = (data: any) => {
    const url = "/performance?perfId=" + data.perfId;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);
        toast.success(res.data.status);
        getDatas();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const deleteApiView = () => {
    const url = "/performance?perfId=" + pubIdView;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);
        toast.success(res.data.status);
        setViewSingle(false);
        getDatas();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getDatas();
  }, [currentPage, sort, searchTerm]);

  const backtoview = "to Result Analysis";
  const deleteCont = "Subject";

  return (
    <>
      <ToastContainer />
      {!viewSingle ?
        <>
          <div className="mainheader">
            Result Analysis
          </div>
          <div className="d-flex justify-content-between greytext">
            <span>Overview of Subjects handled and Student Performance</span>
            {tableDatas.length > 0 && (
            <Link to="/faculty/secondsidebar/proficiency/performance/newperformance">
              <button className="text-white addnewbtn">Add New</button>
            </Link>
            )}
          </div>
          {tableDatas.length > 0 && (
          <div className="addresult">
            <span className="active tablink pb-1 bg addresult">All Results</span>
            <div className="search me-5">
              <img
                alt="search"
                className="fasearch mb-2"
                src={searchIcon}
                onClick={toggleSearch}
              />
              {isSearchOpen && (
                <div className="search-input-container">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
                  />
                  <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }} />
                </div>
              )}
            </div>
          </div>
          )}
          {tableDatas.length === 0 ? (
            <div className="d-flex flex-column align-items-center" style={{ marginTop: "200px" }}>
              <img src={noRecord} alt="No records" />
              <p className="mt-4 greyfont" style={{ textAlign: 'center' }}>You haven't added any publications yet.<br />Start by adding a publication.</p>
              <Link to="/faculty/secondsidebar/proficiency/performance/newperformance">
              <button className="text-white addnewbtn">Add New</button>
              </Link>
            </div>
          ) : (
            <DynamicTables
              currentPage={currentPage}
              pages={totalpages}
              data={tableDatas}
              headers={Header}
              columnRedirects={columnRedirectsOnlineCourse}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              TotalElements={TotalElements}
              handleChangePage={handleChangePage}
              handleSort={handleSort}
              navigateView={navigateView}
              editCourse={editCourse}
              deleteApi={deleteApi}
            />
          )}
          <Outlet />
        </>
        :
        <CourseTable
          data={selectedItem}
          deleteCont={deleteCont}
          backtoview={backtoview}
          back={() => { setViewSingle(!viewSingle) }}
          headers={PerformanceViewHeader}
          heading={heading}
          deleteApiView={deleteApiView}
          subheading={subheading}
          createdOn={createdOn}
          updatedOn={updatedOn}
        />
      }
    </>
  )
}

export default Performances;
