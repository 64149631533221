import React, { useState,useEffect } from 'react';
import { Link,useParams } from 'react-router-dom';
import './ViewEmployees.css'
import user from "../../../../assets/user.png"
import back from "../../../../assets/back.png"
import { employeeSingleData, employeeupdateSingleData,fileDownload , employeeFileView, host} from '../../../../ApiServices';
import { AxiosResponse, AxiosError } from 'axios';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import noRecord from "../../../../assets/norecords.png";
import { format } from 'date-fns';

function ViewEmployee() {
 const [noImage, setNoImage] = useState(true);
   const [activeIndex, setActiveIndex] = useState(0);
   const [tableDatas, settableDatas] = useState([]);
   const { id } = useParams();
   const [profileImage, setProfileImage] = useState("")

   // const ageCalc = employeeIdForm.age 
   
   const [employeeIdForm, setEmployeeIdForm] = useState({
     addressModal: {
       employeeId: "",
       presentAddress: {
         addressLine1: "",
         addressLine2: "",
         state: "",
         city: "",
         pincode: "",
         file: "",
       },
       permanentAddress: {
         addressLine1: "",
         addressLine2: "",
         state: "",
         city: "",
         pincode: "",
         file:"",
       },
       emergencyContact1: {
         name: "",
         relationShip: "",
         email: "",
         mobileNumber: "",
       },
       emergencyContact2: {
         name: "",
         relationShip: "",
         email: "",
         mobileNumber: "",
       },
     },
     empName: "",
     employeeNo:"",
     designation: "",
     deptId: "",
     reportingTo: "",
     joinedOn: "",
     empType: "",
     employeeId: "",
     previouslyWorkedInKings: "no",
     teachExp:"yes",
     otherExp:"no",
     status: "",
     teachingExperiences: {
       details: [
         {
           id: 1,
           institution: "",
           previouslyWorkedInKings1: "",
           designation: "",
           department:"",
           pvskings:"",
           fromDate: "",
           toDate: "",
           experience: "",
           file: "",
           isItUpdated: "",
         }
       ], 
     },
     otherExperiences: {
       details: [
         {
           id: "",
           previouslyWorkedInKings1: "",
           institution: "",
           designation: "",
           pvskings:"",
           fromDate: "",
           department:"",
           category:"",
           toDate: "",
           experience: "",
           file: "",
           isItUpdated: ""
         }
       ]
     },  
     teachingExperiences1: {
       details: [
         {
           id: 1,
           institution: "",
           designation: "",
           fromDate: "",
           toDate: "",
           experience: "",
           file: "",
           isItUpdated: "",
         }
       ],
       
     },
     otherExperiences1: {
       details: [
         {
           id: "",
           institution: "",
           designation: "",
           fromDate: "",
           toDate: "",
           experience: "",
           file: "",
           isItUpdated: ""
         }
       ]
     },
     otherAcademics:{
       academics: [
         {
           id:"",
           yearOfComplete: "",
           mode: "",
           board:"",
           classOfComplete: "",
           cgpa: "",
           institutionName: "",
           universit: "",
           specialization: "",
           isItUpdated: "",
           class: "",
           state:"",
           city: '',
           degree:"",
           markSheetFile:'',
           courseCompletionCertificate:""
         }
       ]
     },
   
     academicDetails: {
       academics: [
         {
           id: "10th",
           yearOfComplete: "",
           mode: "",
           board:"",
           classOfComplete: "",
           cgpa: "",
           institutionName: "",
           universit: "",
           specialization: "",
           isItUpdated: "",
           class: "",
           state:"",
           city: "",
           markSheetFile:"",
         },
         {
           id: "12th",
           yearOfComplete: "",
           mode: "",
           board:"",
           classOfComplete: "",
           cgpa: "",
           institutionName: "",
           university: "",
           specialization: "",
           isItUpdated: "false",
           class: " ",
           state:"",
           city: '',
           markSheetFile:""
         },
         {
           id: "UG",
           yearOfComplete: "",
           mode: "",
           board:"",
           classOfComplete: "",
           cgpa: "",
           institutionName: "",
           university: "",
           specialization: "",
           isItUpdated: "false",
           degree: "",
           class: "",
           state:"",
           city: '',
           markSheetFile:"",
           courseCompletionCertificate:""
         },
         {
           id: "PG",
           degree: "",
           yearOfComplete: "",
           mode: "",
           board:"",
           classOfComplete: "",
           cgpa: "",
           institutionName: "",
           university: "",
           specialization: "",
           isItUpdated: "false",
           class: "",
           state:"",
           city: '',
           markSheetFile:"",
           courseCompletionCertificate:""
         },
         {
           id: "Ph.D",
           degree: "",
           yearOfComplete: "",
           mode: "",
           board:"",
           classOfComplete: "",
           cgpa: "",
           institutionName: "",
           university: "",
           specialization: "",
           isItUpdated: "false",
           class: "",
           state:"",
           city: '',
           remarks:"",
           markSheetFile:"",
           courseCompletionCertificate:""
         },
         {
           id: "OTHER",
           degree: "",
           yearOfComplete: "",
           mode: "",
           board:"",
           classOfComplete: "",
           cgpa: "",
           institutionName: "",
           university: "",
           specialization: "",
           isItUpdated: "false",
           state:"",
           city: '',
         },
         {
           id: "Diploma",
           degree: "",
           yearOfComplete: "",
           mode: "",
           board:"",
           classOfComplete: "",
           cgpa: "",
           institutionName: "",
           university: "",
           specialization: "",
           isItUpdated: "false",
           state:"",
           city: '',
           markSheetFile:"",
         },
       ],
     },
     holders: {
       persistence: [
           {
               id: "",
               key: "",
               value: ""
           }
       ]
   },
     dob: "",
     age: "",
     gender: "",
     bloodGroup: "",
     religion: "",
     community: "",
     nationality: "",
     aadhaarNumber: "",
     martialStatus: "",
     typeOfIdProof: "",
     email: "",
     mobileNumber: "",
     empStatus: "",
     updatedOn: "",
     createdOn: "",
     image: "",
     imageName: "",
     imageType: "",
     updatedBy: "",
   });

   
   const prevTab = () => {
       setActiveIndex(activeIndex - 1);
     };
   
     const nextTab = () => {
       setActiveIndex(activeIndex + 1);
     };
   
     const SaveStudentData = () => {
     };
     const imageUrl = ""; 
 //     const getData = () => {
       
 //       employeeSingleData() 
 //       .then((res) => {
         
 //         setEmployeeIdForm(res.data.data[0])
 //       })
 //       .catch(error => {
 //         console.error('Error fetching data:', error);
 //       });
 //     };
 // useEffect(() => {
 //   getData();
 // }, []);
 const { employeeId } = useParams();
 console.log('employeeId:', employeeId);
 const getSingleData = () => {
   employeeupdateSingleData(id)

       .then((res: AxiosResponse) => { 
         console.log(res.data.data[0],"huihuihiuhi");
         console.log(employeeIdForm.dob , "age" ) 
         // setAge(employeeIdForm.dob , a)
         console.log(res.data.data[0].academicDetails.academics[0].markSheetFile,"markSheet");
         const cgpaAsString = res.data.data[0].academicDetails.academics[0].cgpa.toString();
         console.log(res.data.data[0].academicDetails.academics[0].cgpa ,"am the cgpa ")
           setEmployeeIdForm(res.data.data[0]);
       })
       .catch((err: AxiosError) => console.log(err));
};

const age = employeeIdForm.dob ? calculateAge(employeeIdForm.dob) : "-";

function calculateAge(dob:any) {
 if(dob){ 
const dobDate = new Date(dob);
const today = new Date();
let age = today.getFullYear() - dobDate.getFullYear();
const monthDifference = today.getMonth() - dobDate.getMonth();
if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dobDate.getDate())) {
age--;
}

return age;
 }
}

const displayValue = (value:any)=>(value?value:"-")

useEffect(() => {
 getSingleData();
}, [employeeId]);
const calculateTotalExperience = () => {
 const totalMonths = employeeIdForm.teachingExperiences.details.reduce((sum, detail, index) => {
   if (index !== 4) {
     const experienceStr = String(detail.experience || '0.0');
     
     const [yearsStr, monthsStr] = experienceStr.split('.');
     
     const years = parseInt(yearsStr, 10) || 0;
     const months = parseInt(monthsStr, 10) || 0;

     return sum + (years * 12 + months);
   }
   return sum;
 }, 0);

 const totalYears = Math.floor(totalMonths / 12);
 const remainingMonths = totalMonths % 12;

 return totalMonths > 0 ? `${totalYears}.${remainingMonths < 10 ? '0' : ''}${remainingMonths}` : '';
};
const calculateTotalOtherExperience = () => {
 const totalMonths = employeeIdForm.otherExperiences.details.reduce((sum, detail, index) => {
   if (index !== 4) {
     const experienceStr = String(detail.experience || '0.0');
     
     const [yearsStr, monthsStr] = experienceStr.split('.');
     
     const years = parseInt(yearsStr, 10) || 0;
     const months = parseInt(monthsStr, 10) || 0;

     return sum + (years * 12 + months);
   }
   return sum;
 }, 0);

 const totalYears = Math.floor(totalMonths / 12);
 const remainingMonths = totalMonths % 12;

 return totalMonths > 0 ? `${totalYears}.${remainingMonths < 10 ? '0' : ''}${remainingMonths}` : '';
};
useEffect(() => {
 getSingleData();
}, [employeeId]);
// const calculateTotalOtherExperience = () => {
//   const total = employeeIdForm.otherExperiences.details.reduce((sum, detail, index) => {
//     // if (ind) {
//       const experience = Number(detail.experience);
//       return sum + (isNaN(experience) ? 0 : experience);
//     // }
//     // return sum;
//   }, 0);
//   return total > 0 ? total : '';
// };
// const fileView =()=>{

// }
// const fileView = (imgPath:any) => {
//   // const imgPath = employeeIdForm.academicDetails.academics[0].markSheetFile
//   const url = "https://dev.vinbytes.com/kings-faculty/employee/get/uploaded/file?fileName="+imgPath
//   fileDownload(url)
//     .then((res) => {             
//       toast.success(res.data.status);
//       window.open(url, '_blank');
//       // getDatas();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }
const formatDate = (date: string | Date): string => {
 console.log(date);
 
 if (!date) return '';
 return format(new Date(date), 'dd MMM yyyy'); 
};

const fileView=(imgPath:any)=>{
 const url = host+"employee/get/uploaded/file?fileName="+ imgPath
 fileDownload(url)
 .then((res)=>{
   toast.success(res.data.status);
   window.open(url, '_blank');
 })
 .catch((err) => {
   console.error(err);
   if (err.response && err.response.data && err.response.data.message) {
     toast.error("File not Uploaded");
   } else {
     toast.error('An error occurred while downloading the file'); 
   }
 });
}

useEffect(() => {
 const fetchProfileImage = async () => {
   const imageUrls = host+"employee/get/uploaded/file?fileName=" + employeeIdForm.image;
   try {
     const response = await fetch(imageUrls);
     if (response.ok) {
       setProfileImage(imageUrls);
       setNoImage(true);
     } else {
       console.error('Failed to fetch the profile image');
       setNoImage(false);
     }
   } catch (error) {
     console.error("Error making HTTP call:", error);
   }
 };

 fetchProfileImage();
}, [employeeIdForm.image]); 



     return (
       <div className=" ">
         <div className="row">
           <div className="col-md-12 col-lg-5 col-12">
             
             <div className="mainheader pb-2">
               {/* {displayValue(employeeIdForm.employeeId)} */}
               Employee Bio
               </div>
             <div className="greyfont">View Bio of the employee '{displayValue(employeeIdForm.employeeId)}'</div>
             
             <div className='d-flex mt-2'>
             {noImage ? (
 <img src={profileImage} alt="" className="img-fluid imgW" style={{height:50,width:50}}/>
) : (
 <img src={user} alt="Preview" className="img-fluid imgWidth" style={{height:50,width:50}}/>
)}
           <div className='row mx-2'>
           {/* <div className='emptype labelinput '></div> */}
           <div className='extraDatas'>{displayValue(employeeIdForm.empName)} {employeeIdForm.empType !== "" && employeeIdForm.empType !== null ? <span className='emptype labelinput ms-3 '><span className='px-3'>{displayValue(employeeIdForm.empType)}</span></span> : ''}</div> 
           <div className='labelinput pt-2' id="employeebioid">{displayValue(employeeIdForm.employeeId)} | {displayValue(employeeIdForm.designation)} | {displayValue(employeeIdForm.deptId)}</div>
             {/* <span className='employeeView'>{item.department}</span>
             <span className='employeeView'>Employee since {new Date(item.joinedOn).getFullYear()}</span> */}
           </div>
         </div>
           </div>
           <div className="col-md-12 col-lg-7 col-12 text-md-end text-start">
             <div className="float-md-end pt-3 pt-md-0">
               <span className="bacdept cursorPointer labelinput"> 
                   <Link to={`/faculty/secondsidebar/collections/employees/employeebio/${displayValue(employeeIdForm.employeeId)}`} className='link'>
                   {/* <Link to={`/faculty/secondsidebar/collections/employees/employeebio`} className='link'>  */}
                 <img src={back} className="img-fluid me-2" /> Back to Employee Home
                 </Link>
               </span>  
               <br></br>
               <Link to={`/faculty/secondsidebar/collections/employees/editemploye/${displayValue(employeeIdForm.employeeId)}`}>
               <span className=''>
               <button className='btn sendApproval mt-5'>Edit Employee Bio</button></span>

 </Link> </div>
      
           </div>
          
         </div>
        
         <div className=" mt-3" id="myTab" role="tablist">
           
             <div className="col-md-12 col-lg-11 col-12">
             <div className="float-end">
               <span className="bacdept cursorPointer labelinput">
                 
               {/* <a
         className="mt-3 me-4 profileFont cursorPointer"
         data-bs-toggle="modal"
         data-bs-target="#mymodal"
       >
         Profile Edit History
       </a> */}
               </span>
               </div>
               </div>
               <div className='table-responsive' style={{cursor:"pointer",scrollbarWidth:'none'}}>
     <table className=" mt-3 col-lg-8">
 <tbody>
 <tr className='d-flex cursorPointer'>
     <span className='under'>  
     <td className="academics-info-td">
       <span
         className={`uborder  d-flex mb-2 ${
           activeIndex === 0 && 'active'
         }`}
         id="basic_Info"
         data-bs-toggle="pill"
         data-bs-target="#basicInfo"
         role="tab"  
         aria-controls="home-tab-pane"
         aria-selected={activeIndex === 0}
         onClick={() => setActiveIndex(0)}
       >
         {/* <span className={`me-2 num   ${activeIndex === 0 && 'numval'}`}>1</span> */}
         Basic Info
       </span>
     </td>
     <td className="me-3 academics-info-td" >
       <span
         className={`uborder  d-flex ${
           activeIndex === 1 && 'active'
         }`}
         id="academics_Info"
         data-bs-toggle="tab"
         data-bs-target="#academicsInfo"
         role="tab"
         aria-controls="contact-tab-pane"
         aria-selected={activeIndex === 1}
         onClick={() => setActiveIndex(1)}
       >
         {/* <span className={`me-2 num  ${activeIndex === 1 && 'numval'}`}>2</span> */}
         Academics
       </span>
     </td>
     <td className="me-3 academics-info-td">
       <span
         className={`uborder  d-flex ${
           activeIndex === 2 && 'active'
         }`}
         id="workexperience_Info"
         data-bs-toggle="tab"
         data-bs-target="#workexperienceInfo"
         role="tab"
         aria-controls="contact-tab-pane"
         aria-selected={activeIndex === 2}
         onClick={() => setActiveIndex(2)}
       >
         {/* <span className={`me-1 num  ${activeIndex === 2 && 'numval'}`}>3</span> */}
         Work Experience
       </span>
     </td>
     <td className="me-3 academics-info-td">
       <span
         className={`uborder  d-flex ${
           activeIndex === 3 && 'active'
         }`}
         id="personal_Info"
         data-bs-toggle="tab"
         data-bs-target="#personalInfo"
         role="tab"
         aria-controls="contact-tab-pane"
         aria-selected={activeIndex === 3}
         onClick={() => setActiveIndex(3)}
       >
         {/* <span className={`me-2 num  ${activeIndex === 3 && 'numval'}`}>4</span> */}
         Personal Info
       </span>
     </td>
     <td className="me-3 basic-info-td ">
       <span
         className={`uborder  d-flex ${
           activeIndex === 4 && 'active'
         }`}
         id="contact_Info"
         data-bs-toggle="tab"
         data-bs-target="#contactInfo"
         role="tab"
         aria-controls="profile-tab-pane"
         aria-selected={activeIndex === 4}
         onClick={() => setActiveIndex(4)}
       >
         {/* <span className={`me-2 num ${activeIndex === 4 && 'numval'}`}>5</span> */}
         Contact Info
       </span>
     </td>
     </span>  
   </tr>
 </tbody>
</table>
</div>
<div className={`tab-pane fade ${activeIndex === 0 ? 'show active' : ''}`}
   id="basicInfo"
   role="tabpanel"
   aria-labelledby="basic_Info"
   style={{ display: activeIndex === 0 ? 'block' : 'none' }}>
     
     <form>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Name</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.empName)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Employee Type</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold"> {employeeIdForm.empType !== "" && employeeIdForm.empType !== null ? <span className='emptype px-3'>{displayValue(employeeIdForm.empType)}</span> : '-'} </div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Employee No.</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.employeeNo)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Email Address</div>
 <div className="col-lg-7 col-md-6 col-6 labelBold text-wrap text-break">
   {displayValue(employeeIdForm.email)}
 </div>
</div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Phone No</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.mobileNumber ? `+91 ${employeeIdForm.mobileNumber}` : '-'}</div>
     </div>
     {/* <div className="row px-3 p-2 sbg py-3">
 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Phone No</div>
 <div className="col-lg-7 col-md-6 col-6 labelBold">
   {displayValue(employeeIdForm.addressModal.emergencyContact1.mobileNumber ? `+91 ${displayValue(employeeIdForm.addressModal.emergencyContact1.mobileNumber}` : ''}
 </div> */}
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Designation</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.designation)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Department</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.deptId)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Reporting To</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.reportingTo)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Date of Joining</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold"> {displayValue(formatDate(employeeIdForm.joinedOn))}</div>
     </div>
   </form>
   </div>
   <div  className={`tab-pane fade ${activeIndex === 1 ? 'show active' : ''}`}
   id="academicsInfo"
   role="tabpanel"
   aria-labelledby="academics_Info"
   style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
     <form>
     <form>


     {
       employeeIdForm.academicDetails.academics[4].degree == "" ? "" :(
         <>
   <div className='mainheader mt-3'>Doctoral Program</div>
     <div className="row px-3 p-2 fbg py-3 mt-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Degree/Course Name</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].degree)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Specialization</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].specialization)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Month & year of Passing</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].yearOfComplete)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Mode</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].mode)}</div>
     </div>
     {/* <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Class</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].classOfComplete)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Grade or % CGPA</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].cgpa)}{employeeIdForm.academicDetails.academics[4].cgpa ? "%" :""}</div>
     </div> */}
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">University / Institution</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].institutionName)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">State</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].state)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">City/Town</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].city)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Marksheet</div>
       {/* <div className="col-lg-7 col-md-6 col-6 labelBold"><span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[4].markSheetFile)}>Click Here </span></div> */}
       <div className="col-lg-7 col-md-6 col-6 labelBold">
       {employeeIdForm.academicDetails.academics[4].markSheetFile === "" ? "-" : 
         <span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[4].markSheetFile)}>Click Here</span> 
       } 
         </div>  
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Degree / Provisional Certificate </div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">
       {employeeIdForm.academicDetails.academics[4].courseCompletionCertificate === "" ? "-" : 
        <span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[4].courseCompletionCertificate)}>Click Here </span>
       } 
         </div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Remarks</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[4].remarks)}</div>
     </div>
         </>
       )
     }


<div className='mainheader mt-3'>PG Program</div>
     <div className="row px-3 p-2 fbg py-3 mt-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Degree/Course Name</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[3].degree)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Month & year of Passing</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[3].yearOfComplete)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Mode</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[3].mode)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Class</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[3].classOfComplete)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Grade or % CGPA</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[3].cgpa)}{employeeIdForm.academicDetails.academics[3].cgpa?"%":""}</div>
     </div>  
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">College Name</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[3].institutionName)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">State</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[3].state)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">City/Town</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[3].city)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Marksheet</div>
       {/* <div className="col-lg-7 col-md-6 col-6 labelBold"><span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[3].markSheetFile)}>Click Here </span></div> */}
       <div className="col-lg-7 col-md-6 col-6 labelBold">
       {employeeIdForm.academicDetails.academics[3].markSheetFile === "" ? "-" : 
         <span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[3].markSheetFile)}>Click Here</span> 
       } 
         </div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Degree / Provisional Certificate </div>
       {/* <div className="col-lg-7 col-md-6 col-6 labelBold"><span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[3].courseCompletionCertificate)}>Click Here </span></div> */}
       <div className="col-lg-7 col-md-6 col-6 labelBold">
       {employeeIdForm.academicDetails.academics[3].courseCompletionCertificate === "" ? "-" : 
         <span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[3].courseCompletionCertificate)}>Click Here</span> 
       } 
         </div>
     </div>

     <div className='mainheader mt-3'>UG Program</div>
     <div className="row px-3 p-2 fbg py-3 mt-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Degree/Course Name</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[2].degree)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Month & year of Passing</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[2].yearOfComplete)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Mode</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[2].mode)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Class</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[2].classOfComplete)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Grade or % CGPA</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[2].cgpa)}{employeeIdForm.academicDetails.academics[2].cgpa ? "%":""}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">College Name</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[2].institutionName)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">State</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[2].state)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">City/Town</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[2].city)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Marksheet</div>
       {/* <div className="col-lg-7 col-md-6 col-6 labelBold"><span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[2].markSheetFile)}>Click Here </span></div> */}
       <div className="col-lg-7 col-md-6 col-6 labelBold">
       {employeeIdForm.academicDetails.academics[2].markSheetFile === "" ? "-" : 
         <span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[2].markSheetFile)}>Click Here</span> 
       } 
         </div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Degree / Provisional Certificate</div>
       {/* <div className="col-lg-7 col-md-6 col-6 labelBold"><span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[2].courseCompletionCertificate)}>Click Here </span></div> */}
       <div className="col-lg-7 col-md-6 col-6 labelBold">
       {employeeIdForm.academicDetails.academics[2].courseCompletionCertificate === "" ? "-" : 
         <span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[2].courseCompletionCertificate)}>Click Here</span> 
       } 
         </div>
     </div>
     <div>
 {employeeIdForm.academicDetails.academics[1] && (
   <>
     {employeeIdForm.academicDetails.academics[1].yearOfComplete ||
     employeeIdForm.academicDetails.academics[1].board ||
     employeeIdForm.academicDetails.academics[1].cgpa || 
     employeeIdForm.academicDetails.academics[1].institutionName ||
     employeeIdForm.academicDetails.academics[1].state ||
     employeeIdForm.academicDetails.academics[1].city ||
     employeeIdForm.academicDetails.academics[1].markSheetFile ? (
       <>
         <div className='mainheader mt-3'>XII Std</div>
         <div className="row px-3 p-2 fbg py-3 mt-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Program</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">XII Std</div>
         </div>
         <div className="row px-3 p-2 sbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Month & year of Passing</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[1].yearOfComplete || "-"}</div>
         </div>
         <div className="row px-3 p-2 fbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Board</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[1].board || "-"}</div>
         </div>
         <div className="row px-3 p-2 sbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Grade or % or CGPA</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">
             {employeeIdForm.academicDetails.academics[1].cgpa && parseFloat(employeeIdForm.academicDetails.academics[1].cgpa) >= 1
               ? `${employeeIdForm.academicDetails.academics[1].cgpa}${employeeIdForm.academicDetails.academics[1].cgpa ? "%" : ""}`
               : "-"}
           </div>
         </div>
         <div className="row px-3 p-2 fbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">School Name</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[1].institutionName || "-"}</div>
         </div>
         <div className="row px-3 p-2 sbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">State</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[1].state || "-"}</div>
         </div>
         <div className="row px-3 p-2 fbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">City/Town</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[1].city || "-"}</div>
         </div>
         <div className="row px-3 p-2 sbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Marksheet</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">
             {employeeIdForm.academicDetails.academics[1].markSheetFile
               ? <span className='clickBtn' onClick={() => fileView(employeeIdForm.academicDetails.academics[1].markSheetFile)}>Click Here</span>
               : "-"}
           </div>
         </div>
       </>
     ) : (
       employeeIdForm.academicDetails.academics[6] && (
         <>
           {employeeIdForm.academicDetails.academics[6].yearOfComplete ||
           employeeIdForm.academicDetails.academics[6].degree ||
           employeeIdForm.academicDetails.academics[6].classOfComplete ||
           employeeIdForm.academicDetails.academics[6].cgpa ||
           employeeIdForm.academicDetails.academics[6].institutionName ||
           employeeIdForm.academicDetails.academics[6].state ||
           employeeIdForm.academicDetails.academics[6].city ||
           employeeIdForm.academicDetails.academics[6].markSheetFile ? (
             <>
               <div className='mainheader mt-3'>Diploma</div>
               <div className="row px-3 p-2 fbg py-3 mt-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Program</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">Diploma</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">University / Institution</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[6].degree || "-"}</div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Month & year of Passing</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[6].yearOfComplete || "-"}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Class</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[6].classOfComplete || "-"}</div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Grade or % or CGPA</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">
                   {employeeIdForm.academicDetails.academics[6].cgpa && parseFloat(employeeIdForm.academicDetails.academics[6].cgpa) >= 1
                     ? `${employeeIdForm.academicDetails.academics[6].cgpa}${employeeIdForm.academicDetails.academics[6].cgpa ? "%" : ""}`
                     : "-"}
                 </div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">College</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[6].institutionName || "-"}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">State</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[6].state || "-"}</div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">City/Town</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{employeeIdForm.academicDetails.academics[6].city || "-"}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Marksheet</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">
                   {employeeIdForm.academicDetails.academics[6].markSheetFile
                     ? <span className='clickBtn' onClick={() => fileView(employeeIdForm.academicDetails.academics[6].markSheetFile)}>Click Here</span>
                     : "-"}
                 </div>
               </div>
               
             </>
           ) : 
           <>
           <div className='mainheader mt-3'>XII Std</div>
         <div className="row px-3 p-2 fbg py-3 mt-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Program</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">XII Std</div>
         </div>
         <div className="row px-3 p-2 sbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Month & year of Passing</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">-</div>
         </div>
         <div className="row px-3 p-2 fbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Board</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">-</div>
         </div>
         <div className="row px-3 p-2 sbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Grade or % or CGPA</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">
             -
           </div>
         </div>
         <div className="row px-3 p-2 fbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">School Name</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">-</div>
         </div>
         <div className="row px-3 p-2 sbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">State</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">-</div>
         </div>
         <div className="row px-3 p-2 fbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">City/Town</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">-</div>
         </div>
         <div className="row px-3 p-2 sbg py-3">
           <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Marksheet</div>
           <div className="col-lg-7 col-md-6 col-6 labelBold">
             -
           </div>
         </div>
           </>
           
           }
         </>
       )
     )}
   </>
 )}
</div>
<div className='mainheader'>X Std</div>
     <div className="row px-3 p-2 fbg py-3 mt-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Month & year of Passing</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[0].yearOfComplete)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Board</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[0].board)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Total marks secured in %</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[0].cgpa )}{employeeIdForm.academicDetails.academics[0].cgpa ? '%' : ''}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">School Name</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[0].institutionName)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">State</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[0].state)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">City/Town</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.academicDetails.academics[0].city)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Marksheet</div>
       {/* <div className="col-lg-7 col-md-6 col-6 labelBold"><span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[0].markSheetFile)}>Click Here </span> </div> */}
      
       <div className="col-lg-7 col-md-6 col-6 labelBold">
       {employeeIdForm.academicDetails.academics[0].markSheetFile === "" ? "-" : 
         <span className='clickBtn' onClick={()=>fileView(employeeIdForm.academicDetails.academics[0].markSheetFile)}>Click Here</span> 
       } 
         </div>
      
       
     </div>


    
     
     

   

{employeeIdForm.otherAcademics.academics.map((academics, index) => (
 <div key={index}>
     <>
     <div className='mainheader mt-3'>Other Program {index + 1}</div>
       <div className="row px-3 p-2 sbg py-3 mt-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Specialization</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(academics.specialization)}</div>
       </div>
       <div className="row px-3 p-2 fbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Degree/Course Name</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(academics.degree)}</div>
       </div>
       <div className="row px-3 p-2 sbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Month & year of Passing</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(academics.yearOfComplete)}</div>
       </div>
       <div className="row px-3 p-2 fbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Mode</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(academics.mode)}</div>
       </div>
       <div className="row px-3 p-2 sbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Class</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(academics.classOfComplete)}</div>
       </div>
       <div className="row px-3 p-2 fbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Grade or % CGPA</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(academics.cgpa)}{academics.cgpa ? "%" :""}</div>
       </div>
       <div className="row px-3 p-2 sbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">College Name</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(academics.institutionName)}</div>
       </div>
       <div className="row px-3 p-2 fbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">State</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(academics.state)}</div>
       </div>
       <div className="row px-3 p-2 sbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">City/Town</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(academics.city)}</div>
       </div>
       <div className="row px-3 p-2 fbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">MarkSheet</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">
       {academics.markSheetFile === "" ? "-" : 
         <span className='clickBtn' onClick={()=>fileView(academics.markSheetFile)}>Click Here</span> 
       } 
         </div>
       </div>
       <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Course completion certificate </div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">
       {academics.courseCompletionCertificate === ""?"-":
         <span className='clickBtn' onClick={()=>fileView(academics.courseCompletionCertificate)}>Click Here </span>
       }
       </div>
     </div>
     </>
 </div>
))}


   </form>
   </form>
   </div>


<div className={`tab-pane fade ${activeIndex === 2 ? 'show active' : ''}`} 
    id="workexperienceInfo" 
    role="tabpanel" 
    aria-labelledby="workexperience_Info"
    style={{ display: activeIndex === 2 ? 'block' : 'none' }} >
 {employeeIdForm.teachingExperiences.details.some(experience => experience.institution !== "") ? (
   <form>
     <div className="row mt-4">
       <div className="col-sm-4 col-md-6 col-lg-2">
         <label className="subheader">Teaching Experience</label>
         <br></br>
         <label className="greyfont">Only Engineering College</label>
       </div>
       <div className="col-sm-4 col-md-6  col-lg-4 pt-3 fbg row">
       <div className="col-lg-6 col-md-5 col-6 labelinput">Total Experience (in yrs)</div>
       <div className="col-lg-3 col-md-6 col-6 labelBold">{calculateTotalExperience()}</div>
         {/* <label className="labelinput">Total years of experience</label>
         <input
           type="number"
           readOnly
           className="form-control mt-2 labelinput non-selectable"
           placeholder="Total Experience (in yrs)"
           value={calculateTotalExperience()}
           style={{ backgroundColor: "#F7F8FA", border: "none" }}
           name="experience"
         /> */}
         {/* {isSubmitted && !employeeBasicForm.yrsOfExp && <div className="is-invalid">*Required</div>} */}
       </div>
     </div>
     <div>
       {employeeIdForm.teachingExperiences.details.map((experience, index) => (
         experience.institution === "" 
           ? null
           : (
             <div className="row mt-4" key={index}>
               <div className='mainheader mt-2 mb-3'>Experience {index+1}</div>
               <div className="row px-3 p-2 fbg py-3 mt-2">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Institution</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.institution}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Designation</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.designation}</div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Department</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.department}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">From Date</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.fromDate}</div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">To Date</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.toDate}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Experience in yrs</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.experience}</div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Experience Letter</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">
         {employeeIdForm.teachingExperiences.details[index]
                             ?.file === "" ? "-" : 
         <span className='clickBtn' onClick={()=>fileView(employeeIdForm.teachingExperiences.details[index]
           ?.file)}>Click Here</span> 
       } 

         </div>
       </div>
             </div>
           )
       ))}
     </div>
   </form>
 ) : (
   <div className="d-flex justify-content-center align-self-center">
   <img src={noRecord} /> <p className="mt-4 greyfont">There are no records.</p>
  </div>  
 )}
 {employeeIdForm.otherExperiences.details.some(experience => experience.institution !== "") ? (
   <form>
     <div className="row mt-4">
       <div className="col-sm-4 col-md-6 col-lg-2">
         <label className="subheader">Other Experience</label>
         <br></br>
         <label className="greyfont">  Research, Industry, Teaching in Arts & Science, Diploma,
         School,</label>
       </div>
       <div className="col-sm-4 col-md-6 col-lg-4 d-flex fbg my-1">
         {/* <label className="labelinput">Total years of experience</label>
         <input
           type="number"
           readOnly
           className="form-control mt-2 labelinput non-selectable"
           placeholder="Total Experience (in yrs)"
           value={calculateTotalOtherExperience()}
           style={{ backgroundColor: "#F7F8FA", border: "none" }}
           name="experience"
         /> */}
         <div className="col-lg-6 col-md-5 col-6 labelinput pt-3">Total Experience (in yrs)</div>
         <div className="col-lg-4 col-md-6 col-6 labelBold pt-3">{calculateTotalOtherExperience()}</div>
         {/* {isSubmitted && !employeeBasicForm.yrsOfExp && <div className="is-invalid">*Required</div>} */}
       </div>
     </div>
     <div>
       {employeeIdForm.otherExperiences.details.map((experience, index) => (
         experience.institution === "" 
           ? null
           : (
             <div className="row mt-4" key={index}>
               <div className='mainheader mt-2 mb-3'>Experience {index+1}</div>
               <div className="row px-3 p-2 fbg py-3 mt-2">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Category</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.category}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3 mt-2">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Institution</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.institution}</div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Designation</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.designation}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Department</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.department}</div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">From Date</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.fromDate}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">To Date</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.toDate}</div>
               </div>
               <div className="row px-3 p-2 fbg py-3">
                 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Experience in yrs</div>
                 <div className="col-lg-7 col-md-6 col-6 labelBold">{experience.experience}</div>
               </div>
               <div className="row px-3 p-2 sbg py-3">
         <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Experience Letter</div>
         <div className="col-lg-7 col-md-6 col-6 labelBold">
         {employeeIdForm.otherExperiences.details[index]
                             ?.file === "" ? "-" : 
         <span className='clickBtn' onClick={()=>fileView(employeeIdForm.otherExperiences.details[index]
           ?.file)}>Click Here</span> 
       } 

         </div>
       </div>
             </div>
           )
       ))}
     </div>
   </form>
 ) : (
   <div className="d-flex justify-content-center">
   {/* <img src={noRecord} /> <p className="mt-4 greyfont">There are no records.</p> */}
  </div>  
 )}
</div>

   
   <div  className={`tab-pane fade ${activeIndex === 3 ? "show active" : ""}`}
         id="personalInfo"
         role="tabpanel"
         aria-labelledby="personal_Info"
         style={{ display: activeIndex === 3 ? "block" : "none" }}>
           <form>
           <div className="row px-3 p-2 fbg py-3 mt-2">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Date of Birth</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(formatDate(employeeIdForm.dob))}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput">Age</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{age?age:"-"}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Gender</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.gender)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Blood Group </div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.bloodGroup)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Community</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.community)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3 mt-2">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Religion</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.religion)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Nationality</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.nationality)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Aadhar</div>
       {/* <div className="col-lg-7 col-md-6 col-6 labelBold">XXXX XXXX {displayValue(employeeIdForm.aadhaarNumber.slice(-4)}</div> */}
       <div className="col-lg-7 col-md-6 col-6 labelBold"> {employeeIdForm.aadhaarNumber == "" ? "-" : ` XXXX XXXX  ${employeeIdForm.aadhaarNumber.slice(-4)}`}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0"> Marital Status </div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.martialStatus)}</div>
     </div>
     {/* <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput">{displayValue(employeeIdForm.holders.persistence[0].key}</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold"><span className='clickBtn' onClick={()=>fileView(employeeIdForm.holders.persistence[0].value)}>Click Here</span></div>
     </div> */}
    
{/* {displayValue(employeeIdForm.holders.persistence.map((item:any,index:any)=>(
item.key === "" || "--select--" ? "":(
<>
<div className={`row px-3 p-2 ${index === 0 ? "sbg" : "fbg"} py-3`}>
       <div className="col-lg-3 col-md-5 col-6 labelinput">{item.key}</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold"><span className='clickBtn' onClick={()=>fileView(item.value)}>Click Here</span></div>
       </div>
</>
)
))
} */}
{employeeIdForm.holders.persistence.map((item:any, index:any) => (
 (item.key === "" || item.key === "--Select--") ? null : (
   <div className={`row px-3 p-2 ${index % 2 === 0 ? "sbg" : "fbg"} py-3`} key={index}>
     <div className="col-lg-3 col-md-5 col-6 labelinput">{item.key}</div>
     <div className="col-lg-7 col-md-6 col-6 labelBold">
       {item.value === "" ? "-" : <span className='clickBtn' onClick={() => fileView(item.value)}>Click Here</span> }
     </div>
   </div>
 )
))}

           </form>
     
   </div>
   <div
      className={`tab-pane fade ${activeIndex === 4 ? "show active" : ""}`}
      id="contactInfo"
      role="tabpanel"
      aria-labelledby="contact_Info"
      style={{ display: activeIndex === 4 ? "block" : "none" }}
     >
    <form>
    <div className='mainheader mt-2'>Address Details</div>
     <div className="row px-3 p-2 fbg py-3 mt-2">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Present Address</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold ">
         {displayValue(employeeIdForm.addressModal.presentAddress.addressLine1)},
         {displayValue(employeeIdForm.addressModal.presentAddress.addressLine2)},
         {displayValue(employeeIdForm.addressModal.presentAddress.state)},
         {displayValue(employeeIdForm.addressModal.presentAddress.city)}
         -{displayValue(employeeIdForm.addressModal.presentAddress.pincode)}.
         </div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Permanent Address</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">
       {displayValue(employeeIdForm.addressModal.permanentAddress.addressLine1)},
         {displayValue(employeeIdForm.addressModal.permanentAddress.addressLine2)},
         {displayValue(employeeIdForm.addressModal.permanentAddress.state)},
         {displayValue(employeeIdForm.addressModal.permanentAddress.city)}
         -{displayValue(employeeIdForm.addressModal.permanentAddress.pincode)}.
       </div>
     </div>
     <div className='mainheader mt-2'>Emergency Contact 1</div>
     <div className="row px-3 p-2 fbg py-3 mt-2">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Name</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.addressModal.emergencyContact1.name)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Relationship</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.addressModal.emergencyContact1.relationShip)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Email Address</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.addressModal.emergencyContact1.email)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Phone No</div>
 <div className="col-lg-7 col-md-6 col-6 labelBold">
   {employeeIdForm.addressModal.emergencyContact1.mobileNumber ? `+91 ${employeeIdForm.addressModal.emergencyContact1.mobileNumber}` : '-'}
 </div>
</div>

<div className='mainheader mt-2'>Emergency Contact 2</div>
     <div className="row px-3 p-2 fbg py-3 mt-2">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Name</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.addressModal.emergencyContact2.name)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Relationship</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.addressModal.emergencyContact2.relationShip)}</div>
     </div>
     <div className="row px-3 p-2 fbg py-3">
       <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Email Address</div>
       <div className="col-lg-7 col-md-6 col-6 labelBold">{displayValue(employeeIdForm.addressModal.emergencyContact2.email)}</div>
     </div>
     <div className="row px-3 p-2 sbg py-3">
 <div className="col-lg-3 col-md-5 col-6 labelinput pb-0">Phone No</div>
 <div className="col-lg-7 col-md-6 col-6 labelBold">
   {employeeIdForm.addressModal.emergencyContact2.mobileNumber ? `+91 ${employeeIdForm.addressModal.emergencyContact2.mobileNumber}` : '-'}
 </div>
</div>
    </form>
     </div>
         </div>
       </div>
 // <>
 // <div>
 //   <div></div>
 // <div className="col-md-12 col-lg-5 col-12">
   
 //   <div className='mainheader'>Employee Bio</div>
 //   <div className='greyfont'>View Bio of the employee </div>
 //   </div>
  
 //   <div className='d-flex'>
 //   <img src={user} style={{ width: "96px" }}></img>
 //   <div className='row'>
 //   <div>Vandana Parashar</div>
 //   <span>KECS20220365 | Lecturer | Computer Science and Engineering</span>
   
 //   </div>
 //   </div>
  
           
 // </div>
 // </>
     );
   }

export default ViewEmployee;