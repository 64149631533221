import React from "react"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const SkeletonLoader =()=>{
return(
<>
<div className="start">
        <div className="p-4 mt-3">
          <Skeleton count={1} height={20} width={150} className="mb-5" />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Skeleton
              count={1}
              height={20}
              width={150}
              className="mb-4"
              style={{ marginRight: 20 }}
            />
            <Skeleton
              count={1}
              height={20}
              width={150}
              className="mb-4"
              style={{ marginRight: 20 }}
            />
          </div>
          <Skeleton count={1} height={60} />
          <Skeleton count={6} height={20} className="mt-3" />
        </div>
      </div>
</>
)
}
