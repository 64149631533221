import React, { useState } from "react";
import icon from "../../assets/SidebarAssets/Vin Campus icon (1)/Vin Campus icon.png";
import homes from "../../assets/SidebarAssets/homes/home.png";
import homesActive from "../../assets/SidebarAssets/homes/homeActive.png"
import academics from "../../assets/SidebarAssets/admissions/admissions.png";
import academicsactive from "../../assets/SidebarAssets/admissions/Active.png";
import collection from "../../assets/SidebarAssets/collection/collections.png";
import collectionactive from "../../assets/SidebarAssets/collection/collectionsActive(2).png";
import proficiency from "../../assets/SidebarAssets/Proficiency/proficiency.png"
import proficiencyactive from "../../assets/SidebarAssets/Proficiency/collections.png";
import account from "../../assets/SidebarAssets/finance/finance.png";
import reports from "../../assets/SidebarAssets/reports/reports.png";
import reportsactive from "../../assets/SidebarAssets/reports/reportsActive(2).png";
import "./Sidebar.css";
import { SecondarySideBarMobile } from "../SecondarySidebar/SecondarySidebar";
import menu from "../../assets/SidebarAssets/menu.svg";
import { Link, useLocation } from "react-router-dom";
import admin from "../../assets/SidebarAssets/admin/admin.png"
import admins from "../../assets/SidebarAssets/admin/admins.png"
// type Props = {
// };

const Sidebar = () => {
  const location = useLocation();
  // const SidebarDatas = [
  //   // {
  //   //   title: "Home",
  //   //   redirectUrl: "/faculty/home",
  //   //   class: "padTop2",
  //   //   activeImg:homesActive,
  //   //   img: homes,
  //   //   isDisabled:true,
  //   // },
  //   // {
  //   //   title: "Academics",
  //   //   redirectUrl: "/faculty/secondsidebar/academics/studyMaterials",
  //   //   activeImg: academicsactive,
  //   //   img: academics,
  //   //   isDisabled:true,
  //   // },
  //   {
  //     title: "Collections",
  //     redirectUrl: "/faculty/secondsidebar/collections/employees",
  //     class: "padTop2",
  //     activeImg: collectionactive,
  //     img: collection,
  //     isDisabled:false,
  //   },
  //   {
  //     title: "Proficiency",
  //     redirectUrl: "/faculty/secondsidebar/proficiency/publications",
  //     activeImg: proficiencyactive,
  //     img:proficiency,
  //     isDisabled:false,
  //   },
  //   // {
  //   //   title: "Account",
  //   //   redirectUrl: "/faculty/secondsidebar/account",
  //   //   activeImg: academicsactive,
  //   //   img: account,
  //   //   isDisabled:true,
  //   // },
  //   // {
  //   //   title: "Reports",
  //   //   redirectUrl: "/faculty/secondsidebar/reports",
  //   //   activeImg: reportsactive,
  //   //   img: reports,
  //   //   isDisabled:true,
  //   // },
  // ];

  const SidebarDatas = sessionStorage.getItem("role") === "Admin" ? [
    {  
      title: "Collections",
      redirectUrl: "/faculty/secondsidebar/collections/employees",
      class: "padTop2",
      activeImg: collectionactive,
      img: collection,
      isDisabled:false,
      role : "Admin"
    },
    {  
      title: "Admin",
      redirectUrl: "/faculty/secondsidebar/admin/proficiencyValidation",
      class: "",
      activeImg: admin,
      img: admins,
      isDisabled:false,
      role : "Admin"
    },
  ] : [
    {
      title: "Proficiency",
      class: "padTop2",
      redirectUrl: "/faculty/secondsidebar/proficiency/publications",
      activeImg: proficiencyactive,
      img:proficiency,
      isDisabled:false,
      role : "Faculty"
    },
  ];

  const [showmobilesidebarsecondary, setshowmobilesidebarsecondary] =
    useState(false);

  function GotoMain() {
    setshowmobilesidebarsecondary(false);
  }

  return (
    <>
      <div>
        <div className="wrapper d-none d-md-block position-fixed overflow-auto packagewidth">
          <div className="d-flex flex-column vh-100">
            <div className="mb-auto">
              <div className="text-center">
                <img
                  src={icon}
                  alt="Vin Campus icon"
                  className="padTop img-fluid"
                />
                {SidebarDatas.map((item, index) => (
                  // <Link to={item.redirectUrl} className="text-decoration-none ">
                  <Link
                  to={item.redirectUrl}
                  className={`text-decoration-none ${
                    item.isDisabled ? "disabled" : ""
                  }`}
                  key={index}
                >
                    <div
                      key={index}
                      className={`cursorPointer ${
                        item.class ? item.class : ""
                      }`}
                    >
                      <img
                        src={
                          location.pathname.includes(item.title.toLowerCase())
                            ? item.activeImg
                            : item.img
                        }
                        alt={item.title}
                        className="padTop img-fluid"
                      />
                      <p className="sideBarLink">{item.title}</p>
                    </div>
                  </Link>
                ))}
                
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobileview */}

      <div className="d-block d-md-none mobView py-2 px4">
        <div className="d-flex">
          <div className="col-6">
            <img
              src="assets/sidebar/Vin Campus icon/Vin Campus icon.png"
              alt=""
              className="img-fluid ms-3"
            />
          </div>
          <div className="col-6 my-auto">
            <button
              className="btn float-end hamburg mx-3 bg-light"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offCanvasRight"
              aria-controls="offcanvasRight"
            >
              <img src={menu} alt="menu" className="" />
            </button>
            <div
              className="offcanvas offBg offcanvas-end w-50 mt4"
              id="offCanvasRight"
              aria-labelledby="offcanvasRightLabel "
            >
              <div className="d-block d-md-none sideMob">
                <div
                  className="d-flex flex-column heightSidebar"
                  style={{
                    backgroundColor: showmobilesidebarsecondary
                      ? "white"
                      : null,
                  }}
                >
                  {showmobilesidebarsecondary ? (
                    <SecondarySideBarMobile backMain={() => GotoMain()} />
                  ) : (
                    <div className="px-3">

                      {SidebarDatas.map((item, index) => (
                        <Link
                          to={item.redirectUrl}
                          className="text-decoration-none"
                        >
                          <div className="cursorPointer mt-3">
                            <img
                              src={
                                location.pathname.includes(
                                  item.title.toLowerCase()
                                )
                                  ? item.activeImg
                                  : item.img
                              }
                              alt={item.title}
                              className=""
                            />
                            <span
                              className="sideBarLink ms-2"
                              onClick={() => {
                                setshowmobilesidebarsecondary(true);
                              }}
                            >
                              {item.title}
                            </span>
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
