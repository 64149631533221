import React from 'react'

function Report() {
  return (
    <div>
      Report
    </div>
  )
}

export default Report
