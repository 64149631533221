import React from 'react'
import { Outlet } from 'react-router-dom'

type Props = {}

const ConsultancyEmp = (props: Props) => {
  return (
    <>
    <Outlet/>
    </>
  )
}
export default ConsultancyEmp