import React from 'react'
import { Link ,Outlet,useNavigate} from 'react-router-dom'
import { useState ,useEffect } from 'react';
import searchIcon from "../../../../assets/Icon search.svg";
import cross from "../../../../assets/crossimg.png";
import DynamicTables from '../../../../components/DynamicTable/DynamicTables';
import { OnlineCourseGetTable } from "../../../../ApiServices";
import {ResponsibilitiesGetTable , DeleteTabledata,fileDownload ,ResponsibilitiesGetTableData ,TableSubmitCall,host} from "../../../../ApiServices"
import CourseTable from '../../../../components/CourseDetailTable/CourseTable';
import { ToastContainer, toast } from "react-toastify";
import { format as formatDate } from "date-fns";
import close from "../../../../assets/close.svg";
import noRecord from "../../../../assets/norecords.png"
import { SkeletonLoader } from '../../../skeletonLoader';
import { NoData } from "../../../NoData";


function Responsisbilities() {
  const navigate = useNavigate();
  const columnRedirectsOnlineCourse = {
    courseTitle:"/proficiency/responsibilities/newResponsiblities/new-add",
  };
  
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage,setCurrentPage] = useState(1)
  const [TotalElements , setTotalElements] = useState(0)
  const [totalpages,settotalpages] = useState(0);
  const [sort , setSort] = useState(false);
  const [sortusing,setSortUsing]= useState("resId")
  const [tableDatas, settableDatas] = useState([]);
  const [selectedItem, setselectedItem] = useState<any>([])
  const [viewSingle , setViewSingle] = useState(false)
  const [viewTitle,setViewTitle] = useState();
  const [pubIdView,setPubIdView] = useState();
  const [createdOn , setCreatedOn] = useState<string>('');
  const [updatedOn , setUpdatedOn] = useState<string>('');
  const [getData, setGetData] = useState();
  const [loader , setLoader] = useState(true)

  const heading = viewTitle
  const subheading = "View details of additional roles and responsibilities"

  const [isSearchOpen, setIsSearchOpen] = useState(false);
    
    
  const toggleSearch = () => {
setIsSearching(!isSearching);
setSearchTerm("");
setIsSearchOpen(!isSearchOpen);
if (!isSearchOpen) {
  setSearchTerm('');
}
};
const handleCancel = () => {
setSearchTerm('');
setIsSearchOpen(false);
};


  const handleNextPage=()=>{
    setCurrentPage((prevPage)=>Math.min(prevPage+1,totalpages))
    console.log("next button clicked and the value now is "+ currentPage)
    }
  
   const handlePrevPage=()=>{
   setCurrentPage((prevPage)=>Math.max(prevPage-1,1))
   }
  
   const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
     setCurrentPage(value);
   };

   const handleSort =()=>{
    setSortUsing("academicYear")
    setSort(!sort);
  }

  const sortOrder = sort? "asc":"desc"

  const getDatas = () => {
    const data = {
      empID:sessionStorage.getItem('empId'),
      page:currentPage-1,
      sort:sortOrder,
      sordId:sortusing,
      keyword:searchTerm,
    }

    ResponsibilitiesGetTable(data)
      .then((res) => {
        const formattedData = res.data.datas.content.map((item: any) => {
          let formattedCreatedDate = "";
          let formattedUpdatedDate = "";
          let formattedSubmittedOn = "";
          let formattedValidatedOn = "";
        
          if (item.createdOn) {
            const createdOnDate = new Date(item.createdOn);
            formattedCreatedDate = formatDate(createdOnDate, "dd MMM yyyy hh:mm a");
          }
        
          if (item.updatedOn) {
            const updatedOnDate = new Date(item.updatedOn);
            formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
          }

          if(item.submittedOn){
            const submittedOnDate = new Date(item.submittedOn)
            formattedSubmittedOn = formatDate(submittedOnDate, "dd MMM yyyy hh:mm a")
          }

          if(item.validatedOn){
            const validatedOnDate = new Date(item.validatedOn)
            formattedValidatedOn = formatDate(validatedOnDate, "dd MMM yyyy hh:mm a")
          }
        
          return {
            ...item,
            createdOn: formattedCreatedDate,
            updatedOn: formattedUpdatedDate,
            submittedOn:formattedSubmittedOn,
            validatedOn:formattedValidatedOn
          };
        });

        settableDatas(formattedData);
        setLoader(false)
        setGetData(res.data.data[0]);
        console.log(res.data.datas.content, "gopi");
        setTotalElements(res.data.datas.totalElements)
        settotalpages(res.data.datas.totalPages)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const Header = [
    // {label:"S.No" , key:"SNo"},
    { label: "Academic Year", key: "academicYear" },
    { label: "Name of the Responsibility", key: "responsibilityName" },
    { label: "Role", key: "role" },
    { label: "Submitted on", key: "submittedOn" },
    { label: "Status", key: "status" },
    { label: "Validated On", key: "validatedOn" },
    // {label:"Actions", key:"Actions"},
  ];

  
const ResponsibilitiesViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  {
    "title": "Name of the Responsibility",
    "key":"responsibilityName"
  }, {
    "title": "Role",
    "key":"role"
  }, 
  {
    "title": "Level",
    "key":"type"
  },
  {
    "title": "Duration",
    "key":"duration"
  }, {
    "title": "In-charge / HOD",
    "key":"inCharge"
  }, {
    "title": "Certificate, if any",
    "key":"uploadFile"
  }
]

  function navigateView(item: any){
    ResponsibilitiesGetTableData(item.resId)
    .then((res)=>{
      setselectedItem(res.data.data[0])
      setViewTitle(res.data.data[0].proficiency.responsibilityName);
      setPubIdView(res.data.data[0].proficiency.resId)
      const formattedCreatedDate = new Date(res.data.data[0].proficiency.createdOn);
      const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");
      let updatedOn = "";
      if (res.data.data[0].proficiency.updatedOn !== null) {
          const formattedUpdatedDate = new Date(res.data.data[0].proficiency.updatedOn);
          updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
      }         
      setCreatedOn(createdOn);
      setUpdatedOn(updatedOn);
      setViewSingle(true)
    })
    // setselectedItem([...selectedItem,item])
    // setEdit(true)
  }
  
  function editCourse(item:any){
    console.log(item,"ssbiki")
    const data = item.resId?item.resId:item.proficiency.resId;
    console.log(data);
    navigate("editResponsibilities/"+data)
  }

  const deleteApi=(data: any) =>{
    const url = "/responsibilities?resId=" + data.resId;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);
        toast.success(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  } 
  const deleteApiView=() =>{
    const url = "/responsibilities?resId=" + pubIdView;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);        
        toast.success(res.data.status);
        setViewSingle(false) 
        // alert(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }


  const backtoview = "to Responsibilities"
  const deleteCont = "Responsibility"
  const FileDownload = () => {
    const url = host+"proficiency/get/responsibilities?resId=" + pubIdView + "&keyword=file";
    fileDownload(url)
      .then((res) => {             
        toast.success(res.data.status);
        window.open(url, '_blank');
        getDatas();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const TableSubmit =(item:any)=>{
    console.log("tablesumbit toggled ")
    const payload = {
      empId:sessionStorage.getItem('empId'),
      sectionId:item.resId,
      status :"Pending",
      section:"Responsibilities",
     academicYear:item.academicYear
  }
console.log(payload,"hkjgkjgh");

  TableSubmitCall(payload)
  .then((res)=>{
    console.log(res)
    getDatas()
    toast.success(res.data.responseMessage)
  })
  .catch((err)=>{
    console.log(err)
  })
  console.log(payload , "am the payload")
  // window.location.reload()
  setViewSingle(false)
  }

  useEffect(() => {
    getDatas();
  }, [currentPage,sort,searchTerm]);

  const moduleName = "Responsibilities"
  const AddNewPath = "/faculty/secondsidebar/proficiency/responsibilities/newResponsiblities"

  return (
 <>
 <ToastContainer/>
 {!viewSingle?
 <>
 <div>
        <div className="mainheader">
        Additional Responsibilities
      </div>
      <div className="d-flex justify-content-between greytext">
        <span>Additional Responsibilities</span>
        {getData === true && (
        <Link to="/faculty/secondsidebar/proficiency/responsibilities/newResponsiblities">
          <button className="text-white addnewbtn">Add New</button>
        </Link>
        )}
      </div>
      {getData === true && (
      <div className="addresult">
      <span className="active tablink pb-1 bg addresult mb-3">All Results</span>
      <div className={`${isSearchOpen ? 'me-0' : 'me-5'} search`} style={{boxShadow:'none'}}>
  <img
    alt="search"
    // className="fasearch mb-2"
    className={`${isSearchOpen ? 'ms-0' : 'ms-3'} fasearch mb-2`}
    src={searchIcon}
    onClick={toggleSearch}
    style={{cursor:'pointer'}}
  />
  {isSearchOpen && (
    <div className="search-input-container">
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
      />
      <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }}/>
    </div>
  )}
</div>
      </div>
      )}
      {/* {getData === false ? (
                    <div className="d-flex flex-column align-items-center" style={{ marginTop: "200px" }}>
                      <img src={noRecord} alt="No records" />
                      <p className="mt-4 greyfont" style={{ textAlign: 'center' }}>
                        You haven't added any responsibilities yet.<br />Start by adding a responsibility.
                      </p>
                      <Link to="/faculty/secondsidebar/proficiency/responsibilities/newResponsiblities">
                        <button className="text-white addnewbtn">Add New</button>
                      </Link>
                    </div>
                  ) : (
      <DynamicTables data={tableDatas} currentPage={currentPage} headers={Header} pages={totalpages} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} deleteApi={deleteApi} navigateView={navigateView} editCourse={editCourse} TableSubmit={TableSubmit} />
    )} */}
    {
      loader?
      <SkeletonLoader/> :
      getData ? 
      <DynamicTables data={tableDatas} currentPage={currentPage} headers={Header} pages={totalpages} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} deleteApi={deleteApi} navigateView={navigateView} editCourse={editCourse} TableSubmit={TableSubmit} />
      :
      <NoData name={moduleName} path={AddNewPath}/>
    }
    <Outlet/>
                 
    </div>
 </>
 :

 <CourseTable data={[selectedItem]} deleteCont={deleteCont} backtoview={backtoview} back={()=>{setViewSingle(!viewSingle)}} edit={()=>{setselectedItem([]);setViewSingle(false)}} headers={ResponsibilitiesViewHeaders}
 heading = {heading} deleteApiView = {deleteApiView} FileDownload = {FileDownload}
 subheading = {subheading}
 createdOn = {createdOn}
 updatedOn = {updatedOn}
 TableSubmit={TableSubmit}
 editCourse={editCourse}
 />
}
 </>
    
  )
}

export default Responsisbilities
