import React from "react"
import edit from "../../../../assets/Icon material-edit.png"
import "../AcademicYear/AcademicYear.css"

const AcademicYear =()=>{

    const Dummydatas =[
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
        {
            year:"2023-2024",
            oddSem:"24 May 2023 to 25 Oct 2023",
            evenSem:"15 Nov 2023 to 24 Apr 2024",
        },
    ]

    
return (
    <>
       <>
            <div className="row">
                <div className="col-md-12 col-lg-5 col-12">
                    <div className="mainheader">Academic Year</div>
                    <div className="greyfont">View all the academic year</div>
                </div>

                <div className="col-md-12 col-lg-7 col-12">
                    <div className="float-end">
                        <span className='bacdept cursorPointer labelinput'>
                            <button className=" ms-3 addnewbtn" style={{ color: 'white' }} >Create New</button>
                        </span>
                    </div>
                </div>

                <div>

                    {/* <div className="pt-3">  
                    <span>Active</span> 
                    <span>closed</span>
                   </div> */}   
                   <div className="d-flex flex-wrap gap-3 pt-5">
                   {Dummydatas.map((data)=>(
                         <div className="border rounded col-3 containersAY ">
                       <div className="d-flex justify-content-between year">
                        <span>{data.year}</span>
                        <img src={edit} className="editlogo"/>
                       </div>

                       <div className="oddRow row">
                        <span>Odd Semester (Term I)</span>
                        <span>{data.oddSem}</span>
                       </div>   

                       <div className="evenRow row">
                        <span>Even Semester (Term II)</span>
                        <span>{data.evenSem}</span>
                       </div>

                     </div>
                    ))}
                   </div>
                </div>
            </div>
   
        </>
    </>
)
}

export default AcademicYear