import "./DynamicTable.css";
import { MdEdit, MdDeleteForever } from "react-icons/md";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import "./DynamicTable.css";
import { Link, useNavigate } from "react-router-dom";
import sortIcon from "../../assets/SortIcon.png";
import { GoChevronUp } from "react-icons/go";
import noRecord from "../../assets/norecords.png";
import { FaCaretUp } from "react-icons/fa6";
import { FaCaretDown } from "react-icons/fa6";
import YetToSubmit from "../../assets/yet to submit.png";
import verfied from "../../assets/verified.png";
import rejected from "../../assets/Icon ionic-ios-close-circle.png";
import pending from "../../assets/Icon ionic-ios-alert.png";
import edit from "../../assets/Icon material-edit.png";
import deleteicon from "../../assets/delete.png";
import tick from "../../assets/disabled submit.png";
import edits from "../../assets/edit.png";
import path from "path";
import { useLocation } from "react-router-dom";
// import sortIcon from "../../../../assets/SortIcon.png"
interface Column {
  label: string;
  key: string;
}

interface DataItem {
  [key: string]: any;
  location:any;
}

interface Props {
  heading?: any;
  currentPage?: any;
  pages?: any;
  data?: DataItem[];
  headers?: Column[];
  columnRedirects?: { [key: string]: string };
  handleNextPage?: () => void;
  handlePrevPage?: () => void;
  TotalElements?: any;
  handleChangePage?: any;
  sort?: any;
  sortType?: any;
  handleSort?:any;
  deleteApi?: any;
  navigateView?: any;
  editCourse?: any;
  TableSubmit?: any;
  showSNo?: boolean;
}

const DynamicTables: React.FC<Props> = ({
  pages,
  currentPage,
  data = [],
  headers,
  columnRedirects,
  handleNextPage,
  handlePrevPage,
  TotalElements,
  handleChangePage,
  handleSort,
  deleteApi,
  editCourse,
  navigateView,
  TableSubmit,
  heading,
  showSNo = true,
}) => {
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const location = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setselectedItem] = useState<any>(null);
  const [sortIcons, setSortIcons] = useState(false);
  const [submitToggle, setSubmitToggle] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleDeleteClick = (item: any) => {
    console.log(item, " am the item ur searching for ");
    setselectedItem(item);
    setIsModalOpen(true);
  };
  const sortingChanges = () => {
    setSortIcons((prevState) => !prevState);
  };
  const passData = (data: any) => {
    navigate(
      "/faculty/secondsidebar/proficiency/onlinecourses/CourseDetailsTable/" +
        data.coId
    );
    console.log(data, "gopi");
  };
  const getTitle = (heading: any) => {
    if (heading.length > 25) {
      return heading.substring(0, 25) + "...";
    }
    return heading;
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);

  const toggleSubmit = (item: any) => {
    setSubmitToggle(true);
    setselectedItem(item);
  };
  console.log(headers , "headers")

  return (
    <div>
      <div className=" mt-3">
        {data.length === 0 ? (
          <div className="align-content-center" style={{height:'50vh'}}>
          <div
            className="d-flex justify-content-center"
            // style={{ marginTop: "150px" }}
          >
            <div className="text-center">
            <img src={noRecord} />{" "}<br/>
            <p className="mt-3 greyfont" style={{color:'#1B1922'}}>There are no records.</p>
            </div>
          </div>
          </div>
        ) : (
          <>
          <div className="partiTables">
            <Table
              className="col-md-12 Border"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead className="border-bottom">
                <TableRow style={{verticalAlign:'super',}}>
                  {/* <TableCell className="cursorPointer table-head">S.NO</TableCell> */}
                  {showSNo && (
                    <TableCell className="cursorPointer table-head">
                      S.No
                    </TableCell>
                  )}
                  {headers?.map((header, index) => (
                    // <TableCell key={index} className="cursorPointer" onClick={() => handleSort(header.key, index)}>
                    <TableCell
                      key={index}
                      className="cursorPointer table-head"
                      // onClick={handleSort}
                    >
                      {header.label}
                      {/* {header.label === "Academic Year" &&
                        (!sortIcons ? (
                          <FaCaretDown
                            className="mx-2"
                            onClick={sortingChanges}
                          />
                        ) : (
                          <FaCaretUp
                            className="mx-2"
                            onClick={sortingChanges}
                          />
                        ))} */}
                      {header.label === "Name" && location.pathname === "/faculty/secondsidebar/admin/proficiencyValidation" &&  (
                        <span onClick={()=>{handleSort("name")}} >
                          {!sortIcons ? <FaCaretDown className="mx-2" onClick={sortingChanges} /> : <FaCaretUp className="mx-2" onClick={sortingChanges}/>} 
                        </span>             
)}
   {header.label === "Department" && location.pathname === "/faculty/secondsidebar/admin/proficiencyValidation" && (
                        <span onClick={()=>{handleSort("department")}} >
                          {!sortIcons ? <FaCaretDown className="mx-2" onClick={sortingChanges} /> : <FaCaretUp className="mx-2" onClick={sortingChanges}/>} 
                        </span>                
)}
                      {/* {header.label === "Academic Year" ? (
  sortIcons ? (
    <img src={sortIcon} className="px-2" /> 
  ) : (
    <GoChevronUp /> 
  )
) : null } */}
                    </TableCell>
                  ))}
                  {showSNo && (
                    <TableCell className="cursorPointer table-head">
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className="cursorPointer">
                {data?.map((item, index) => (
                  <TableRow key={index}>
                    {showSNo && (
                      <TableCell className="table-SNo py-0">
                        {(currentPage - 1) * 10 + index + 1}
                      </TableCell>
                    )}
                    {headers?.map((columnItem, index) => (
                      <TableCell
                        key={index}
                        className="table-datas py-0"
                        onClick={() => navigateView(item)}
                      >
                        {/* {item[columnItem.key] ? getTitle(item[columnItem.key]) : "-"} */}
                        {columnItem.key === "status" ? (
                          <span
                            className={
                              item[columnItem.key] === "Yet to submit"
                                ? "bg-YetToSubmit"
                                : item[columnItem.key] === "Verified"
                                ? "bg-Verified"
                                : item[columnItem.key] === "Pending"
                                ? "bg-Pending"
                                : item[columnItem.key] === "Rejected"
                                ? "bg-Rejected"
                                : ""
                            }
                          >
                            {/* {item[columnItem.key] ? getTitle(item[columnItem.key]) : "-"} */}
                            {item[columnItem.key] === "Yet to submit" && (
                              <img src={YetToSubmit} className="statusIconY" />
                            )}
                            {item[columnItem.key] === "Pending" && (
                              <img src={pending} className="statusIcon" />
                            )}
                            {item[columnItem.key] === "Rejected" && (
                              <img src={rejected} className="statusIcon" />
                            )}
                            {item[columnItem.key] === "Verified" && (
                              <img src={verfied} className="statusIcon" />
                            )}
                            {item[columnItem.key]
                              ? getTitle(item[columnItem.key])
                              : "-"}
                          </span>
                        ) : columnItem.key !== "amountSanction" ? (
                          item[columnItem.key] ? (
                            <span style={{textDecoration:columnItem.label==='Employee No.'? 'underLine':''}}>
                            {getTitle(item[columnItem.key])}</span>
                          ) : (
                            "-"
                          )
                        ) : (
                          "₹ " +
                          new Intl.NumberFormat("en-IN").format(
                            item[columnItem.key]
                          )
                        )}
                      </TableCell>
                    ))}
                    {/* {item.status ==="Yet to submit"?
                  <TableCell className="icons">
                  <button className=" btn CreateBtn" onClick={()=>toggleSubmit(item)} >Submit</button>
                  <img src={edit} onClick={()=>editCourse(item)} className="editicons"/>
                  <img src={deleteicon} onClick={() => handleDeleteClick(item)} className="DeleteIcon"  />
                  </TableCell>
                  :
                  item.status ==="Verified" || "Pending" ?
                  <TableCell className="icons">
                  
                  <button className="SubmittedBtn"><span className="px-1"><img src ={tick}/></span>Submitted</button>                
                  <img src={edit} onClick={()=>editCourse(item)} className="editicons" />
                  </TableCell>
                  :
                  item.employeeId ?
                  <TableCell className="icons">             
                  <img src={edit} onClick={()=>editCourse(item)} className="editicons" />
                </TableCell>
                  :
                  <TableCell className="icons">
                    <img src={edit} onClick={()=>editCourse(item)} className="editicons" />             
                    <img src={deleteicon} onClick={() => handleDeleteClick(item)} className="DeleteIcon"  />
                  </TableCell>
                  } */}

                    {showSNo && (
                      <TableCell className="icons py-1">
                        {item.status === "Yet to submit" &&
                        item.overAllStatus === false ? (
                          <>
                            <button
                              className="btn CreateBtn"
                              onClick={() => toggleSubmit(item)}
                            >
                              Submit
                            </button>
                            <img
                              src={edits}
                              onClick={() => editCourse(item)}
                              className="editicons"
                            />
                            <img
                              src={deleteicon}
                              onClick={() => handleDeleteClick(item)}
                              className="DeleteIcon"
                            />
                          </>
                        ) : item.status === "Yet to submit" &&
                          item.overAllStatus === true ? (
                          <TableCell className="icons ps-0 py-1">
                            <button
                              className=" btn CreateBtn"
                              onClick={() => toggleSubmit(item)}
                            >
                              Submit
                            </button>
                            <img
                              src={edits}
                              onClick={() => editCourse(item)}
                              className="editicons"
                            />
                          </TableCell>
                        ) : item.status === "Pending" ? (
                          <TableCell className="icons py-1 ps-0">
                            <button className="SubmittedBtn">
                              <span className="px-1">
                                <img src={tick} />
                              </span>
                              Submitted
                            </button>
                          </TableCell>
                        ) : item.status === "Verified" ||
                          item.status === "Rejected" ? (
                          <>
                            <button className="SubmittedBtn">
                              <span className="px-1">
                                <img src={tick} />
                              </span>
                              Submitted
                            </button>
                            <img
                              src={edits}
                              onClick={() => editCourse(item)}
                              className="editicons"
                            />
                          </>
                        ) : item.employeeId ? (
                          <>
                            <img
                              src={edit}
                              onClick={() => editCourse(item)}
                              className="editicons"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={edits}
                              onClick={() => editCourse(item)}
                              className="editicons"
                            />
                            <img
                              src={deleteicon}
                              onClick={() => handleDeleteClick(item)}
                              className="DeleteIcon"
                            />
                          </>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="pagination col-md-12 d-flex justify-content-between align-items-center mt-3">
              <div className="labelinput ms-3" style={{ color: "grey" }}>
                Total {TotalElements} Results
              </div>
              <div className="d-flex align-items-center labelinput me-2">
                <div
                  onClick={handlePrevPage}
                  className={`mr-2 cursorPointer ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  style={{ color: currentPage === 1 ? "grey" : "black" }}
                >
                  Prev
                </div>
                <Pagination
                  className="mx-2"
                  count={pages}
                  onChange={(event, value) => handleChangePage(event, value)}
                  page={currentPage}
                  hideNextButton
                  hidePrevButton
                  sx={{
                    "& .MuiPaginationItem-root.Mui-selected": {
                      backgroundColor: "#D3DFF8",
                    },
                  }}
                />
                <div
                  onClick={handleNextPage}
                  className={`ml-2 cursorPointer me-4 ${
                    currentPage === pages ? "disabled" : ""
                  }`}
                  style={{ color: currentPage === pages ? "grey" : "black" }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>

          {/* <div className="pagination d-block align-items-center mt-3">
            <div className="row">
              <div className="col-md-3 col-12">
              <div className="labelinput pb-3 pb-md-0 mt-2" style={{ color: "grey" }}>
                Total {TotalElements} Results
              </div>
              </div>
              <div className="col-md-9 col-12">
              <div className="d-flex align-items-center justify-content-end labelinput">
                <div
                  onClick={handlePrevPage}
                  className={`mr-2 cursorPointer ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  style={{ color: currentPage === 1 ? "grey" : "black" }}
                >
                  Prev
                </div>
                <Pagination
                  className="mx-1 px-1"
                  count={pages}
                  onChange={(event, value) => handleChangePage(event, value)}
                  page={currentPage}
                  hideNextButton
                  hidePrevButton
                  sx={{
                    "& .MuiPaginationItem-root.Mui-selected": {
                      backgroundColor: "#D3DFF8",
                    },
                    ".MuiPaginationItem-root":{
                     margin:'-1px'
                    }
                  }}
                />
                <div
                  onClick={handleNextPage}
                  className={`ml-2 cursorPointer ${
                    currentPage === pages ? "disabled" : ""
                  }`}
                  style={{ color: currentPage === pages ? "grey" : "black" }}
                >
                  Next
                </div>
              </div>
              </div>
              </div>
            </div> */}
          </>
        )}
      </div>

      {isModalOpen && (
        <div
          className="modal fade show modal-sm"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content ps-4 pt-1">
              <div className="text-end">
                <button
                  // style={{ marginLeft: "88%" }}
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                ></button>
                </div>
              <div className="">
                <h5 className="modal-title text-start col-12 ms-3 ">
                  Delete Confirmation
                </h5>
              </div>
              <div className="">
                <p className="fontModel pt-2 text-start ms-3">
                  Are you sure you want to delete? <br />
                  You can't undo this action.
                </p>
              </div>
              <div className="text-start mt-1 mb-4 ms-3">
                <button
                  type="button"
                  className="btn bg-white text-black cancelBtn me-2"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btnBack"
                  onClick={() => {
                    deleteApi(selectedItem);
                    setIsModalOpen(false);
                  }}
                >
                  Yes, delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {submitToggle && (
        <div
          className="modal fade show modal-sm"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content centered">
              <button
                style={{ marginLeft: "88%" }}
                type="button"
                className="btn-close mt-2"
                aria-label="Close"
                onClick={() => {
                  setSubmitToggle(false);
                }}
              ></button>
              <div className="mt-4">
                <h5 className="modal-title text-center col-12">
                  Submit for validation
                </h5>
              </div>
              <div className="mt-2">
                <p className="fontModel">
                  This entry will be sent to admin <br />
                  for validation.
                </p>
              </div>
              <div className="mt-2 mb-5">
                <button
                  type="button"
                  className="btn bg-white text-black cancelBtn mx-1"
                  onClick={() => setSubmitToggle(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btnBack mx-1"
                  // onClick={() => {()=>TableSubmit(selectedItem);
                  //   setSubmitToggle(false);
                  // }}
                  onClick={() => {
                    TableSubmit(selectedItem);
                    setSubmitToggle(false);
                    // window.location.reload();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Overlay */}
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
      {submitToggle && <div className="modal-backdrop fade show"></div>}

      {/* submitToggle */}
    </div>
  );
};

export default DynamicTables;
