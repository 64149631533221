import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "../../OnlineCourse/AddNew/AddNew.css";
import { GoArrowLeft } from "react-icons/go";
import { SlArrowDown } from "react-icons/sl";
import { ConsultancyCreate, AcademicYear } from "../../../../../ApiServices";
import back from "../../../../../assets/back.png";
import { ToastContainer, toast } from "react-toastify";
import yearList from "../../../../../YearList.json"; 

import "./AddConsultancy.css";
// import { useState } from 'react';

type Props = {};

const AddConsultancy = (props: Props) => {
  const navigate = useNavigate();
  const [formDatas, setformDatas] = useState({
    academicYear: "",
    Category: "",
    Agency: "",
    SanctionedAmount: "",
    CompletionPercentage: "",
    awardingInstitution: "",
    uploadFile: "",
  });
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [fileVal, setFileVal] = useState<string>("");
  const [toggleSubMod, setToggleSubMod] = useState(false);
  const [completionError, setCompletionError] = useState(false); 

  const handleSave = (e: FormEvent<HTMLButtonElement>) => {
    setToggleSubMod(false);
    createConsultancy(e, "Yet to submit");
  };

  const handleSubmit = (e: FormEvent<HTMLButtonElement>) => {
    if(validation()===true){
      if (!toggleSubMod) {
        setToggleSubMod(true);
      } else {
        setToggleSubMod(false);
        createConsultancy(e, "Pending");
      }
    }
  };

  const validation=()=>{
    setIsSubmitted(true);
    if (
      !formDatas.academicYear ||
      !formDatas.Category ||
      !formDatas.Agency ||
      !formDatas.SanctionedAmount ||
      !formDatas.CompletionPercentage ||
      formDatas.CompletionPercentage === "0"
    ) {
      console.log("All fields are required");
      return;
    }else return true
  }

  const createConsultancy = (e: FormEvent<HTMLButtonElement>, status: any) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (
      !formDatas.academicYear ||
      !formDatas.Category ||
      !formDatas.Agency ||
      !formDatas.SanctionedAmount ||
      !formDatas.CompletionPercentage ||
      formDatas.CompletionPercentage === "0"
    ) {
      console.log("All fields are required");
      return;
    }
    const payload = {
      empId: sessionStorage.getItem("empId"),
      academicYear: formDatas.academicYear,
      createdBy: "Jebarson Samuel",
      category: formDatas.Category,
      agency: formDatas.Agency,
      amountSanction: formDatas.SanctionedAmount,
      completionPercentage: formDatas.CompletionPercentage,
      fileUpload: uploadFiles,
      updatedBy: "",
      status: status,
    };
    console.log(payload, "test");
    ConsultancyCreate(payload)
      .then((res) => {
        setIsButtonClicked(true);
        // alert(res.data.status);
        navigate("/faculty/secondsidebar/proficiency/consultancy");
        setTimeout(() => {
          toast.success(res.data.status);
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(payload);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");
    let updatedValue = value;

    if (name == "CompletionPercentage") {
      if (parseInt(value) > 100) {
        updatedValue = 100;
      }
      const allowedCharacters = /[0-9.]/;
      if (!allowedCharacters.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
      setformDatas({
        ...formDatas,
        [e.target.name]: e.target.value,
        [name]: updatedValue,
      });
    } else {
      if (value.length > 0) {
        var words = value.split(" ");
        for (var i = 0; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        updatedValue = words.join(" ");
      }
      setformDatas({
        ...formDatas,
        [e.target.name]: e.target.value,
        [name]: updatedValue,
      });
    }
  };
  const onlyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;     
    if (!/^[0-9]$/.test(key)) {
      e.preventDefault();
    }
  };
  const handleBackButtonClick = () => {
    setShowModal(true);
  };
  var base64CodePhoto = "";
  const base64ConverterFile = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error(
        "File size exceeds 2 MB limit. Please upload a smaller file."
      );
    } else {
      if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
        toast.error(
          "File format not supported. Please try uploading PDF or JPG files."
        );
        return;
      } else {
        getBase64(file);
        handleChange(e);
        setFileVal(file.name);
      }
    }
  };

  const onLoad = (fileString: any) => {
    base64CodePhoto = fileString;
    setUploadFiles(fileString);
  };
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedCharacters = /[0-9]/;
    if (!allowedCharacters.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };
  const handleInputKeyDownPer = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedCharacters = /[0-9.]/; // Updated regular expression to include %
    if (!allowedCharacters.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };
  const [academicYears, setAcademicYear] = useState([]);
  interface AcademicYear {
    id: number;
    academicYear: string;
    term: number;
    startDate: string;
    endDate: string;
  }
  const acad = () => {
    AcademicYear().then((res) => {
      console.log(res.data.data);
      const uniqueAcademicYears = res.data.data.filter(
        (item: any, index: any, self: any) =>
          index ===
          self.findIndex((t: any) => t.academicYear === item.academicYear)
      );
      setAcademicYear(uniqueAcademicYears);
    });
  };
  const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
  useEffect(() => {
    acad();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/consultancy"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
      <form>
        {/* <h4 className="mainheader">Add New Consultancy</h4>
         <div className="row greytext">
         <span className="col-8">
         Consultancy or suggestions and feedback recently.
         </span>
         <div className="col-4 d-flex justify-content-between align-items-center">
         <Link to="/faculty/secondsidebar/proficiency/consultancy" style={{textDecoration:"none" , color:"black" }}>
         <p className="backtext">
        <GoArrowLeft />
        Back to Consultancy
      </p>
    </Link>
    <button className="col-3 bg-primary text-white btn" type="submit" onClick={createConsultancy}>
      Save
    </button>
  </div>
</div> */}
        <div className="row">
          <div className="col-md-12 col-lg-5 col-12">
            <div className="mainheader">Add New Consultancy</div>
            <div className="greyfont mt-2">New consultancy</div>
          </div>
          <div className="col-md-12 col-lg-7 col-12">
            <div className="float-end">
              {/* <Link to="/faculty/secondsidebar/proficiency/consultancy" className="link"> */}
              <span className="bacdept cursorPointer labelinput">
                <span
                  onClick={handleBackButtonClick}
                  data-bs-toggle="modal"
                  data-bs-target="#myModal"
                >
                  <img src={back} className="img-fluid me-2" />
                  Back to Consultancy
                </span>

                <button
                  className=" ms-3 saveBtn"
                  style={{ color: "white" }}
                  onClick={handleSave}
                  disabled={isButtonClicked}
                >
                  Save
                </button>
                <button
                  type="button"
                  className=" ms-3 addnewbtn"
                  style={{ color: "white" }}
                  onClick={handleSubmit}
                  disabled={isButtonClicked}
                >
                  Save & Submit
                </button>

                {toggleSubMod && (
                  <>
                    <div
                      className="modal fade show"
                      tabIndex={-1}
                      style={{ display: "block" }}
                    >
                      <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content mt-2 mb-2">
                          <div className="d-flex justify-content-end ">
                            <button
                              type="button"
                              className="btn-close"
                              aria-label="Close"
                              onClick={() => {
                                setToggleSubMod(false);
                              }}
                            ></button>
                          </div>
                          <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title submitHead">
                              Submit for validation
                            </h5>
                          </div>
                          <div className="">
                            <p className="fontModel items-center px-5">
                              This entry will be sent to admin for validation.
                              Click 'Save' if you don't want to submit now.
                            </p>
                          </div>
                          <div className="modal-footer d-flex justify-content-center">
                            <button
                              type="button"
                              className="btn bg-white text-black cancelBtn"
                              // onClick={() => setToggleSubMod(false)}
                              onClick={handleSave}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn btnBack"
                              onClick={handleSubmit}
                            >
                              Save & Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {toggleSubMod && (
                      <div className="modal-backdrop fade show"></div>
                    )}
                  </>
                )}
              </span>
              {/* </Link>*/}
            </div>
          </div>
        </div>
        <div
          className="form row"
          style={{
            marginTop: "36px",
            fontSize: "14px",
            fontFamily: "Satoshi medium",
          }}
        >
          <div className="form-group col-md-6 col-lg-2">
            <label className="labelinput">Academic Year</label>
            <select
              className="form-select inputWidth"
              id=""
              name="academicYear"
              value={formDatas.academicYear}
              onChange={handleChange}
              required
              style={{
                fontSize: formDatas.academicYear ? "14px" : "12px",
                height: "38px",
                fontFamily: "Satoshi medium",
                color: formDatas.academicYear ? "black" : "grey",
              }}
            >
              <option value="" disabled className="select">
                --Select--
              </option>
              {yearList.map((item) => (
                <option
                  key={item}
                  value={item}
                  style={{ color: "black", fontSize: "14px" }}
                >
                  {item}
                </option>
              ))}
            </select>
            {isSubmitted && !formDatas.academicYear && (
              <div className="is-invalid">*Required</div>
            )}
          </div>
          <div className="form-group col-md-6 col-lg-2">
            <label className="labelinput">Category</label>
            <input
              className="form-control inputWidth"
              placeholder="Enter Category"
              name="Category"
              value={formDatas.Category}
              onChange={handleChange}
              required
            />
            {isSubmitted && !formDatas.Category && (
              <div className="is-invalid">*Required</div>
            )}
          </div>
          <div className="form-group col-md-6 col-lg-4">
            <label className="labelinput">Agency</label>
            <input
              className="form-control inputWidth"
              placeholder="Enter Agency"
              name="Agency"
              value={formDatas.Agency}
              onChange={handleChange}
              required
            />
            {isSubmitted && !formDatas.Agency && (
              <div className="is-invalid">*Required</div>
            )}
          </div>
        </div>

        <div
          className="form row pt-2 pt-md-3"
          style={{
            paddingTop: "25px",
            fontSize: "14px",
            fontFamily: "Satoshi medium",
          }}
        >
          {/* <div className="form-group col-md-6 col-lg-2">
      <label className="labelinput">Sanctioned Amount (INR)</label>
      <input  className="form-control" placeholder="Enter Amount" name="SanctionedAmount" type="text" value={formDatas.amountSanction} onChange={handleChange} required/>
      {isSubmitted && !formDatas.amountSanction && (
                <div className="is-invalid">*Required</div>
              )}
    </div> */}
          {/* <div className="form-group col-md-6 col-lg-2">
    <div className="input-container currency">
      <label className="labelinput">Sanctioned Amount (INR)</label>
      <span className="currency-symbol" style={{color:"#7784A1"}}>₹</span>
      <input  className="form-control labelinput" placeholder="Enter Amount" name="SanctionedAmount" maxLength={6} type="text"value={formDatas.SanctionedAmount} onChange={handleChange} required
      onKeyDown={handleInputKeyDown}
      />
      </div>
      {isSubmitted && !formDatas.SanctionedAmount && (
                <div className="is-invalid">*Required</div>
              )}
    </div> */}
          <div className="col-sm-12 col-md-4 col-lg-3 col-xl-2">
            <label className="labelinput">Sanctioned Amount (INR)</label>
            <div className="input-container currency">
              <span className="currency-symbol" style={{ color: "#7784A1" }}>
                ₹
              </span>
              <input
                className="form-control labelinput inputWidth"
                placeholder="Enter Amount"
                name="SanctionedAmount"
                maxLength={6}
                type="text"
                value={formDatas.SanctionedAmount === '0' ? '' : formDatas.SanctionedAmount}
                onChange={handleChange}
                required
                onKeyDown={handleInputKeyDown}
                onKeyPress={onlyNumber}
              />
            </div>
            {/* {isSubmitted && !formDatas.SanctionedAmount && ( */}
            {(!formDatas.SanctionedAmount || formDatas.SanctionedAmount === "0") && isSubmitted && (
              <div className="is-invalid">*Required</div>
            )}
          </div>
          <div className="form-group col-md-6 col-lg-2">
            <label className="labelinput ">Completion Percentage</label>
            <div className="input-container">
              <input
                className="form-control inputWidth"
                placeholder="Enter Percentage"
                name="CompletionPercentage"
                maxLength={4}
                type="text"
                value={formDatas.CompletionPercentage}
                onChange={handleChange}
                required
                onKeyDown={handleInputKeyDownPer}
                onKeyPress={onlyNumber}
              />
              <span className="percentage-symbol" style={{ color: "#7784A1" }}>
                %
              </span>
            </div>
            {isSubmitted && !formDatas.CompletionPercentage && (
              <div className="is-invalid">*Required</div>
            )}
          </div>

          <div className=" col-md-6 col-lg-4">
            <div className="d-flex justify-content-between mt-1">
              <label className="labelinput ">Proof Document</label>
              <span
                className="ms-1 justify-content-end"
                style={{ color: "#7784A1", font: "12px satoshi medium" }}
              >
                Only pdf/jpg file under 2MB
              </span>
            </div>
            {/* <input  className="form-control" type="file" value={formDatas.uploadFile} onChange={e => {base64ConverterFile(e)}} name="uploadFile"/> */}
            <div className="input-group">
              <label className="form-control boxText boxtextellipse browsetext">
                {fileVal}
                <input
                  type="file"
                  id="fileInput2"
                  className="invisible"
                  onChange={(e) => base64ConverterFile(e)}
                />
              </label>
              <span
                className="input-group-text lname cursorPointer"
                onClick={() => document.getElementById("fileInput2")?.click()}
              >
                Select File
              </span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default AddConsultancy;
