import Keycloak from "keycloak-js";

const Keycloak_initializer = {
  realm: "Kings",
  clientId: "Kings_Faculty",
  url: "https://auth.kingsedu.ac.in",
};

const keycloak = new Keycloak(Keycloak_initializer);

export default keycloak;
