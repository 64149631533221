import React, { useState, ChangeEvent, FormEvent ,useEffect } from "react";
import { Link, Outlet, useNavigate ,useParams } from "react-router-dom";
import "../../OnlineCourse/AddNew/AddNew.css";
import { GoArrowLeft } from "react-icons/go";
import { SlArrowDown } from "react-icons/sl";
import { ConsultancyCreate ,ConsultancyGetTableData,AcademicYear } from "../../../../../ApiServices";
import back from "../../../../../assets/back.png"
import { ToastContainer , toast  } from "react-toastify";
import { upload } from "@testing-library/user-event/dist/upload";
import yearList from "../../../../../YearList.json"; 

type Props = {}

const EditConsultancy = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [inputType, setInputType] = useState("text");
  const [formDatas, setformDatas] = useState({
  contId:"",
  academicYear: "",
  category: "",
  agency: "",
  amountSanction: "",
  completionPercentage: "",
  awardingInstitution: "",
  fileUpload: "",
  })
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<string>('');
  const [fileVal, setFileVal] = useState<string>('');

  const handleSave=(e: FormEvent<HTMLButtonElement> )=>{
    editConsultancy(e,"Yet to submit")
  }

  const handleSubmit=(e: FormEvent<HTMLButtonElement> )=>{
    editConsultancy(e,"Pending")
  }

  const editConsultancy = (event:any , status:any) =>{
    event?.preventDefault();
    setIsSubmitted(true);
    if (
      !formDatas.academicYear ||
      !formDatas.category ||
      !formDatas.agency ||
      !formDatas.amountSanction ||
      !formDatas.completionPercentage  ||
      formDatas.amountSanction === "0"
      
    ) {
      console.log("All fields are required");
      return;
    }
    const payload = {
    contId:formDatas.contId,
    empId:sessionStorage.getItem('empId'),
    academicYear:formDatas.academicYear,
    createdBy:sessionStorage.getItem('empId'),
    category:formDatas.category,
    agency:formDatas.agency,
    amountSanction:formDatas.amountSanction,
    completionPercentage:formDatas.completionPercentage,
    fileUpload:uploadFiles,
    updatedBy:"",
    status:status
    };
    console.log(payload,"test");
    ConsultancyCreate(payload)
      .then((res) => {
        setIsButtonClicked(true)
      // alert(res.data.status);
       navigate("/faculty/secondsidebar/proficiency/consultancy")
       setTimeout(()=>{
        toast.success(res.data.status);
      } , 200) 
      })
      .catch((err) => {
        console.log(err);
      });
      console.log(payload)
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");
    let updatedValue = value;

    if (name == "completionPercentage") {
      if (parseInt(value) > 100) {
        updatedValue = 100;
      }
      const allowedCharacters = /[0-9.]/;
      if (!allowedCharacters.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
      setformDatas({
        ...formDatas,
        [e.target.name]: e.target.value,
        [name]: updatedValue,
      });
    } else {
      if (value.length > 0) {
        var words = value.split(" ");
        for (var i = 0; i < words.length; i++) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        updatedValue = words.join(" ");
      }
      setformDatas({
        ...formDatas,
        [e.target.name]: e.target.value,
        [name]: updatedValue,
      });
    }
  };
  const getSingleData = () => {
    ConsultancyGetTableData(id)
      .then((res) => {
        setformDatas(res.data.data[0].proficiency);
        console.log(res.data.data[0],"am datas")
        setTimeout(()=>{
          // toast.success("consultancy Edited Successfully");
        } , 200) 
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSingleData();
    setInputType("text");
  }, [id]);

  const handleBackButtonClick = () => {
    setShowModal(true);
  };

  const handleClick = () => {
    setInputType("file");
  };
  var base64CodePhoto = "";
  const base64ConverterFile = (e:any) => {
    const files = e.target.files;
    const file = files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error('File size exceeds 2 MB limit. Please upload a smaller file.');
    }else{
      if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
        toast.error('File format not supported. Please try uploading PDF or JPG files.');
        return;
      }else{
        getBase64(file);
        handleChange(e);
        setFileVal(file.name);
      }
  }
}
 
  const onLoad = (fileString :any) =>{
  base64CodePhoto = fileString;  
  setUploadFiles(fileString)
  }
  const getBase64 = (file:any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>{
      onLoad(reader.result);
    }
  }   

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedCharacters = /[0-9]/;
    if (!allowedCharacters.test(e.key) && e.key !== 'Backspace') {
        e.preventDefault();
    }
};
const handleInputKeyDownPer = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const allowedCharacters = /[0-9.]/; // Updated regular expression to include %
  if (!allowedCharacters.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
  }
};
const [academicYears,setAcademicYear] = useState([]);
interface AcademicYear {
  id: number;
  academicYear: string;
  term: number;
  startDate: string;
  endDate: string;
}
const acad = ()=>{
  AcademicYear()
  .then((res =>{
    console.log(res.data.data);
    const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
      index === self.findIndex((t:any) => (
        t.academicYear === item.academicYear
      ))
    );
    setAcademicYear(uniqueAcademicYears)
  })) 
}
const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
useEffect(()=>{
  acad()
  },[])
  return (
    <>
    <ToastContainer/>
    <div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/consultancy"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
    <form>
       {/* <h4 className="mainheader">Add New Consultancy</h4>
         <div className="row greytext">
         <span className="col-8">
         Consultancy or suggestions and feedback recently.
         </span>
         <div className="col-4 d-flex justify-content-between align-items-center">
         <Link to="/faculty/secondsidebar/proficiency/consultancy" style={{textDecoration:"none" , color:"black" }}>
         <p className="backtext">
        <GoArrowLeft />
        Back to Consultancy
      </p>
    </Link>
    <button className="col-3 bg-primary text-white btn" type="submit" onClick={createConsultancy}>
      Save
    </button>
  </div>
</div> */}
   <div className="row">
    <div className="col-md-12 col-lg-5 col-12">
    <div className="mainheader">Edit Consultancy</div>
    </div>
    <div className="col-md-12 col-lg-7 col-12">
    <div className="float-end">
    <span className='bacdept cursorPointer labelinput'
         
         >
           <span onClick={handleBackButtonClick}
          data-bs-toggle="modal"
          data-bs-target="#myModal">
           <img src={back} className="img-fluid me-2 " />
             Back to Consultancy
           </span>
             
             <button  className=" ms-3 saveBtn"style={{ color: 'white' }} onClick={handleSave} disabled={isButtonClicked}
>Save</button>
  
<button  className=" ms-3 addnewbtn"style={{ color: 'white' }} onClick={handleSubmit} disabled={isButtonClicked}
>Save & Submit</button>
             </span>          
</div>        
    </div>
</div>
  <div className="form row"style={{ marginTop: "36px",fontSize: '14px', fontFamily: 'Satoshi medium' }}>
    <div className="form-group col-md-6 col-lg-2">
      <label className="labelinput">Academic Year</label>
      <select className="form-select" id=""  name="academicYear" value={formDatas.academicYear} onChange={handleChange} required>
      <option value="" disabled className="select">--Select--</option> 
      {yearList.map((item) => (
    <option key={item} value={item} style={{ color: "black", fontSize: "14px" }}>
      {item}
    </option>
  ))}
    </select>
    {isSubmitted && !formDatas.academicYear && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
    <div className="form-group col-md-6 col-lg-2">
      <label className="labelinput">Category</label>
      <input className="form-control" placeholder="Enter Category" name="category" value={formDatas.category} onChange={handleChange} required />
      {isSubmitted && !formDatas.category && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
    <div className="form-group col-md-6 col-lg-4">
      <label className="labelinput">Agency</label>
      <input  className="form-control" placeholder="Enter Agency" name ="agency" value={formDatas.agency} onChange={handleChange} required/>
      {isSubmitted && !formDatas.agency && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
  </div>
  
  <div className="form row pt-2 pt-md-3" style={{ paddingTop: "25px" ,fontSize: '14px', fontFamily: 'Satoshi medium'}}>
  {/* <div className="form-group col-md-6 col-lg-2">
    <div className="input-container currency">
    <span className="currency-symbol" style={{color:"#7784A1"}}>₹</span>
      <label className="labelinput">Sanctioned Amount (INR)</label>
      <input  className="form-control" placeholder="Enter Amount" name="amountSanction" maxLength={6} type="text"value={formDatas.amountSanction} onChange={handleChange} required
      onKeyDown={handleInputKeyDown}
      />
      </div>
      {isSubmitted && !formDatas.amountSanction && (
                <div className="is-invalid">*Required</div>
              )}
    </div> */}

<div className="col-sm-12 col-md-4 col-lg-3 col-xl-2">
    <label className="labelinput">Sanctioned Amount (INR)</label>
    <div className="input-container currency">
        <span className="currency-symbol" style={{color:"#7784A1"}}>₹</span>
        <input  className="form-control labelinput" placeholder="Enter Amount" name="amountSanction" maxLength={6} type="text" value={formDatas.amountSanction === '0' ? '' : formDatas.amountSanction} onChange={handleChange} required
      onKeyDown={handleInputKeyDown}
      />
    </div>
    {/* {isSubmitted && !formDatas.amountSanction && ( */}
    {(!formDatas.amountSanction || formDatas.amountSanction === "0") && isSubmitted && (
        <div className="is-invalid">*Required</div>
    )}
</div>
    <div className="form-group col-md-6 col-lg-2">
      <label className="labelinput">Completion Percentage</label>
      <div className="input-container">
      <input className="form-control" placeholder="Enter Percentage" name="completionPercentage" maxLength={4} type="text" value={formDatas.completionPercentage} onChange={handleChange} required
      onKeyDown={handleInputKeyDownPer}
      />
       <span className="percentage-symbol"  style={{color:"#7784A1"}}>%</span>
       </div>
      {isSubmitted && !formDatas.completionPercentage && (
                <div className="is-invalid">*Required</div>
              )}
    </div>
     <div className=" col-md-6 col-lg-4">
     <div className="d-flex justify-content-between mt-1">
      <label className="labelinput ">Proof Document</label>
      <span className="ms-1 justify-content-end" style={{ color: '#7784A1',font: '12px satoshi medium'}}>Only pdf/jpg file under 2MB</span>
      </div>
      {/* <input  className="form-control" type="file" value={formDatas.uploadFile} onChange={handleChange} /> */}
      {/* <input
                // type="file"
                type={inputType}
                className="form-control labelinput"
                value={inputType === "text" ? formDatas.fileUpload : undefined} 
                onChange={e => {base64ConverterFile(e)}}
                name="uploadFile"
                onClick={handleClick}
              /> */}
                <div className="input-group">
  <label className="form-control boxText boxtextellipse browsetext">
  {fileVal !== "" ? fileVal : formDatas.fileUpload }
    <input
      type="file"
      id="fileInput2"
      className="invisible"
      onChange={(e) => base64ConverterFile(e)} 
    />
  </label>
  <span className="input-group-text lname cursorPointer"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
</div>
    </div>
  </div>
</form>
       
    </>
  )
}
export default EditConsultancy;