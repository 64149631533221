import React, { useEffect, useState } from "react";
import './SponsoredResearch.css'
import { Link ,useNavigate } from "react-router-dom";
import DynamicTables from "../../../../components/DynamicTable/DynamicTables";
import { sponsoredGetTable ,sponsoredGetTableData,DeleteTabledata,fileDownload ,TableSubmitCall,host} from "../../../../ApiServices";
import CourseTable from "../../../../components/CourseDetailTable/CourseTable";
import { MdCancel } from "react-icons/md";
import searchIcon from "../../../../assets/Icon search.svg"
import { ToastContainer, toast } from "react-toastify";
import { format as formatDate } from "date-fns";
import close from "../../../../assets/close.svg";
import noRecord from "../../../../assets/norecords.png"
import { LuLoader } from "react-icons/lu";
import { SkeletonLoader } from "../../../skeletonLoader";
import { NoData } from "../../../NoData";
function SponsoredResponse() {
  const navigate = useNavigate();
  const columnRedirectsOnlineCourse = {
    courseTitle:"/faculty/secondsidebar/proficiency/onlinecourses/courseDetailsTable",
  };
  
  const [tableDatas, settableDatas] = useState([]);
  const [currentPage,setCurrentPage] = useState(1)
  const [TotalElements , setTotalElements] = useState(0)
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalpages,settotalpages] = useState(0);
  const [sort , setSort] = useState(false);
  const [sortusing,setSortUsing]= useState("sprId") 
  const [selectedItem, setselectedItem] = useState<any>([])
  const [viewSingle, setViewSingle] = useState(false)
  const [viewTitle,setViewTitle] = useState();
  const [pubIdView,setPubIdView] = useState();
  const [createdOn , setCreatedOn] = useState<string>('');
  const [updatedOn , setUpdatedOn] = useState<string>('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [getData, setGetData] = useState();  
  const [loader , setLoader] = useState(true)
    
  const toggleSearch = () => {
setIsSearching(!isSearching);
setSearchTerm("");
setIsSearchOpen(!isSearchOpen);
if (!isSearchOpen) {
  setSearchTerm('');
}
};
const handleCancel = () => {
setSearchTerm('');
setIsSearchOpen(false);
};

    const handleNextPage=()=>{
      setCurrentPage((prevPage)=>Math.min(prevPage+1,totalpages))
      console.log("next button clicked and the value now is "+ currentPage)
      }
    
     const handlePrevPage=()=>{
     setCurrentPage((prevPage)=>Math.max(prevPage-1,1))
     }
    
     const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
       setCurrentPage(value);
     };

     const handleSort =()=>{
      setSortUsing("academicYear")
      setSort(!sort);
    }
  
    const sortOrder = sort? "asc":"desc"

    const getDatas = () => {
      const data = {
        empID:sessionStorage.getItem('empId'),  
        page:currentPage-1,  
        sort:sortOrder,  
        sordId:sortusing,  
        keyword:searchTerm, 
      }
        sponsoredGetTable(data)
     .then((res) => {
      const formattedData = res.data.datas.content.map((item: any) => {
        let formattedCreatedDate = "";
        let formattedUpdatedDate = "";
         let formattedSubmittedOn = "";
         let formattedValidatedOn = ""
      
        if (item.createdOn) {
          const createdOnDate = new Date(item.createdOn);
          formattedCreatedDate = formatDate(createdOnDate, "dd MMM yyyy hh:mm a");
        }
      
        if (item.updatedOn) {
          const updatedOnDate = new Date(item.updatedOn);
          formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
        }

        if(item.submittedOn){
          const submittedOnDate = new Date(item.submittedOn)
          formattedSubmittedOn = formatDate(submittedOnDate, "dd MMM yyyy hh:mm a")
        }

        if(item.validatedOn){
          const validatedOnDate = new Date(item.validatedOn)
          formattedValidatedOn = formatDate(validatedOnDate, "dd MMM yyyy hh:mm a")
        }
      
        return {
          ...item,
          createdOn: formattedCreatedDate,
          updatedOn: formattedUpdatedDate,
          submittedOn: formattedSubmittedOn,
          validatedOn: formattedValidatedOn
        };
      });

        settableDatas(formattedData);
        setLoader(false)
       console.log(res.data.data[0], "");
       setGetData(res.data.data[0]);
       setTotalElements(res.data.datas.totalElements)
       settotalpages(res.data.datas.totalPages)
     })
     .catch((err) => {
       console.log(err);
     });
 };
 const Header = [
   { label: "Academic Year", key: "academicYear" },
   { label: "Category", key: "category" },
   { label: "Sanctioned Amnt", key: "amountSanction" },
   { label: "Submitted on", key: "submittedOn" },
   { label: "Status", key: "status" },
   { label: "Validated on", key: "validatedOn" },
 ];

 const SponsoredViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  // , {
  //   "title": "Title",
  //   "key":"tittle"
  // },
  {
    "title": "Category",
    "key":"category"
  }, {
    "title": "Agency",
    "key":"agency"
  }, {
    "title": "Sanctioned Amount in Rupees",
    "key":"amountSanction"
  },  {
    "title": "Completion Percentage",
    "key":"completionPercentage"
  }, {
    "title": "Sanctioned Letter",
    "key":"fileUpload"
  },
]

function editCourse(item:any){
  console.log(item,"ssbiki")
  const data = item.sprId?item.sprId:item.proficiency.sprId;
  console.log(data);
  navigate("editSponsoredResearch/"+data)
}
function navigateView(item: any){
  sponsoredGetTableData(item.sprId).then((res:any)=>{
    setselectedItem(res.data.data[0]);
    setViewTitle(res.data.data[0].proficiency.category);
    setPubIdView(res.data.data[0].proficiency.sprId);
    const formattedCreatedDate = new Date(res.data.data[0].proficiency.createdOn);
    const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");
    let updatedOn = "";
    if (res.data.data[0].proficiency.updatedOn !== null) {
        const formattedUpdatedDate = new Date(res.data.data[0].proficiency.updatedOn);
        updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
    }         
    setCreatedOn(createdOn);
    setUpdatedOn(updatedOn);

    setViewSingle(true)
  }).catch((err:any)=>console.log(err))
}

  const deleteApi=(data: any) =>{
    const url = "/sponsoredResearch?spId=" + data.sprId;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);
        toast.success(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }
  const deleteApiView=() =>{
    const url = "/sponsoredResearch?spId=" + pubIdView;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);        
        toast.success(res.data.status);
        setViewSingle(false) 
        // alert(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }
  
  const FileDownload = () => {
    const url = host+"proficiency/get/sponsoredResearch?sprId=" + pubIdView + "&keyword=file";
    fileDownload(url)
      .then((res) => {             
        toast.success(res.data.status);
        window.open(url, '_blank');
        getDatas();
      })
      .catch((err) => {
        console.log(err);
      });
  }


 const backtoview = "to Sponsored Research"
 const heading = viewTitle
 const deleteCont = "sponsored research"
 const subheading = "View details of the ongoing sponsored research project"

 const TableSubmit =(item:any)=>{
  console.log("tablesumbit toggled ")
  const payload = {
    empId:sessionStorage.getItem('empId'),
    sectionId:item.sprId,
    status :"Pending",
    section:"Sponsored Research",
   academicYear:item.academicYear
}
console.log(payload,"hkjgkjgh");

TableSubmitCall(payload)
.then((res)=>{
  console.log(res)
  getDatas()
  toast.success(res.data.responseMessage)
})
.catch((err)=>{
  console.log(err)
})
console.log(payload , "am the payload")
// window.location.reload()
setViewSingle(false)
}

useEffect(() => {
  getDatas();
}, [currentPage,sort,searchTerm]);

const moduleName = "Sponsored Research"
const AddNewPath = "/faculty/secondsidebar/proficiency/sponsoredresearch/sponsoredresearchcreate"

 
  return (
    <>
    <ToastContainer/>
    {!viewSingle?
    <>
        <div className="">
    {/* <div className='position-relative  d-flex justify-content-between align-items-center'>
    <p className="mt-3">Sponsored Research<br></br>Sponsored Research Projects during this Academic Year.</p>
    <Link to="/faculty/secondsidebar/proficiency/sponsoredresearchcreate" className="btn btn-primary CreateBtn">Add New</Link>
    </div> */}
     <div className="row">
             <div className="col-md-12 col-lg-5 col-12">
            <div className="mainheader">Sponsored Research Projects</div>
            <div className="greyfont mt-2">List of all the sponsored research projects</div>
            </div>
            {getData === true && (
            <div className="col-md-12 col-lg-7 col-12 AddNewBtnDiv">
            <div className="float-end">
            <Link to="/faculty/secondsidebar/proficiency/sponsoredresearch/sponsoredresearchcreate" className="link">
                <span className='bacdept cursorPointer  '>
                <button  className=" ms-3 addnewbtn " style={{ color: 'white' }}>Add New</button>
                    </span>
                    </Link>           
    </div>        
            </div>
            )}
            </div>
            
    <div className="">
        {/* <ul className="nav mt-4">
            <li className="nav-item">
                <span className="active tablink pb-1 bg addresult ">
                    All Results
                </span>

            </li>

        </ul> */}
        {getData === true && (
          <div className="addresult mt-4">
        <span className="active tablink pb-1 bg addresult mb-3">All Results</span>
        <div className={`${isSearchOpen ? 'me-0' : 'me-5'} search`} style={{boxShadow:'none'}}>
  <img
    alt="search"
    // className="fasearch mb-2"
    className={`${isSearchOpen ? 'ms-0' : 'ms-3'} fasearch mb-2`}
    src={searchIcon}
    onClick={toggleSearch}
    style={{cursor:'pointer'}}
  />
  {isSearchOpen && (
    <div className="search-input-container">
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
      />
      <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }}/>
    </div>
  )}
</div>
      </div>
        )}
      {/* {getData === false ? (
                    <div className="d-flex flex-column align-items-center" style={{ marginTop: "200px" }}>
                      <img src={noRecord} alt="No records" />
                      <p className="mt-4 greyfont" style={{ textAlign: 'center' }}>
                        You haven't added any sponsored research yet.<br />Start by adding a sponsored research.
                      </p>
                      <Link to="/faculty/secondsidebar/proficiency/sponsoredresearch/sponsoredresearchcreate">
                        <button className="text-white addnewbtn">Add New</button>
                      </Link>
                    </div>
                  ) : (
      <DynamicTables data={tableDatas} headers={Header}  currentPage={currentPage} pages={totalpages} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} navigateView={navigateView} deleteApi={deleteApi} editCourse={editCourse} TableSubmit={TableSubmit} />    
                  )} */}
                  {loader ?
                  <SkeletonLoader/> :
                  getData ?
                  <DynamicTables data={tableDatas} headers={Header}  currentPage={currentPage} pages={totalpages} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} navigateView={navigateView} deleteApi={deleteApi} editCourse={editCourse} TableSubmit={TableSubmit} />    
                :
                <NoData name={moduleName} path={AddNewPath} />
                }
                  </div>
      </div>
    </>
    :
<CourseTable data={[selectedItem]} deleteCont = {deleteCont} backtoview={backtoview} back={()=>{setViewSingle(!viewSingle)}} headers={SponsoredViewHeaders}
heading = {heading} deleteApiView= {deleteApiView} FileDownload ={FileDownload}
subheading = {subheading}
createdOn = {createdOn}
updatedOn = {updatedOn}
TableSubmit={TableSubmit}
editCourse={editCourse}
/>
  }

</>
  )
}

export default SponsoredResponse
