import React from "react"
import back from "../../../../assets/back.png"
import edit from "../../../../assets/Icon material-edit.png"
import user from "../../../../assets/user.png"
import { MdPadding } from "react-icons/md"
import "../Department/Department.css"
import { LuGraduationCap } from "react-icons/lu";
import { useNavigate } from "react-router-dom"
import {Outlet} from "react-router-dom"


const Dummydatas =[
    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },
    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },
    {
        degree:"Biomedical Engineering",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },
    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },
    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },
    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },
    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },
    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },
    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },

    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },

    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },

    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },

    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },

    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },

    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },

    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },

    {
        degree:"Artificial Intelligence & Data Science",
        coureses:"1",
        name:"Mithil Gade",
        designation:"Head of the department"
    },
]


const Department = () => {

    const navigate = useNavigate()

    const toggleCreate=()=>{
        navigate("/faculty/secondsidebar/admin/department/newDepartment")
        }
        
    return (
        <>
            <div className="row">
                <div className="col-md-12 col-lg-5 col-12">
                    <div className="mainheader">Departments</div>
                    <div className="greyfont">View all the departments</div>
                </div>

                <div className="col-md-12 col-lg-7 col-12">
                    <div className="float-end">
                        <span className='bacdept cursorPointer labelinput'>
                            <button className=" ms-3 addnewbtn" style={{ color: 'white' }} onClick={toggleCreate}>Create New</button>
                        </span>
                    </div>
                </div>

                <div>

                    {/* <div className="pt-3">  
                    <span>Active</span> 
                    <span>closed</span>
                   </div> */}   
                   <div className="d-flex flex-wrap gap-3 pt-5">
                   {Dummydatas.map((data)=>(
                         <div className="border rounded col-md- containerss">
                         <div className="firstRow">
                             <div className="d-flex justify-content-between">
                                 <span className="degree col-lg-6">{data.degree}</span>
                                 <img src={edit} className ="editIcon mt-6"/>
                             </div>
                             <div className="pt-3 pb-1">
                             <LuGraduationCap />
                                 <span className="courses">{data.coureses}  courses</span>
                             </div>
                         </div>  
                         <div className="border-top secondRow">
                             <div className="d-flex gap-2">
                             <div> <img src={user} className="userimg"></img></div>
                             <div className="row">
                             <span className="userName">{data.name}</span>
                             <span className="designation">{data.designation}</span>
                             </div>
                             </div>
                         </div>
                     </div>
                    ))}
                   </div>
                   
                </div>
            </div>
            <Outlet /> 
        </>
    )
}

export default Department