import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import './AddNew.css'
import back from "../../../../../assets/back.png";
import {
  trainingsCreate,
  trainingsGetTableData,
  AcademicYear,
} from "../../../../../ApiServices";
import { ToastContainer, toast } from "react-toastify";
import yearList from "../../../../../YearList.json";

function TrainingsEdit() {
  const { id } = useParams();
  const [inputType, setInputType] = useState("text");
  const navigate = useNavigate();
  const [TrainingsCreateForm, setTrainingsCreateForm] = useState({
    trainId: "",
    academicYear: "",
    duration: "",
    programTitle: "",
    actedAs: "",
    organization: "",
    startDate: "",
    endDate: "",
    uploadFile: "",
    values: "",
    year: "",
    month: "",
    day: "",
  });
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileVal, setFileVal] = useState<string>("");
  const [uploadFiles, setUploadFiles] = useState<string>("");

  const handleSave = (e: FormEvent<HTMLButtonElement>) => {
    CreateTrainings(e, "Yet to submit");
  };

  const handleSubmit = (e: FormEvent<HTMLButtonElement>) => {
    CreateTrainings(e, "Pending");
  };

  const CreateTrainings = (e: FormEvent<HTMLButtonElement>, status: any) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (
      !TrainingsCreateForm.academicYear ||
      !TrainingsCreateForm.duration ||
      !TrainingsCreateForm.programTitle ||
      !TrainingsCreateForm.actedAs ||
      !TrainingsCreateForm.organization ||
      !TrainingsCreateForm.startDate ||
      !TrainingsCreateForm.endDate ||
      !TrainingsCreateForm.values ||
      (!TrainingsCreateForm.year &&
        !TrainingsCreateForm.month &&
        !TrainingsCreateForm.day)
    ) {
      console.log("All fields are required");
      return;
    }

    const payload = {
      trainId: TrainingsCreateForm.trainId,
      empId: sessionStorage.getItem("empId"),
      academicYear: TrainingsCreateForm.academicYear,
      createdBy: "",
      duration: TrainingsCreateForm.duration,
      programTitle: TrainingsCreateForm.programTitle,
      actedAs: TrainingsCreateForm.actedAs,
      organization: TrainingsCreateForm.organization,
      startDate: TrainingsCreateForm.startDate,
      endDate: TrainingsCreateForm.endDate,
      uploadFile: uploadFiles,
      values: TrainingsCreateForm.values,
      status: status,
      year: TrainingsCreateForm.year !== "" ? TrainingsCreateForm.year : 0,
      month: TrainingsCreateForm.month !== "" ? TrainingsCreateForm.month : 0,
      day: TrainingsCreateForm.day !== "" ? TrainingsCreateForm.day : 0,
    };
    console.log(payload, "trainingsCreate");
    trainingsCreate(payload)
      .then((res) => {
        setIsButtonClicked(true);
        // alert(res.data.status);
        navigate("/faculty/secondsidebar/proficiency/trainings");
        setTimeout(() => {
          toast.success(res.data.status);
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(payload);
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");
    let updatedValue = value;
    if (value.length > 0) {
      var words = value.split(" ");
      for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      updatedValue = words.join(" ");
    }
    setTrainingsCreateForm({
      ...TrainingsCreateForm,
      [e.target.name]: e.target.value,
      [name]: updatedValue,
    });
  };
  // const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
  //     const { name, value } = event.target;
  //     setTrainingsCreateForm({ ...TrainingsCreateForm, [name]: value });
  // };

  const getSingleData = () => {
    trainingsGetTableData(id)
      .then((res) => {
        setTrainingsCreateForm(res.data.data[0].proficiency);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSingleData();
    setInputType("text");
  }, [id]);

  const handleBackButtonClick = () => {
    setShowModal(true);
  };
  const handleClick = () => {
    setInputType("file");
  };
  var base64CodePhoto = "";
  const base64ConverterFile = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error(
        "File size exceeds 2 MB limit. Please upload a smaller file."
      );
    } else {
      if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
        toast.error(
          "File format not supported. Please try uploading PDF or JPG files."
        );
        return;
      } else {
        getBase64(file);
        handleChange(e);
        setFileVal(file.name);
      }
    }
  };

  const onLoad = (fileString: any) => {
    base64CodePhoto = fileString;
    setUploadFiles(fileString);
  };
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const handleToClear = (e: any) => {
    setTrainingsCreateForm((prevState) => ({
      ...prevState,
      endDate: "",
    }));
  };
  const updateType = (e: any) => {
    setTrainingsCreateForm((prevState) => ({
      ...prevState,
      actedAs: "",
      organization: "",
      startDate: "",
      endDate: "",
      uploadFile: "",
    }));
    setIsSubmitted(false);
    setUploadFiles("none");
  };
  const today = new Date().toISOString().split("T")[0];
  const onlyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    if (e.ctrlKey || e.altKey || key.length > 1) {
      return;
    }
    if (!/^[0-9]$/.test(key)) {
      e.preventDefault();
      return;
    }
    const input = e.currentTarget;
    if (input.value.length === 0 && key === "0") {
      e.preventDefault();
    }
  };
  const [academicYears, setAcademicYear] = useState([]);
  interface AcademicYear {
    id: number;
    academicYear: string;
    term: number;
    startDate: string;
    endDate: string;
  }
  const acad = () => {
    AcademicYear().then((res) => {
      console.log(res.data.data);
      const uniqueAcademicYears = res.data.data.filter(
        (item: any, index: any, self: any) =>
          index ===
          self.findIndex((t: any) => t.academicYear === item.academicYear)
      );
      setAcademicYear(uniqueAcademicYears);
    });
  };
  const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
  useEffect(() => {
    acad();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/trainings"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
      <div className="">
        <div className="row">
          <div className="col-md-12 col-lg-5 col-12">
            <div className="mainheader">Edit {TrainingsCreateForm.values}</div>
          </div>
          <div className="col-md-12 col-lg-7 col-12">
            <div className="float-end">
              {/* <Link to="/faculty/secondsidebar/proficiency/trainings" className="link"> */}
              <span className="bacdept cursorPointer labelinput">
                <span
                  onClick={handleBackButtonClick}
                  data-bs-toggle="modal"
                  data-bs-target="#myModal"
                >
                  <img src={back} className="img-fluid me-2 " />
                  Back to Trainings
                </span>
                <button
                  className=" ms-3 saveBtn"
                  style={{ color: "white" }}
                  onClick={handleSave}
                  disabled={isButtonClicked}
                >
                  Save
                </button>
                <button
                  className=" ms-3 addnewbtn"
                  style={{ color: "white" }}
                  onClick={handleSubmit}
                  disabled={isButtonClicked}
                >
                  Save & Submit
                </button>
              </span>
              {/* </Link>            */}
            </div>
          </div>
        </div>
        <form className="mt-4">
          <div className="row mt-4">
            <div className="col-sm-4 col-md-4 col-lg-2">
              <label className="labelinput">Academic Year</label>
              <select
                className="form-select mt-2 labelFont inputWidth"
                value={TrainingsCreateForm.academicYear}
                onChange={handleChange}
                name="academicYear"
                style={{
                  fontSize: TrainingsCreateForm.academicYear ? "14px" : "12px",
                  height: "38px",
                  fontFamily: "Satoshi medium",
                  color: TrainingsCreateForm.academicYear ? "black" : "grey",
                }}
              >
                <option value="" disabled className="select">
                  --Select--
                </option>
                {yearList.map((item) => (
                  <option
                    key={item}
                    value={item}
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {item}
                  </option>
                ))}
              </select>
              {isSubmitted && !TrainingsCreateForm.academicYear && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4 mt-2">
              <label className="labelinput">Title of the Programme</label>
              <input
                type="text"
                className="form-control labelinput inputWidth"
                placeholder="Enter Title of the programme"
                value={TrainingsCreateForm.programTitle}
                onChange={handleChange}
                name="programTitle"
              />
              {isSubmitted && !TrainingsCreateForm.programTitle && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 mt-2">
              <label className="labelinput">Acted as</label>
              {/* <input
                type="text"
                className="form-control mt-2 labelinput"
                placeholder="Acted as"
                value={TrainingsCreateForm.actedAs}
                onChange={handleChange}
                name="actedAs"
            /> */}
              <select
                className="form-select labelFont inputWidth" 
                value={TrainingsCreateForm.actedAs}
                onChange={handleChange}
                name="actedAs"
                style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
              >
                <option value="">--Select--</option>
                <option value="Convener">Convener</option>
                <option value="Co-ordinator">Co-ordinator</option>
                <option value="Committee Member">Committee Member</option>
                <option value="Speaker / Resource Person">
                  Speaker / Resource person
                </option>
              </select>
              {isSubmitted && !TrainingsCreateForm.actedAs && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 mt-2">
              <label className="labelinput">Organization (AICTE/AU..)</label>
              <input
                type="text"
                className="form-control labelinput inputWidth"
                placeholder="Enter Organization"
                value={TrainingsCreateForm.organization}
                onChange={handleChange}
                name="organization"
              />
              {isSubmitted && !TrainingsCreateForm.organization && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 col-md-9 col-lg-4 mt-2">
              <label className="labelinput">Choose the type</label>
              <div
                className="d-flex"
                style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
              >
                <div className="form-check d-flex align-items-center me-4 labelinput">
                  <input
                    type="radio"
                    className="form-check-input me-2 mb-1"
                    name="values"
                    value="FDPS"
                    checked={TrainingsCreateForm.values === "FDPS"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label className="form-check-label" htmlFor="FDPS">
                    FDPS
                  </label>
                </div>
                <div className="form-check d-flex align-items-center me-4 labelinput">
                  <input
                    type="radio"
                    className="form-check-input me-2 mb-1"
                    name="values"
                    value="Training"
                    checked={TrainingsCreateForm.values === "Training"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label className="form-check-label" htmlFor="Training">
                    Training
                  </label>
                </div>
                <div className="form-check d-flex align-items-center me-4 labelinput">
                  <input
                    type="radio"
                    className="form-check-input me-2 mb-1"
                    name="values"
                    value="STTPS"
                    checked={TrainingsCreateForm.values === "STTPS"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label className="form-check-label" htmlFor="STTPS">
                    STTPS
                  </label>
                </div>
                <div className="form-check d-flex align-items-center me-4 labelinput">
                  <input
                    type="radio"
                    className="form-check-input me-2 mb-1"
                    name="values"
                    value="Workshops"
                    checked={TrainingsCreateForm.values === "Workshops"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label className="form-check-label" htmlFor="Workshops">
                    Workshops
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
          >
            <div className="col-sm-3 col-md-3 col-lg-2 mt-1">
            <div style={{position:'relative'}}>
              <label className="labelinput">From</label>
              <input
                type="date"
                className="form-control labelFont inputWidth"
                placeholder="15 May 2019"
                value={TrainingsCreateForm.startDate}
                max={today}
                onChange={(e) => {
                  handleChange(e);
                  handleToClear(e);
                }}
                name="startDate"
              />
              <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
              </div>
              {isSubmitted && !TrainingsCreateForm.startDate && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
            <div className="col-sm-3 col-md-3 col-lg-2 mt-1">
            <div style={{position:'relative'}}>
              <label className="labelinput">To</label>
              <input
                type="date"
                className="form-control labelFont inputWidth"
                placeholder="15 May 2019"
                value={TrainingsCreateForm.endDate}
                min={TrainingsCreateForm.startDate}
                onChange={handleChange}
                name="endDate"
              />
              <span className="labelinput pb-0 dateplaceholder" style={{position:'absolute',bottom:'16%',padding:'.375rem .75rem'}}>--Select--</span>
              </div>
              {isSubmitted && !TrainingsCreateForm.endDate && (
                <div className="is-invalid">*Required</div>
              )}
            </div>
            <div className="col-sm-4 col-md-4 col-lg-2 mt-1">
              <label className="labelinput">Duration</label>
              {/* <input
                type="text"
                className="form-control mt-2 labelinput"
                placeholder="Enter Duration"
                value={TrainingsCreateForm.duration}
                onChange={handleChange}
                name="duration"
            /> */}
              <div className="d-flex col-md-4 col-3">
                <input
                  type=""
                  className="col-lg-12 col-xl-7 col-md-8 col-sm-10 col-4 durationBox me-2 inputWidth"
                  style={{ fontSize: "16px", fontFamily: "Satoshi medium" }}
                  value={TrainingsCreateForm.year}
                  maxLength={2}
                  onKeyPress={onlyNumber}
                  onChange={handleChange}
                  name="year"
                />{" "}
                <span className="mt-2 me-2">Yrs</span>
                <input
                  type=""
                  className="col-lg-12 col-xl-7 col-md-8 col-sm-10 col-4 durationBox me-2 inputWidth"
                  style={{ fontSize: "16px", fontFamily: "Satoshi medium" }}
                  maxLength={2}
                  onKeyPress={onlyNumber}
                  value={TrainingsCreateForm.month}
                  onChange={handleChange}
                  name="month"
                />
                <span className="mt-2 me-2">Mo</span>
                <input
                  type=""
                  className="col-lg-12 col-xl-7 col-md-8 col-sm-10 col-4 durationBox me-2 inputWidth"
                  style={{ fontSize: "16px", fontFamily: "Satoshi medium" }}
                  maxLength={2}
                  onKeyPress={onlyNumber}
                  value={TrainingsCreateForm.day}
                  onChange={handleChange}
                  name="day"
                />
                <span className="mt-2"> Days</span>
              </div>
              {isSubmitted &&
                !TrainingsCreateForm.year &&
                !TrainingsCreateForm.month &&
                !TrainingsCreateForm.day && (
                  <div className="is-invalid">*Required</div>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-10 col-lg-8 col-xl-4">
              <label className="labelinput mt-2 d-flex justify-content-between">
                <span className="" style={{ color: "#1B1922" }}>
                  Proof Document
                </span>{" "}
                <span className="" style={{ color: "#7784A1" }}>
                  Only pdf/jpg file under 2MB
                </span>
              </label>
              {/* <input
   className="form-control labelFont mt-2"
   type={inputType}
   onChange={e => {handleChange(e); base64ConverterFile(e)}}
   name="uploadFile"
   onClick={handleClick}
   value={inputType === "text" ? TrainingsCreateForm.uploadFile : undefined} 
   /> */}
              <div className="input-group">
                <label className="form-control boxText boxtextellipse browsetext labelinput">
                  {fileVal !== "" ? fileVal : TrainingsCreateForm.uploadFile}
                  <input
                    type="file"
                    id="fileInput2"
                    className="invisible"
                    onChange={(e) => base64ConverterFile(e)}
                  />
                </label>
                <span
                  className="input-group-text lname cursorPointer labelinput"
                  onClick={() => document.getElementById("fileInput2")?.click()}
                >
                  Select File
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default TrainingsEdit;
