  import React, { useState , ChangeEvent, FormEvent,useEffect } from "react";
  import './AddNew.css'
  import { Link,useNavigate } from "react-router-dom";
  import back from '../../../../../assets/back.png'
  import { sponsoredCreate ,AcademicYear} from "../../../../../ApiServices";
  import { ToastContainer , toast  } from "react-toastify";
  import yearList from "../../../../../YearList.json" 


  function SponsoredResearchCreate() {
      const navigate = useNavigate();
      
      const [SponsoredResearchCreateForm, setSponsoredResearchCreate] = useState({
          academicYear:'',
          category:'',
          agency:'',
          amountSanction:'',
          completionPercentage:'',
          fileUpload:'',
          // tittle:''
      })
      const [isButtonClicked, setIsButtonClicked] = useState(false);
      const [isSubmitted, setIsSubmitted] = useState(false);
      const [showModal, setShowModal] = useState(false);
      const [uploadFiles, setUploadFiles] = useState<string>('');
      const [fileVal, setFileVal] = useState<string>('');
      const [toggleSubMod , setToggleSubMod] = useState(false)
      const [completionError, setCompletionError] = useState(false); 
      const prefix = "@"; 
    
      
      const handleSave=(e: FormEvent<HTMLButtonElement> )=>{
        e.preventDefault();
    if (completionError) {
        return; 
    }
        console.log("toggled")
        CreateHonour(e,"Yet to submit")
        setToggleSubMod(false)
      }
    
      const handleSubmit=(e: FormEvent<HTMLButtonElement> )=>{
        
        if(validation()===true){
          e.preventDefault();
          if (completionError) {
              return; 
          }
          if (!toggleSubMod) {
            setToggleSubMod(true);
          } else {
            setToggleSubMod(false);
            CreateHonour(e,"Pending")
          }
        }else {}
      }

      const validation =()=>{
        setIsSubmitted(true);
        if (
          !SponsoredResearchCreateForm.academicYear ||
          // !SponsoredResearchCreateForm.tittle ||
          !SponsoredResearchCreateForm.category ||
          !SponsoredResearchCreateForm.agency ||
          !SponsoredResearchCreateForm.amountSanction ||
          !SponsoredResearchCreateForm.completionPercentage ||
       
SponsoredResearchCreateForm.amountSanction === "0"
        ) {
          console.log("All fields are required");
          return;
        }else return true
      }
      
      const CreateHonour= (e: FormEvent<HTMLButtonElement> , status:any) =>{
          e.preventDefault();
          setIsSubmitted(true);
          if (
              !SponsoredResearchCreateForm.academicYear ||
              // !SponsoredResearchCreateForm.tittle ||
              !SponsoredResearchCreateForm.category ||
              !SponsoredResearchCreateForm.agency ||
              !SponsoredResearchCreateForm.amountSanction ||
              !SponsoredResearchCreateForm.completionPercentage ||
           
SponsoredResearchCreateForm.amountSanction === "0"
 
            ) {
              console.log("All fields are required");
              return;
            }
          const payload = {
            empId: sessionStorage.getItem('empId'),
            academicYear: SponsoredResearchCreateForm.academicYear,
            createdBy:"",
            // tittle:SponsoredResearchCreateForm.tittle,
            category: SponsoredResearchCreateForm.category,
            agency: SponsoredResearchCreateForm.agency,
            amountSanction: SponsoredResearchCreateForm.amountSanction,
            completionPercentage: SponsoredResearchCreateForm.completionPercentage,
            fileUpload: uploadFiles,
            status:status
          };
          console.log(payload,"honourCreate");
          sponsoredCreate(payload)
            .then((res) => {
              setIsButtonClicked(true)
            // alert(res.data.status);
            navigate("/faculty/secondsidebar/proficiency/sponsoredresearch")
            setTimeout(()=>{
              toast.success(res.data.status);
            } , 200)
            })
            .catch((err) => {
              console.log(err);
            });
            console.log(payload)
        };
        const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
          const { name, value } = e.target;
          let updatedValue = value;
        
          if (name === "completionPercentage") {
            if (parseInt(value) > 100) {
              updatedValue = "100";
            }
            setCompletionError(parseInt(updatedValue) > 100);
          }
        
          if (name === "amountSanction") {
            // Remove leading zeros
            updatedValue = value.replace(/^0+/, '');
        
            // Ensure the value is a valid number and has a maximum length of 6 digits
            if (/^\d*$/.test(updatedValue) && updatedValue.length <= 6) {
              setSponsoredResearchCreate({
                ...SponsoredResearchCreateForm,
                [name]: updatedValue,
              });
            }
          } else {
            // Capitalize each word if not for amountSanction or completionPercentage
            if (value.length > 0) {
              var words = value.split(' ');
              for (var i = 0; i < words.length; i++) {
                words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
              }
              updatedValue = words.join(' ');
            }
            setSponsoredResearchCreate({
              ...SponsoredResearchCreateForm,
              [name]: updatedValue,
            });
          }
        };
        
    
        // const handleChangePercentage =(e:any)=>{
        //   const { name, value } = e.target;
        //   const trimmedValue = value.replace(/^\s+/,"");
        //   let updatedValue = value;
        //   if (value.length > 0) {
        //     var words = value.split(' ');
        //     for (var i = 0; i < words.length; i++) {
        //         words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        //     }
        //     updatedValue = words.join(' ');
        // }
        //     setSponsoredResearchCreate({
        //       ...SponsoredResearchCreateForm,
        //       [e.target.name]:e.target.value,
        //       [name]: updatedValue 
        //   })
        //   }
      // const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      //     const { name, value } = event.target;
      //     setSponsoredResearchCreateForm({ ...SponsoredResearchCreateForm, [name]: value });
      // };
    
      const onlyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;     
        if (!/^[0-9]$/.test(key)) {
          e.preventDefault();
        }
      };

      const handleBackButtonClick = () => { 
        setShowModal(true); 
          };
          var base64CodePhoto = "";
          const base64ConverterFile = (e:any) => {
            const files = e.target.files;
            const file = files[0];
            if (file.size > 2 * 1024 * 1024) {
              toast.error('File size exceeds 2 MB limit. Please upload a smaller file.');
            }else{
            if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
              toast.error('File format not supported. Please try uploading PDF or JPG files.');
              return;
            }  else{
              getBase64(file);
              handleChange(e);
              setFileVal(file.name);
            }
          }
        }
          const onLoad = (fileString :any) =>{
          base64CodePhoto = fileString;  
          setUploadFiles(fileString)
          }
          const getBase64 = (file:any) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>{
              onLoad(reader.result);
            }
          }   
          const [academicYears,setAcademicYear] = useState([]);
          interface AcademicYear {
            id: number;
            academicYear: string;
            term: number;
            startDate: string;
            endDate: string;
          }
          const acad = ()=>{
            AcademicYear()
            .then((res =>{
              console.log(res.data.data);
              const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
                index === self.findIndex((t:any) => (
                  t.academicYear === item.academicYear
                ))
              );
              setAcademicYear(uniqueAcademicYears)
            })) 
          }
          const academicYearFromDb: AcademicYear[] = academicYears as AcademicYear[];
          useEffect(()=>{
            acad()
            },[]) 
    return (
      <>
      <ToastContainer/>

<div className="modal fade" id="myModal" tabIndex={-1}>
        <div className="modal-dialog modalposition modal-sm">
          <div className="modal-content">
            <div className="text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-flex py-2 justify-content-center">
        <h5 className="modal-title submitHead">Unsaved Changes</h5>
      </div>
      <div className="d-flex justify-content-center">
           <p className='fontModel text-center px-5 mb-0'>Are you sure you want to discard the changes? Your changes will be lost.</p>
           </div>
           <div className="modal-footer row justify-content-center pb-5">
           <button
                type="button"
                className="btn cancelBtn "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btnBack ms-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setShowModal(false);
                  navigate("/faculty/secondsidebar/proficiency/sponsoredresearch"); 
                }}
              >
                Yes, discard
              </button>
              </div>
            </div>
        </div>
      </div>
      <div className="">
      <div className="row">
      <div className="col-md-12 col-lg-5 col-12">
      <div className="mainheader">Add New Ongoing Sponsored Research Project</div>
      <div className="greyfont mt-2">New Ongoing Sponsored Research Project</div>
      </div>
      <div className="col-md-12 col-lg-7 col-12">
      <div className="float-end">
      {/* <Link to="/faculty/secondsidebar/proficiency/sponsoredresearch" className="link"> */}
          <span className='bacdept cursorPointer labelinput'
          
          >
            <span
              onClick={handleBackButtonClick}
              data-bs-toggle="modal"
              data-bs-target="#myModal"
            >
                <img src={back} className="img-fluid me-2 " />
              Back to Sponsored Research
            </span>
              <button  className=" ms-3 saveBtn"style={{ color: 'white' }} onClick={handleSave} disabled={isButtonClicked}>Save</button>
              <button  className=" ms-3 addnewbtn"style={{ color: 'white' }} onClick={handleSubmit} disabled={isButtonClicked}>Save & submit</button>
            
              {toggleSubMod && (
                        <>
                        <div className="modal fade show" tabIndex={-1} style={{ display: "block" }}>
              <div className="modal-dialog modal-sm modal-dialog-centered">
              <div className="modal-content mt-2 mb-2">
                <div className="d-flex justify-content-end ">
              <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => {
                      setToggleSubMod(false);
                    }}
                  ></button>
                  </div>
                <div className="modal-header d-flex justify-content-center">
                  <h5 className="modal-title submitHead">Submit for validation</h5> 
                </div>
                <div className="">
                  <p className="fontModel items-center px-5">
                    This entry will be sent to admin for validation. Click 'Save' if you don't want to submit now.
                  </p>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn bg-white text-black cancelBtn"
                    // onClick={() => setToggleSubMod(false)}
                    onClick={handleSave}
                  >
                    Save  
                  </button>
                  <button
                    type="button"
                    className="btn btnBack"
                    onClick={handleSubmit}
                  >
                  Save & Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          {toggleSubMod && <div className="modal-backdrop fade show"></div> }
                        </>
                      )
                    }
            
              </span>
              {/* </Link>            */}
  </div>        
      </div>
  </div>
          <form className="mt-2">
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
          <label className="labelinput">Academic Year</label>
          <select
              className="form-select labelinput inputWidth"
              value={SponsoredResearchCreateForm.academicYear}
              onChange={handleChange}
              name="academicYear"
              style={{
                fontSize: SponsoredResearchCreateForm.academicYear ? '14px' : '12px',
                height: "38px",
                fontFamily: 'Satoshi medium',
                color: SponsoredResearchCreateForm.academicYear ? 'black' : 'grey', 
              }}
        >
            <option value="" disabled className="select">--Select--</option>
            {yearList.map((item) => (
      <option key={item} value={item} style={{ color: "black", fontSize: "14px" }}>
        {item}
      </option>
    ))}
            </select>
          {isSubmitted && !SponsoredResearchCreateForm.academicYear && (
                  <div className="is-invalid">*Required</div>
                )}
      </div>
    
      <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
          <label className="labelinput">Category</label>
          <input
              type="text"
              className="form-control labelinput inputWidth"
              placeholder="Enter Category"
              value={SponsoredResearchCreateForm.category}
              onChange={handleChange}
              name="category"
          />
          {/* <select
            className="form-select mt-2 labelinput"
            value={SponsoredResearchCreateForm.category}
            onChange={handleChange}
            name="category"
            style={{
              fontSize: SponsoredResearchCreateForm.category ? '14px' : '12px',
              height: "38px",
              fontFamily: 'Satoshi medium',
              color: SponsoredResearchCreateForm.category ? 'black' : 'grey', 
            }}
          >
              <option value="" disabled className="select">--Select--</option>
              <option value="Minor" style={{color:"black" ,fontSize:"14px"}}>Minor</option>
              <option value="Major" style={{color:"black" ,fontSize:"14px"}}>Major</option>
          </select> */}
          {isSubmitted && !SponsoredResearchCreateForm.category && (
                  <div className="is-invalid">*Required</div>
                )}
      </div>
      <div className="col-sm-4 col-md-4 col-lg-6 col-xl-4 mt-2">
          <label className="labelinput">Agency</label>
          <input
              type="text"
              className="form-control labelinput inputWidth"
              placeholder="Enter Agency"
              value={SponsoredResearchCreateForm.agency}
              onChange={handleChange}
              name="agency"
          />
          {isSubmitted && !SponsoredResearchCreateForm.agency && (
                  <div className="is-invalid">*Required</div>
                )}
      </div>
      </div>
      
  <div className="row mt-2">
  {/* <div className="col-sm-4 col-md-4 col-lg-4">
          <label className="labelinput">Title</label>
          <input
              type="text"
              className="form-control mt-2 labelinput"
              placeholder="Enter Title"
              value={SponsoredResearchCreateForm.tittle}
              onChange={handleChange}
              name="tittle"
          />
          {isSubmitted && !SponsoredResearchCreateForm.tittle && (
                  <div className="is-invalid">*Required</div>
                )}
      </div> */}
  <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
      <label className="labelinput">Sanctioned Amount (INR)</label>
      <div className="input-container currency">
          <span className="currency-symbol" style={{color:"#7784A1"}}>₹</span>
          <input
        type="text"
        className="form-control labelinput rupee inputWidth"
        placeholder=""
        value={SponsoredResearchCreateForm.amountSanction === '0' ? '' : SponsoredResearchCreateForm.amountSanction}
        onChange={handleChange}
        name="amountSanction"
        onKeyPress={onlyNumber}
        maxLength={6}
      />
      </div>
      {(!SponsoredResearchCreateForm.amountSanction || SponsoredResearchCreateForm.amountSanction === "0") && isSubmitted && (
          <div className="is-invalid">*Required</div>
      )}
  </div>
  <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                              <label className="labelinput">Completion Percentage</label>
                              <div className="input-container"> <input
                                  type="text"
                                  className="form-control labelinput inputWidth"
                                  placeholder=""
                                  value={SponsoredResearchCreateForm.completionPercentage}
                                  onChange={handleChange}
                                  name="completionPercentage"
                                  onKeyPress={onlyNumber}
                                  maxLength={4}
                              />
                <span className="percentage-sign">%</span>
                </div>
                              {completionError 
    ? <div className="is-invalid">Completion percentage cannot exceed 100</div>
    : isSubmitted && !SponsoredResearchCreateForm.completionPercentage 
      ? <div className="is-invalid">*Required</div>
      : null
  }
                          </div>
      
      <div className="col-sm-4 col-md-6 col-lg-6 col-xl-4 mt-3">
        <div className="d-flex justify-content-between">
        <label className="labelinput">Sanctioned Letter</label>
        <span className="ms-1 justify-content-end" style={{ color: '#7784A1',font: '12px satoshi medium'}}>Only pdf/jpg file under 2MB</span>
        </div>
      {/* <input
      type="file"
      className="form-control labelinput"
      value={SponsoredResearchCreateForm.fileUpload}
      onChange={e => {base64ConverterFile(e)}}
      name="fileUpload"
      /> */}
      <div className="input-group">
    <label className="form-control boxText boxtextellipse browsetext labelinput">
    {fileVal}
      <input
        type="file"
        id="fileInput2"
        className="invisible"
        onChange={(e) => base64ConverterFile(e)} 
      />
    </label>
    <span className="input-group-text lname cursorPointer labelinput"  onClick={() => document.getElementById("fileInput2")?.click()} >Select File</span>
  </div>
      </div>
  </div>
          </form>
          </div>
  </> 
    )
  }

  export default SponsoredResearchCreate;