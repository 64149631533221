import React, { useEffect, useState } from "react";import './Trainings.css'
import { Link ,useNavigate} from "react-router-dom";
import { trainingsGetTable ,trainingsGetTableData ,DeleteTabledata,fileDownload ,TableSubmitCall,host} from '../../../../ApiServices';
import DynamicTables from "../../../../components/DynamicTable/DynamicTables";
import CourseTable from "../../../../components/CourseDetailTable/CourseTable";
import { MdCancel } from "react-icons/md";
import searchIcon from "../../../../assets/Icon search.svg"
import { ToastContainer, toast } from "react-toastify";
import { format as formatDate } from "date-fns";
import { format, parseISO } from 'date-fns';
import close from "../../../../assets/close.svg";
import noRecord from "../../../../assets/norecords.png"
import { SkeletonLoader } from "../../../skeletonLoader";
import { NoData } from "../../../NoData";

function Trainings() {
  const navigate = useNavigate();
  const columnRedirectsOnlineCourse = {
    courseTitle:"/proficiency/responsibilities/newResponsiblities/new-add",
  };
  const [tableDatas, settableDatas] = useState([]);
  const [currentPage,setCurrentPage] = useState(1)
  const [TotalElements , setTotalElements] = useState(0)
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalpages,settotalpages] = useState(0);
  const [sort , setSort] = useState(false);
  const [sortusing,setSortUsing]= useState("trainId") 
  const [selectedItem, setselectedItem] = useState<any>([])
  const [viewTitle,setViewTitle] = useState();
  const [viewSingle, setViewSingle] = useState(false)
  const [pubIdView,setPubIdView] = useState();
  const [period,setperiod]  = useState<string>('');
  const [createdOn , setCreatedOn] = useState<string>('');
  const [updatedOn , setUpdatedOn] = useState<string>('');
  const [viewType, setViewType] = useState();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [getData, setGetData] = useState(); 
  const [loader , setLoader] = useState(true)  

    
  const toggleSearch = () => {
setIsSearching(!isSearching);
setSearchTerm("");
setIsSearchOpen(!isSearchOpen);
if (!isSearchOpen) {
  setSearchTerm('');
}
};
const handleCancel = () => {
setSearchTerm('');
setIsSearchOpen(false);
};

    const handleNextPage=()=>{
      setCurrentPage((prevPage)=>Math.min(prevPage+1,totalpages))
      console.log("next button clicked and the value now is "+ currentPage)
      }
    
     const handlePrevPage=()=>{
     setCurrentPage((prevPage)=>Math.max(prevPage-1,1))
     }
    
     const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
       setCurrentPage(value);
     };

     const handleSort =()=>{
      setSortUsing("academicYear")
      setSort(!sort);
    }

    const sortOrder = sort? "asc":"desc"

    const getDatas = () => {
      const data = {
        empID:sessionStorage.getItem('empId'),  
        page:currentPage-1,  
        sort:sortOrder,  
        sordId:sortusing,  
        keyword:searchTerm, 
      }
        trainingsGetTable(data)
     .then((res) => {
      const formattedData = res.data.datas.content.map((item: any) => {
        let formattedCreatedDate = "";
        let formattedUpdatedDate = "";
        let formattedSubmittedOn = ""
        let formattedValidatedOn = ""
      
        if (item.createdOn) {
          const createdOnDate = new Date(item.createdOn);
          formattedCreatedDate = formatDate(createdOnDate, "dd MMM yyyy hh:mm a");
        }
      
        if (item.updatedOn) {
          const updatedOnDate = new Date(item.updatedOn);
          formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
        }

        if(item.submittedOn){
          const submittedOnDate = new Date(item.submittedOn)
          formattedSubmittedOn = formatDate(submittedOnDate, "dd MMM yyyy hh:mm a")
        }

        if(item.validatedOn){
          const validatedOnDate = new Date(item.validatedOn)
          formattedValidatedOn = formatDate(validatedOnDate, "dd MMM yyyy hh:mm a")
        }

      
        return {
          ...item,
          createdOn: formattedCreatedDate,
          updatedOn: formattedUpdatedDate,
          submittedOn:formattedSubmittedOn,
          validatedOn:formattedValidatedOn
        };
      });
      setGetData(res.data.data[0]);
       settableDatas(formattedData);
       setLoader(false)
       console.log(res.data.datas.content, "");
       setTotalElements(res.data.datas.totalElements)
       settotalpages(res.data.datas.totalPages)
     })
     .catch((err) => {
       console.log(err);
     });
 };
 
 const Header = [
   { label: "Academic Year", key: "academicYear" },
   { label: "Title of the programme", key: "programTitle" },
   { label: "Type", key: "values" },
   { label: "Submitted on", key: "submittedOn" },
   { label: "Status", key: "status" },
   { label: "Validated on", key: "validatedOn" },
 ];

 const TrainingViewHeaders=[
  {
    "title": "Academic Year",
    "key":"academicYear"
  },
  { title: "Type",
     key: "values"
  },{
    "title": "Title of the programme",
    "key":"programTitle"
  }, {
    "title": "Organization (AICTE/AU..)",
    "key":"organization"
  }, {
    "title": "Acted as",
    "key":"actedAs"
  }, {
    "title": "Duration",
    "key":"duration"
  }, {
    "title": "Period",
    "key":"period"
  }, {
    "title": "Proof Document",
    "key":"uploadFile"
  }
]

function navigateView(item: any){
  trainingsGetTableData(item.trainId).then((res:any)=>{
    setselectedItem(res.data.data[0]);
    setViewSingle(true);
    setViewTitle(res.data.data[0].proficiency.programTitle);
    setPubIdView(res.data.data[0].proficiency.trainId);
    setViewType(res.data.data[0].proficiency.values)
    setperiod(format(parseISO(res.data.data[0].proficiency.startDate), 'dd MMM yyyy') + " to " + format(parseISO(res.data.data[0].proficiency.endDate), 'dd MMM yyyy'))
    const formattedCreatedDate = new Date(res.data.data[0].createdOn);
    const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");
    let updatedOn = "";
    if (res.data.data[0].updatedOn !== null) {
        const formattedUpdatedDate = new Date(res.data.data[0].updatedOn);
        updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
    }         
    setCreatedOn(createdOn);
    setUpdatedOn(updatedOn);
  }).catch((err:any)=>console.log(err))

}

function editCourse(item:any){
  console.log(item,"ssbiki")
  const data = item.trainId?item.trainId:item.proficiency.trainId;
  console.log(data , "am the data ");
  navigate("editTrainings/"+data)
}

const deleteApi=(data: any) =>{
  const url = "/trainings?trainId=" + data.trainId;
  DeleteTabledata(url)
    .then((res) => {
      console.log(res.data.status);
      toast.success(res.data.status);
      getDatas()
    })
    .catch((err) => {
      console.log(err)
    });
}
const deleteApiView=() =>{
  const url = "/trainings?trainId=" + pubIdView;
  DeleteTabledata(url)
    .then((res) => {
      console.log(res.data.status);        
      toast.success(res.data.status);
      setViewSingle(false) 
      // alert(res.data.status);
      getDatas()
    })
    .catch((err) => {
      console.log(err)
    });
}
const FileDownload = () => {
  const url = host+"proficiency/get/trainings?trainId=" + pubIdView + "&keyword=file";
  fileDownload(url)
    .then((res) => {             
      toast.success(res.data.status);
      window.open(url, '_blank');
      getDatas();
    })
    .catch((err) => {
      console.log(err);
    });
}


 const backtoview = "to Trainings";
 const backlink = "trainings"
 const deleteCont = viewType === "Workshops" ? "workshop" : viewType
 const heading = viewTitle
 const subheading = viewType === "Workshops" ? "View details of workshop" : "View details of " + viewType

//  Function to navigate back to the view specified in `backtoview`
 const navigateBackToView = () => {
  navigate(`/faculty/secondsidebar/proficiency/${backtoview}`);
 };

 const TableSubmit =(item:any)=>{
  console.log("tablesumbit toggled ")
  const payload = {
    empId:sessionStorage.getItem('empId'),
    sectionId:item.trainId,
    status :"Pending",
    section:"Trainings",
   academicYear:item.academicYear
}
console.log(payload,"hkjgkjgh");

TableSubmitCall(payload)
.then((res)=>{
  console.log(res)
  getDatas()
  toast.success(res.data.responseMessage)
})
.catch((err)=>{
  console.log(err)
})
console.log(payload , "am the payload")
// window.location.reload()
setViewSingle(false)
}

useEffect(() => {
  getDatas();
}, [currentPage,sort,searchTerm]);

const moduleName = "Training"
  const AddNewPath = "/faculty/secondsidebar/proficiency/trainings/trainingscreate"


  return (
    <>
    <ToastContainer/>
{!viewSingle?
<>
<div className="">
            {/* <div className='position-relative  d-flex justify-content-between align-items-center'>
            <p className="mt-3">Trainings<br></br>Training Activities / STTPS / Workshops conducted as Convener / Co-ordinator etc.</p>
            <Link to="/faculty/secondsidebar/proficiency/trainingscreate" className="btn btn-primary CreateBtn">Add New</Link>
            </div> */}
             <div className="row">
             <div className="col-md-12 col-lg-7 col-12">
            <div className="mainheader">Trainings</div>
            <div className="greyfont mt-2">FDPS / Training Activities / STTPS / Workshops conducted as Convener / Co-ordinator etc.</div>
            </div>
            <div className="col-md-12 col-lg-5 col-12 AddNewBtnDiv">
            {getData === true && (

            <div className="float-end">
            <Link to="/faculty/secondsidebar/proficiency/trainings/trainingscreate" className="link">
                <span className='bacdept cursorPointer  '>
                <button  className=" ms-3 addnewbtn CreateBtn" >Add New</button>
                    </span>
                    </Link>           
      </div>  )}      
            </div>
            
            </div>
            <div className="">
                {/* <ul className="nav mt-4">
                    <li className="nav-item">
                        <span className="active tablink pb-1 bg addresult">
                            All Results
                        </span>

                    </li>

                </ul> */}
                {getData === true && (
                 <div className="addresult mt-4">
        <span className="active tablink pb-1 bg addresult mb-3">All Results</span>
        <div className={`${isSearchOpen ? 'me-0' : 'me-5'} search`} style={{boxShadow:'none'}}>
  <img
    alt="search"
    // className="fasearch mb-2"
    className={`${isSearchOpen ? 'ms-0' : 'ms-3'} fasearch mb-2`}
    src={searchIcon}
    onClick={toggleSearch}
    style={{cursor:'pointer'}}
  />
  {isSearchOpen && (
    <div className="search-input-container">
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
      />
      <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }}/>
    </div>
  )}
</div>
      </div>
                )}
      {/* {getData === false ? (
                    <div className="d-flex flex-column align-items-center" style={{ marginTop: "200px" }}>
                      <img src={noRecord} alt="No records" />
                      <p className="mt-4 greyfont" style={{ textAlign: 'center' }}>
                        You haven't added any trainings yet.<br />Start by adding a training.
                      </p>
                      <Link to="/faculty/secondsidebar/proficiency/trainings/trainingscreate">
                        <button className="text-white addnewbtn">Add New</button>
                      </Link>
                    </div>
                  ) : (
      <DynamicTables data={tableDatas} headers={Header}  currentPage={currentPage} pages={totalpages} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} navigateView={navigateView} editCourse={editCourse} deleteApi={deleteApi} TableSubmit={TableSubmit}/>
                  )} */}

                  {loader ?
                  <SkeletonLoader/> :
                  getData ?
                  <DynamicTables data={tableDatas} headers={Header}  currentPage={currentPage} pages={totalpages} columnRedirects={columnRedirectsOnlineCourse} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} TotalElements={TotalElements} handleChangePage={handleChangePage} handleSort={handleSort} navigateView={navigateView} editCourse={editCourse} deleteApi={deleteApi} TableSubmit={TableSubmit}/>
                  :
                  <NoData name={moduleName} path={AddNewPath}/>
                }
      </div>
        </div>
</>:
<CourseTable 
data={[selectedItem]}
backlink={backlink}
 backtoview={backtoview} 
 deleteCont={deleteCont}
 deleteApiView = {deleteApiView}
 FileDownload = {FileDownload}
 period = {period}
back={()=>{setViewSingle(!viewSingle)}} 
createdOn = {createdOn}
updatedOn = {updatedOn}
// back={navigateBackToView}
heading = {heading}
subheading = {subheading}
headers={TrainingViewHeaders}
TableSubmit={TableSubmit}
editCourse={editCourse}
/>
}
       
    </>
  )
}

export default Trainings