import React, { useEffect, useState } from "react";
// import DynamicTables from "../../../../components/DynamicTable/DynamicTable";
import DynamicTables from "../../../../components/DynamicTable/DynamicTables";
import searchIcon from "../../../../assets/Icon search.svg"
import "../../../FacultyModule/Proficiency/OnlineCourse/OnlineCourse.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import cross from "../../../../assets/crossimg.png";
import { MdCancel } from "react-icons/md";
import { DeleteTabledata, OnlineCourseGetTable,fileDownload, OnlineCourseGetTableData ,TableSubmitCall,host } from "../../../../ApiServices";
import CourseTable from "../../../../components/CourseDetailTable/CourseTable";
import { ToastContainer , toast  } from "react-toastify";
import { format as formatDate } from "date-fns";
import close from "../../../../assets/close.svg";
import noRecord from "../../../../assets/norecords.png"
import { format, parseISO } from 'date-fns';
import { SkeletonLoader } from "../../../skeletonLoader";
import { NoData } from "../../../NoData";

function OnlineCourse() {
  const navigate = useNavigate();
  const columnRedirectsOnlineCourse = {
    courseTitle:
      "/faculty/secondsidebar/proficiency/onlinecourses/courseDetailsTable",
  };

  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalElements, setTotalElements] = useState(0);
  const [totalpages, settotalpages] = useState(0);
  const [sort, setSort] = useState(false);
  const [sortusing, setSortUsing] = useState("coId");
  const [tableDatas, settableDatas] = useState([]);
  const [selectedItem, setselectedItem] = useState<any>([])
  const [viewSingle, setViewSingle] = useState(false)
  const [viewTitle,setViewTitle] = useState();
  const [coIdView,setCoIdView] = useState();
  const [createdOn , setCreatedOn] = useState<string>('');
    const [updatedOn , setUpdatedOn] = useState<string>('');
  const heading = viewTitle
  const subheading = "View details of online certification course"
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [period,setperiod]  = useState<string>(''); 
  const [getData, setGetData] = useState();  
  const [loader , setLoader] = useState(true)

  const toggleSearch = () => {
setIsSearching(!isSearching);
setSearchTerm("");
setIsSearchOpen(!isSearchOpen);
if (!isSearchOpen) {
  setSearchTerm('');
}
};
const handleCancel = () => {
setSearchTerm('');
setIsSearchOpen(false);
};


  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalpages));
    console.log("next button clicked and the value now is " + currentPage);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleSort = () => {
    setSortUsing("academicYear");
    setSort(!sort);
  };

  const sortOrder = sort ? "asc" : "desc";
  // const sordId = sort? "academicYear":"coId"
  // console.log(sortOrder,"testsort")

  const getDatas = () => {
    const data = {
      empID: sessionStorage.getItem('empId'),
      page: currentPage - 1,
      sort: sortOrder,
      sordId: sortusing,
      keyword: searchTerm,
    };

    OnlineCourseGetTable(data)
      .then((res) => {
        // const formattedData = res.data.datas.content.map((item: any) => {
        //   const createdOnDate = new Date(item.createdOn);
        //   const formattedCreatedDate = formatDate(createdOnDate, "dd MMM yyyy hh:mm a");
  
        //   const updatedOnDate = new Date(item.updatedOn);
        //   const formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
  
        //   return {
        //     ...item,
        //     createdOn: formattedCreatedDate,
        //     updatedOn: formattedUpdatedDate,
        //   };
        // });
        const formattedData = res.data.datas.content.map((item: any) => {
          let formattedCreatedDate = "";
          let formattedUpdatedDate = "";
          let formattedSubmittedOn = ""
          let formattedValidatedOn = ""
        
          if (item.createdOn) {
            const createdOnDate = new Date(item.createdOn);
            formattedCreatedDate = formatDate(createdOnDate, "dd MMM yyyy hh:mm a");
          }
        
          if (item.updatedOn) {
            const updatedOnDate = new Date(item.updatedOn);
            formattedUpdatedDate = formatDate(updatedOnDate, "dd MMM yyyy hh:mm a");
            console.log(formattedCreatedDate,"am the date ")
          }

          if(item.submittedOn){
            const submittedOnDate = new Date(item.submittedOn)
            formattedSubmittedOn = formatDate(submittedOnDate, "dd MMM yyyy hh:mm a")
          }

          if(item.validatedOn){
            const validatedOnDate = new Date(item.validatedOn)
            formattedValidatedOn = formatDate(validatedOnDate, "dd MMM yyyy hh:mm a")
          }
        
          return {
            ...item,
            createdOn: formattedCreatedDate,
            updatedOn: formattedUpdatedDate,
            submittedOn:formattedSubmittedOn,
            validatedOn:formattedValidatedOn
          };
        });
        
        settableDatas(formattedData);
        setLoader(false)
        setGetData(res.data.data[0]);
        console.log(res.data.datas.content.updatedOn, "updated  On" )
        console.log(res.data.datas.content, "gopi");
        setTotalElements(res.data.datas.totalElements);
        settotalpages(res.data.datas.totalPages);      
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Header = [
    // {label:"S.No" , key:"SNo"},
    { label: "Academic Year", key: "academicYear" },
    { label: "Course Title", key: "courseTitle" },
    { label: "Completed on", key: "completionOn" },
    { label: "Submitted on", key: "submittedOn" },
    { label: "Status", key: "status" },
    { label: "Validated on", key: "validatedOn" },
    // {label:"Actions", key:"Actions"},
  ];


  const OnlineCourseViewHeaders=[
    {
      "title": "Academic Year",
      "key":"academicYear"
    },
    {
      "title": "Course Title",
      "key":"courseTitle"
    },
    {
      "title": "Awarding Institution",
      "key":"awardingInstitution"
    },
    {
      "title": "Grade or % of marks",
      "key":"grade"
    },
    {
      "title": "Course Completed on",
      "key":"period"
    },
    {
      "title": "Course duration",
      "key":"courseDuration"
    }, 
     
    {
      "title": "Proof Document", 
      "key":"uploadFile"
    }
  ]

  function navigateView(item: any){
    OnlineCourseGetTableData(item.coId).then((res:any)=>{
      console.log("amtoggled")
      setselectedItem(res.data.data[0]);
      setViewTitle(res.data.data[0].proficiency.courseTitle);
      setCoIdView(res.data.data[0].proficiency.coId)
      setperiod(format(parseISO(res.data.data[0].proficiency.completionOn), 'dd MMM yyyy'));
      const formattedCreatedDate = new Date(res.data.data[0].proficiency.createdOn);
      const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");
      let updatedOn = "";
      if (res.data.data[0].proficiency.updatedOn !== null) {
          const formattedUpdatedDate = new Date(res.data.data[0].proficiency.updatedOn);
          updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
      }         
      setCreatedOn(createdOn);
      setUpdatedOn(updatedOn);
      setViewSingle(true)
    }).catch((err:any)=>console.log(err))
  }
  
  function editCourse(item:any){
    console.log(item,"am the online course item ")
    const data = item.coId?item.coId:item.proficiency.coId;
    console.log(data);
    navigate("editOnlineCourse/"+data)
  }

  const deleteApi=(data: any) =>{
    const url = "/online/courses?coId=" + data.coId;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);
        toast.success(res.data.status);
        // alert(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }
  const deleteApiView=() =>{
    const url = "/online/courses?coId=" + coIdView;
    DeleteTabledata(url)
      .then((res) => {
        console.log(res.data.status);        
        toast.success(res.data.status);
        setViewSingle(false) 
        // alert(res.data.status);
        getDatas()
      })
      .catch((err) => {
        console.log(err)
      });
  }
  const FileDownload = () => {
    const url = host+"proficiency/get/online/courses?coId=" + coIdView + "&keyword=file";
    fileDownload(url)
      .then((res) => {             
        toast.success(res.data.status);
        window.open(url, '_blank');
        getDatas();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const deleteCont = "online course"
  const backtoview ="to Online Course"

  const TableSubmit =(item:any)=>{
    console.log("tablesumbit toggled ")
    const payload = {
      empId:sessionStorage.getItem('empId'),
      sectionId:item.coId,
      status :"Pending",
      section:"Online Courses",
     academicYear:item.academicYear
  }
console.log(payload,"hkjgkjgh");

  TableSubmitCall(payload)
  .then((res)=>{
    console.log(res)
    getDatas()
    toast.success(res.data.responseMessage)
  })
  .catch((err)=>{
    console.log(err)
  })
  console.log(payload , "am the payload")
  // window.location.reload()
  setViewSingle(false)
  }

  useEffect(() => {
    getDatas();
  }, [currentPage,sort,searchTerm]);

  const moduleName = "Online Courses"
  const AddNewPath = "/faculty/secondsidebar/proficiency/onlinecourses/newOnlineCourse"

  return (
    <>
     <ToastContainer />
      {!viewSingle?
      <>
      <div className="mainheader">Online Certification Courses</div>
      <div className="d-flex justify-content-between greytext">
        <span>List of Online Certification Course (NPTEL etc.)</span>
        {getData === true && (
        <Link to="/faculty/secondsidebar/proficiency/onlinecourses/newOnlineCourse">
          <button className="text-white addnewbtn">Add New</button>
        </Link>
        )}
      </div>
      {getData === true && (
      <div className="addresult">
      <span className="active tablink pb-1 bg addresult mb-3">All Results</span>
      <div className={`${isSearchOpen ? 'me-0' : 'me-5'} search`} style={{boxShadow:'none'}}>
  <img
    alt="search"
    // className="fasearch mb-2"
    className={`${isSearchOpen ? 'ms-0' : 'ms-3'} fasearch mb-2`}
    src={searchIcon}
    onClick={toggleSearch}
    style={{cursor:'pointer'}}
  />
  {isSearchOpen && (
    <div className="search-input-container">
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
      />
      <img src={close} alt="" onClick={handleCancel} style={{ position: 'absolute', top: '17px', right: '10px', transform: 'translateY(-50%)', width: '15px', height: '15px', cursor: 'pointer' }}/>
    </div>
  )}
</div>
      </div>
      )}
{/* 
      {getData === false ? (
                    <div className="d-flex flex-column align-items-center" style={{ marginTop: "200px" }}>
                      <img src={noRecord} alt="No records" />
                      <p className="mt-4 greyfont" style={{ textAlign: 'center' }}>
                        You haven't added any online courses yet.<br />Start by adding a online course.
                      </p>
                      <Link to="/faculty/secondsidebar/proficiency/onlinecourses/newOnlineCourse">
                        <button className="text-white addnewbtn">Add New</button>
                      </Link>
                    </div>
                  ) 
                  
                  : (
      <DynamicTables
      editCourse={editCourse}
        currentPage={currentPage}
        pages={totalpages}
        data={tableDatas}
        headers={Header}
        columnRedirects={columnRedirectsOnlineCourse}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        TotalElements={TotalElements}
        handleChangePage={handleChangePage}
        handleSort={handleSort}
        deleteApi={deleteApi}
        navigateView={navigateView}
        TableSubmit={TableSubmit}
      />
    )} */}
    {loader ? 
    <SkeletonLoader/> :
    getData ? 
    <DynamicTables
    editCourse={editCourse}
      currentPage={currentPage}
      pages={totalpages}
      data={tableDatas}
      headers={Header}
      columnRedirects={columnRedirectsOnlineCourse}
      handleNextPage={handleNextPage}
      handlePrevPage={handlePrevPage}
      TotalElements={TotalElements}
      handleChangePage={handleChangePage}
      handleSort={handleSort}
      deleteApi={deleteApi}
      navigateView={navigateView}
      TableSubmit={TableSubmit}
    />
    :
    <NoData name={moduleName} path={AddNewPath}/>
}
  </>          
      :
      <CourseTable data={[selectedItem]} deleteCont={deleteCont} back={()=>{setViewSingle(!viewSingle)}} headers={OnlineCourseViewHeaders} backtoview={backtoview}
      heading = {heading}
      subheading = {subheading}
      deleteApiView={deleteApiView}
      FileDownload = {FileDownload}
      createdOn = {createdOn}
      updatedOn = {updatedOn}
      period = {period}
      TableSubmit={TableSubmit}
      editCourse={editCourse}

      />}
      <Outlet />
    </>
  );
}

export default OnlineCourse;
